import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as RetryIcon } from '../../assets/icons/property/refresh-upload.svg';
import { ReactComponent as RoundExplanationIcon } from '../../assets/icons/round-explanation.svg';
import { ReactComponent as UploadFileIcon } from '../../assets/icons/upload-file.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcons.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { ReactComponent as ViewIcon } from '../../assets/images/hide-password.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { bytesToSize } from '../../helpers/utils';
import { downloadFile } from '../../store/features/downloadSlice';
import { uploadFile } from '../../store/features/fileuploadSlice';
import {
  createQuoteAttachments,
  deleteQuoteAttachment,
  setQuoteAttachments,
  updateQuoteAttachment,
} from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import Checkbox from '../common/checkbox';
import IconContainer from '../common/icon-container';
import InputElement from '../common/input';
import Menu from '../common/menu';
import UpdateAction from '../common/update-action';
import ViewFile from '../view-file/view-file';
import ViewFileImage from '../view-file/view-file-image';
import QuoteFileUploadWrapper from './wrapper/QuoteFileUploadWrapper';

const maxFileSize = 50 * 1024 * 1024; // 50 MB max file size
const imageExtensions = [
  'bmp',
  'git',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg',
  'jpeg',
  'jpg',
  'png',
  'svg',
];

const File = ({
  file,
  fileUploading,
  handleFileSelection,
  handleQuoteUpdateAttachment,
  isChecked,
  onDownloadFile,
  onFileUploadDone,
  setUploadedFiles,
  handleDeletePopup,
  onClosingFailedUpload,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, name, size, file_data, media } = file || {};
  const { format, url, id: mediaId } = media || {};

  const [progress, setProgress] = useState(0);
  const [maxSizeError, setMaxSizeError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [isEdit, setEdit] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [showViewFile, setShowViewFile] = useState(false);
  const [showViewImage, setShowViewImage] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);

  const onUploadFile = async () => {
    if (size > maxFileSize) {
      setMaxSizeError(true);
      return;
    }
    setFileUpload(false);
    setProgress(0);
    dispatch(
      uploadFile({
        file: file_data,
        onSuccess: data =>
          onFileUploadDone({
            ...file,
            media_id: data?.media_id,
            media_url: data?.url,
            created_on: moment().unix(),
          }),
        onError: () => {
          dispatch(addToast({ error: true, text: t('ERROR_WHILE_UPLOADING_FILE', { name: name }), id: nanoid() }));
          setUploadError(true);
        },
        setProgress,
      }),
    );
  };

  const onRetryFile = () => {
    setUploadError(false);
    setProgress(0);
    onUploadFile();
  };

  const onRemoveFile = () => {
    if (maxSizeError || uploadError) {
      onClosingFailedUpload(file);
      return;
    }
    setUploadedFiles(prev => {
      const files = prev.filter(item => item.id !== id);
      dispatch(setQuoteAttachments(files));
      return file;
    });
  };

  const onEditFile = () => {
    if (isEdit == '') {
      dispatch(
        addToast({
          error: true,
          text: t('Please_ADD_NAME_FOR_THE_FILE'),
          id: nanoid(),
        }),
      );
      return;
    }
    handleQuoteUpdateAttachment(file, isEdit);
    setEdit(null);
    setIsEditable(false);
  };

  const onViewFile = file => {
    if (file?.media?.format === 'pdf') {
      setShowViewFile(file);
    } else if (
      file?.media?.type === 'PHOTO' ||
      file?.media?.format === 'jpg' ||
      file?.media?.format === 'jpeg' ||
      file?.media?.format === 'png'
    ) {
      setShowViewImage(file);
    } else {
      dispatch(
        addToast({
          error: true,
          text: file?.media ? t('FORMAT_NOT_SUPPORTED_TO_VIEW') : t('THERE_IS_NO_MEDIA_ATTECHED'),
          id: nanoid(),
        }),
      );
    }
  };

  useEffect(() => {
    if (id && fileUploading && !mediaId) {
      setFileUpload(true);
    }
  }, []);

  useEffect(() => {
    if (fileUpload) {
      onUploadFile();
    }
  }, [fileUpload]);

  return (
    <div>
      <div className="flex items-center gap-2 mb-2">
        <div>
          <Checkbox
            checked={isChecked}
            is_checked_done={true}
            onChange={() => handleFileSelection(file)}
            variant="small"
          />
        </div>
        <div className="border px-4 py-3 radius-1_5 w-full flex gap-3">
          <div className="flex items-center uploaded-file">
            {imageExtensions.includes(format) ? (
              <img
                onDoubleClick={() => imageExtensions.includes(format) && setShowViewImage(true)}
                className={classNames(
                  'radius-1_5',
                  fileUploading || maxSizeError || uploadError ? 'uploading' : 'uploaded',
                )}
                src={url}
              />
            ) : (
              <IconContainer
                Icon={FileIcon}
                iconWidth={fileUploading || maxSizeError || uploadError ? 24 : 12}
                iconHeight={fileUploading || maxSizeError || uploadError ? 24 : 12}
                iconContainerClassname={classNames(fileUploading || maxSizeError ? 'uploading' : 'uploaded')}
              />
            )}
          </div>
          <div className="w-full">
            <div className="flex items-center justify-between">
              {isEditable ? (
                <div className="flex flex-1 items-center justify-between">
                  <InputElement
                    className="flex-1 w-full edit-file-input"
                    value={isEdit}
                    onChange={value => setEdit(value)}
                  />
                  <UpdateAction
                    className="w-fit-content"
                    btnClassname="specified-width"
                    width="70px"
                    onCancel={() => {
                      setEdit(null);
                      setIsEditable(false);
                    }}
                    onUpdate={onEditFile}
                  />
                </div>
              ) : (
                <Fragment>
                  <div className="flex inter-400-text line-height-20 items-center gap-1">
                    <p className="natural-900-text one-line" style={{ maxWidth: '100px' }}>
                      {name}
                    </p>
                    {size && <p className="natural-400-text font-12">{`(${bytesToSize(size)})`}</p>}
                  </div>
                  <div className="flex items-center gap-3">
                    {fileUploading || maxSizeError || uploadError ? (
                      <Fragment>
                        {uploadError && (
                          <IconContainer
                            Icon={RetryIcon}
                            iconHeight={12}
                            iconWidth={12}
                            iconColor="natural_500"
                            backgroundColor="transparent"
                            iconContainerClassname="cursor"
                            onClick={onRetryFile}
                          />
                        )}
                        <IconContainer
                          Icon={CloseIcon}
                          iconHeight={16}
                          iconWidth={16}
                          iconColor="natural_500"
                          backgroundColor="transparent"
                          iconContainerClassname="cursor"
                          onClick={onRemoveFile}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <IconContainer
                          Icon={ViewIcon}
                          iconHeight={20}
                          iconWidth={20}
                          iconColor="natural_500"
                          backgroundColor="transparent"
                          iconContainerClassname="cursor"
                          onClick={() => onViewFile(file)}
                        />
                        <IconContainer
                          Icon={DownloadIcon}
                          iconColor="natural_500"
                          backgroundColor="transparent"
                          iconContainerClassname="cursor"
                          onClick={() => onDownloadFile(file)}
                        />
                        <Menu
                          iconClassName="rotate-90"
                          place="bottom"
                          menuList={[
                            {
                              name: t('EDIT'),
                              onClick: () => {
                                setEdit(name);
                                setIsEditable(true);
                              },
                            },
                            {
                              name: t('DELETE'),
                              onClick: () => handleDeletePopup(file),
                            },
                          ]}
                        />
                      </Fragment>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
            {fileUploading && !maxSizeError && !uploadError ? (
              <div className="flex items-center w-full gap-3 mt-2">
                <div className="w-full progress-container">
                  <div className="h-full progress-bar" style={{ width: `${Math.ceil(progress)}%` }}></div>
                </div>
                <div className="inter-400-text natural-400-text progress-label">{Math.ceil(progress)}%</div>
              </div>
            ) : (
              (maxSizeError || uploadError) && (
                <div className="flex items-center mt-2">
                  <IconContainer
                    Icon={RoundExplanationIcon}
                    iconColor="error_500"
                    iconContainerClassname="mr-1 p-0"
                    backgroundColor="transparent"
                  />
                  <p className="error-text inter-400-text font-12">
                    {maxSizeError ? t('THIS_FILE_TOO_LARGE') : t('ERROR_WHILE_UPLOADING_FILE_TRY_AGAIN')}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {showViewFile && (
        <ViewFile
          close={() => setShowViewFile(null)}
          file={showViewFile}
          selectedDoc={showViewFile}
          downloadPdf={onDownloadFile}
        />
      )}
      {showViewImage && <ViewFileImage close={() => setShowViewImage(null)} file={file} selectedDoc={file} />}
    </div>
  );
};

const QuoteFileHandler = props => {
  const { quoteDetails, setUploadedFiles, setUploadingFiles, uploadedFiles, uploadingFiles } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setModal } = useContext(OrganisationContext);

  const [onFile, setOnFile] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const uploadingFilesCount = uploadingFiles.length;
  const uploadedFilesCount = uploadedFiles.length;

  const onAddFiles = addedFiles => {
    const newFiles = addedFiles?.map(file => ({
      file_data: file,
      name: file.name,
      size: file.size,
      media: {
        format: file.type,
        url: URL.createObjectURL(file),
      },
      id: nanoid(),
    }));
    setUploadingFiles(newFiles);
  };

  const onFileDrop = e => {
    e.preventDefault();
    onAddFiles([...e.dataTransfer?.files]);
    setOnFile(false);
  };

  const onFileAdd = e => {
    e.preventDefault();
    onAddFiles([...e?.target?.files]);
    e.target.value = null;
  };

  const onFileUploadDone = async file => {
    if (!quoteDetails.id) {
      setUploadingFiles(prev => prev.filter(p => p.id !== file.id));
      setUploadedFiles(prev => {
        const files = [...prev, file];
        dispatch(setQuoteAttachments(files));
        return files;
      });
    } else {
      dispatch(
        createQuoteAttachments({
          id: quoteDetails.id,
          request: [{ name: file.name, media: { id: file.media_id } }],
        }),
      ).then(data => {
        setUploadingFiles(prev => prev.filter(p => p.id !== file.id));
        setUploadedFiles(prev => {
          const files = [...prev, ...data];
          dispatch(setQuoteAttachments(files));
          return files;
        });
      });
    }
  };

  const onClosingFailedUpload = file => {
    setUploadingFiles(prev => prev.filter(p => p.id !== file.id));
  };

  const onDownloadFile = async file => {
    const { media } = file || {};
    if (media?.url) {
      dispatch(
        downloadFile({
          url: media?.url,
          filename: file?.name,
        }),
      );
    } else {
      dispatch(addToast({ error: true, text: t('THERE_IS_NO_MEDIA_ATTECHED'), id: nanoid() }));
    }
  };

  const handleFileSelection = file => {
    const isChecked = selectedAttachments.find(f => f.id === file.id);
    if (isChecked) {
      setSelectedAttachments(prev => prev.filter(f => f.id !== file.id));
    } else {
      setSelectedAttachments(prev => [...prev, file]);
    }
  };

  const handleQuoteUpdateAttachment = (attachment, name) => {
    if (!quoteDetails?.id) {
      const updatedFiles = uploadedFiles.map(p => (p.id === attachment.id ? { ...p, name } : p));
      dispatch(setQuoteAttachments(updatedFiles));
      setUploadedFiles(updatedFiles);
    } else {
      dispatch(
        updateQuoteAttachment({ quote_id: quoteDetails.id, attachment_id: attachment.id, request: { name } }),
      ).then(() => {
        const updatedFiles = uploadedFiles.map(p => (p.id === attachment.id ? { ...p, name } : p));
        dispatch(setQuoteAttachments(updatedFiles));
        setUploadedFiles(updatedFiles);
      });
    }
  };

  const handleDeletePopup = file => {
    setModal({
      type: 'jobfiles-action',
      content: {
        isEnable: true,
        fileData: file ? [file] : selectedAttachments,
        imageExtensions: imageExtensions,
        displayFile: true,
        isMulti: true,
        onDelete: () => handleDeleteQuoteAttachment(file ? [file] : []),
        title: `${t('DELETE_THESE_FILES')}`,
        description: t('UPLOAD_AT_ANYTIME'),
      },
    });
  };

  const handleDeleteQuoteAttachment = async files => {
    const filesForDelete = files?.length > 0 ? files : selectedAttachments;
    quoteDetails?.id &&
      (await Promise.all(
        filesForDelete.map(({ id }) => {
          dispatch(deleteQuoteAttachment({ quote_id: quoteDetails.id, attachment_id: id }));
        }),
      ));
    const updatedFiles = [];
    uploadedFiles.forEach(uf => {
      if (!filesForDelete.find(f => f.id === uf.id)) {
        updatedFiles.push(uf);
      }
    });
    dispatch(setQuoteAttachments(updatedFiles));
    setSelectedAttachments([]);
    setUploadedFiles(updatedFiles);
  };

  return (
    <QuoteFileUploadWrapper className="flex-column row-gap-6 overflow-hidden">
      <div className="mb-2 flex-1 p-6 overflow-auto custom-scrollbar thin-scrollbar">
        <div
          className={classNames(
            'py-10 border-dotted radius-1_5 flex-column items-center justify-center mb-6',
            onFile ? 'on-droparea' : 'off-droparea',
          )}
          onDragLeave={e => {
            e.preventDefault();
            setOnFile(false);
          }}
          onDragOver={e => {
            e.preventDefault();
            setOnFile(true);
          }}
          onDrop={onFileDrop}>
          <IconContainer
            iconContainerClassname="mr-2"
            Icon={UploadFileIcon}
            iconColor="natural_300"
            backgroundColor="transparent"
            iconWidth={52}
            iconHeight={52}
          />
          <div className="flex font-16 inter-400-text mt-4">
            <span className="natural-700-text mr-1">{t('DRAG_AND_DEOP_FILES_HERE')}</span>
            <div className="flex">
              <input type="file" multiple id="file-upload-input" className="hidden" onChange={onFileAdd} />
              <label htmlFor="file-upload-input" className="primary-text cursor">
                {t('BROWSE')}
              </label>
            </div>
          </div>
          <label className="font-12 inter-400-text natural-500-text mt-1">{t('MAXIMUM_FILE_SIZE_50MP')}</label>
        </div>
        <div className="flex-column row-gap-4">
          {uploadingFilesCount > 0 && (
            <div>
              <div className="mb-4 inter-500-text natural-700-text line-height-20 flex gap-2">
                <span>{t('UPLOADING')}</span>
                <span className="flex justify-center font-12 px-3 radius-1_5 bg-natural-100">
                  {uploadingFilesCount}
                </span>
              </div>
              {uploadingFiles.map(file => (
                <File
                  fileUploading={true}
                  onFileUploadDone={onFileUploadDone}
                  key={file.id}
                  file={file}
                  handleDeletePopup={handleDeletePopup}
                  onDownloadFile={onDownloadFile}
                  onClosingFailedUpload={onClosingFailedUpload}
                />
              ))}
            </div>
          )}
          {uploadedFilesCount > 0 && (
            <div>
              <div className="flex items-center justify-between mb-4">
                <div className="inter-500-text natural-700-text line-height-20 flex gap-2">
                  <span>{t('ATTACHED')}</span>
                  <span className="flex justify-center font-12 px-3 radius-1_5 bg-natural-100">
                    {uploadedFilesCount}
                  </span>
                </div>
                <div
                  onClick={() => selectedAttachments.length && handleDeletePopup(false)}
                  className={classNames(selectedAttachments.length > 0 && 'cursor')}>
                  <IconContainer
                    Icon={DeleteIcon}
                    iconColor={selectedAttachments.length ? 'primary_500' : 'natural_300'}
                    backgroundColor="natural_50"
                    iconWidth={14}
                    iconHeight={14}
                  />
                </div>
              </div>
              {uploadedFiles.map(file => (
                <File
                  file={file}
                  fileUploading={false}
                  handleFileSelection={handleFileSelection}
                  isChecked={selectedAttachments.find(f => f.id === file.id)}
                  key={file.id}
                  handleQuoteUpdateAttachment={handleQuoteUpdateAttachment}
                  onDownloadFile={onDownloadFile}
                  onFileUploadDone={onFileUploadDone}
                  handleDeletePopup={handleDeletePopup}
                  setUploadedFiles={setUploadedFiles}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </QuoteFileUploadWrapper>
  );
};

export default QuoteFileHandler;
