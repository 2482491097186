import * as turf from '@turf/turf';
import isEquals from 'lodash.isequal';
import moment from 'moment';
import { getItemFromLocalStorage } from './localstorage';

export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]+$/;

export const initModal = {
  type: null,
  content: {},
};
export const initSideModal = {
  type: null,
  content: {},
};

export const capitalize = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export function capitalizeFirstLetterOfWords(str) {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}

export const formatText = (text, replaceWith = '-') => {
  if (!text) return '';
  return text?.toLowerCase().replaceAll('_', replaceWith);
};

export const uppercase = str => {
  if (!str) return '';
  return str.toUpperCase();
};

export const lowercase = str => {
  if (!str) return '';
  return str?.toLowerCase();
};

export const handleCopyClick = async url => {
  try {
    await navigator.clipboard.writeText(url);
    return true;
  } catch (error) {
    console.error('Failed to copy:', error);
    return false;
  }
};

export const getUsersPermissions = () => {
  const user = getItemFromLocalStorage('user');
  return user?.api_tokens?.permissions;
};

export const countMatchesInRegex = (regex, str) => {
  return ((str || '').match(regex) || []).length;
};

export const getUserInitials = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}${userName?.[1]?.charAt(0)?.toUpperCase() || ''}`
    : forename || surname
    ? `${forename?.charAt(0)?.toUpperCase() || ''}${surname?.charAt(0)?.toUpperCase() || ''}`
    : '';
};

export const getContactValueByType = (contactTypes, field, field_value, return_field = 'value') => {
  const isForMobile = field_value === 'MOBILE';
  const contactTypeByField = contactTypes?.find(type => type[field] === field_value);
  return contactTypeByField?.[return_field]
    ? isForMobile
      ? `${contactTypeByField?.country_code || ''}${contactTypeByField?.[return_field]}`
      : contactTypeByField?.[return_field]
    : '';
};

export const getCurrencySymbol = currency => {
  switch (currency) {
    case 'USD':
    case 'usd':
      return '$';
    case 'GBP':
    case 'gbp':
      return '£';
    case 'EUR':
    case 'eur':
      return '€';
    default:
      return '$';
  }
};

export const getFormattedDate = (dateInUnix, format = 'DD/MM/YYYY', formatInUTC = true) => {
  if (!formatInUTC) return moment(dateInUnix * 1000).format(format);
  return moment(dateInUnix * 1000)
    .utc()
    .format(format);
};

export const getDateDifference = (date, compareWith = null, unit = 'days') => {
  const compareWithMoment = compareWith ? moment(compareWith * 1000) : moment();

  return date ? compareWithMoment.utc(false).diff(moment(date * 1000).utc(false), unit) : 0;
};

export const onlyNumbers = /^\d+$/;

export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const getFormattedNumber = (number, currency, showSymbol = true) => {
  // Create our number formatter.
  const currentCurrency = currency || 'GBP';
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency || 'GBP',
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    currencyDisplay: showSymbol ? 'symbol' : 'code',
  });
  const formattedNumber = formatter.format(number);

  if (!showSymbol) {
    return formattedNumber.replace(currentCurrency, '');
  }
  return formattedNumber;
};

export const getFormattedNumberStyle = (
  number,
  style = 'currency',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  ...rest
) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-GB', {
    style: style,
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
    ...rest,
  });
  return formatter.format(number);
};

export const getImageThumbnail = mediaData => {
  const { url, media = [] } = mediaData;
  const thumbnail = media ? media?.at(0) : null;
  return thumbnail?.url || url;
};

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isDataChanged = (inputType, newValue, oldValue, confirmAction = () => {}, removeAction = () => {}) => {
  switch (inputType) {
    case 'INPUT': {
      if (newValue !== oldValue) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'DROPDOWN': {
      if (newValue?.id !== oldValue?.id) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'ADDRESS': {
      const { line1, line2, city, state, postcode } = newValue;
      const { line1: oldLine1, line2: oldLine2, city: oldCity, state: oldState, postcode: oldPostCode } = oldValue;
      if (
        !isEquals(line1, oldLine1) ||
        !isEquals(line2, oldLine2) ||
        !isEquals(city, oldCity) ||
        !isEquals(state, oldState) ||
        !isEquals(postcode, oldPostCode)
      ) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'MULTI_DROPDOWN': {
      const newValueIds = newValue.map(v => v.id);
      const oldValueIds = oldValue.map(v => v.id);
      if (!isEquals(newValueIds, oldValueIds)) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    case 'MULTI_VALUE': {
      if (!isEquals(newValue, oldValue)) {
        confirmAction();
        return true;
      } else {
        removeAction();
        return false;
      }
    }
    default:
      confirmAction();
      return true;
  }
};

export const isUserSassOperator = () => {
  const user = getItemFromLocalStorage('user');
  const userOrganization = user?.organizations?.[0];
  return userOrganization?.organization_type === 'SAAS_OPERATOR';
};

export const isUserTagSassOperator = () => {
  const user = getItemFromLocalStorage('user');
  const userOrganization = user?.organizations?.[0];
  return userOrganization?.category?.tag?.tag === 'SAAS_OPERATOR';
};

export const getErrorFieldJoined = (errorObject, getValue = () => {}, joinBy = ', ') => {
  return Object.entries(errorObject)
    .filter(([_, value]) => value)
    .map(([key, value]) => getValue(key, value))
    .join(joinBy);
};

export const getErrorDescription = (error, defaultErrorText = '') => {
  if (!error) return '';
  return error?.response?.data?.error_description || defaultErrorText;
};

export const getProductPrice = product => {
  const { pricing } = product || {};
  const { prices } = pricing?.[0] || {};
  const { price, currency } = prices?.[0] || {};

  return getFormattedNumber(price || 0, currency || 'GBP');
};

export const getRoofDetailsFromManualOrImported = (
  roofData,
  key,
  fromManualOnly = false,
  fromImportOnly = false,
  format = true,
) => {
  const { manual, imported } = roofData || {};
  const area = manual?.area ?? imported?.area;
  const boundary = manual?.boundary ?? imported?.boundary;
  const panels_count = manual?.panels_count > 0 ? manual?.panels_count : imported?.panels_count;
  const yearly_energy = manual?.yearly_energy > 0 ? manual?.yearly_energy : imported?.yearly_energy;
  const azimuth = manual?.azimuth ?? imported?.azimuth;
  const pitch = manual?.pitch ?? imported?.pitch;

  let value = 0.0;

  const segmentDetails = {
    area: area || 0,
    boundary: boundary || [],
    panels_count: panels_count || 0,
    yearly_energy: yearly_energy || 0,
    azimuth: azimuth || 0,
    pitch: pitch || 0,
  };

  if (fromManualOnly) {
    value = manual ? manual[key] : key === 'boundary' ? [] : 0;
  } else if (fromImportOnly) {
    value = imported ? imported[key] : key === 'boundary' ? [] : 0;
  } else {
    value = segmentDetails[key];
  }
  if (format) {
    return getFormattedNumberStyle(value, 'decimal');
  }
  return value;
};

export const getPolygonCenter = polygonPaths => {
  // put all latitudes and longitudes in arrays
  const latitudes = polygonPaths.map(path => path.lat);
  const longitudes = polygonPaths.map(path => path.lng);

  // sort the arrays low to high
  latitudes.sort();
  longitudes.sort();

  // get the min and max of each
  const lowX = latitudes[0];
  const highX = latitudes[latitudes.length - 1];
  const lowy = longitudes[0];
  const highy = longitudes[latitudes.length - 1];

  // center of the polygon is the starting point plus the midpoint
  const centerX = lowX + (highX - lowX) / 2;
  const centerY = lowy + (highy - lowy) / 2;

  return { lat: centerX, lng: centerY };
};

export const rotatePoint = (point, origin, angle) => {
  const angleRad = (angle * Math.PI) / 180.0;
  return {
    x: Math.cos(angleRad) * (point.x - origin.x) - Math.sin(angleRad) * (point.y - origin.y) + origin.x,
    y: Math.sin(angleRad) * (point.x - origin.x) + Math.cos(angleRad) * (point.y - origin.y) + origin.y,
  };
};

export const groupFilesByMonth = (files, months) => {
  const groupedFiles = [];

  switch (files.length) {
    case 1:
      groupedFiles.push({ label: 'Jan-Dec', files });
      break;
    case 2:
      groupedFiles.push({ label: 'Jan-Jun', files: [files[0]] });
      groupedFiles.push({ label: 'Jul-Dec', files: [files[1]] });
      break;
    case 4:
      groupedFiles.push({ label: 'Jan-Mar', files: [files[0]] });
      groupedFiles.push({ label: 'Apr-Jun', files: [files[1]] });
      groupedFiles.push({ label: 'Jul-Sep', files: [files[2]] });
      groupedFiles.push({ label: 'Oct-Dec', files: [files[3]] });
      break;
    case 6:
      groupedFiles.push({ label: 'Jan-Feb', files: [files[0]] });
      groupedFiles.push({ label: 'Mar-Apr', files: [files[1]] });
      groupedFiles.push({ label: 'May-Jun', files: [files[2]] });
      groupedFiles.push({ label: 'Jul-Aug', files: [files[3]] });
      groupedFiles.push({ label: 'Sep-Oct', files: [files[4]] });
      groupedFiles.push({ label: 'Nov-Dec', files: [files[5]] });
      break;
    case 12:
      months.forEach((month, index) => {
        groupedFiles.push({ label: month, files: [files[index]] });
      });
      break;
    default:
      return [];
  }

  return groupedFiles;
};

export function calculateOrientation(latlngs) {
  try {
    const mostWest = latlngs.reduce((prev, curr) => (curr.lng < prev.lng ? curr : prev));
    const mostSouth = latlngs.reduce((prev, curr) => (curr.lat < prev.lat ? curr : prev));

    const angle = calculateAngleInMeters(mostWest, mostSouth);
    const adjustedAngle = 90 - Math.abs(angle);

    return {
      angle: adjustedAngle.toFixed(2),
    };
  } catch (error) {
    console.error('Error calculating orientation:', error);
  }
}

function calculateAngleInMeters(point1, point2) {
  const R = 6371000; // Earth radius in meters

  const lat1 = (point1.lat * Math.PI) / 180;
  const lat2 = (point2.lat * Math.PI) / 180;
  const lon1 = (point1.lng * Math.PI) / 180;
  const lon2 = (point2.lng * Math.PI) / 180;

  const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
  const y = lat2 - lat1;

  const distanceX = x * R;
  const distanceY = y * R;

  return Math.atan2(distanceY, distanceX) * (180 / Math.PI);
}

export function metersToDegreesLongitude(meters, latitude) {
  const earthCircumference = 40075017; // meters
  const degreesPerMeter = 360 / earthCircumference;
  return (meters * degreesPerMeter) / Math.cos((latitude * Math.PI) / 180);
}

export function adjustHeightForPitch(height, pitch) {
  const pitchRadians = pitch * (Math.PI / 180);
  return height / Math.cos(pitchRadians);
}

export function metersToDegreesLatitude(meters) {
  const earthCircumference = 40075017; // meters
  const degreesPerMeter = 360 / earthCircumference;
  return meters * degreesPerMeter;
}

export function rotateBoundaryToNorth(geoJSONData, angle = 0) {
  const center = turf.centerOfMass(geoJSONData).geometry.coordinates;
  const rotatedGeoJSON = turf.transformRotate(geoJSONData, angle, {
    pivot: center,
  });

  return rotatedGeoJSON;
}

export function calculateRotationAngleToNorth(path) {
  let maxDistance = 0;
  let sw = path[0],
    se = path[0];

  for (let i = 0; i < path.length - 1; i++) {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(path[i], path[i + 1]);
    if (distance > maxDistance) {
      maxDistance = distance;
      sw = path[i];
      se = path[i + 1];
    }
  }

  let angle = google.maps.geometry.spherical.computeHeading(sw, se);

  return { angle, firstPoint: sw, secondPoint: se, maxDistance };
}

export const getCenterOfBoundaryLines = (boundary = []) => {
  let centerOfAllLines = [];

  if (boundary.length > 0) {
    const boundaryWithoutLast = boundary;
    for (let i = 0; i < boundaryWithoutLast.length - 1; i++) {
      const firstPoint = boundaryWithoutLast[i];
      const secondPoint = boundaryWithoutLast[i + 1];
      const lat1 = firstPoint.lat;
      const lon1 = firstPoint.lon || firstPoint.lng;
      const lat2 = secondPoint.lat;
      const lon2 = secondPoint.lon || secondPoint.lng;

      const center = {
        lat: (lat1 + lat2) / 2,
        lng: (lon1 + lon2) / 2,
      };

      centerOfAllLines.push(center);
    }
  }
  return centerOfAllLines;
};

export function calculatePeriodicPaymentAmount(
  principleAmount,
  interestRate = 11.9 / 100 / 12,
  periodValue = 60,
  futureValue = 0,
) {
  const term = Math.pow(1 + interestRate, periodValue);
  return (futureValue * interestRate) / (term - 1) + (principleAmount * interestRate) / (1 - 1 / term);
}

export const calculateInterest = (principal, apr, months) => {
  let interestPayment = principal * (apr / 100 / 12) * months;
  return interestPayment;
};

export function customRectangleGrid(bbox, gap, cellWidth, cellHeight, options = {}) {
  // Extract bounding box points
  const [west, south, east, north] = bbox;

  // Create gap in meters using Google Maps API
  const northGapLatLng = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(north, west), -gap, 0);
  const southGapLatLng = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(south, west), gap, 0);
  const westGapLatLng = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(south, west), gap, 90);
  const eastGapLatLng = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(south, east), -gap, 90);

  // Define new bbox with gaps
  const newBbox = [westGapLatLng.lng(), southGapLatLng.lat(), eastGapLatLng.lng(), northGapLatLng.lat()];

  // Calculate the number of rows and columns
  const latDiff = google.maps.geometry.spherical.computeDistanceBetween(
    new google.maps.LatLng(newBbox[1], newBbox[0]),
    new google.maps.LatLng(newBbox[3], newBbox[0]),
  );
  const lngDiff = google.maps.geometry.spherical.computeDistanceBetween(
    new google.maps.LatLng(newBbox[1], newBbox[0]),
    new google.maps.LatLng(newBbox[1], newBbox[2]),
  );
  const rows = Math.ceil(latDiff / cellHeight);
  const cols = Math.ceil(lngDiff / cellWidth);

  // Start point (northwest corner)
  let startLatLng = new google.maps.LatLng(newBbox[3], newBbox[0]);

  // Create grid cells
  const features = [];
  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      // Calculate the northwest corner of the cell
      let nw = google.maps.geometry.spherical.computeOffset(
        google.maps.geometry.spherical.computeOffset(startLatLng, row * cellHeight, 180),
        col * cellWidth,
        90,
      );

      // Calculate the southeast corner of the cell
      let se = google.maps.geometry.spherical.computeOffset(
        google.maps.geometry.spherical.computeOffset(nw, cellHeight, 180),
        cellWidth,
        90,
      );

      // Create polygon for the grid cell
      const coordinates = [
        [nw.lng(), nw.lat()],
        [se.lng(), nw.lat()],
        [se.lng(), se.lat()],
        [nw.lng(), se.lat()],
        [nw.lng(), nw.lat()],
      ];
      const polygon = turf.polygon([coordinates]);
      if (options.mask) {
        if (turf.booleanIntersects(polygon, options.mask)) {
          polygon.properties = { row, column: col };
          features.push(polygon);
        }
      } else {
        polygon.properties = { row, column: col };
        features.push(polygon);
      }
    }
  }

  return turf.featureCollection(features);
}

export const getComponentPrice = (component, format = true) => {
  const { pricing, quantity } = component || {};
  const { prices } = pricing?.[0] || {};
  const { price, currency, tax_model, taxation_scheme, discount_amount } = prices?.[0] || {};
  let priceWithTax = price || 0;
  if (tax_model === 'EXCLUSIVE' && taxation_scheme) {
    const { rate } = taxation_scheme || {};
    priceWithTax = price + (price * rate) / 100;
  }
  if (!format)
    return {
      qty: quantity || 1,
      discount_amount: discount_amount || 0,
      unit_price: price,
      total_net: price * (quantity || 1),
      total_amount: priceWithTax * (quantity || 1),
      total_tax: (priceWithTax - price) * (quantity || 1),
    };
  return getFormattedNumber(price || 0, currency || 'GBP');
};

export const normaLizeAngle = angle => {
  if (angle < 0) {
    return 360 + angle;
  } else if (angle > 360) {
    return angle % 360;
  }
  return angle;
};

export const findClosestAngle = (angle, firstAngle, secondAngle) => {
  const firstDiff = normaLizeAngle(angle) - normaLizeAngle(firstAngle);
  const secondDiff = normaLizeAngle(angle) - normaLizeAngle(secondAngle);

  if (Math.abs(firstDiff) < Math.abs(secondDiff)) {
    return normaLizeAngle(firstAngle);
  }
  return normaLizeAngle(secondAngle);
};
