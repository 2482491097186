import classNames from 'classnames';
import { Reorder } from 'framer-motion';
import isEqual from 'lodash.isequal';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { components } from 'react-select';
import MaskedInput from 'react-text-mask';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { ReactComponent as NoDocumentIcon } from '../../../assets/icons/empty-document.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/property/uploadicon.svg';
import { ReactComponent as RightIcon } from '../../../assets/icons/right-Icons.svg';
import { ReactComponent as PanelIcon } from '../../../assets/icons/solar-panel.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-image.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/DeleteIcons.svg';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';

import Logo from '../../../assets/images/logo.svg';
import { ReactComponent as StarFilledIcon } from '../../../assets/images/start.svg';
import BreadcrumbWrapper from '../../../components/common/breadcrumb-wrapper';
import Button from '../../../components/common/button/button';
import Editor from '../../../components/common/editor-html';
import IconContainer from '../../../components/common/icon-container';
import InfiniteScrollV2 from '../../../components/common/infinite-scroll-v2';
import InputElement from '../../../components/common/input';
import NoData from '../../../components/common/no-data';
import SearchableDropdown from '../../../components/common/searchable-dropdown';
import Dropdown from '../../../components/common/select-dropdown';
import SkeletonTransition from '../../../components/common/skeleton-transition';
import Switch from '../../../components/common/switch';
import TableHeader from '../../../components/common/table-header';
import CustomTooltip from '../../../components/common/tooltip-new';
import ProductSpecification from '../../../components/product-details/product-specification';
import { OrganisationContext } from '../../../context/organisationContext';
import { createNumberMask } from '../../../helpers/createNumberMask';
import { productType, soldAs } from '../../../helpers/optionData';
import { capitalize, formatText, getContactValueByType, getFormattedNumber, lowercase } from '../../../helpers/utils';
import { useQuery } from '../../../hooks/useQuery';
import {
  createProductPrice,
  deleteProductSpecification,
  getBrands,
  getProductFamilies,
  getProductInfo,
  getProductMedia,
  getSuppliersList,
  getTaxationSchemes,
  setProductInfo,
  setProductSpecifications,
  updateProductPrice,
  updateProductSpecifications,
} from '../../../store/features/quotesSlice';
import { addToast } from '../../../store/features/toastSlice';

const productDrawStatusFilter = [
  { id: 'ALL', value: 'ALL', label: 'All', selected: true },
  { id: 'ACTIVE', value: 'ACTIVE', label: 'Active' },
  { id: 'INACTIVE', value: 'INACTIVE', label: 'Inactive' },
];

const initialState = {
  status: productDrawStatusFilter.at(0),
  soldAs: {
    single: false,
    bundle: false,
  },
};

const tabs = [
  { name: 'General', key: 'GENERAL' },
  // { name: 'Pricing', key: 'PRICING' },
  { name: 'Suppliers', key: 'SUPPLIERS' },
  { name: 'Attributes', key: 'ATTRIBUTES' },
  { name: 'Components', key: 'COMPONENTS' },
];

const getPricingInfo = p => {
  const [pricingTemplate] = p.pricing || [];
  const [price] = pricingTemplate?.prices || [];
  return price ? { ...price, pricingId: pricingTemplate?.id, pricing: pricingTemplate } : {};
};

const ProductPriceRight = ({ product, taxationSchemes, productInitial, setProductInitial, setProduct }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const { hasPermission } = useContext(OrganisationContext);
  const { pricingId, pricing, taxation_scheme, price: productPrice, id: priceId } = getPricingInfo(product);
  const { pricing: pricingInitial, owner } = product || {};
  const [editingPrice, setEditingPrice] = useState({ editing: false, price: productPrice || '' });
  const [loading, setLoading] = useState(false);

  const hasPermissionToEdit = hasPermission('PRICING_MANAGE');

  const onUpdateProductPrice = editingPriceVal => {
    if (pricing) {
      const { price, taxation_scheme: updated_taxation_scheme } = editingPriceVal;
      if (productPrice === price && updated_taxation_scheme?.id === taxation_scheme?.id) {
        setEditingPrice({ editing: false });
        return;
      }
      setEditingPrice({ ...editingPriceVal, price, taxation_scheme: updated_taxation_scheme });
      const request = {
        ...pricing,
        prices: pricing?.prices
          .map(p => (p.id === priceId ? { ...p, price, taxation_scheme: updated_taxation_scheme } : p))
          .map(p => ({ ...p, merchant: p?.merchant?.id ? { id: p?.merchant?.id } : null })),
      };

      setLoading(true);
      dispatch(updateProductPrice({ product_id: product.id, price_id: pricingId, request }))
        .then(pricing => {
          const newPricing = pricingInitial?.map(p => (p.id === pricingId ? pricing : p));
          setEditingPrice({ price: 0, editing: false });
          setProductInitial({ ...productInitial, pricing: newPricing });
          setProduct({ ...product, pricing: newPricing });
          setLoading(false);
          dispatch(addToast({ error: false, text: t('PRODUCT_UPDATED_SUCCESS'), id: nanoid() }));
        })
        .catch(() => {
          setLoading(false);
          dispatch(addToast({ error: true, text: t('ERROR_PRODUCT_UPDATE'), id: nanoid() }));
        });
    } else {
      if (!editingPrice?.price && !editingPrice?.taxation_scheme?.id) {
        setEditingPrice({ editing: false });
        return;
      }
      const request = {
        name: null,
        price_type: 'ONE_OFF',
        payment_model: 'PREPAY',
        price_model: 'FLAT',
        ratecard: null,
        variant: null,
        prices: [
          {
            location_code: null,
            currency: null,
            display_price: null,
            effective_date: null,
            during_binding: null,
            price: editingPrice?.price,
            tax_model: 'INCLUSIVE',
            taxation_scheme: {
              id: editingPrice?.taxation_scheme?.id,
            },
            price_type: 'SALES',
            merchant: null,
            retailer: owner,
          },
        ],
      };
      setLoading(true);
      dispatch(createProductPrice({ product_id: product.id, request }))
        .then(pricing => {
          const newPricing = [pricing];
          setEditingPrice({ editing: false });
          setProductInitial({ ...productInitial, pricing: newPricing });
          setProduct({ ...product, pricing: newPricing });
          setLoading(false);
          dispatch(addToast({ error: false, text: t('PRODUCT_UPDATED_SUCCESS'), id: nanoid() }));
        })
        .catch(() => {
          setLoading(false);
          dispatch(addToast({ error: true, text: t('ERROR_PRODUCT_UPDATE'), id: nanoid() }));
        });
    }
  };

  const renderPrice = () => {
    const { editing, price } = editingPrice;
    return editing ? (
      <div className="relative price-container">
        <MaskedInput
          mask={createNumberMask({
            prefix: '£',
            allowDecimal: true,
            includeThousandsSeparator: false,
            allowLeadingZeroes: true,
          })}
          value={price}
          className="inter-400-text natural-900-text one-line font-20 input price-input w-full"
          placeholder={'£00.00'}
          guide={false}
          autoFocus
          onChange={({ target: { value } }) => {
            let newValue = value?.replaceAll('£', '');
            setEditingPrice({ ...editingPrice, price: newValue });
          }}
        />
        <div className="flex items-center justify-end col-gap-2 action-wrapper">
          <IconContainer
            Icon={CloseIcon}
            iconHeight={18}
            iconWidth={18}
            disabled={loading}
            iconContainerClassname="border bg-white radius-full cursor"
            onClick={() => setEditingPrice({ editing: false })}
          />
          <IconContainer
            Icon={loading ? LoadingIcon : RightIcon}
            disabled={loading}
            iconHeight={18}
            iconWidth={18}
            iconColor="white"
            iconContainerClassname="bg-primary-500 radius-full cursor"
            onClick={() => onUpdateProductPrice({ ...editingPrice, taxation_scheme })}
          />
        </div>
      </div>
    ) : (
      <div
        className={classNames('pxy-1', hasPermissionToEdit && 'cursor price-value-hover')}
        onClick={() =>
          hasPermissionToEdit && productPrice
            ? setEditingPrice({ editing: true, price: productPrice || '' })
            : setEditingPrice({ ...editingPrice, editing: true })
        }>
        <p className="inter-600-text font-20">{getFormattedNumber(productPrice || 0)}</p>
      </div>
    );
  };

  return (
    <div className="bg-white py-5 px-6 radius-3">
      <div className="flex-column gap-2">
        <p className="inter-400-text font-14 line-height-150">{t('SALES_PRICE_INCLUSIVE')}</p>
        {renderPrice()}
        <Dropdown
          placeholder={t('SELECT_TAX_SCHEME')}
          id="product_tax_scheme"
          isDisabled={!hasPermissionToEdit}
          options={taxationSchemes}
          value={editingPrice.taxation_scheme || taxation_scheme}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          customStyle={{
            control: {
              backgroundColor: '#FAFAFA',
              height: '24px',
              minHeight: '24px',
              borderRadius: '4px',
            },
            controlIsOpen: {
              backgroundColor: theme.white,
            },
            singleValue: {
              fontSize: '12px',
              lineHeight: '20px',
              color: theme.natural_700,
            },
            valueContainer: {
              padding: '2px 8px !important',
            },
            dropdownIndicator: {
              padding: '0px',
              svg: {
                width: '10px',
              },
            },
          }}
          onChange={option => {
            if (productPrice) {
              onUpdateProductPrice({ ...editingPrice, taxation_scheme: option });
            } else {
              setEditingPrice({ ...editingPrice, taxation_scheme: option });
            }
          }}
        />
      </div>
    </div>
  );
};

const Product = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation();
  const { id } = useParams();

  const query = useQuery();
  const tab = query.get('selectedTab');

  const { setModal } = useContext(OrganisationContext);

  const [loading, setLoading] = useState(false);
  const [isProductSaving, setIsProductSaving] = useState(false);
  const [productInitial, setProductInitial] = useState({});
  const [product, setProduct] = useState({});
  const [productMedia, setProductMedia] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tab || tabs.at(0).key);
  const [showEditButtons, setShowEditButtons] = useState(false);
  const [isEditSpecification, setIsEditSpecification] = useState(false);
  const [isUpdateSpecification, setIsUpdateSpecification] = useState(false);
  const [specifications, setSpecifications] = useState([]);
  const [taxationSchemes, setTaxationSchemes] = useState([]);
  const { user } = useSelector(state => state.user);
  const { organizations } = user || {};
  const { id: organisation_id } = organizations?.[0] || {};
  const { owner, image, id: product_id } = product || {};
  const { id: primaryImageId } = image || {};
  const { id: owner_id } = owner || {};
  const is_product_owner = owner_id === organisation_id;

  const [searchParams, setSearchParams] = useSearchParams({
    selectedTab: selectedTab || tabs.at(0).key,
  });

  const textareaRef = useRef(null);

  const updateTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  };

  useLayoutEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      updateTextareaHeight();
    }
  }, [textareaRef.current, product?.description]);

  async function fetchTaxationSchemes() {
    await dispatch(getTaxationSchemes({ params: {} }))
      .then(data => {
        setTaxationSchemes(data.map(d => ({ ...d, value: d.id, label: d.name })));
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_TAX_SCHEMES'), id: nanoid() }));
      });
  }

  async function fetchProductDetails() {
    await dispatch(getProductInfo({ id }))
      .then(data => {
        setProductInitial(data);
        setProduct(data);
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PRODUCT'), id: nanoid() }));
      });
  }

  async function fetchProductMedia() {
    await dispatch(getProductMedia({ id }))
      .then(data => {
        setProductMedia(data);
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PRODUCT_MEDIA'), id: nanoid() }));
      });
  }

  const updateSpecifications = async () => {
    setIsProductSaving(true);
    const successMessages = [];

    try {
      const specificationActions = (specifications || []).reduce((acc, spec) => {
        const {
          id: specification_id,
          isDelete,
          isNew,
          isUpdate,
          classification,
          specification: specName,
          units,
        } = spec || {};

        if (isDelete) {
          acc.push(dispatch(deleteProductSpecification({ product_id, id: specification_id })));
        }
        if (isNew) {
          const result = { classification, specification: specName, unit: units };
          acc.push(dispatch(setProductSpecifications({ id, request: result })));
        }
        if (isUpdate) {
          const result = { classification, specification: specName, unit: units };
          acc.push(dispatch(updateProductSpecifications({ product_id, id: specification_id, request: result })));
        }

        return acc;
      }, []);

      const results = await Promise.allSettled(specificationActions);

      results.forEach((result, index) => {
        const spec = specifications[index];
        if (result.status === 'fulfilled') {
          if (spec.isDelete) successMessages.push(t('PRODUCT_DELETED_SUCCESSFULLY'));
          if (spec.isNew) successMessages.push(t('PRODUCT_ADDED_SUCCESSFULLY'));
          if (spec.isUpdate) successMessages.push(t('PRODUCT_UPDATED_SUCCESS'));
        } else {
          const errorMsg = spec.isDelete
            ? t('ERROR_WHILE_SPECIFICATIONS_DELETING')
            : spec.isNew
            ? t('ERROR_WHILE_SPECIFICATIONS_ADDING')
            : spec.isUpdate
            ? t('ERROR_WHILE_SPECIFICATIONS_UPDATEING')
            : t('ERROR_WHILE_PROCESSING_SPECIFICATIONS');
          dispatch(addToast({ error: true, text: errorMsg, id: nanoid() }));
        }
      });

      successMessages.forEach(msg => {
        dispatch(addToast({ error: false, text: msg, id: nanoid() }));
      });
    } catch (error) {
      dispatch(addToast({ error: true, text: t('ERROR_WHILE_PROCESSING_SPECIFICATIONS'), id: nanoid() }));
    } finally {
      setIsProductSaving(false);
    }
  };

  const handleProductInfoUpdate = async () => {
    setIsProductSaving(true);

    try {
      await dispatch(setProductInfo({ id, request: product }));
      dispatch(addToast({ error: false, text: t('UPDATE_PRODUCT_SUCCESSFULLY'), id: nanoid() }));
    } catch (error) {
      dispatch(addToast({ error: true, text: t('ERROR_PRODUCT_UPDATE'), id: nanoid() }));
    } finally {
      setIsProductSaving(false);
    }
  };

  const functionOnSave = async () => {
    setIsProductSaving(true);

    try {
      const actions = [];
      if (showEditButtons) {
        actions.push(handleProductInfoUpdate());
      }
      if (isEditSpecification) {
        actions.push(updateSpecifications());
      }

      await Promise.all(actions);
      await fetchProductDetails();

      if (isEditSpecification) {
        setIsUpdateSpecification(!isUpdateSpecification);
      }
    } catch (error) {
      console.error('Error in functionOnSave:', error);
    } finally {
      setIsProductSaving(false);
      setIsEditSpecification(false);
    }
  };

  function functionOnCancel() {
    setProduct(productInitial);
    setIsEditSpecification(false);
    setIsUpdateSpecification(!isUpdateSpecification);
  }

  function updateProductInfo(key, value) {
    setProduct(prev => ({ ...prev, [key]: value }));
  }

  const stripHtmlTags = htmlString => {
    if (!htmlString) return htmlString;
    return htmlString.replace(/<[^>]+>/g, '');
  };

  useEffect(() => {
    if (productInitial) {
      const normalizedProduct = {
        ...product,
        long_description: stripHtmlTags(product.long_description),
      };
      const normalizedProductInitial = {
        ...productInitial,
        long_description: stripHtmlTags(productInitial.long_description),
      };

      const isProductUpdated = isEqual(normalizedProductInitial, normalizedProduct);
      setShowEditButtons(!isProductUpdated);
    }
  }, [product]);

  async function fetchProductInfo() {
    setLoading(true);
    await fetchProductDetails();
    await fetchProductMedia();
    await fetchTaxationSchemes();
    setLoading(false);
  }

  useEffect(() => {
    fetchProductInfo();
  }, []);

  useEffect(() => {
    const imageId = productInitial?.image?.id;
    let updatedMedia = productMedia;
    if (imageId) {
      const primaryImage = productMedia?.find(m => m?.media?.id === imageId);
      const mediaWithoutPrimaryImage = productMedia?.filter(m => m?.media?.id !== imageId);
      updatedMedia = [primaryImage, ...mediaWithoutPrimaryImage].filter(Boolean);
    }
    setProductImages(updatedMedia);
  }, [productMedia, productInitial]);

  const onUploadMedia = () => {
    setModal({
      type: 'upload-product-media',
      content: {
        product: productInitial,
        onSuccess: () => {
          fetchProductMedia();
          fetchProductDetails();
        },
      },
    });
  };

  const onDeleteProductMedia = (product, media, productImages, product_media_id, isPrimaryImage = false) => {
    setModal({
      type: 'delete-product-media',
      content: {
        product: product,
        productImages: productImages,
        product_media_id: product_media_id,
        media: media,
        isPrimaryImage: isPrimaryImage,
        onSuccess: () => {
          fetchProductMedia();
          isPrimaryImage && fetchProductDetails();
        },
      },
    });
  };

  return (
    <div className="pxy-6 bg-natural-150" style={{ height: '100%', minHeight: 'calc(100vh - 100px)' }}>
      <BreadcrumbWrapper
        breadcrumbs={[
          { text: t('SETTINGS'), onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }) },
          {
            text: t('PLATFORM_CONFIGURATION'),
            onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }),
          },
          {
            text: t('PRODUCTS'),
            onClick: () => navigate('/settings/platform-configuration/products', { state: 'PLATFORM_CONFIGURATION' }),
          },
          { text: productInitial.name, onClick: () => {} },
        ]}
      />

      <SkeletonTransition
        loading={loading}
        customLoaderComponent={
          <div className="line-height-1 h-full pxy-6 item flex col-gap-4">
            <div className="flex-column gap-4 w-70">
              <Skeleton height={'64px'} />
              <Skeleton height={'100%'} containerClassName="line-height-1 h-full flex-1" />
            </div>
            <div className="flex-column gap-4 w-30">
              <Skeleton height={'64px'} />
              <Skeleton height={'140px'} />
              <Skeleton height={'100%'} containerClassName="line-height-1 h-full flex-1" />
            </div>
          </div>
        }>
        <ProductWrapper className="flex col-gap-4 natural-900-text h-full">
          <div className="flex-column gap-4 w-70">
            <div className="flex bg-white py-4 px-6 radius-3">
              <p className="flex-1 inter-600-text font-20 line-height-32">{product.name}</p>
              {(showEditButtons || isEditSpecification) && (
                <div className="flex gap-3">
                  <Button
                    bgColor={theme.white}
                    borderColor={theme.natural_200}
                    color={theme.natural_700}
                    label={t('CANCEL')}
                    onClick={functionOnCancel}
                    className="px-5 py-1_5"
                    size="medium"
                  />
                  <Button
                    bgColor={theme.primary_500}
                    borderColor={theme.primary_500}
                    color={theme.white}
                    onClick={functionOnSave}
                    className="px-5 py-1_5"
                    label={t('SAVE')}
                    loading={isProductSaving}
                    size="medium"
                  />
                </div>
              )}
            </div>

            <div className="flex-1 overflow-hidden flex-column bg-white pt-2 pb-5 px-6 radius-3">
              <ProductTabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setSearchParams={setSearchParams}
                product={product}
                user={user}
              />

              {selectedTab === 'GENERAL' && (
                <GeneralTab
                  product={product}
                  updateProductInfo={updateProductInfo}
                  setProduct={setProduct}
                  textareaRef={textareaRef}
                  is_product_owner={is_product_owner}
                />
              )}

              {selectedTab === 'SUPPLIERS' && (
                <SuppliersTab
                  product={product}
                  updateProductInfo={updateProductInfo}
                  is_product_owner={is_product_owner}
                />
              )}

              {selectedTab === 'ATTRIBUTES' && (
                <AttributesTab
                  product={product}
                  updateProductInfo={updateProductInfo}
                  is_product_owner={is_product_owner}
                  setIsEditSpecification={setIsEditSpecification}
                  setSpecifications={setSpecifications}
                  specifications={specifications}
                  isUpdateSpecification={isUpdateSpecification}
                />
              )}

              {selectedTab === 'COMPONENTS' && (
                <ComponentsTab
                  product={product}
                  updateProductInfo={updateProductInfo}
                  is_product_owner={is_product_owner}
                />
              )}
            </div>
          </div>
          <div className="flex-column gap-4 w-30">
            <div className="bg-white py-5 px-6 radius-3">
              <div className="flex">
                <p className="inter-600-text w-full font-16 line-height-150">{t('ACTIVE')}</p>
                <CustomTooltip
                  id={'lifecycle-tooltip'}
                  hidden={is_product_owner}
                  place="left"
                  wrapperClassName="tooltip-wrapper"
                  content={<span className="inter-400-text font-12">{t('NO_PRODUCT_OWNER_MESSAGE')}</span>}>
                  <Switch
                    enabled={product.lifecycle === 'ACTIVE'}
                    disabled={!is_product_owner}
                    switchWrapperClassName={classNames(!is_product_owner && 'disabled')}
                    onClick={e =>
                      updateProductInfo('lifecycle', product.lifecycle === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')
                    }
                  />
                </CustomTooltip>
              </div>
            </div>
            <ProductPriceRight
              taxationSchemes={taxationSchemes}
              product={product}
              productInitial={productInitial}
              setProduct={setProduct}
              setProductInitial={setProductInitial}
            />
            <div className="flex-column overflow-hidden flex-1 bg-white pb-5 radius-3">
              <div className="pt-5 z-1 bg-white flex items-center pb-4 px-6 border-bottom">
                <p className="inter-600-text line-height-150 font-16 flex-1">{t('PRODUCT_MEDIA')}</p>
                {is_product_owner && (
                  <div>
                    <Button
                      bgColor={theme.primary_50}
                      borderColor={theme.primary_50}
                      color={theme.primary_500}
                      label={t('UPLOAD')}
                      className="px-3 py-1_5"
                      size="medium"
                      onClick={onUploadMedia}
                      icon={<UploadIcon className="primary-500-text" />}
                    />
                  </div>
                )}
              </div>
              <div className="flex-column row-gap-3 px-6 mt-5 overflow-scroll thin-scrollbar custom-scrollbar">
                {productImages?.length > 0
                  ? productImages.map(({ media, product_media_type, id: product_media_id }) => {
                      const isPrimaryImage =
                        primaryImageId === media?.id || primaryImageId === media?.media_external_id;
                      return (
                        <div key={media?.id} className="relative product-image-hover">
                          <img
                            src={media?.url}
                            className="radius-1_5 block object-fit-cover aspect-ratio-square w-full"
                          />
                          {product_media_type && (
                            <div className="absolute flex items-center px-3 py-1 radius-6 product-media-type-container">
                              <p className="inter-500-text white-text font-12">
                                {capitalize(formatText(product_media_type, ' '))}
                              </p>
                            </div>
                          )}
                          {is_product_owner && (
                            <div className="absolute delete-icon flex items-center gap-2">
                              {isPrimaryImage ? (
                                <div
                                  className={classNames(
                                    ' w-24px h-24px flex gap-2 items-center cursor justify-center radius-6 product-media-type-container ',
                                    isPrimaryImage && 'primary-image',
                                  )}>
                                  <p className="inter-500-text white-text font-12">
                                    <IconContainer
                                      Icon={StarFilledIcon}
                                      iconColor="primary_500"
                                      backgroundColor="rgba(0, 0, 0, 0.5)"
                                      iconContainerClassname="p-0"
                                      iconWidth={16}
                                      iconHeight={16}
                                    />
                                  </p>
                                </div>
                              ) : (
                                <CustomTooltip
                                  tooltipClassname="tooltip"
                                  id={`file-${media?.id}-tooltip`}
                                  content={<span className="inter-400-text font-12">Make primary image</span>}>
                                  <div
                                    className={classNames(
                                      ' w-24px h-24px flex gap-2 items-center cursor justify-center radius-6 product-media-type-container',
                                    )}
                                    onClick={e => updateProductInfo('image', { id: media?.media_external_id })}>
                                    <p className="inter-500-text white-text font-12">
                                      <IconContainer
                                        Icon={StarIcon}
                                        iconColor="natural_150"
                                        backgroundColor="rgba(0, 0, 0, 0.5)"
                                        iconContainerClassname="radius-50-percent star-icon cursor"
                                        iconWidth={16}
                                        iconHeight={16}
                                      />
                                    </p>
                                  </div>
                                </CustomTooltip>
                              )}
                              <CustomTooltip
                                tooltipClassname="tooltip"
                                id={`delete-${media?.id}-tooltip`}
                                content={<span className="inter-400-text font-12">Delete this image</span>}>
                                <div
                                  className="w-24px h-24px flex gap-2 items-center cursor justify-center radius-6 product-media-type-container"
                                  onClick={() =>
                                    onDeleteProductMedia(
                                      product,
                                      media,
                                      productImages,
                                      product_media_id,
                                      primaryImageId === media?.id,
                                    )
                                  }>
                                  <p className="inter-500-text white-text font-12">
                                    <IconContainer
                                      Icon={DeleteIcon}
                                      iconColor="white-text"
                                      backgroundColor="rgba(0, 0, 0, 0.5)"
                                      iconContainerClassname="p-0"
                                      iconWidth={16}
                                      iconHeight={16}
                                    />
                                  </p>
                                </div>
                              </CustomTooltip>
                            </div>
                          )}
                        </div>
                      );
                    })
                  : !is_product_owner && (
                      <label className="inter-400-text font-14 line-height-20 natural-400-text">No Media</label>
                    )}
              </div>
            </div>
          </div>
        </ProductWrapper>
      </SkeletonTransition>
    </div>
  );
};

export default Product;

const getFilteredTabs = (tabs, tag) => {
  switch (tag) {
    case 'MERCHANT':
      return tabs.filter(tab => tab.key !== 'SUPPLIERS');
    default:
      return tabs;
  }
};

const ProductTabs = ({ selectedTab, setSearchParams, setSelectedTab, product, user }) => {
  const { organizations } = user || {};
  const { category } = organizations[0] || {};
  const { tag: innerTag } = category || {};
  const { tag } = innerTag || {};

  let tabsToRender = product?.sold_as === 'BUNDLE' ? tabs : tabs.filter(tab => tab.key !== 'COMPONENTS');
  tabsToRender = getFilteredTabs(tabsToRender, tag);

  return (
    <div className="flex border-bottom">
      {tabsToRender.map(tab => {
        const activeTab = selectedTab === tab.key;
        return (
          <div
            key={tab.key}
            onClick={() => {
              setSelectedTab(tab.key);
              setSearchParams({ selectedTab: tab.key });
            }}
            className={classNames(
              'px-4 py-4_5 cursor natural-700-text',
              activeTab && 'selected-tab border-bottom-primary',
            )}>
            <label className={classNames('inter-500-text natural-700-text', activeTab && 'primary-500-text')}>
              {tab.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};

const GeneralTab = ({ product, setProduct, updateProductInfo, textareaRef, is_product_owner }) => {
  const { t } = useTranslation();

  const RenderElement = (name, value) => {
    return (
      <div className="p-4 flex-column gap-1 bg-natural-50 radius-1_5">
        <label className={classNames('inter-500-text font-14 line-height-20 natural-900-text')}>{name}</label>
        <label
          className={classNames('inter-400-text font-14 line-height-20', {
            'natural-400-text': !value,
            'natural-900-text': value,
          })}>
          {value || `No ${name}`}
        </label>
      </div>
    );
  };

  return (
    <div className="pt-5 h-full overflow-y-scroll">
      <div className="flex gap-6 mb-5">
        <div className="w-50">
          {is_product_owner ? (
            <InputElement
              name={t('NAME')}
              id="product_name"
              value={product?.name}
              disabled={!is_product_owner}
              onChange={value => updateProductInfo('name', value)}
            />
          ) : (
            RenderElement(t('NAME'), product?.name)
          )}
        </div>
        <div className="w-50">
          {is_product_owner ? (
            <InputElement
              name={t('SKU')}
              id="product_sku"
              value={product?.sku}
              disabled={!is_product_owner}
              onChange={value => updateProductInfo('sku', value)}
            />
          ) : (
            RenderElement(t('SKU'), product?.sku)
          )}
        </div>
      </div>
      <div className="flex gap-6 mb-5">
        <div className="w-50">
          {is_product_owner ? (
            <Dropdown
              name={t('PRODUCT_TYPE')}
              id="product_type"
              options={productType}
              isDisabled={!is_product_owner}
              value={productType.find(s => s?.value === product?.product_type)}
              onChange={e => updateProductInfo('product_type', e?.value)}
            />
          ) : (
            RenderElement(t('PRODUCT_TYPE'), productType.find(s => s.value === product?.product_type)?.label)
          )}
        </div>
        <div className="w-50">
          {is_product_owner ? (
            <Dropdown
              name={t('SOLD_AS')}
              id="product_sold_as"
              options={soldAs}
              isDisabled={!is_product_owner}
              value={soldAs.find(s => s?.value === product?.sold_as)}
              onChange={e => updateProductInfo('sold_as', e?.value)}
            />
          ) : (
            RenderElement(t('SOLD_AS'), soldAs.find(s => s?.value === product?.sold_as)?.label)
          )}
        </div>
      </div>
      <div className="mb-5">
        {is_product_owner ? (
          <InputElement
            name={t('DESCRIPTION')}
            placeholder={t('SORT_DESCRIPTION_GOES_HEAR')}
            value={product.description}
            type="textarea"
            inputRef={textareaRef}
            disabled={!is_product_owner}
            className="product-texarea"
            onChange={e => updateProductInfo('description', e)}
          />
        ) : (
          RenderElement(t('DESCRIPTION'), product?.description)
        )}
      </div>
      <div>
        {is_product_owner ? (
          <>
            <div className="mb-1">
              <span className="inter-500-text natural-900-text">{t('LONG_DESCRIPTION')}</span>
            </div>
            <Editor
              onChange={e => updateProductInfo('long_description', e)}
              getParmsValue={product?.long_description}
            />
          </>
        ) : (
          <div className="p-4 flex-column gap-1 bg-natural-50 radius-1_5">
            <label className={classNames('inter-500-text font-14 line-height-20 natural-900-text')}>
              {t('LONG_DESCRIPTION')}
            </label>
            <label
              className={classNames('inter-400-text font-14 line-height-20', {
                'natural-400-text': !product?.long_description,
                'natural-900-text': product?.long_description,
              })}
              dangerouslySetInnerHTML={{
                __html: `<div>${product?.long_description || 'No long description'}</div>`,
              }}></label>
          </div>
        )}
      </div>
    </div>
  );
};

const SupplierItem = ({ priceData, prices, index }) => {
  const { merchant, price_group, price, taxation_scheme } = priceData;
  const { name, image, contacts } = merchant || {};
  const { rate } = taxation_scheme || {};
  const isLast = index === prices?.length - 1;

  return (
    <div
      className={classNames(
        'data-container py-3 items-center custom-scrollbar thin-scrollbar',
        !isLast && 'border-bottom',
      )}>
      <div className="flex items-center gap-4 ">
        <img className="logo" src={image?.url || Logo} alt="logo" height={64} width={64} />
        <div className="flex-column overflow-hidden">
          <p className="inter-500-text font-18 line-height-28 natural-900-text one-line">{name}</p>

          <p className="inter-400-text font-12 line-height-20 natural-500-text sone-line">
            {getContactValueByType(contacts, 'contact_type', 'MOBILE', 'contact_value')}
          </p>
          <p className="inter-400-text font-12 line-height-20 natural-500-text one-line">
            {getContactValueByType(contacts, 'contact_type', 'EMAIL', 'contact_value')}
          </p>
        </div>
      </div>
      <div>
        <p className="inter-500-text font-18 line-height-28 natural-900-text">{price_group?.name || '-'}</p>
      </div>
      <div>
        <p className="inter-500-text font-18 line-height-28 natural-900-text">{getFormattedNumber(price || 0)}</p>
      </div>
      <div>
        <p className="inter-500-text font-18 line-height-28 natural-900-text">{rate}%</p>
      </div>
    </div>
  );
};

const SuppliersTab = ({ product }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const pageRef = useRef(null);

  const { id } = product;

  const [loading, setLoading] = useState(false);
  const [supplierList, setSupplierList] = useState([]);

  const prices = (supplierList || []).flatMap(item => item?.prices || []);

  const taskTableHeaders = [
    { name: 'MERCHANT', key: 'MERCHANT' },
    { name: 'PRICE GROUP', key: 'PRICE GROUP' },
    { name: 'PRICE', key: 'PRICE' },
    { name: 'TAX', key: 'TAX' },
  ];

  const fetchSuppliersData = (page, loading = true) => {
    setLoading(loading);
    dispatch(
      getSuppliersList({
        product_id: id,
        params: {
          page: page,
        },
      }),
    )
      .then(data => {
        setSupplierList(data);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SUPPLIERS_PRICING'), id: nanoid() }));
        setSupplierList([]);
      })
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchSuppliersData(pageRef.current + 1, false);
  };

  useEffect(() => {
    fetchSuppliersData(0);
  }, []);

  return (
    <SuppliersWrapper className="flex-column flex-1 w-full radius-1 overflow-hidden h-full">
      <SkeletonTransition
        loading={loading}
        loaderClassName="item flex items-center justify-center flex-1 mb-6 h-full"
        height={'60vh'}
        containerClassName="line-height-1 h-full w-full">
        {supplierList?.length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={taskTableHeaders} />
              <InfiniteScrollV2 hasMore={!supplierList} fetchMoreData={fetchMoreData}>
                {(prices || [])?.map((priceData, index) => (
                  <SupplierItem key={priceData?.id} priceData={priceData} prices={prices} index={index} />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : (
          <div className="flex-column flex-1 items-center justify-center">
            <NoData
              title={t('NO_SUPPLIER_DATA')}
              description={t('NO_SUPPLIER_DATA_HERE')}
              className="search-quote-tempalte"
              EmptyIcon={NoDocumentIcon}
              iconClassName="relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </SuppliersWrapper>
  );
};

const AttributesTab = ({
  product,
  updateProductInfo,
  is_product_owner,
  setIsEditSpecification,
  setSpecifications,
  specifications,
  isUpdateSpecification,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setModal } = useContext(OrganisationContext);

  const { brands, features, families } = product;
  const addFeaturesOption = { id: 'add-feature', value: 'add-new-feature', label: 'Add New Feature' };

  const [addBrands, setAddBrands] = useState(false);
  const [addFamilies, setAddFamilies] = useState(false);
  const [addFeatures, setAddFeatures] = useState(false);
  const [featureData, setFeatureData] = useState([]);

  useEffect(() => {
    setFeatureData(features);
  }, [features]);

  const updatePriority = newOrder => {
    const updatedFeatures = newOrder.map((feature, index) => ({
      ...feature,
      priority: index,
    }));
    setFeatureData(updatedFeatures);
    updateProductInfo('features', updatedFeatures);
  };

  const CustomFamilyOption = props => {
    const { innerProps, data } = props;
    const { icon, name, description, tag } = data || {};
    return (
      <components.Option {...props}>
        <div className="flex items-start py-3 px-4 gap-2" {...innerProps}>
          <div className="mt-1">
            {icon ? (
              <img src={icon?.inactive} alt="icon" width={24} height={24} />
            ) : (
              <PanelIcon width={24} height={24} className="natural-400-text" />
            )}
          </div>
          <div className="flex-1 inter-400-text line-height-150">
            <p className="natural-900-text">{name}</p>
            <p className="natural-500-text">{description}</p>
          </div>
          <div>
            {tag && tag?.length > 0 && (
              <p className="radius-6 py-0_5 px-2_5 primary-500-text inter-500-text line-height-150 bg-primary-50">
                {capitalize(formatText(tag[0], ' '))}
              </p>
            )}
          </div>
        </div>
      </components.Option>
    );
  };

  const MultiValue = () => null;

  const CustomBrandsOption = props => {
    const { innerProps, data } = props;
    return (
      <components.Option {...props}>
        <div className="px-4 py-3 inter-400-text font-14 line-height-20" {...innerProps}>
          <p className="natural-900-text">{data.name}</p>
          <p className="natural-500-text">{data.description}</p>
        </div>
      </components.Option>
    );
  };

  const CustomFeatureOption = props => {
    const { innerProps, data } = props;

    return (
      <components.Option {...props}>
        <div className="flex py-3 px-4 natural-500-text gap-2" {...innerProps}>
          <div>
            <AddIcon />
          </div>
          <div className="inter-500-text natural-500-text font-14 line-height-20">
            <p>{data.label}</p>
          </div>
        </div>
      </components.Option>
    );
  };

  return (
    <div className="pt-6 h-full overflow-y-scroll">
      <div className="border-bottom pb-6">
        <p className="mb-1 inter-600-text text-uppercase line-height-20 font-14 natural-500-text letter-spacing-1">
          {t('BRANDS')}
        </p>
        {brands?.length > 0 || addBrands ? (
          <div className="mt-4 radius-1_5 border">
            <div className="border-top-radius-1_5 brands-grid bg-natural-50 border-bottom">
              <div>
                <p className="inter-600-text font-12 text-uppercase line-height-20 py-2_5 px-4">{t('NAME')}</p>
              </div>
              <div>
                <p className="inter-600-text font-12 text-uppercase line-height-20 py-2_5 px-4">
                  {lowercase(formatText(t('DESCRIPTION')))}
                </p>
              </div>
            </div>
            {brands?.length > 0 && (
              <div>
                {brands?.map(brand => (
                  <div key={brand.id} className="items-center border-bottom brands-grid">
                    <div className="py-2_5 px-4">
                      <p>{brand.name}</p>
                    </div>
                    <div className="py-2_5 px-4">
                      <p>{brand.description}</p>
                    </div>
                    {is_product_owner && (
                      <div
                        className="px-4 natural-900-text cursor"
                        onClick={() =>
                          updateProductInfo(
                            'brands',
                            brands.filter(b => b.id !== brand.id),
                          )
                        }>
                        <CloseIcon className="w-4 h-4" />
                      </div>
                    )}
                  </div>
                ))}
                {!addBrands && is_product_owner && (
                  <div className="px-4 py-2">
                    <Button
                      label={t('ADD_BRAND')}
                      icon={<AddIcon />}
                      onClick={() => setAddBrands(true)}
                      size="medium"
                      className="w-fit-content py-2_5 pr-4"
                      bgColor={theme.white}
                      color={theme.primary_500}
                      borderColor={theme.white}
                    />
                  </div>
                )}
              </div>
            )}
            {addBrands && (
              <div className="py-3 px-4">
                <div className="mb-4">
                  <SearchableDropdown
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={true}
                    isCustomSearchable={false}
                    isMulti={true}
                    value={brands}
                    customComponent={{ Option: CustomBrandsOption, MultiValue }}
                    onChange={e => {
                      updateProductInfo('brands', e);
                      setAddBrands(false);
                    }}
                    placeholder={t('SELECT_BRAND')}
                    defaultAdditional={{
                      page: 0,
                      fetchFunction: getBrands,
                      pageable: false,
                    }}
                    customStyle={{
                      option: {
                        padding: 0,
                      },
                    }}
                  />
                </div>
                <div className="flex justify-end gap-3">
                  <Button
                    bgColor={theme.white}
                    borderColor={theme.natural_200}
                    color={theme.natural_700}
                    label={t('CANCEL')}
                    onClick={() => setAddBrands(false)}
                    className="w-auto px-5 py-1_5"
                    size="medium"
                  />
                  <Button
                    bgColor={theme.primary_500}
                    borderColor={theme.primary_500}
                    color={theme.white}
                    // onClick={functionOnSave}
                    className="w-auto px-5 py-1_5"
                    label={t('ADD')}
                    size="medium"
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          !is_product_owner && (
            <label className="inter-400-text font-14 line-height-20 natural-400-text">No Brands</label>
          )
        )}
        {brands?.length === 0 && !addBrands && is_product_owner && (
          <Button
            label={t('ADD_BRAND')}
            icon={<AddIcon />}
            onClick={() => setAddBrands(true)}
            size="medium"
            className="w-fit-content primary-500-text py-2_5 pr-4"
            bgColor={theme.white}
            borderColor={theme.white}
            color={theme.primary_500}
          />
        )}
      </div>
      <div className="border-bottom py-6">
        <p className="mb-1 inter-600-text text-uppercase line-height-20 font-14 natural-500-text letter-spacing-1">
          {t('FAMILIES')}
        </p>
        {families?.length > 0 || addFamilies ? (
          <div className="mt-4 radius-1_5 border">
            <div className="border-top-radius-1_5 families-grid bg-natural-50 border-bottom">
              <div>
                <p className="inter-600-text font-12 text-uppercase line-height-20 py-2_5 px-4">{t('ICON')}</p>
              </div>
              <div>
                <p className="inter-600-text font-12 text-uppercase line-height-20 py-2_5 px-4">{t('NAME')}</p>
              </div>
              <div>
                <p className="inter-600-text font-12 text-uppercase line-height-20 py-2_5 px-4">
                  {lowercase(formatText(t('DESCRIPTION')))}
                </p>
              </div>
              <div>
                <p className="inter-600-text font-12 text-uppercase line-height-20 py-2_5 px-4">{t('ICON_TAG')}</p>
              </div>
            </div>
            {families?.length > 0 && (
              <div>
                {families?.map(family => (
                  <div key={family.id} className="families-grid items-center border-bottom">
                    <div className="mt-1 py-2_5 px-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.1791 0C16.6145 6.78226e-05 17.038 0.14219 17.3852 0.404788C17.7324 0.667387 17.9845 1.03612 18.1031 1.455L18.1402 1.608L19.8601 10.206C19.9014 10.4125 19.8988 10.6254 19.8523 10.8308C19.8058 11.0363 19.7167 11.2296 19.5905 11.3983C19.4644 11.5669 19.3042 11.7072 19.1203 11.8098C18.9364 11.9124 18.7329 11.9752 18.5231 11.994L18.3891 12H10.9991V14H12.9991C13.254 14.0003 13.4992 14.0979 13.6845 14.2728C13.8699 14.4478 13.9814 14.687 13.9963 14.9414C14.0113 15.1958 13.9285 15.4464 13.7649 15.6418C13.6013 15.8373 13.3692 15.9629 13.1161 15.993L12.9991 16H6.99915C6.74427 15.9997 6.49912 15.9021 6.31378 15.7272C6.12844 15.5522 6.01691 15.313 6.00198 15.0586C5.98704 14.8042 6.06982 14.5536 6.23341 14.3582C6.397 14.1627 6.62905 14.0371 6.88215 14.007L6.99915 14H8.99915V12H1.60915C1.39852 12 1.19026 11.9556 0.997919 11.8697C0.805583 11.7839 0.633486 11.6584 0.492832 11.5017C0.352178 11.3449 0.24612 11.1602 0.181564 10.9597C0.117008 10.7592 0.0954007 10.5474 0.118149 10.338L0.138149 10.206L1.85815 1.608C1.94341 1.18114 2.1656 0.793757 2.49099 0.504635C2.81639 0.215514 3.22723 0.0404447 3.66115 0.00600004L3.81915 0H16.1791ZM17.1791 7H13.6041L13.9041 10H17.7792L17.1791 7ZM11.5941 7H8.40415L8.10415 10H11.8941L11.5941 7ZM6.39415 7H2.81915L2.21915 10H6.09415L6.39415 7ZM16.1791 2H13.1041L13.4041 5H16.7792L16.1791 2ZM11.0941 2H8.90415L8.60415 5H11.3941L11.0941 2ZM6.89415 2H3.81915L3.21915 5H6.59415L6.89415 2Z"
                          fill="#A3A3A3"
                        />
                      </svg>
                    </div>
                    <div className="py-2_5 px-4 inter-400-text line-height-150">
                      <p className="natural-900-text">{family.name}</p>
                    </div>
                    <div className="py-2_5 px-4">
                      <p className="natural-500-text">{family.description}</p>
                    </div>
                    <div className="py-2_5 px-4">
                      {family?.tag && family?.tag?.length > 0 && (
                        <p className="text-center radius-6 py-0_5 px-2_5 primary-500-text inter-500-text line-height-150 bg-primary-50">
                          {capitalize(formatText(family.tag[0], ' '))}
                        </p>
                      )}
                    </div>
                    {is_product_owner && (
                      <div
                        className={classNames('px-4 natural-900-text cursor', !is_product_owner && 'disabled')}
                        onClick={() =>
                          updateProductInfo(
                            'families',
                            families.filter(e => e.value !== family.value),
                          )
                        }>
                        <CloseIcon className="w-4 h-4" />
                      </div>
                    )}
                  </div>
                ))}
                {!addFamilies && is_product_owner && (
                  <div className="px-4 py-2">
                    <Button
                      label={t('ADD_FAMILY')}
                      icon={<AddIcon />}
                      onClick={() => setAddFamilies(true)}
                      size="medium"
                      className="w-fit-content py-2_5 pr-4"
                      bgColor={theme.white}
                      color={theme.primary_500}
                      borderColor={theme.white}
                    />
                  </div>
                )}
              </div>
            )}
            {addFamilies && (
              <div className="py-3 px-4">
                <div className="mb-4">
                  <SearchableDropdown
                    closeMenuOnSelect={true}
                    customComponent={{ Option: CustomFamilyOption, MultiValue }}
                    hideSelectedOptions={true}
                    isCustomSearchable={false}
                    isMulti={true}
                    isSearchable={false}
                    onChange={e => {
                      updateProductInfo('families', e);
                      setAddFamilies(false);
                    }}
                    placeholder={t('SELECT_FAMILY')}
                    value={null}
                    defaultAdditional={{
                      page: 0,
                      fetchFunction: getProductFamilies,
                      pageable: false,
                    }}
                    customStyle={{
                      option: {
                        padding: 0,
                      },
                    }}
                  />
                </div>
                <div className="flex justify-end gap-3">
                  <Button
                    bgColor={theme.white}
                    borderColor={theme.natural_200}
                    color={theme.natural_700}
                    label={t('CANCEL')}
                    onClick={e => setAddFamilies(false)}
                    className="w-auto px-5 py-1_5"
                    size="medium"
                  />
                  <Button
                    bgColor={theme.primary_500}
                    borderColor={theme.primary_500}
                    color={theme.white}
                    // onClick={functionOnSave}
                    className="w-auto px-5 py-1_5"
                    label={t('ADD')}
                    size="medium"
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          !is_product_owner && (
            <label className="inter-400-text font-14 line-height-20 natural-400-text">No Families</label>
          )
        )}
        {families?.length === 0 && !addFamilies && is_product_owner && (
          <Button
            label={t('ADD_FAMILY')}
            icon={<AddIcon />}
            onClick={() => setAddFamilies(true)}
            size="medium"
            className="w-fit-content primary-500-text py-2_5 pr-4"
            bgColor={theme.white}
            borderColor={theme.white}
            color={theme.primary_500}
          />
        )}
      </div>

      <div className="py-6 border-bottom">
        <p className="mb-1 inter-600-text text-uppercase line-height-20 font-14 natural-500-text letter-spacing-1">
          {t('FEATURES')}
        </p>
        {features?.length > 0 || addFeatures ? (
          <div className="mt-4 radius-1_5 border">
            <div className="border-top-radius-1_5 features-grid bg-natural-50 border-bottom">
              <div>
                <p className="inter-600-text font-12 text-uppercase line-height-20 py-2_5 px-4">{t('NAME')}</p>
              </div>
              <div>
                <p className="inter-600-text font-12 text-uppercase line-height-20 py-2_5 px-4">
                  {lowercase(formatText(t('DESCRIPTION')))}
                </p>
              </div>
              <div>
                <p className="inter-600-text font-12 text-uppercase line-height-20 py-2_5 px-4">
                  {t('LONG_DESCRIPTION')}
                </p>
              </div>
            </div>
            <div className="feature-drag">
              <Reorder.Group
                className={classNames('m-0', !is_product_owner && 'disabled')}
                onReorder={newOrder => updatePriority(newOrder)}
                values={featureData || []}>
                {featureData?.map(feature => (
                  <Reorder.Item key={feature?.id} value={feature}>
                    <div className="features-grid items-center border-bottom">
                      <div className="py-2_5 px-4 inter-400-text line-height-150">
                        <p className="natural-900-text one-line">{feature.name}</p>
                      </div>
                      <div className="py-2_5 px-4">
                        <p className="natural-500-text one-line">{feature.description}</p>
                      </div>
                      <div className="py-2_5 px-4">
                        <p className="natural-500-text one-line">{feature.long_description}</p>
                      </div>
                      {is_product_owner && (
                        <div
                          className={classNames('px-4 natural-900-text cursor', !is_product_owner && 'disabled')}
                          onClick={() =>
                            updateProductInfo(
                              'features',
                              features.filter(f => f.id !== feature.id),
                            )
                          }>
                          <CloseIcon className="w-4 h-4" />
                        </div>
                      )}
                    </div>
                  </Reorder.Item>
                ))}
              </Reorder.Group>

              {!addFeatures && is_product_owner && (
                <div className="px-4 py-2">
                  <Button
                    label={t('ADD_FEATURE')}
                    icon={<AddIcon />}
                    onClick={() => setAddFeatures(true)}
                    size="medium"
                    className="w-fit-content py-2_5 pr-4"
                    bgColor={theme.white}
                    color={theme.primary_500}
                    borderColor={theme.white}
                  />
                </div>
              )}
            </div>
            {addFeatures && (
              <div className="py-3 px-4">
                <div className="mb-4">
                  <Dropdown
                    options={[addFeaturesOption]}
                    onChange={e => {
                      setModal({
                        type: 'add-feature',
                        content: {
                          product,
                          updateProductInfo,
                        },
                      });
                    }}
                    isSearchable={false}
                    isCustomSearchable={false}
                    placeholder={t('SELECT_FEATURE')}
                    customComponent={{ Option: CustomFeatureOption }}
                    customStyle={{
                      option: {
                        padding: 0,
                      },
                    }}
                  />
                </div>
                <div className="flex justify-end gap-3">
                  <Button
                    bgColor={theme.white}
                    borderColor={theme.natural_200}
                    color={theme.natural_700}
                    label={t('CANCEL')}
                    onClick={() => setAddFeatures(false)}
                    className="w-auto px-5 py-1_5"
                    size="medium"
                  />
                  <Button
                    bgColor={theme.primary_500}
                    borderColor={theme.primary_500}
                    color={theme.white}
                    // onClick={functionOnSave}
                    className="w-auto px-5 py-1_5"
                    label={t('ADD')}
                    size="medium"
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          !is_product_owner && (
            <label className="inter-400-text font-14 line-height-20 natural-400-text">No Features</label>
          )
        )}

        {features?.length === 0 && !addFeatures && is_product_owner && (
          <Button
            label={t('ADD_FEATURE')}
            onClick={() => setAddFeatures(true)}
            icon={<AddIcon />}
            size="medium"
            className="w-fit-content primary-500-text py-2_5 pr-4"
            bgColor={theme.white}
            borderColor={theme.white}
            color={theme.primary_500}
          />
        )}
      </div>
      <div className="pt-6">
        <p className="mb-1 inter-600-text text-uppercase line-height-20 font-14 natural-500-text letter-spacing-1">
          {t('SPECIFICATIONS')}
        </p>
        <div className="">
          <ProductSpecification
            updateProductInfo={updateProductInfo}
            setIsEditSpecification={setIsEditSpecification}
            setSpecifications={setSpecifications}
            specifications={specifications}
            isUpdateSpecification={isUpdateSpecification}
            is_product_owner={is_product_owner}
          />
        </div>
      </div>
    </div>
  );
};

const ComponentsTab = ({ is_product_owner }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [addComponentMap, setAddComponentMap] = useState(false);

  return (
    <>
      <div className="pt-9">
        {is_product_owner && (
          <Button
            label={t('ADD_COMPONENT_MAP')}
            icon={<AddIcon />}
            onClick={() => setAddComponentMap(true)}
            size="medium"
            className="w-fit-content py-2_5 pr-4"
            bgColor={theme.white}
            color={theme.primary_500}
            borderColor={theme.white}
          />
        )}
      </div>
    </>
  );
};

const SuppliersWrapper = styled.div`
  border-radius: 6px;
  padding-top: 24px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(160px, 1fr) 120px 100px 80px;
    column-gap: 48px;
    padding: 12px 31px;
  }

  .header-container {
    height: 40px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 300px;
  }

  .search-terms {
    max-width: 343px;
  }
`;

const ProductWrapper = styled.div`
  .selected-tab {
    margin-bottom: -1px;
  }

  .brands-grid {
    display: grid;
    grid-template-columns: 240px 1fr 50px;
  }

  .product-texarea textarea {
    min-height: 44px !important;
  }

  .features-grid {
    display: grid;
    grid-template-columns: 240px 240px 1fr 50px;
  }

  .families-grid {
    display: grid;
    grid-template-columns: 64px 192px 1fr 150px 50px;
  }
  .feature-drag ul {
    list-style: none;
    padding: 0;
  }

  .product-media-type-container {
    left: 8px;
    bottom: 8px;
    background: rgba(0, 0, 0, 0.5);
  }
  .delete-icon {
    right: 8px;
    bottom: 8px;
    left: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
  .primary-image {
    right: 20px;
  }
  .product-image-hover {
    &:hover {
      .delete-icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .price-container {
    .price-input {
      padding: 2px 12px;
      height: 32px;
    }

    .action-wrapper {
      position: absolute;
      right: 0;
      bottom: -40px;
      z-index: 1000;
      padding: 4px 8px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
    }
  }

  .price-value-hover {
    &:hover {
      background-color: ${({ theme }) => theme.natural_100};
      border-radius: 4px;
    }
  }
`;
