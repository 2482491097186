import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import GoogleMaps from '../../components/common/google-maps';
import SkeletonTransition from '../../components/common/skeleton-transition';
import { getRoofDetailsFromManualOrImported } from '../../helpers/utils';
import { useQuery } from '../../hooks/useQuery';
import {
  createSegmentPanels,
  getRoofInformation,
  getRoofPanelInformation,
  getSolarOptions,
  setRoofInformation,
  setRoofPanelInformation,
  updateRoofPanels,
  updateRoofSegment,
} from '../../store/features/propertySlice';
import {
  createQuote,
  getSolarOerviewData,
  getSystemDetailsData,
  updateQuoteDetails,
} from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const RoofDetailsV2 = ({ fromProperty, property_id, fetchSolarDetails = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { property_id: property_id_param } = useParams();

  const firstTimeLoad = useRef(true);
  const prevPanelCount = useRef(0);
  const visiblePanelsBySegment = useRef({});
  const hiddenPanelsBySegment = useRef({});

  const query = useQuery();
  const takePhoto = query.get('take_photo');
  const designId = query.get('design_id');

  const { propertyDetails, roofInformation, roofPanelInformation, jobDetails } = useSelector(state => state.property);
  const { workitemDetails } = useSelector(state => state.board);
  const { details, property_class } = propertyDetails || {};
  const { engagement } = jobDetails || {};
  const { primary_contact } = engagement || {};

  const engagement_id = workitemDetails?.engagement?.id;

  const segments = useMemo(() => {
    return (
      (roofInformation &&
        roofInformation?.map(({ segments, whole_roof }) => segments.map(s => ({ ...s, whole_roof }))).flat()) ||
      []
    );
  }, [roofInformation]);

  const [roofInfoLoading, setRoofInfoLoading] = useState(true);
  const [roofPanelInfoLoading, setRoofPanelInfoLoading] = useState(true);
  const [roofPanels, setRoofPanels] = useState([]);
  const [segmentsState, setSegmentsState] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);
  const [segmentsToSave, setSegmentsToSave] = useState([]);
  const [panelsToDelete, setPanelsToDelete] = useState([]);
  const [solarDetails, setSolarDetails] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [changingSegmentFormat, setChangingSegmentFormat] = useState(null);
  const [savingSegment, setSavingSegment] = useState({});

  const propertyId = fromProperty ? property_id_param : property_id;
  const { panel_product, quote } = selectedDesign || {};

  const panels_count = useMemo(() => {
    return roofPanelInformation?.length || 0;
  }, [roofPanelInformation]);

  const isResidencial = useMemo(() => {
    return property_class?.class_type === 'RESIDENTIAL';
  }, [property_class?.class_type]);

  const maxBundle = useMemo(() => {
    // const optionData = solarOptions?.options || [];
    // const configWithMaxPanels = optionData?.sort((a, b) => b?.config?.panels - a?.config?.panels);
    // return configWithMaxPanels?.[0];
    return {};
  }, []);

  const panelDetails = useMemo(() => {
    const { specifications = [], name, image } = panel_product || { specifications: [] };
    const capacity = specifications.find(({ classification }) => classification === 'POWER_OUT')?.specification || 430;
    const width = specifications.find(({ classification }) => classification === 'WIDTH')?.specification || 187.9;
    const height = specifications.find(({ classification }) => classification === 'HEIGHT')?.specification || 104.5;
    const boundaryGap = isResidencial ? 20 : 50;
    return {
      name,
      image,
      capacity,
      width: parseFloat(width / 100).toFixed(3),
      height: parseFloat(height / 100).toFixed(3),
      boundaryGap: parseFloat(boundaryGap / 100).toFixed(3),
    };
  }, [panel_product, isResidencial]);

  const fetchSolarDesignData = (firstTime = false) => {
    dispatch(
      getSolarOerviewData({
        engagement_id: engagement_id,
      }),
    )
      .then(data => {
        setSolarDetails(data);
        if (firstTime) {
          const designData = designId ? data.find(({ id }) => id === designId) : data?.[0];
          setSelectedDesign({ ...(selectedDesign || {}), ...designData });
        }
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SOLAR_DESIGNS') })));
  };

  const fetchSolarDesignAdditionalData = async () => {
    try {
      const data = await dispatch(
        getSystemDetailsData({
          engagementId: engagement_id,
          designId: selectedDesign?.id,
        }),
      );
      setSelectedDesign({ ...selectedDesign, ...data });
      return { ...selectedDesign, ...data };
    } catch (e) {
      dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SYSTEM_DETAILS') }));
      return selectedDesign;
    }
  };

  const fetchSolarOptions = async (params = {}, fetchOnly = false) => {
    try {
      const data = await dispatch(getSolarOptions({ id: selectedDesign.id, params }));
      return data;
    } catch (error) {
      dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SOLAR_OPTIONS') }));
      return {};
    }
  };

  const fetchRoofDetailsHandler = (design_id, loading = true) => {
    setRoofInfoLoading(loading);
    dispatch(getRoofInformation({ id: design_id }))
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_ROOF_DETAILS') })))
      .finally(() => setRoofInfoLoading(false));
  };

  const fetchRoofPanels = design_id => {
    const segmentData = roofInformation?.map(({ segments }) => segments).flat();
    // const wholeRoofIds = roofInformation?.map(({ whole_roof }) => whole_roof.id);
    setRoofPanelInfoLoading(true);
    Promise.all(
      segmentData.map(roof =>
        dispatch(
          getRoofPanelInformation({
            id: design_id,
            roof_id: roof.id,
            params: { source: roof?.panel_grid?.length ? 'MANUAL' : 'IMPORTED' },
          }),
        ),
      ),
    )
      .then(data => {
        const updatedRoofPanels = data.flat();
        setRoofPanels(updatedRoofPanels);
        dispatch(setRoofPanelInformation(updatedRoofPanels));
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PANEL_DETAILS') })))
      .finally(() => setRoofPanelInfoLoading(false));
  };

  const updateSegmentsToSave = (segment_id, action = 'add') => {
    const updatedSegmentsToSave = segmentsToSave.filter(id => id !== segment_id);
    if (action === 'add') {
      setSegmentsToSave([...segmentsToSave, segment_id]);
    }
    if (action === 'remove') {
      setSegmentsToSave(updatedSegmentsToSave);
    }
  };

  const onSegmentBoundryUpdate = (segment_id, boundary, area) => {
    const firstBoundary = boundary?.[0];
    const updatedSegments = segmentsState.map(segment => {
      if (segment.id === segment_id) {
        return { ...segment, manual: { ...segment.manual, boundary: [...boundary, firstBoundary], area } };
      }
      return segment;
    });
    setSegmentsState(updatedSegments);
    updateSegmentsToSave(segment_id);
  };

  const onSegmentDetailsUpdate = (segment_id, key, value) => {
    const updatedSegments = segmentsState.map(segment => {
      if (segment.id === segment_id) {
        return {
          ...segment,
          alignment_line: key === 'manual' ? null : segment.alignment_line,
          manual: key === 'manual' ? { ...value } : { ...segment.manual, [key]: value },
        };
      }
      return segment;
    });
    setSegmentsState(updatedSegments);
    updateSegmentsToSave(segment_id);
  };

  const onCancelSegment = segment_id => {
    const currentSegment = segments.find(segment => segment.id === segment_id);
    const updatedRoofPanels = roofPanels
      .map(panel => {
        if (panel.roof?.id === segment_id) {
          if (panel.isNew) return;
          const currentPanel = roofPanelInformation.find(({ id }) => id === panel.id);
          return { ...currentPanel };
        }
        return panel;
      })
      .filter(Boolean);
    const updatedSegments = segmentsState.map(segment => {
      if (segment.id === segment_id) {
        return { ...currentSegment };
      }
      return segment;
    });
    setRoofPanels(updatedRoofPanels);
    setSegmentsState(updatedSegments);
    setPanelsToDelete([]);
    updateSegmentsToSave(segment_id, 'remove');
  };

  const onSaveSegment = async (segment_id, updatedSegment = null, onlyUpdateRoof = false) => {
    const segment = updatedSegment ? updatedSegment : segmentsState.find(segment => segment.id === segment_id);
    const manualBoundary = getRoofDetailsFromManualOrImported(segment, 'boundary', false, false, false);
    const manualArea = getRoofDetailsFromManualOrImported(segment, 'area', false, false, false);
    const azimuth = getRoofDetailsFromManualOrImported(segment, 'azimuth', false, false, false);
    const grid_alignment = getRoofDetailsFromManualOrImported(segment, 'grid_alignment', true, false, false) || azimuth;
    const pitch = getRoofDetailsFromManualOrImported(segment, 'pitch', false, false, false);
    const whole_roof_id = segment?.whole_roof?.id;
    const isManual = segment?.panel_grid?.length;

    const hiddenPanelsToDelete = hiddenPanelsBySegment.current[segment_id] || [];

    const segmentPanels = roofPanels
      .filter(panel => panel.roof?.id === segment_id)
      .filter(({ id }) => !panelsToDelete.includes(id) && !hiddenPanelsToDelete.includes(id));

    const panelRequest = segmentPanels?.filter(panel => !panel.isNew);
    const newPanelRequest = segmentPanels?.filter(panel => panel.isNew);

    const request = {
      alignment_line: segment.alignment_line,
      boundary: manualBoundary,
      area: manualArea,
      azimuth: azimuth,
      grid_alignment: grid_alignment,
      pitch: pitch,
      panel_grid: segment.panel_grid,
      panel_count: panelRequest.length,
    };

    try {
      setSavingSegment(prev => ({ ...prev, [segment_id]: true }));
      let data = [];
      if (!onlyUpdateRoof) {
        data = await dispatch(
          updateRoofPanels({
            id: selectedDesign.id,
            roof_id: segment_id,
            request: panelRequest,
            params: {
              source: isManual ? 'MANUAL' : 'IMPORTED',
            },
          }),
        );
        if (newPanelRequest.length) {
          const newPanelData = await dispatch(
            createSegmentPanels({
              id: selectedDesign.id,
              roof_id: segment_id,
              request: newPanelRequest,
              params: {
                source: isManual ? 'MANUAL' : 'IMPORTED',
              },
            }),
          );
          data = newPanelData;
        }
      }
      const segmentData = await dispatch(
        updateRoofSegment({ id: selectedDesign.id, roof_id: segment_id, whole_roof_id, request }),
      );
      fetchSolarDesignAdditionalData();
      if (!onlyUpdateRoof) {
        const panelsWithoutCurrentData = roofPanels?.filter(panel => panel.roof?.id !== segment_id);
        const updatedPanels = [...panelsWithoutCurrentData, ...data];
        setRoofPanels(updatedPanels);
        dispatch(setRoofPanelInformation(updatedPanels));
      }
      setSegmentsState(
        segmentsState.map(s => (s.id === segment_id ? { ...segmentData, whole_roof: segment?.whole_roof } : s)),
      );
      updateSegmentsToSave(segment_id, 'remove');
      setSavingSegment(prev => ({ ...prev, [segment_id]: false }));
    } catch (error) {
      setSavingSegment(prev => ({ ...prev, [segment_id]: false }));
      dispatch(addToast({ error: true, text: t('ERROR_WHILE_SAVING_SEGMENT_AND_PANELS') }));
    }
  };

  const onDeleteRoofOrSegment = (roof_id, isSegment) => {
    if (isSegment) {
      const updatedRoofInformation = roofInformation.map(({ segments, ...rest }) => ({
        ...rest,
        segments: segments.filter(segment => segment.id !== roof_id),
      }));
      const updatedPanels = roofPanels.filter(panel => panel.roof?.id !== roof_id);
      setRoofPanels(updatedPanels);
      dispatch(setRoofPanelInformation(updatedPanels));
      dispatch(setRoofInformation(updatedRoofInformation));
    } else {
      const updatedRoofInformation = roofInformation.filter(({ whole_roof }) => whole_roof.id !== roof_id);
      const deletedRoof = roofInformation.find(({ whole_roof }) => whole_roof.id === roof_id);
      const { segments } = deletedRoof || {};
      const segmentIds = segments.map(({ id }) => id);
      const updatedPanels = roofPanels.filter(panel => !segmentIds.includes(panel.roof?.id));
      setRoofPanels(updatedPanels);
      dispatch(setRoofPanelInformation(updatedPanels));
      dispatch(setRoofInformation(updatedRoofInformation));
    }
  };

  const onUpdatePanelPaths = (panel_id, panelCenter, segment_id) => {
    const updatedPanels = roofPanels.map(panel => {
      if (panel.id === panel_id) {
        return { ...panel, center: panelCenter };
      }
      return panel;
    });
    setRoofPanels(updatedPanels);
    updateSegmentsToSave(segment_id);
  };

  const onUpdatePanelCenter = (panels, segment_ids) => {
    const panelIds = panels.map(panel => panel.id);
    const updatedPanels = roofPanels.map(panel => {
      if (panelIds.includes(panel.id)) {
        const panelData = panels.find(({ id }) => id === panel.id);
        return { ...panelData };
      }
      return panel;
    });
    const updatedSegments = segmentsToSave.filter(segment_id => !segment_ids.includes(segment_id));
    setRoofPanels(updatedPanels);
    setSegmentsToSave([...updatedSegments, ...segment_ids]);
  };

  const onPanelSelectForDelete = (panel_id, segment_id, removeIfSelected) => {
    const isPanelAlreadySelected = panelsToDelete.includes(panel_id);
    if (isPanelAlreadySelected) {
      removeIfSelected && setPanelsToDelete(panelsToDelete.filter(id => id !== panel_id));
    } else {
      setPanelsToDelete([...panelsToDelete, panel_id]);
    }
    const updatedSegments = segmentsToSave.filter(s_id => s_id !== segment_id);
    setSegmentsToSave([...updatedSegments, segment_id]);
  };

  const onPanelAdd = ({ center: panelCenter, row, column }, segment_id, panelId) => {
    const panelOrientation = roofPanels?.find(panel => panel.roof?.id === segment_id)?.orientation ?? 'PORTRAIT';
    const newPanel = {
      id: panelId ?? uuid(),
      roof: { id: segment_id },
      center: panelCenter,
      row: row >= 0 ? row : null,
      column: column >= 0 ? column : null,
      capacity: panelDetails.capacity,
      orientation: panelOrientation,
      width: panelDetails.width,
      height: panelDetails.height,
      yearly_energy: null,
      isNew: true,
    };
    const updatedPanels = [...roofPanels, newPanel];
    setRoofPanels(updatedPanels);
    updateSegmentsToSave(segment_id);
  };

  const onAddedPanelDelete = (panel_id, segment_id) => {
    const updatedPanels = roofPanels.filter(panel => panel.id !== panel_id);
    setRoofPanels(updatedPanels);
    updateSegmentsToSave(segment_id);
  };

  const updateQuoteQuantity = async design => {
    try {
      const { quote, panel_product } = design || selectedDesign || {};
      const { id: quote_id, products } = quote || {};
      const qty = panels_count;
      const isProductWithComponents = products?.find(p => p?.components?.length);

      let request = {
        ...quote,
        integration: quote?.integration?.id ? { id: quote?.integration?.id } : null,
      };
      const panel_product_id = panel_product?.id;
      const solarOptions = await fetchSolarOptions(
        {
          panel_product_id: panel_product_id,
          force_components: isResidencial && isProductWithComponents ? false : true,
        },
        true,
      );

      if (solarOptions?.options?.length) {
        if (isResidencial && isProductWithComponents) {
          const { options } = solarOptions || {};
          const sortedOptions = options?.sort((a, b) => b?.config?.panels - a?.config?.panels);
          const optionWithMatchingPanels = sortedOptions.find(option => option?.config?.panels <= qty);

          const { bundle } = optionWithMatchingPanels || {};
          request = {
            ...request,
            products: [
              {
                name: bundle?.name,
                description: bundle?.description,
                sku: bundle?.sku,
                components: bundle?.component_maps
                  ?.map(c => {
                    return (
                      c.components.find(comp => comp?.id === panel_product_id) ||
                      c.components.find(comp => comp?.is_inclusive)
                    );
                  })
                  .filter(Boolean)
                  .map((c, index) => ({
                    name: c?.name,
                    description: c?.description,
                    sku: c?.sku,
                    line_item_type: 'OTHER',
                    image: c?.image?.media_external_id ? { id: c?.image?.media_external_id } : null,
                    qty: c.quantity,
                    product: { id: c?.id },
                    line_no: index + 1,
                    unit: 'COUNT',
                    product_type: 'PHYSICAL',
                    total_net: c?.pricing?.total_net,
                    total_amount: c?.pricing?.total_amount,
                    total_tax: c?.pricing?.total_tax,
                    unit_price: c?.pricing?.unit_price,
                  })),
                product: { id: bundle?.id },
                qty: 1,
                line_no: 1,
                unit: 'COUNT',
                line_item_type: 'OTHER',
                product_type: 'PHYSICAL',
                total_net: bundle?.pricing?.total_net,
                total_amount: bundle?.pricing?.total_amount,
                total_tax: bundle?.pricing?.total_tax,
                unit_price: bundle?.pricing?.unit_price,
              },
            ],
            total_net: bundle?.pricing?.total_net,
            total_amount: bundle?.pricing?.total_amount,
            total_tax: bundle?.pricing?.total_tax,
            unit_price: bundle?.pricing?.unit_price,
          };
        } else {
          const firstOption = solarOptions?.options?.[0];
          const { bundle, products: optionProducts } = firstOption || {};
          const products = bundle ? [{ ...bundle }] : optionProducts || [];

          request = {
            ...request,
            products: products.map((product, index) => {
              const { pricing, quantity, id, image, ...rest } = product;
              return {
                ...rest,
                image: image?.media_external_id ? { id: image?.media_external_id } : null,
                product_type: 'PHYSICAL',
                unit: 'COUNT',
                line_no: index + 1,
                product: { id },
                qty: quantity || 1,
                unit_price: pricing?.unit_price || 0,
                discount_amount: pricing?.discount_amount || 0,
                total_net: pricing?.total_net || 0,
                total_tax: pricing?.total_tax || 0,
                total_amount: pricing?.total_amount || 0,
              };
            }),
            total_amount: products.reduce((acc, product) => acc + (product.pricing?.total_amount || 0), 0),
            total_net: products.reduce((acc, product) => acc + (product.pricing?.total_net || 0), 0),
            total_tax: products.reduce((acc, product) => acc + (product.pricing?.total_tax || 0), 0),
            total_discount: products.reduce((acc, product) => acc + (product.pricing?.discount_amount || 0), 0),
          };
        }
        await dispatch(updateQuoteDetails({ quote_id, request }))
          .then(data => {
            setSelectedDesign({ ...selectedDesign, quote: data });
            fetchSolarDesignAdditionalData();
          })
          .catch(error => dispatch(addToast({ error: true, message: 'Failed to update quote', id: nanoid() })))
          .finally(() => setChangingSegmentFormat(false));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (segments?.length && selectedDesign?.id) {
      fetchRoofPanels(selectedDesign?.id);
    }
  }, [segments?.length, selectedDesign?.id]);

  useEffect(() => {
    setSegmentsState(segments);
  }, [segments.length]);

  useEffect(() => {
    if (fetchSolarDetails && engagement_id) {
      fetchSolarDesignData(true);
    }
  }, [fetchSolarDetails, engagement_id]);

  useEffect(() => {
    if (fetchSolarDetails && selectedDesign?.id) {
      fetchSolarDesignAdditionalData();
      fetchRoofDetailsHandler(selectedDesign?.id);
    }
  }, [fetchSolarDetails, selectedDesign?.id]);

  useEffect(() => {
    if (quote?.id && panels_count >= 0 && !roofPanelInfoLoading) {
      const isCountSame = prevPanelCount.current === panels_count;
      if (firstTimeLoad.current) {
        firstTimeLoad.current = false;
      } else {
        if (!isCountSame) {
          updateQuoteQuantity();
          prevPanelCount.current = panels_count;
        }
      }
    }
  }, [panels_count, quote?.id, roofPanelInfoLoading]);

  const onCreateQuote = async solarOptions => {
    let request = {
      integration: null,
      code: null,
      status: 'DRAFT',
      solar_design: {
        id: selectedDesign?.id,
      },
      quote_type: 'COST',
      payment_terms_template: null,
      quote_template: null,
      recipients: primary_contact?.id ? [{ contact: { id: primary_contact?.id }, requires_acceptance: true }] : null,
    };
    if (isResidencial) {
      const sortedOptions = solarOptions?.sort((a, b) => b?.products?.length - a?.products?.length);
      const optionWithMatchingPanels = sortedOptions.find(option => option?.config?.panels <= panels_count);
      const { bundle } = optionWithMatchingPanels || {};

      request = {
        ...request,
        products: [
          {
            name: bundle?.name,
            description: bundle?.description,
            sku: bundle?.sku,
            components: bundle?.component_maps
              ?.map(c => {
                return c.components.find(comp => comp?.sell_type === 'BASE');
              })
              .filter(Boolean)
              .map((c, index) => ({
                name: c?.name,
                description: c?.description,
                sku: c?.sku,
                line_item_type: 'OTHER',
                image: c?.image?.media_external_id ? { id: c?.image?.media_external_id } : null,
                qty: c.quantity,
                line_no: index + 1,
                unit: 'COUNT',
                product_type: 'PHYSICAL',
              })),
            product: { id: bundle?.id },
            qty: 1,
            line_no: 1,
            unit: 'COUNT',
            line_item_type: 'OTHER',
            product_type: 'PHYSICAL',
            total_net: bundle?.pricing?.total_net,
            total_amount: bundle?.pricing?.total_amount,
            total_tax: bundle?.pricing?.total_tax,
            unit_price: bundle?.pricing?.unit_price,
          },
        ],
        total_net: bundle?.pricing?.total_net,
        total_amount: bundle?.pricing?.total_amount,
        total_tax: bundle?.pricing?.total_tax,
        unit_price: bundle?.pricing?.unit_price,
      };
    } else {
      const firstOption = solarOptions?.[0];
      const { bundle, products: optionProducts } = firstOption || {};
      const products = bundle ? [{ ...bundle }] : optionProducts || [];
      request = {
        ...request,
        products: products.map((product, index) => {
          const { pricing, quantity, id, image, ...rest } = product;
          return {
            ...rest,
            image: image?.media_external_id ? { id: image?.media_external_id } : null,
            product_type: 'PHYSICAL',
            unit: 'COUNT',
            line_no: index + 1,
            product: { id },
            qty: quantity || 1,
            unit_price: pricing?.unit_price || 0,
            discount_amount: pricing?.discount_amount || 0,
            total_net: pricing?.total_net || 0,
            total_tax: pricing?.total_tax || 0,
            total_amount: pricing?.total_amount || 0,
          };
        }),
        total_amount: products.reduce((acc, product) => acc + (product.pricing?.total_amount || 0), 0),
        total_net: products.reduce((acc, product) => acc + (product.pricing?.total_net || 0), 0),
        total_tax: products.reduce((acc, product) => acc + (product.pricing?.total_tax || 0), 0),
        total_discount: products.reduce((acc, product) => acc + (product.pricing?.discount_amount || 0), 0),
      };
    }
    try {
      const quoteData = await dispatch(createQuote({ request: request, engagement_id }));
      setSelectedDesign({ ...selectedDesign, quote: quoteData });
      fetchSolarDesignData();
      fetchSolarDesignAdditionalData();
    } catch (error) {
      dispatch(addToast({ error: true, text: t('ERROR_WHILE_CREATING_QUOTE') }));
    }
  };

  const fetchSolarOptionsAndCreateQuote = async (create_quote = false) => {
    if (selectedDesign?.quote?.id) {
      return;
    }
    try {
      setRoofInfoLoading(true);
      const optionsData = await dispatch(
        getSolarOptions({
          id: selectedDesign.id,
          params: { panel_count: create_quote && !isResidencial ? panels_count : null },
        }),
      );
      const { options } = optionsData || {};
      if (create_quote) {
        await onCreateQuote(options);
      }
      setRoofInfoLoading(false);
    } catch (error) {
      dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SOLAR_OPTIONS') }));
      setRoofInfoLoading(false);
    }
  };

  return (
    <SkeletonTransition
      loading={roofInfoLoading}
      loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
      height={'100%'}
      containerClassName="line-height-1 h-full w-full rounded-3">
      <div className="overflow-scroll edit-roof flex-1">
        <div className={'flex items-center justify-center radius-3 entire-map-wrapper h-full relative'}>
          <GoogleMaps
            coordinates={[propertyDetails.location]}
            roofInformation={roofInformation}
            isSolar={false}
            isRoofEditV2={true}
            additionalOptions={{ draggable: true }}
            property_id={fromProperty ? property_id_param : property_id}
            roofPanels={roofPanels}
            setRoofPanels={setRoofPanels}
            segments={segmentsState}
            onSegmentBoundryUpdate={onSegmentBoundryUpdate}
            onSaveSegment={onSaveSegment}
            onUpdatePanelPaths={onUpdatePanelPaths}
            propertyImages={propertyImages}
            segmentsToSave={segmentsToSave}
            onSegmentDetailsUpdate={onSegmentDetailsUpdate}
            onPanelSelectForDelete={onPanelSelectForDelete}
            panelsToDelete={panelsToDelete}
            annual_electric_kwh={details?.annual_electric_kwh}
            onClearPanelSelection={() => setPanelsToDelete([])}
            onUpdatePanelCenter={onUpdatePanelCenter}
            onCancelSegment={onCancelSegment}
            solarDetails={solarDetails}
            fetchSolarDesignData={fetchSolarDesignData}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            fetchSolarDetails={fetchSolarDetails}
            panelDetails={panelDetails}
            onPanelAdd={onPanelAdd}
            onAddedPanelDelete={onAddedPanelDelete}
            takePhoto={takePhoto}
            designId={designId}
            engagement_id={engagement_id}
            setSegmentsState={setSegmentsState}
            onDeleteRoofOrSegment={onDeleteRoofOrSegment}
            fetchSolarDesignAdditionalData={fetchSolarDesignAdditionalData}
            maxBundle={isResidencial ? maxBundle : null}
            fetchSolarOptionsAndCreateQuote={fetchSolarOptionsAndCreateQuote}
            updateQuoteQuantity={updateQuoteQuantity}
            changingSegmentFormat={changingSegmentFormat}
            setChangingSegmentFormat={setChangingSegmentFormat}
            isResidencial={isResidencial}
            savingSegment={savingSegment}
            visiblePanelsBySegment={visiblePanelsBySegment}
            hiddenPanelsBySegment={hiddenPanelsBySegment}
          />
        </div>
      </div>
    </SkeletonTransition>
  );
};

export default RoofDetailsV2;
