import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { bytesToSize, getErrorDescription, initModal } from '../../helpers/utils';
import { deleteProductMedia, setProductInfo } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const DeleteProductMedia = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal, setModal } = useContext(OrganisationContext);
  const { product, media, productImages, product_media_id, onSuccess, isPrimaryImage } = modal.content;
  const { id: product_id } = product || {};
  const { id: mediaId, name: media_name, created_on, filesize } = media || {};
  const [loading, setLoading] = useState(false);
  const findNextImage = (productImages || []).find(image => image?.media?.id !== mediaId);

  const onDone = async () => {
    setLoading(true);
    try {
      if (isPrimaryImage) {
        const request = {
          ...product,
          image: findNextImage
            ? {
                id: findNextImage?.media?.media_external_id,
              }
            : null,
        };
        await dispatch(setProductInfo({ id: product_id, request: request }))
          .then(() => {
            dispatch(addToast({ error: false, text: t('PRODUCT_PRIMARY_MEDIA_UPDATE_SUCCESSFULLY'), id: nanoid() }));
          })
          .catch(error =>
            dispatch(addToast({ error: true, text: t('ERROR_WHILE_UPDATING_PRODUCT_PRIMARY_MEDIA'), id: nanoid() })),
          )
          .finally(() => {});
      }
      await dispatch(deleteProductMedia({ product_id: product_id, media_id: product_media_id }));
      dispatch(addToast({ error: false, text: t('PRODUCT_MEDIA_DELETED_SUCCESSFULLY'), id: nanoid() }));
      onSuccess && onSuccess();
      setModal(initModal);
    } catch (error) {
      const errorMessage = t('ERROR_WHILE_DELETING_PRODUCT_MEDIA');
      const textFromError = getErrorDescription(error, errorMessage);
      dispatch(addToast({ error: true, text: textFromError, id: nanoid() }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteProductWrapper className="flex-column items-center radius-3 pxy-6">
        <div className="flex-column content-wrapper gap-5 items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">
              {t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PHOTO')}
            </label>
            <p className="inter-400-text natural-400-text text-center font-14">{t('THIS_ACTION_CANT_UNDONE')}</p>
          </div>
        </div>
        <div className="flex-column border radius-1_5 py-4 px-5 w-full row-gap-1">
          <label className="inter-600-text natural-900-text font-16">{media_name}</label>
          <label className="inter-400-text natural-500-text font-14">
            {moment(created_on).format('MMM DD, YYYY')} ({filesize ? bytesToSize(filesize) : t('NO_FILES')})
          </label>
          <img src={media?.url} className="radius-1_5 mt-3 block object-fit-cover aspect-ratio-square w-full" />
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative')}
            label={t('DELETE')}
            disabled={loading}
            loading={loading}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </DeleteProductWrapper>
    </CSSTransition>
  );
};

export const DeleteProductWrapper = styled.div`
  width: 420px;
  gap: 24px;
`;

export default DeleteProductMedia;
