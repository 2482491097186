import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const taskSlice = createSlice({
  name: 'task',
  initialState: {
    taskList: [],
    propertyTasks: [],
  },
  reducers: {
    setTaskList: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.taskList = { ...data, content: [...state.taskList.content, ...data.content] };
      } else {
        state.taskList = data;
      }
    },
    setPropertyTasks: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.propertyTasks = { ...data, content: [...state.propertyTasks.content, ...data.content] };
      } else {
        state.propertyTasks = data;
      }
    },
  },
});

export const getTaskList =
  ({ params, merge }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/tasks`, { params: params });
      dispatch(setTaskList({ data, merge }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPropertyTaskList =
  ({ params, merge }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/tasks`, { params: params });
      dispatch(setPropertyTasks({ data, merge }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getTaskEvidences =
  ({ params, merge, task_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/tasks/${task_id}/evidence`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getTaskSubTasks =
  ({ params, merge, task_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/tasks/${task_id}/tasks`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getForms = () => async dispatch => {
  try {
    const { data } = await api.get(`/forms`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTaskComments = payload => async dispatch => {
  try {
    const { engagement_id, params } = payload;
    const { data } = await api.get(`/engagements/${engagement_id}/notes`, { params: params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addTaskComment =
  ({ engagement_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/engagements/${engagement_id}/notes`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getJobTypeServices =
  ({ job_type_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/job_types/${job_type_id}/services`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addJobActivityTask =
  ({ params, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/tasks`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateTask =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/tasks/${id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateTaskProgress =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/tasks/${id}/progress`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateActivityTask =
  ({ activity_id, id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/activities/${activity_id}/tasks/${id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getTaskDetails =
  ({ id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/tasks/${id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setTaskList, setPropertyTasks } = taskSlice.actions;
export default taskSlice.reducer;
