import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow-to-left.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { initSideModal } from '../../helpers/utils';
import { createJob } from '../../store/features/jobSlice';
import { addToast } from '../../store/features/toastSlice';
import JobsDetails from './job-details';

const AddNewJob = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { sideModal, setSideModal } = useContext(OrganisationContext);
  const { property, onSuccess } = sideModal?.content || {};

  const [job, setJob] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const checkJobErrors = () => {
    const { project, job_type, workflow, primary_contact } = job;
    if (!project || !job_type || !workflow || !primary_contact?.id) {
      setError({
        project: !project,
        job_type: !job_type,
        workflow: !workflow,
        primary_contact: !primary_contact?.id,
      });
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_THE_ALL_FIELDS') }));
      return true;
    }
    return false;
  };

  const onDone = async () => {
    if (checkJobErrors()) return;
    const { line1, postcode } = property || {};
    const { campaign_type, job_type, job_name, primary_contact, project, workflow, workflowStartAt } = job || {};
    const jobName = job_name ? job_name.trim() : `${line1}, ${postcode}, ${job_type?.name}`;
    const { stage_type, stage_id, id: route_id, stage } = workflowStartAt || {};
    const workflowRequest = {
      id: workflow.id,
      stage: stage?.id
        ? stage_type === 'CREATOR'
          ? {
              id: stage?.id,
              route: {
                id: route_id,
              },
            }
          : { id: stage_id }
        : null,
    };

    setLoading(true);
    const request = {
      job_type: { id: job_type.id },
      engagement: {
        parent: null,
        quote: null,
        agent: null,
        primary_contact: primary_contact?.id
          ? {
              ...primary_contact,
              phones: (primary_contact?.phones || [])
                .map(p => ({ ...p, contact_value: p.value }))
                .filter(p => p.contact_value),
            }
          : null,
        workflow: workflowRequest,
        campaign: campaign_type?.id ? { id: campaign_type.id } : null,
        property: { id: property.id },
        name: jobName,
        project: { id: project.id },
        engagement_value: null,
        engagement_dates: null,
      },
      job_number: null,
      po_number: null,
      job_description: null,
      partners: null,
      lead_gen: null,
    };
    try {
      await dispatch(createJob({ request }));
      setLoading(false);
      onSuccess && onSuccess();
      setSideModal(initSideModal);
      dispatch(addToast({ error: false, text: t('JOB_CREATE_SUCCESSFULLY') }));
    } catch (error) {
      setLoading(false);
      dispatch(addToast({ error: true, text: t('ERROR_WHILE_CREATING_JOB') }));
    }
  };

  return (
    <AddJobWrapper className="flex flex-column h-full w-full overflow-scroll">
      <div className="flex w-full items-center py-5 px-6 border-bottom justify-between">
        <LeftArrow height={24} width={24} className="cursor" onClick={() => setSideModal(initSideModal)} />
      </div>
      <div className="flex flex-column flex-1 gap-6 overflow-auto custom-scrollbar thin-scrollbar pxy-6">
        <JobsDetails job={job} setJob={setJob} setError={setError} error={error} property={property} />
      </div>
      <div className="flex col-gap-6 justify-center pt-5 px-6 border-top pb-6 w-full">
        <Button
          onClick={() => setSideModal(initSideModal)}
          className={classNames('flex-1 primary-grey')}
          label={t('CANCEL')}
          disabled={loading}
          size="large"
          borderRadius="100px"
        />
        <Button
          onClick={onDone}
          className={classNames('primary flex-1')}
          label={t('ADD')}
          loading={loading}
          size="large"
          borderRadius="100px"
        />
      </div>
    </AddJobWrapper>
  );
};

const AddJobWrapper = styled.div`
  .span-2 {
    grid-column: span 2;
  }

  .input-center {
    top: 50%;
    transform: translateY(-50%);
  }

  .input-element.textarea-element {
    height: 44px;
    max-height: 200px;
  }

  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  .summary-details,
  .job-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .right-container-wrapper,
  .left-container-wrapper {
    max-width: 390px;
  }

  .tooltip-container {
    max-width: 300px;
  }

  .job-value-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .project-info {
    .custom-tooltip {
      padding: 8px 12px 8px 10px;
      border-radius: 6px;
      background: #404040;
      label {
        color: ${({ theme }) => theme.white};
      }
    }
  }
  .description-show-more-less {
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.white};
  }
  .three-lines {
    word-break: break-all !important;
  }

  .text-ellipsis-multi-line {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    max-height: 150px;
    overflow: auto;
    &.hide {
      overflow: hidden;
      -webkit-line-clamp: 3;
    }
  }
`;

export default AddNewJob;
