import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import { ReactComponent as AddIcon } from '../assets/icons/add-icon.svg';
import { ReactComponent as CornerDown } from '../assets/icons/corner-down-right-alt.svg';
import Button from '../components/common/button/button';
import IconContainer from '../components/common/icon-container';
import InputElement from '../components/common/input';
import SearchableDropdown from '../components/common/searchable-dropdown';
import { OrganisationContext } from '../context/organisationContext';
import { getErrorFieldJoined, initSideModal } from '../helpers/utils';
import { getJobTypeList, getProjectDetails, getWorkflowList, updateProjectDetails } from '../store/features/jobSlice';
import { addToast } from '../store/features/toastSlice';
import SideModelHeader from './sidemodel-header';

const WorkFlow = ({ data, index, t }) => {
  const { job_type, workflow } = data;
  return (
    <div>
      <label className="inter-400-text grey-text-04 ">
        {t('JOB_TYPE')} {index + 1} : <span className="natural-900-text inter-500-text">{job_type.name}</span>
      </label>
      <div className="bg-natural-100 radius-2_5  mt-2">
        {workflow.map((item, index) => (
          <div className={`flex items-center gap-2 py-2_5  px-2 ${index === 0 ? '' : 'border-top'}`} key={index}>
            <IconContainer
              iconHeight={16}
              iconWidth={16}
              Icon={CornerDown}
              iconContainerClassname="mr-0 p-0"
              backgroundColor="transparent"
            />

            <p className="natural-900-text inter-400-text">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const EditJobType = ({ jobType, error, index, setJobTypes, showCloseIcon, allJobs }) => {
  const { job_type, workflow } = jobType;
  const { t } = useTranslation();
  const [searchJob, setSearchJob] = useState('');
  const [searchedWorkflow, setSearchedWorkflow] = useState('');
  const alreadyWorkflowList = workflow?.map(s => s?.id) || [];
  const onJobUpdate = option => {
    setJobTypes(jobType => {
      const prevJobs = [...jobType];
      prevJobs[index].job_type = option;
      prevJobs[index].workflow = [''];
      return prevJobs;
    });
  };
  const onWorkflowUpdate = (option, wokflowIndex) => {
    setJobTypes(jobType => {
      const prevJobs = [...jobType];
      prevJobs[index].workflow[wokflowIndex] = option;
      return prevJobs;
    });
  };

  const onAddWorkflow = () => {
    setJobTypes(jobTypes => {
      const updatedJobTypes = jobTypes.map((jobType, idx) => {
        if (idx !== index) {
          return jobType;
        }
        return {
          ...jobType,
          workflow: [...jobType.workflow, ''],
        };
      });
      return updatedJobTypes;
    });
  };

  const removeWorkflow = indexToRemove => {
    setJobTypes(prevData => {
      const updatedItems = prevData.map((item, idx) => {
        if (idx === index) {
          const updatedWorkflow = item.workflow.filter((_, index) => index !== indexToRemove);
          return { ...item, workflow: updatedWorkflow };
        }
        return item;
      });
      return updatedItems;
    });
  };

  return (
    <JobTypeEditWrapper className="bg-natural-100">
      <div className="p-4">
        <div className="w-full flex justify-between items-center job-label">
          <label className="font-14 inter-500-text color-neutral-900 text-start">
            {t('JOB_TYPE')} {`${index + 1}`}
          </label>
          {showCloseIcon && (
            <CrossIcon
              className="cursor"
              onClick={() => setJobTypes(prev => prev.filter((item, filterIndex) => filterIndex !== index))}
            />
          )}
        </div>
        <SearchableDropdown
          isClearable
          inputValue={searchJob}
          onInputChange={setSearchJob}
          className="w-full"
          placeholder={t('SELECT_JOB_TYPE')}
          value={job_type}
          onChange={option => onJobUpdate(option)}
          getOptionLabel={option => option?.name}
          filterOption={option => !allJobs.includes(option.value)}
          customStyle={{
            control: {
              borderRadius: '6px',
              '&:hover': {
                boxShadow: null,
              },
            },
          }}
          isSearchable={true}
          defaultAdditional={{
            page: 0,
            fetchFunction: getJobTypeList,
            pageable: false,
          }}
          error={error?.job_type && !job_type}
        />
      </div>
      {job_type && (
        <div className="p-4 border-top">
          {workflow.map((item, workflowIndex) => (
            <div className="flex items-center gap-2 mb-2" key={workflowIndex}>
              <IconContainer
                iconHeight={16}
                iconWidth={16}
                Icon={CornerDown}
                iconContainerClassname="mr-0 p-0"
                backgroundColor="transparent"
              />
              <SearchableDropdown
                key={job_type.id}
                inputValue={searchedWorkflow}
                onInputChange={setSearchedWorkflow}
                className="w-full"
                placeholder={t('SELECT_WORKFLOW')}
                filterOption={option => !alreadyWorkflowList.includes(option.value)}
                value={item}
                getOptionLabel={option => option?.name}
                menuPlacement="bottom"
                onChange={option => onWorkflowUpdate(option, workflowIndex)}
                isSearchable={true}
                customStyle={{
                  control: {
                    height: '32px',
                    minHeight: '32px',
                    borderRadius: '6px',
                    '&:hover': {
                      boxShadow: null,
                    },
                  },
                  valueContainer: { padding: '0 16px' },
                }}
                defaultAdditional={{
                  page: 0,
                  fetchFunction: getWorkflowList,
                  params: {},
                  pageable: false,
                }}
                error={error?.workflow[workflowIndex] && !workflow[workflowIndex]}
              />
              {workflow.length > 1 && <CrossIcon className="cursor" onClick={() => removeWorkflow(workflowIndex)} />}
            </div>
          ))}
          <button
            className={`text-button inter-500-text cursor font-12 blue-primary-icon items-center flex  h-40px gap-6px`}
            onClick={onAddWorkflow}>
            <AddIcon className="mr-0 blue-primary-icon" height={14} width={14} />
            {t('ADD_WORKFLOW')}
          </button>
        </div>
      )}
    </JobTypeEditWrapper>
  );
};

const ProjectDetails = ({ detailsData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setSideModal } = useContext(OrganisationContext);

  const { projects, onDeleteProject, onSuccess } = detailsData || {};

  const [showSave, setShowSave] = useState(false);
  const [jobTypeError, setjobTypeError] = useState({});
  const [jobTypes, setJobTypes] = useState();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState({ ...projects });

  const groupedData = useMemo(() => {
    return project.workflow_job_types.reduce((acc, curr) => {
      const existingJob = acc.find(job => job.job_type.id === curr.job_type.id);
      if (existingJob) {
        existingJob.workflow.push(curr.workflow);
      } else {
        acc.push({
          job_type: curr.job_type,
          workflow: [curr.workflow],
        });
      }
      return acc;
    }, []);
  }, [project?.workflow_job_types]);

  useEffect(() => {
    setJobTypes(groupedData);
  }, [groupedData]);

  const renderInput = (label, placeholder, value, onChange, error, type, showSubLabel, subLabel) => (
    <InputElement
      type={type}
      name={label}
      sub_name={showSubLabel && `(${subLabel})`}
      placeholder={placeholder}
      value={value}
      onChange={value => {
        onChange(value);
      }}
      error={error[label?.toLowerCase()] && label?.toLowerCase() === 'code' ? !value || value.length > 6 : !value}
    />
  );

  const renderDetail = (label, labelText, value) => {
    return (
      <>
        <div className="flex items-center justify-between w-full cursor relative">
          <div className="flex-column w-full">
            <span className="inter-400-text grey-text-04 font-12 mb-1">{labelText}</span>
            <div className="flex items-center">
              <label className="font-14 inter-400-text line-height-20 natural-900-text one-line flex-1">{value}</label>
            </div>
          </div>
        </div>
      </>
    );
  };

  const checkEditQuoteErrors = () => {
    const { code, name, description } = project;
    const jobErrorObj = jobTypes.map(item => {
      return { job_type: item.job_type === null, workflow: item.workflow.map(work => work === '') };
    });
    if (
      !code?.trim() ||
      !name?.trim() ||
      !description?.trim() ||
      jobErrorObj.some(item => {
        return item.job_type || item.workflow.some(value => value === true);
      })
    ) {
      const error = {
        code: !code?.trim(),
        name: !name?.trim(),
        description: !description?.trim(),
        jobTypes: jobErrorObj.some(item => {
          return item.job_type || item.workflow.some(value => value === true);
        }),
      };
      const errorFields = getErrorFieldJoined(error, (key, value) => (key === 'jobTypes' ? 'job types' : key), ' and ');
      const errorText = `Please add ${errorFields}`;
      setError(error);
      setjobTypeError(jobErrorObj);

      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      return true;
    } else if (code.trim().length > 6) {
      setError({ code: true });
      dispatch(addToast({ error: true, text: t('MAX_SIX_CHARACTERS'), id: nanoid() }));
      return true;
    }
    setError({});
    setjobTypeError({});

    return false;
  };

  const fetchQuoteDetails = () => {
    setLoading(true);
    dispatch(getProjectDetails({ projects_id: projects?.id }))
      .then(data => {
        setProject({ ...data });
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROJECT_DETAILS'), id: nanoid() }));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchQuoteDetails();
  }, [projects?.id]);

  const onDone = async () => {
    if (checkEditQuoteErrors()) {
      return;
    }
    setLoading(true);

    const workflow_job_types = jobTypes.flatMap(jobs =>
      jobs.workflow.map(workflow => ({ job_type: { id: jobs.job_type.id }, workflow: { id: workflow.id } })),
    );
    const request = { name: project.name, description: project.description, code: project.code, workflow_job_types };
    dispatch(updateProjectDetails({ projects_id: project?.id, request: request }))
      .then(data => {
        dispatch(
          addToast({
            error: false,
            text: t('PROJECT_UPDATED_SUCCESSFULLY', { value: project?.name }),
            id: nanoid(),
          }),
        );
        setLoading(false);
        setShowSave(false);
        fetchQuoteDetails();
        onSuccess && onSuccess(data);
      })
      .catch(err => {
        dispatch(
          addToast({
            error: true,
            text: t('SOMETHING_WRONG'),
            id: nanoid(),
          }),
        );
        setLoading(false);
      });
  };

  const handleCodeInput = value => {
    if (value.length <= 6) {
      const regex = /^[A-Za-z0-9]{0,6}$/;
      if (regex.test(value)) {
        setProject({ ...project, code: value.toUpperCase() });
      }
    }
  };

  return (
    <ProjectDetailstWrapper className={`flex-column flex-1  ${loading && 'h-full'} `}>
      {loading ? (
        <div className="item flex items-center justify-center flex-1 w-full">
          <Skeleton height={'100%'} containerClassName="line-height-1 h-full w-full" />
        </div>
      ) : (
        <>
          <SideModelHeader
            title={t('PROJECT_DETAILS')}
            onClose={() => (showSave ? setShowSave(false) : setSideModal(initSideModal))}
            onSaveUpdate={onDone}
            isLoading={loading}
            showSave={showSave}
            permission={'PROJECTS_MANAGE'}
            handleEdit={() => setShowSave(true)}
            handleDelete={() => onDeleteProject(projects)}
            isIconButtons
          />
          <div className="flex-column flex-1 ">
            <div className="pxy-6 flex-column row-gap-4 border-bottom">
              {showSave ? (
                <>
                  {renderInput(
                    t('NAME'),
                    t('NAME'),
                    project.name || '',
                    value => setProject({ ...project, name: value }),
                    error,
                    'input',
                  )}
                  {renderInput(
                    t('DESCRIPTION'),
                    t('DESCRIPTION'),
                    project.description || '',
                    value => setProject({ ...project, description: value }),
                    error,
                    'textarea',
                  )}
                  {renderInput(
                    t('CODE'),
                    t('CODE'),
                    project.code || '',
                    handleCodeInput,
                    error,
                    'input',
                    true,
                    t('MAX_SIX_CHARACTERS'),
                  )}
                </>
              ) : (
                <>
                  {renderDetail('NAME', t('NAME'), project?.name)}
                  {renderDetail('DESCRIPTION', t('DESCRIPTION'), project?.description)}
                  {renderDetail('CODE', t('CODE'), project?.code)}
                </>
              )}
            </div>
          </div>
          <div className="px-6 flex-column row-gap-4 py-4 overflow-auto custom-scrollbar thin-scrollbar h-full ">
            {showSave ? (
              <>
                {jobTypes.map((jobType, index) => (
                  <div className="w-full gap-5px flex-column " key={index}>
                    <EditJobType
                      jobType={jobType}
                      index={index}
                      setJobTypes={setJobTypes}
                      error={jobTypeError[index]}
                      showCloseIcon={jobTypes.length > 1}
                      allJobs={jobTypes.map(item => item.job_type?.id)}
                    />
                  </div>
                ))}
                <div className="w-full gap-5px flex-column ">
                  <Button
                    className={`secondary h-40px gap-6px`}
                    label={t('ADD_JOB_TYPE')}
                    size="customsize"
                    onClick={() => setJobTypes(jobTypes => [...jobTypes, { job_type: null, workflow: [''] }])}
                    icon={<AddIcon className="mr-0 blue-primary-icon" height={16} width={16} />}
                    borderRadius="100px"
                  />
                </div>
              </>
            ) : (
              <>
                {groupedData && groupedData.length > 0 ? (
                  groupedData.map((data, index) => <WorkFlow key={index} data={data} index={index} t={t} />)
                ) : (
                  <label className="inter-400-text font-14 line-height-20 natural-400-text">
                    {t('NO_JOB_TYPE_WORKFLOW_SET_THIS_PROJECT')}
                  </label>
                )}
              </>
            )}
          </div>
        </>
      )}
    </ProjectDetailstWrapper>
  );
};

export const ProjectDetailstWrapper = styled.div`
  overflow: hidden;

  .update-detail-contaner {
    display: flex;
    padding: 12px;
    gap: 32px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    .input {
      height: 40px;
    }
  }
  .edit-delete-icon {
    width: 24px;
    height: 24px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: ${({ theme }) => theme.primary_500};
  }
  .close-icon {
    path {
      fill: #171717;
    }
  }
`;
const JobTypeEditWrapper = styled.div`
  border-radius: 10px;
  .job-label {
    margin-bottom: 5px;
  }
`;

export default ProjectDetails;
