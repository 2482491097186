import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow-to-left.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import MobileInput from '../../components/common/mobile-input';
import PhoneInput from '../../components/common/phone-input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import Dropdown from '../../components/common/select-dropdown';
import Switch from '../../components/common/switch';
import { OrganisationContext } from '../../context/organisationContext';
import { contactTitleOptions, contactTypeOptions } from '../../helpers/optionData';
import { initSideModal } from '../../helpers/utils';
import {
  createProperty,
  getContactDetails,
  getPropertyAddress,
  getPropertyAddressById,
} from '../../store/features/newPropertySlice';
import { addToast } from '../../store/features/toastSlice';

const formatted_address_format = ['line1', 'line2', 'line3', 'city', 'state', 'postcode'];

const propertyTypeOptions = [
  { label: 'Residential', value: 'RESIDENTIAL' },
  { label: 'Land', value: 'LAND' },
  { label: 'Commercial', value: 'COMMERCIAL' },
  { label: 'Block', value: 'BLOCK' },
];

const PropertyAddress = ({
  propertyAddress,
  setPropertyAddress,
  setError,
  error,
  setLoadingAddress,
  propertyClass,
  setPropertyClass,
  propertyName,
  setPropertyName,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formatted_address, line1, line2, line3, city, state, postcode, name } = propertyAddress || {};
  const [search, setSearch] = useState('');

  const onAddressUpdate = address => {
    const formatted_address = formatted_address_format
      .map(a => address[a])
      .filter(value => Boolean(value))
      .join(', ');
    setPropertyAddress({
      is_billing: true,
      is_postal: true,
      is_primary: true,
      is_residential: true,
      ...address,
      formatted_address,
    });
  };

  const onChangeAddress = (key, value) => {
    const updatedAddress = { ...propertyAddress, [key]: value };
    const newErrors = { ...error, [key]: false };
    const formatted_address = formatted_address_format
      .map(a => updatedAddress[a])
      .filter(value => Boolean(value))
      .join(', ');
    setPropertyAddress({
      is_billing: true,
      is_postal: true,
      is_primary: true,
      is_residential: true,
      ...updatedAddress,
      formatted_address,
    });
    setError(newErrors);
  };

  const onAddressSelect = address => {
    if (!address) {
      onAddressUpdate({});
      return;
    }
    setLoadingAddress(true);

    dispatch(getPropertyAddressById({ id: address.id }))
      .then(data => {
        onAddressUpdate({ ...data, external_reference: address.id });
      })
      .catch(e => {
        return '';
      })
      .finally(() => setLoadingAddress(false));
  };

  return (
    <div className="flex flex-column row-gap-6 w-full">
      <p className="inter-600-text font-16 flex-1">{t('PROPERTY_ADDRESS')}</p>
      <Dropdown
        name="Property type"
        placeholder="Select Property Type"
        options={propertyTypeOptions}
        value={propertyTypeOptions.find(option => option.value === propertyClass)}
        onChange={option => setPropertyClass(option.value)}
        error={error.propertyClass && !propertyClass}
        customStyle={{ menu: { zIndex: 1000 } }}
      />
      <InputElement
        type="text"
        name={t('PROPERTY_NAME')}
        value={name}
        placeholder={t('PROPERTY_NAME')}
        onChange={value => setPropertyName(value)}
      />
      <div className="property-search-dropdown relative">
        <div className="search-icon absolute o03">
          <SearchIcon />
        </div>
        <SearchableDropdown
          isClearable
          loadOptionsOnMenuOpen={false}
          className="w-full"
          placeholder={t('SEARCH_FOR_AN_ADDRESS')}
          customStyle={{
            control: { height: '32px', borderRadius: '100px', background: '#F5F5F5' },
            valueContainer: { padding: '0 16px' },
          }}
          getOptionLabel={option => option.address}
          getOptionValue={option => option.id}
          defaultAdditional={{ page: 0, fetchFunction: getPropertyAddress, pageable: false }}
          inputValue={search}
          onInputChange={setSearch}
          value={formatted_address ? { address: formatted_address } : null}
          onChange={onAddressSelect}
          isSearchable={true}
          isCustomSearchable={false}
        />
      </div>
      <InputElement
        className="w-full"
        name={t('ADDRESS_LINE_ONE')}
        placeholder={t('EXAMPLE_WALNUT_AVENUE')}
        value={line1 || ''}
        onChange={value => onChangeAddress('line1', value)}
        error={error.line1 && !line1}
      />
      <InputElement
        className="w-full"
        name={t('ADDRESS_LINE_TWO')}
        sub_name={t('OPTIONAL')}
        placeholder={t('EXAMPLE_APARTMENT')}
        value={line2 || ''}
        onChange={value => onChangeAddress('line2', value)}
        error={error.line2 && !line2}
      />
      <InputElement
        className="w-full"
        name={t('ADDRESS_LINE_THREE')}
        sub_name={t('OPTIONAL')}
        placeholder={t('EXAMPLE_ACOMB')}
        value={line3 || ''}
        onChange={value => onChangeAddress('line3', value)}
        error={error.line3 && !line3}
      />
      <InputElement
        className="w-full"
        name={t('CITY')}
        placeholder={t('EXAMPLE_RYDE')}
        value={city || ''}
        onChange={value => onChangeAddress('city', value)}
        error={error.city && !city}
      />
      <div className="flex items-center w-full col-gap-6">
        <InputElement
          className="w-full"
          name={t('POSTCODE')}
          placeholder={t('EXAMPLE_BN16_78F')}
          value={postcode || ''}
          onChange={value => onChangeAddress('postcode', value)}
          error={error.postcode && !postcode}
        />
        <InputElement
          className="w-full"
          name={t('COUNTY')}
          sub_name={t('OPTIONAL')}
          placeholder={t('EXAMPLE_ISLE_OF_WIGHT')}
          value={state || ''}
          onChange={value => onChangeAddress('state', value)}
        />
      </div>
    </div>
  );
};

const renderContactInfo = (contacts, contactType) => {
  if (contacts && contacts.length > 0) {
    const filteredContacts = contactType ? contacts.filter(contact => contact.contact_type === contactType) : contacts;

    return (
      <>
        {filteredContacts.map((contact, index) => (
          <div className="" key={index}>
            <span className={'inter-400-text natural-700-text font-12 option-text'}>{contact.value}</span>
            {contact?.formatted_address && (
              <div className="title-one-line one-line">
                <span key={contact.id} className={'inter-400-text natural-700-text font-12 option-text '}>
                  {contact?.formatted_address}
                </span>
              </div>
            )}
          </div>
        ))}
      </>
    );
  }
  return null;
};

const CustomContactOption = props => {
  const { innerProps, data } = props;

  return (
    <div className={'w-full px-4 py-3 cursor border-bottom option-wrapper'} {...innerProps}>
      <div className="flex items-center justify-between pb-1_5 gap-3 select-primary-contact">
        <span className={'inter-400-text natural-900-text font-14 option-text'}>{data.label}</span>
      </div>
      <div className="flex items-center gap-2">
        {renderContactInfo(data.emails, null)}
        <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
        {renderContactInfo(data.phones, 'MOBILE')}
        <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
        {renderContactInfo(data.phones, 'LANDLINE')}
      </div>
      <div>
        <span className={'inter-400-text natural-900-text font-14 option-text'}>
          {renderContactInfo(data.addresses, null)}
        </span>
      </div>
    </div>
  );
};

const PropertyContact = ({
  contact,
  onUpdateContact,
  onRemoveContact,
  onPrimaryContactChange,
  index,
  error,
  propertyAddress,
}) => {
  const { t } = useTranslation();
  const { setInsideModal } = useContext(OrganisationContext);
  const {
    temp_id,
    contact_id,
    contact_type,
    name,
    company_name,
    type,
    title,
    email,
    mobile,
    country_code,
    landline,
    address,
    is_primary,
    same_as_property,
    use_existing,
  } = contact || {};

  const contactName = contact_type === 'PERSON' ? [title?.label || '', name].join(' ') : company_name;

  const [search, setSearch] = useState('');

  const onAddressAddOrUpdate = () => {
    setInsideModal({
      type: 'address-popup',
      content: {
        addressDetails: address,
        setAddressDetails: address => onUpdateContact({ ...contact, address }),
        propertyAddress: propertyAddress,
        hideSameAsPropertyToggle: true,
      },
    });
  };

  const onUseExisting = () => {
    onUpdateContact({ temp_id, same_as_property, is_primary, contact_type: contact_type, use_existing: true });
  };

  const onAddManually = () => {
    onUpdateContact({
      temp_id,
      same_as_property,
      is_primary,
      contact_type: contact_type,
      contact_id: null,
      use_existing: false,
    });
  };

  const onSelectContact = option => {
    const { forename, surname, title, name, emails, phones, addresses, id: contact_id } = option;
    const temp_name = contact_type === 'PERSON' ? `${forename} ${surname}` : '';
    const temp_company_name = contact_type === 'COMPANY' ? name : '';
    const temp_title = contact_type === 'PERSON' ? contactTitleOptions.find(({ label }) => label === title) : null;
    const temp_email = emails?.find(({ is_primary }) => is_primary)?.value || '';
    const temp_mobile =
      contact_type === 'PERSON'
        ? phones?.find(({ is_primary, contact_type }) => is_primary && contact_type === 'MOBILE') || {}
        : {};
    const temp_landline =
      contact_type === 'COMPANY'
        ? phones?.find(({ is_primary, contact_type }) => is_primary && contact_type === 'LANDLINE') || {}
        : {};
    const temp_address = addresses?.find(({ is_primary }) => is_primary) || null;

    onUpdateContact({
      temp_id,
      contact_id,
      contact_type,
      use_existing,
      is_primary,
      name: temp_name,
      company_name: temp_company_name,
      title: temp_title,
      email: temp_email,
      mobile: temp_mobile?.value || '',
      country_code: temp_mobile?.country_code || '+44',
      landline: temp_landline?.value || '',
      address: temp_address,
      same_as_property: !temp_address,
    });
  };

  return (
    <div className={classNames('flex flex-column row-gap-6 w-full pb-6', index !== 0 && 'border-top pt-6')}>
      <div className="flex items-center justify-between">
        <p className="inter-500-text flex-1">
          {t('CONTACT_DETAILS')} {index + 1}
        </p>
        {use_existing ? (
          <label className="inter-500-text primary-500-text cursor" onClick={onAddManually}>
            {'Add manually'}
          </label>
        ) : (
          <label className="inter-500-text primary-500-text cursor" onClick={onUseExisting}>
            {'Use existing contact'}
          </label>
        )}
        <IconContainer
          Icon={DeleteIcon}
          iconContainerClassname="cursor ml-2"
          onClick={() => onRemoveContact(temp_id)}
          width={16}
          height={16}
        />
      </div>

      <div className="flex items-center col-gap-6">
        <Button
          size="medium"
          borderRadius="8px"
          className={classNames(contact_type === 'PERSON' ? 'secondary' : 'primary-grey')}
          label="Person"
          onClick={() =>
            onUpdateContact({
              temp_id,
              contact_type: 'PERSON',
              is_primary: is_primary,
              same_as_property: same_as_property,
              use_existing: use_existing,
            })
          }
        />
        <Button
          size="medium"
          borderRadius="8px"
          className={classNames(contact_type === 'COMPANY' ? 'secondary' : 'primary-grey')}
          label="Company"
          onClick={() =>
            onUpdateContact({
              temp_id,
              contact_type: 'COMPANY',
              is_primary: is_primary,
              same_as_property: same_as_property,
              use_existing: use_existing,
            })
          }
        />
      </div>
      {use_existing && (
        <SearchableDropdown
          key={contact_type}
          isClearable
          className="w-full"
          placeholder={t('SEARCH_FOR_AN_CONTACT')}
          customStyle={{
            control: {
              height: '32px',
              borderRadius: '100px',
              background: '#F5F5F5',
              width: '100%',
              border: 'none',
            },
            valueContainer: { padding: '0 16px' },
          }}
          inputValue={search}
          onInputChange={setSearch}
          value={contact_id ? { label: contactName, value: contact_id } : null}
          onChange={option => onSelectContact({ ...option })}
          error={error.contact_id && !contact_id}
          isSearchable={true}
          isCustomSearchable={false}
          defaultAdditional={{
            page: 0,
            fetchFunction: getContactDetails,
            pageable: true,
            params: { contact_type: contact_type },
          }}
          customComponent={{ Option: CustomContactOption }}
        />
      )}
      {((use_existing && contact_id) || !use_existing) && (
        <Fragment>
          <Dropdown
            name={t('TYPE')}
            placeholder={t('SELECT_FROM_LIST')}
            options={contactTypeOptions}
            value={contactTypeOptions.find(option => option.value === type)}
            onChange={option => onUpdateContact({ ...contact, type: option.value })}
            error={error.type && !type}
          />
          {contact_type === 'PERSON' && (
            <Fragment>
              <Dropdown
                name={t('TITLE')}
                options={contactTitleOptions}
                placeholder={t('SELECT_FROM_LIST')}
                value={title}
                error={error.title && !title}
                onChange={value => onUpdateContact({ ...contact, title: value })}
              />
              <InputElement
                type="text"
                name={t('FULL_NAME')}
                placeholder={t('FULL_NAME')}
                value={name || ''}
                onChange={value => onUpdateContact({ ...contact, name: value })}
              />
            </Fragment>
          )}
          {contact_type === 'COMPANY' && (
            <InputElement
              type="text"
              name={t('COMPANY_NAME')}
              placeholder={t('COMPANY_NAME')}
              value={company_name || ''}
              onChange={value => onUpdateContact({ ...contact, company_name: value })}
            />
          )}
          <InputElement
            type="text"
            name={t('EMAIL')}
            placeholder={t('ENTER_EMAIL')}
            value={email}
            onChange={value => onUpdateContact({ ...contact, email: value })}
            error={error?.contact && !mobile && !landline && !email}
          />
          {contact_type === 'PERSON' && (
            <div className="w-full flex-column gap-1">
              <div className="one-line">
                <span className="inter-500-text natural-900-text">{t('MOBILE')}</span>
              </div>
              <PhoneInput
                selectedCountry={country_code}
                setSelectedCountry={country_code => onUpdateContact({ ...contact, country_code: country_code })}
                phone={mobile}
                setPhone={phone => onUpdateContact({ ...contact, mobile: phone })}
                error={error?.contact && !mobile && !landline && !email}
              />
            </div>
          )}
          {contact_type === 'COMPANY' && (
            <div className="w-full flex-column gap-1">
              <div className="one-line">
                <span className="inter-500-text natural-900-text">{t('LANDLINE')}</span>
              </div>
              <MobileInput
                phone={landline}
                setPhone={phone => onUpdateContact({ ...contact, landline: phone })}
                error={error?.contact && !mobile && !landline && !email}
              />
            </div>
          )}

          <div className="flex-column col-gap-3">
            <div className="mb-1 flex flex-1 one-line">
              <span className="inter-500-text flex-1 natural-900-text">{'Same address as property'}</span>
              <Switch
                enabled={same_as_property}
                onClick={() => onUpdateContact({ ...contact, same_as_property: !same_as_property, address: null })}
                className="cursor"
              />
            </div>
            {!same_as_property && (
              <Fragment>
                <div className="mb-1 one-line">
                  <span className="inter-500-text natural-900-text">{t('ADDRESS')}</span>
                </div>
                <div
                  className={classNames(
                    'radius-1_5 px-4 py-3 flex items-center justify-between cursor address-field',
                    error.address && !address?.formatted_address && 'address-field-error',
                  )}
                  onClick={onAddressAddOrUpdate}>
                  {address?.formatted_address && (
                    <span className="inter-400-text natural-900-text font-14">{address?.formatted_address || ''}</span>
                  )}
                  {!address?.formatted_address && (
                    <span className="inter-300-text natural-400-text font-14">{t('CLICK_TO_ADD_ADDRESS')}</span>
                  )}
                  <div className="address-plus-icon o03">
                    {address?.formatted_address ? (
                      <EditIcon height={16} width={16} />
                    ) : (
                      <AddIcon height={16} width={16} />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
          <div className="flex items-center col-gap-6">
            <span className="inter-500-text flex-1 natural-900-text">{'Use as primary contact'}</span>
            <Switch
              enabled={is_primary}
              onClick={() => !is_primary && onPrimaryContactChange(temp_id)}
              className="cursor"
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

const AddNewPropertySide = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { sideModal, setSideModal } = useContext(OrganisationContext);
  const { onSuccess } = sideModal.content || {};

  const [propertyAddress, setPropertyAddress] = useState({});
  const [propertyClass, setPropertyClass] = useState(null);
  const [propertyName, setPropertyName] = useState('');
  const [propertyContacts, setPropertyContacts] = useState([
    { temp_id: nanoid(), contact_type: 'PERSON', is_primary: true, same_as_property: true, use_existing: false },
  ]);
  const [error, setError] = useState({});
  const [contactErrors, setContactErrors] = useState({});
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [loading, setLoading] = useState(false);

  const onUpdateContact = contact => {
    const updatedContacts = propertyContacts.map(c => (c.temp_id === contact.temp_id ? contact : c));
    setPropertyContacts(updatedContacts);
  };

  const onRemoveContact = temp_id => {
    const updatedContacts = propertyContacts.filter(c => c.temp_id !== temp_id);
    if (updatedContacts.length === 0) {
      setPropertyContacts([
        { temp_id: nanoid(), contact_type: 'PERSON', is_primary: true, same_as_property: true, use_existing: false },
      ]);
    }
    setPropertyContacts(updatedContacts);
  };

  const onPrimaryContactChange = temp_id => {
    const contactsWithoutPrimary = propertyContacts.map(c => ({ ...c, is_primary: false }));
    const updatedContacts = contactsWithoutPrimary.map(c => (c.temp_id === temp_id ? { ...c, is_primary: true } : c));
    setPropertyContacts(updatedContacts);
  };

  const checkPropertyAddressErrors = () => {
    const { line1, city, postcode } = propertyAddress || {};
    if (!line1 || !city || !postcode) {
      return {
        propertyClass: !propertyClass,
        line1: !line1,
        city: !city,
        postcode: !postcode,
      };
    }
    return false;
  };

  const checkContactErrors = () => {
    const contactErrors = propertyContacts.reduce((acc, contact) => {
      const {
        contact_type,
        name,
        type,
        temp_id,
        company_name,
        email,
        mobile,
        landline,
        address,
        same_as_property,
        use_existing,
        contact_id,
      } = contact;
      const isContactError = !email && !mobile && !landline;
      const newError = {
        type: !type,
        title: contact_type === 'PERSON' ? !type : false,
        name: contact_type === 'PERSON' ? !name : false,
        company_name: contact_type === 'COMPANY' ? !company_name : false,
        contact: isContactError,
        address: same_as_property ? false : !address?.formatted_address,
        contact_id: use_existing ? !contact_id : false,
      };
      return { ...acc, [temp_id]: newError };
    }, {});
    return contactErrors;
  };

  const getContactRequest = ({ contacts }) => {
    const companyContacts = contacts.filter(contact => contact.contact_type === 'COMPANY');
    const personContacts = contacts.filter(contact => contact.contact_type === 'PERSON');

    return [...companyContacts, ...personContacts].map(contact => {
      const {
        contact_id,
        use_existing,
        address,
        name,
        title,
        type,
        company_name,
        contact_type,
        email,
        landline,
        mobile,
        country_code,
        is_primary,
        same_as_property,
      } = contact;

      const contactAddress = same_as_property ? propertyAddress : address;

      return {
        id: use_existing ? contact_id : null,
        forename: name?.split(' ')?.[0] || '',
        surname: name?.split(' ')?.[1] || '',
        title: title ? title.label : '',
        property_contact_type: type,
        parent: null,
        company_name: company_name || '',
        contact_type: contact_type,
        date_of_birth: null,
        profession: null,
        emails: email
          ? [
              {
                value: email,
                is_primary: is_primary,
                email_type: 'WORK',
              },
            ]
          : null,
        phones:
          landline || mobile
            ? [
                landline
                  ? {
                      contact_type: 'LANDLINE',
                      contact_value: landline?.replaceAll(' ', ''),
                      is_primary: is_primary,
                      phone_type: 'WORK',
                      country_code: '+44',
                    }
                  : null,
                mobile
                  ? {
                      contact_type: 'MOBILE',
                      contact_value: mobile?.replaceAll(' ', ''),
                      is_primary: is_primary,
                      phone_type: 'WORK',
                      country_code: country_code || '+44',
                    }
                  : null,
              ].filter(Boolean)
            : null,
        addresses: [
          {
            country_code: 'GB',
            is_billing: true,
            is_residential: true,
            is_postal: true,
            ...contactAddress,
            location:
              contactAddress?.location?.lat && contactAddress?.location?.lon ? contactAddress?.address?.location : null,
            is_primary: is_primary,
          },
        ],
      };
    });
  };

  const onAddProperty = async () => {
    const addressErrors = checkPropertyAddressErrors();
    const contactErrors = checkContactErrors();
    const isContactError = Object.values(contactErrors).some(error => Object.values(error).some(e => e));
    if (addressErrors || isContactError) {
      setError(addressErrors);
      setContactErrors(contactErrors);
      dispatch(addToast({ error: true, text: 'Please fill all the required fields', id: nanoid() }));
      return;
    }

    setLoading(true);
    const request = {
      country_code: 'GB',
      ...propertyAddress,
      location: null,
      name: propertyName || '',
      address_reference: {
        integration_id: propertyAddress.integration_id || '',
        external_reference: propertyAddress.external_reference || '',
      },
      details: null,
      contacts: getContactRequest({ contacts: propertyContacts }),
      property_class: {
        class_type: propertyClass,
      },
    };
    try {
      const response = await dispatch(createProperty({ request }));
      setLoading(false);
      setSideModal(initSideModal);
      dispatch(addToast({ error: false, text: t('SUCCESSFULLY_PROPERTY_CREATED') }));
      onSuccess && onSuccess(response);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({ error: true, text: error?.response?.data?.error_description ?? t('ERROR_PROPERTY_CREATED') }),
      );
    }
  };

  return (
    <AddNewPropertyWrapper className="flex flex-column h-full w-full">
      <div className="flex w-full items-center py-5 px-6 border-bottom justify-between">
        <LeftArrow height={24} width={24} className="cursor" onClick={() => setSideModal(initSideModal)} />
      </div>
      <div className="flex flex-column flex-1 overflow-auto custom-scrollbar thin-scrollbar">
        <div className="flex flex-column p-6 border-bottom">
          <PropertyAddress
            propertyAddress={propertyAddress}
            setPropertyAddress={setPropertyAddress}
            setError={setError}
            error={error}
            setLoadingAddress={setLoadingAddress}
            propertyClass={propertyClass}
            setPropertyClass={setPropertyClass}
            propertyName={propertyName}
            setPropertyName={setPropertyName}
          />
        </div>
        <div className="flex flex-column row-gap-4 px-6 pt-6">
          {propertyContacts.map((contact, index) => (
            <PropertyContact
              error={contactErrors[contact.temp_id] || {}}
              index={index}
              key={contact.temp_id}
              contact={contact}
              propertyAddress={propertyAddress}
              onUpdateContact={onUpdateContact}
              onRemoveContact={onRemoveContact}
              onPrimaryContactChange={onPrimaryContactChange}
            />
          ))}
        </div>
        <div className="flex flex-column px-6 pb-6 pt-2">
          <Button
            size="average"
            className="secondary"
            label="Add Another Contact"
            afterIcon={<AddIcon className="primary-500-text" />}
            onClick={() =>
              setPropertyContacts([
                ...propertyContacts,
                {
                  temp_id: nanoid(),
                  contact_type: 'PERSON',
                  is_primary: false,
                  same_as_property: true,
                  use_existing: false,
                },
              ])
            }
          />
        </div>
      </div>
      <div className="flex items-center col-gap-6 justify-between border-top p-6">
        <Button
          size="average"
          className="secondary"
          label="Cancel"
          onClick={() => setSideModal(initSideModal)}
          disabled={loading}
        />
        <Button size="average" className="primary" label="Add" onClick={onAddProperty} loading={loading} />
      </div>
    </AddNewPropertyWrapper>
  );
};

const AddNewPropertyWrapper = styled.div`
  .property-search-dropdown {
    .search-icon {
      top: 14px;
      left: 16px;
      z-index: 11;
    }

    .searchable-select__input-container,
    .searchable-select__placeholder,
    .searchable-select__single-value {
      margin-left: 20px;
    }
  }

  .address-field {
    height: 44px;
    border: 1px solid ${({ theme }) => theme.natural_200};
    background-color: ${({ theme }) => theme.natural_100};
  }

  .address-field-error {
    border-color: ${({ theme }) => theme.error_500};
    box-shadow: 0 0 0 4px ${({ theme }) => theme.error_border};
  }
`;

export default AddNewPropertySide;
