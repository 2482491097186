import { ReactComponent as RightIcon } from '@assets/images/arrow-right.svg';
import { ReactComponent as CloseIcon } from '@assets/images/toast-close.svg';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as ProjectIcon } from '../../../assets/icons/folder-outline.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-outline.svg';
import { ReactComponent as JobIcon } from '../../../assets/images/jobs-outline.svg';
import { getAccountsListItems } from '../../../store/features/accountsSlice';
import { getCampaignsList } from '../../../store/features/campaignSlice';
import { getJobTypeList, getProjectList } from '../../../store/features/jobSlice';
import { getUsers } from '../../../store/features/userManagementSlice';
import Button from '../../common/button/button';
import Checkbox from '../../common/checkbox';
import IconContainer from '../../common/icon-container';
import InputSearch from '../../common/input-search';
import SearchableDropdown from '../../common/searchable-dropdown';
import CustomTooltip from '../../common/tooltip-new';

const BoardsFilter = ({ setShowSortPopover, setBoardsFilters, boardsFilters, onClearFilters = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isShowProjects, setIsShowProjects] = useState(false);
  const [isShowJobTypes, setIsShowJobTypes] = useState(false);
  const [isShowCampaign, setIsShowCampaign] = useState(false);
  const [isShowLeadSource, setIsShowLeadSource] = useState(false);
  const [searchProjects, setSearchProjects] = useState('');
  const [searchJobType, setSearchJobType] = useState('');
  const [searchCampaign, setSearchCampaign] = useState('');
  const [searchOrganisation, setSearchOrganisation] = useState('');
  const [searchUserType, setSearchUserType] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [jobTypeList, setJobTypeList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [openSection, setOpenSection] = useState('');
  const { userOrganization, user } = useSelector(state => state.user);
  const { id: user_organization_id } = userOrganization || {};
  const [filters, setFilters] = useState({});
  useEffect(() => {
    if (boardsFilters) {
      setFilters(boardsFilters);
    }
  }, [boardsFilters]);

  const fetchProjectList = (debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getProjectList({
        params: {
          page: 0,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setProjectList(data);
        setShowLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROJECT_LIST'), id: nanoid() })),
          setProjectList([]);
      });
  };

  const fetchJobTypeList = (debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getJobTypeList({
        params: {
          page: 0,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setJobTypeList(data);
        setShowLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROJECT_LIST'), id: nanoid() })),
          setJobTypeList([]);
      });
  };

  const fetchCampaignList = (debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getCampaignsList({
        params: {
          page: 0,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setCampaignList(data);
        setShowLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROJECT_LIST'), id: nanoid() })),
          setCampaignList([]);
      });
  };

  useEffect(() => {
    fetchProjectList(searchProjects, false);
  }, [searchProjects]);

  useEffect(() => {
    fetchJobTypeList(searchJobType, false);
  }, [searchJobType]);

  useEffect(() => {
    fetchCampaignList(searchCampaign, false);
  }, [searchCampaign]);

  function updateFilters(key, value) {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      if (Array.isArray(newFilters[key])) {
        if (newFilters[key].find(item => item.id === value.id)) {
          newFilters[key] = newFilters[key].filter(item => item.id !== value.id);
        } else {
          newFilters[key] = [...newFilters[key], value];
        }
      } else {
        newFilters[key] = newFilters[key]?.id === value.id ? null : value;
      }
      return newFilters;
    });
  }

  const applyFilters = () => {
    setBoardsFilters(filters);
    setShowSortPopover(false);
  };

  const toggleSection = section => {
    setOpenSection(prevSection => (prevSection === section ? null : section));
  };

  return (
    <SortPopoverWrapper className="popover-content bg-white py-5 radius-2 flex-column">
      <div className="justify-between px-5">
        <label className="inter-500-text font-18 natural-900-text line-height-28">{t('FILTER_BY')}</label>
        <IconContainer
          Icon={CloseIcon}
          iconHeight={16}
          iconWidth={16}
          iconColor="natural_500"
          backgroundColor="transparent"
          iconContainerClassname="cursor"
          onClick={() => setShowSortPopover(false)}
        />
      </div>
      <div className={classNames('my-4')}>
        <div>
          <div
            className={classNames('justify-between py-2 px-5', { 'border-bottom': !openSection !== 'project' })}
            onClick={() => toggleSection('project')}>
            <div className="flex items-center gap-1">
              <IconContainer
                Icon={ProjectIcon}
                iconHeight={20}
                iconColor={openSection === 'project' ? 'natural_700' : 'natural_400'}
                iconWidth={20}
                backgroundColor="transparent"
              />
              <label
                className={classNames('inter-500-text font-14', {
                  'natural-700-text': openSection === 'project',
                  'natural-400-text': !openSection !== 'project',
                })}>
                {t('PROJECT')}
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={openSection === 'project' ? 'natural_700' : 'natural_400'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !openSection !== 'project',
                'rotate-270': openSection === 'project',
              })}
            />
          </div>
          <motion.div
            initial={{ visibility: 'hidden', opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: openSection === 'project' ? 1 : 0,
              visibility: openSection === 'project' ? 'visible' : 'hidden',
              height: openSection === 'project' ? 'auto' : 0,
              maxHeight: 250,
              transition: { duration: 0.5 },
              overflow: 'auto',
            }}
            className={classNames('bg-natural-50')}>
            <div className="sticky top-0 pb-0 pxy-5 bg-natural-50 z-1">
              <InputSearch placeholder={'Search'} value={searchProjects} onChange={setSearchProjects} />
            </div>
            <div className="checkbox-content flex-column gap-3 px-5 pb-5">
              {projectList?.map(project => {
                return (
                  <div className="flex items-center gap-2" key={project?.id}>
                    <Checkbox
                      is_checked_done={true}
                      checked={filters?.project?.id === project?.id || false}
                      onChange={() => updateFilters('project', project)}
                      id={`project-${project?.id}`}
                    />
                    <label htmlFor={`project-${project?.id}`} className="no-select inter-400-text font-16">
                      {project?.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
        <div>
          <div
            className={classNames('justify-between py-2 px-5', { 'border-bottom': !openSection !== 'job_type' })}
            onClick={() => toggleSection('job_type')}>
            <div className="flex items-center gap-1">
              <IconContainer
                Icon={JobIcon}
                iconHeight={20}
                iconColor={openSection === 'job_type' ? 'natural_700' : 'natural_400'}
                iconWidth={20}
                backgroundColor="transparent"
              />
              <label
                className={classNames('inter-500-text font-14', {
                  'natural-700-text': openSection === 'job_type',
                  'natural-400-text': !openSection !== 'job_type',
                })}>
                {t('SELECT_JOB_TYPE')}
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={openSection === 'job_type' ? 'natural_700' : 'natural_400'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !openSection !== 'job_type',
                'rotate-270': openSection === 'job_type',
              })}
            />
          </div>
          <motion.div
            initial={{ visibility: 'none', opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: openSection === 'job_type' ? 1 : 0,
              visibility: openSection === 'job_type' ? 'visible' : 'hidden',
              height: openSection === 'job_type' ? 'auto' : 0,
              transition: { duration: 0.5 },
              maxHeight: 250,
              overflow: 'auto',
            }}
            className={classNames('bg-natural-50')}>
            <div className="sticky top-0 pb-0 pxy-5 bg-natural-50 z-1">
              <InputSearch placeholder={'Search'} value={searchJobType} onChange={setSearchJobType} />
            </div>
            <div className="checkbox-content flex-column gap-3 px-5 pb-5">
              {jobTypeList?.map(job => {
                return (
                  <div className="flex items-center gap-2" key={job?.id}>
                    <Checkbox
                      is_checked_done={true}
                      checked={filters?.job_type?.id === job?.id || false}
                      onChange={() => updateFilters('job_type', job)}
                      id={`job-${job?.id}`}
                    />
                    <label htmlFor={`job-${job?.id}`} className="no-select inter-400-text font-16">
                      {job?.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
        <div>
          <div
            className={classNames('justify-between py-2 px-5', { 'border-bottom': !openSection !== 'campaign' })}
            onClick={() => toggleSection('campaign')}>
            <div className="flex items-center gap-1">
              <IconContainer
                Icon={JobIcon}
                iconHeight={20}
                iconColor={openSection === 'campaign' ? 'natural_700' : 'natural_400'}
                iconWidth={20}
                backgroundColor="transparent"
              />
              <label
                className={classNames('inter-500-text font-14', {
                  'natural-700-text': openSection === 'campaign',
                  'natural-400-text': !openSection !== 'campaign',
                })}>
                {t('CAMPAIGN')}
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={openSection === 'campaign' ? 'natural_700' : 'natural_400'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !openSection !== 'campaign',
                'rotate-270': openSection === 'campaign',
              })}
            />
          </div>
          <motion.div
            initial={{ visibility: 'none', opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: openSection === 'campaign' ? 1 : 0,
              visibility: openSection === 'campaign' ? 'visible' : 'hidden',
              height: openSection === 'campaign' ? 'auto' : 0,
              transition: { duration: 0.5 },
              overflow: 'auto',
              maxHeight: 250,
            }}
            className={classNames('bg-natural-50')}>
            <div className="sticky top-0 pb-0 pxy-5 bg-natural-50 z-1">
              <InputSearch placeholder={'Search'} value={searchCampaign} onChange={setSearchCampaign} />
            </div>
            <div className="checkbox-content flex-column gap-3 px-5 pb-5">
              {campaignList?.map(campaign => {
                return (
                  <div className="flex items-center gap-2" key={campaign?.id}>
                    <Checkbox
                      is_checked_done={true}
                      checked={filters?.campaign?.id === campaign?.id || false}
                      onChange={() => updateFilters('campaign', campaign)}
                      id={`campaign-${campaign?.id}`}
                    />
                    <label htmlFor={`campaign-${campaign?.id}`} className="no-select inter-400-text font-16">
                      {campaign?.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
        <div>
          <div
            className={classNames('justify-between py-2 px-5', { 'border-bottom': openSection !== 'lead_source' })}
            onClick={() => toggleSection('lead_source')}>
            <div className="flex items-center gap-1">
              <IconContainer
                Icon={UserIcon}
                iconHeight={20}
                iconColor={openSection === 'lead_source' ? 'natural_700' : 'natural_400'}
                iconWidth={20}
                backgroundColor="transparent"
              />
              <label
                className={classNames('inter-500-text font-14', {
                  'natural-700-text': openSection === 'lead_source',
                  'natural-400-text': !openSection !== 'lead_source',
                })}>
                Lead source
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={openSection === 'lead_source' ? 'natural_700' : 'natural_400'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !openSection !== 'lead_source',
                'rotate-270': openSection === 'lead_source',
              })}
            />
          </div>
          <motion.div
            initial={{ visibility: 'hidden', opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: openSection === 'lead_source' ? 1 : 0,
              visibility: openSection === 'lead_source' ? 'visible' : 'hidden',
              height: openSection === 'lead_source' ? 'auto' : 0,
              transition: { duration: 0.5 },
              maxHeight: 250,
            }}
            className={classNames('bg-natural-50')}>
            <div className="bg-natural-50 flex flex-column gap-2 pxy-5">
              <SearchableDropdown
                isSearchable={true}
                isCustomSearchable={false}
                isClearable
                inputValue={searchOrganisation}
                onInputChange={setSearchOrganisation}
                className="w-full"
                name="Organisation"
                placeholder={t('SELECT_OR_TYPE_ORGANISATION')}
                showIconInOption={false}
                value={filters?.lead_organisation}
                onChange={option => updateFilters('lead_organisation', option)}
                defaultAdditional={{
                  page: 0,
                  fetchFunction: getAccountsListItems,
                  payload: { organization_id: user_organization_id },
                  params: {
                    category_tag: 'LEAD_GENERATION',
                  },
                  pageable: false,
                }}
              />
              <CustomTooltip
                id={'toggle-overlay'}
                place="left"
                wrapperClassName="tooltip-wrapper"
                content={
                  !filters?.lead_organisation && (
                    <span className="inter-400-text font-12">{t('PLEASE_SELECT_ORGANISATION_FIRST')}</span>
                  )
                }>
                <SearchableDropdown
                  isSearchable={false}
                  isClearable
                  inputValue={searchUserType}
                  onInputChange={setSearchUserType}
                  className="w-full"
                  placeholder={t('SELECT_OR_TYPE_USER')}
                  showIconInOption={false}
                  name="User"
                  value={filters?.lead_user}
                  onChange={option => updateFilters('lead_user', option)}
                  isDisabled={filters?.lead_organisation ? false : true}
                  defaultAdditional={{
                    page: 0,
                    fetchFunction: getUsers,
                    params: {
                      role_tag: 'LEAD_GENERATION',
                      organization_id: filters?.lead_organisation?.id,
                    },
                    pageable: true,
                  }}
                />
              </CustomTooltip>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="flex col-gap-10 px-5 justify-center mt-1 ">
        <Button
          className={classNames('primary-grey')}
          label={t('CLEAR_ALL')}
          onClick={() => {
            setShowSortPopover(false);
            onClearFilters();
          }}
          size="average"
          borderRadius="100px"
        />
        <Button
          className={classNames('primary ')}
          label={t('APPLy_FILTERS')}
          onClick={applyFilters}
          size="average"
          borderRadius="100px"
        />
      </div>
    </SortPopoverWrapper>
  );
};

const SortPopoverWrapper = styled.div`
  &.popover-content {
    z-index: 10;
    width: 357px;
    box-shadow: 0px 2px 8px -4px rgba(16, 24, 40, 0.1), 0px 6px 17px -3px rgba(16, 24, 40, 0.1);
  }

  .radio-container {
    margin-bottom: 14px;
  }

  .radio-label {
    margin-left: 5px;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
  }
`;

export default BoardsFilter;
