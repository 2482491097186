import classNames from 'classnames';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EditPensil } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/right-Icons.svg';
import { ReactComponent as ExplanationIcon } from '../../assets/icons/round-explanation.svg';
import { ReactComponent as DeleteIcons } from '../../assets/images/DeleteIcons.svg';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { calculateInterest, getFormattedNumber, getFormattedNumberStyle } from '../../helpers/utils';
import { getQuoteProducts, updateQuoteProducts } from '../../store/features/productsSlice';
import {
  createFinancial,
  getFinancialData,
  getFinancialDesign,
  updateFinancial,
} from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import Checkbox from '../common/checkbox';
import IconContainer from '../common/icon-container';
import InputElement from '../common/input';
import Switch from '../common/switch';
import TableHeader from '../common/table-header';
import CustomTooltip from '../common/tooltip-new';

const PPAHeader = ({ financial, onCancel, onUpdate, onDelete, onEdit, index, isEditing, isUpdating }) => {
  const {
    contract_length_years,
    ppa_energy_cost,
    ppa_revenue,
    total_annual_net_revenue,
    total_annual_revenue,
    revenue,
    energy_cost,
    total_install_cost,
  } = financial || {};
  const { rates } = energy_cost || {};

  const autoConsumptionRate = rates?.find(rate => rate.charge_type === 'AUTOCONSUMPTION') || {};
  const { price_per_kwh } = autoConsumptionRate || {};

  return (
    <div
      className="flex flex-1 items-center pxy-3 cursor"
      onClick={() => {
        if (isUpdating) return;
        if (isEditing) {
          onCancel();
        } else {
          onEdit();
        }
      }}>
      <div className="flex items-center justify-center bg-natural-100 radius-full w-24px h-24px mr-3">
        <label className="inter-400-text font-12">{index + 1}</label>
      </div>
      <div className="flex flex-1 flex-wrap items-center col-gap-3">
        <label className="inter-500-text natural-900-text font-14 one-line">
          {getFormattedNumber((total_annual_revenue || revenue || 0) * (contract_length_years || 0))} Total revenue
        </label>
        <div className="vertical-line" />
        <label className="inter-500-text natural-900-text font-14 one-line">
          {getFormattedNumber(total_install_cost || 0)} Total capital
        </label>
        <div className="vertical-line" />
        <label className="inter-500-text natural-900-text font-14 one-line">
          {getFormattedNumber((total_annual_net_revenue || ppa_revenue || 0) * (contract_length_years || 0))} Net
          revenue
        </label>
        <div className="vertical-line" />
        <label className="inter-500-text natural-900-text font-14 one-line">
          {getFormattedNumberStyle((price_per_kwh || ppa_energy_cost || 0) * 100, 'decimal', 1, 1)} p/kWh
        </label>
        <div className="vertical-line" />
        <label className="inter-500-text natural-900-text font-14 one-line">
          {parseFloat(contract_length_years || 0).toFixed(0)} Years
        </label>
      </div>
      <div className="flex gap-3">
        {isEditing ? (
          <Fragment>
            <IconContainer
              Icon={CloseIcon}
              backgroundColor={'transparent'}
              iconHeight={14}
              iconWidth={14}
              iconColor={'natural_900'}
              iconContainerClassname="border radius-50-percent pxy-1 cursor"
              onClick={e => {
                e.stopPropagation();
                if (isUpdating) return;
                onCancel();
              }}
            />
            <IconContainer
              Icon={isUpdating ? LoadingIcon : CheckIcon}
              iconHeight={16}
              disabled={isUpdating}
              iconWidth={16}
              backgroundColor={'primary_500'}
              iconColor={'white'}
              iconContainerClassname="radius-50-percent pxy-1 cursor"
              onClick={e => {
                e.stopPropagation();
                if (isUpdating) return;
                onUpdate();
              }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <IconContainer
              Icon={EditPensil}
              iconColor="natural_500"
              backgroundColor="transparent"
              iconContainerClassname="cursor p-0"
              iconHeight={16}
              iconWidth={16}
              onClick={e => {
                e.stopPropagation();
                if (isUpdating) return;
                onEdit();
              }}
            />
            <IconContainer
              Icon={DeleteIcons}
              iconColor="natural_500"
              backgroundColor="transparent"
              iconContainerClassname="cursor p-0"
              iconHeight={16}
              iconWidth={16}
              onClick={e => {
                e.stopPropagation();
                if (isUpdating) return;
                onDelete();
              }}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

const PPAContentSlider = ({
  label,
  value,
  onChange,
  formattedValue,
  min,
  max,
  step,
  disabled = false,
  showInput = false,
  inputExtra = '',
  inputValue,
}) => {
  return (
    <div className="flex flex-1 justify-between items-center col-gap-2 slider-wrapper mr-2">
      <label className="inter-400-text natural-700-text one-line">{label}</label>
      <div className="flex items-center col-gap-1 flex-1">
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={value || 0}
          className="slider flex-1"
          onChange={onChange}
          disabled={disabled}
        />
        {showInput ? (
          <InputElement
            className="input-element"
            value={inputValue}
            onChange={value => {
              onChange({ target: { value } });
            }}
            placeholder={'0'}
            disabled={disabled}
            min={min}
            max={max}
            step={step}
            variant="size_32"
            type="number"
            extra_text_after={inputExtra}
          />
        ) : (
          <label className="inter-400-text natural-900-text">{formattedValue}</label>
        )}
      </div>
    </div>
  );
};

const RenderPPADetails = ({ label, value, valueMetric }) => {
  return (
    <div className="flex-column radius-1 bg-natural-50 px-4 py-3 row-gap-1">
      <label className="inter-400-text natural-500-text one-line">{label}</label>
      <div className="flex items-center col-gap-1">
        <label className="inter-700-text font-20 natural-900-text line-height-36">{value}</label>
        <label className="inter-700-text font-20 natural-900-text line-height-32">{valueMetric}</label>
      </div>
    </div>
  );
};

const formatNumberWithoutSymbol = num => {
  return getFormattedNumber(num || 0, undefined, false);
};

const ExcludedProducts = ({ quoteProducts, onUpdateQuoteProducts, updatingQuote, onClose }) => {
  const [productsList, setProductsList] = useState(quoteProducts);
  const [currentTab, setCurrentTab] = useState('ALL');

  useEffect(() => {
    setProductsList(quoteProducts);
  }, [quoteProducts]);

  const excluded_products_ids = quoteProducts.filter(product => product.ppa_excluded).map(product => product.id) || [];
  const currentProducts =
    currentTab === 'EXCLUDED'
      ? productsList.filter(product => excluded_products_ids.includes(product.id))
      : productsList;

  const onSave = async () => {
    try {
      const previousExcludedProducts = quoteProducts.filter(product => product.ppa_excluded).map(product => product.id);
      const newExcludedProducts = productsList.filter(product => product.ppa_excluded).map(product => product.id);
      const isSameIds =
        newExcludedProducts.every(id => previousExcludedProducts.includes(id)) &&
        newExcludedProducts.length === previousExcludedProducts.length;

      if (isSameIds) {
        onClose();
        return;
      }
      await onUpdateQuoteProducts(productsList);
      onClose();
    } catch (error) {
      console.log('error', error);
    }
  };

  const onUpdateProduct = product => {
    const updatedProducts = productsList.map(item => {
      if (item.id === product.id) {
        return { ...item, ppa_excluded: !item.ppa_excluded };
      }
      return item;
    });
    setProductsList(updatedProducts);
  };

  return (
    <div className="flex-column" style={{ maxHeight: '500px', minWidth: '500px', maxWidth: '540px' }}>
      <div className="flex border-bottom items-center justify-between px-4 py-3">
        <div className="flex items-center col-gap-2">
          <Button
            size="medium"
            label={`Excluded (${excluded_products_ids.length})`}
            borderRadius="4px"
            className={classNames('specified-width', currentTab === 'EXCLUDED' ? 'secondary' : 'transparent')}
            width="120px"
            onClick={() => setCurrentTab('EXCLUDED')}
            disabled={updatingQuote}
          />
          <Button
            size="medium"
            borderRadius="4px"
            label={`All products (${productsList.length})`}
            className={classNames('specified-width', currentTab === 'ALL' ? 'secondary' : 'transparent')}
            width="120px"
            onClick={() => setCurrentTab('ALL')}
            disabled={updatingQuote}
          />
        </div>
        <Button
          size="medium"
          label="Save"
          className="primary specified-width"
          width="100px"
          onClick={onSave}
          loading={updatingQuote}
        />
      </div>
      <div className="flex-1 overflow-auto custom-scrollbar thin-scrollbar flex-column row-gap-2">
        {currentProducts.map((product, index) => (
          <div
            className={classNames('flex items-start px-4 py-3 col-gap-2', index !== 0 && 'border-top')}
            key={product.id}>
            <Checkbox is_checked_done checked={product.ppa_excluded} onChange={() => onUpdateProduct(product)} />
            <div className="flex-column flex-1 row-gap-1">
              <div className="flex items-center justify-between">
                <label className="inter-400-text one-line">{product.name}</label>
                <label className="inter-400-text">{getFormattedNumber(product?.total_net || 0)}</label>
              </div>
              <label className="inter-400-text natural-500-text">{product.description}</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PPACosts = ({
  financialData,
  onFinancialUpdate,
  design_data = {},
  quoteProducts = [],
  onUpdateQuoteProducts = () => {},
  updatingQuote,
}) => {
  const {
    hardware_cost_per_kw,
    service_cost_per_kw,
    finance_amount,
    finance_period_months,
    apr,
    interest_payments,
    excluded_cost,
    total_amount,
  } = financialData || {};
  const { system_size } = design_data || {};

  const [showingExcludedProducts, setShowingExcludedProducts] = useState(false);

  return (
    <div className="flex-column border-top pt-6">
      <label className="inter-600-text font-12">COSTS</label>
      <div className="costs-wrapper mt-2">
        <div className="flex flex-column row-gap-2">
          <div className="flex flex-1 items-center justify-between">
            <label className="inter-400-text natural-500-text one-line">{t('HARDWARE_COSTS')}</label>
            <div className="flex items-center col-gap-2">
              <InputElement
                placeholder={'0'}
                value={hardware_cost_per_kw || ''}
                type="number"
                onChange={value => onFinancialUpdate({ ...financialData, hardware_cost_per_kw: value })}
                variant="size_32"
                extra_text_before="£"
              />
              <InputElement
                placeholder={'0'}
                value={formatNumberWithoutSymbol(hardware_cost_per_kw * system_size || 0)}
                disabled
                variant="size_32"
                extra_text_before="£"
              />
            </div>
          </div>
          <div className="flex flex-1 items-center justify-between">
            <label className="inter-400-text natural-500-text one-line">{t('SERVICE_COSTS')}</label>
            <div className="flex items-center col-gap-2">
              <InputElement
                placeholder={'0'}
                value={service_cost_per_kw || ''}
                type="number"
                onChange={value => onFinancialUpdate({ ...financialData, service_cost_per_kw: value })}
                variant="size_32"
                extra_text_before="£"
              />
              <InputElement
                placeholder={'0'}
                value={formatNumberWithoutSymbol(service_cost_per_kw * system_size || 0)}
                disabled
                variant="size_32"
                extra_text_before="£"
              />
            </div>
          </div>
          <div className="flex flex-1 items-center justify-between">
            <label className="inter-400-text natural-500-text one-line">{t('EXCLUDED')}</label>
            <div className="flex items-center col-gap-2">
              <CustomTooltip
                id="excluded-products"
                tooltipClassname="excluded-products-tooltip"
                onBlurTooltip={() => setShowingExcludedProducts(false)}
                isOpen={showingExcludedProducts}
                hidden={!showingExcludedProducts}
                place="bottom"
                positionStrategy={'fixed'}
                noArrow
                content={
                  <ExcludedProducts
                    quoteProducts={quoteProducts}
                    onUpdateQuoteProducts={onUpdateQuoteProducts}
                    updatingQuote={updatingQuote}
                    onClose={() => setShowingExcludedProducts(false)}
                  />
                }
                clickable>
                <IconContainer
                  Icon={ExplanationIcon}
                  iconHeight={16}
                  iconWidth={16}
                  iconColor={'primary_500'}
                  backgroundColor={'transparent'}
                  iconContainerClassname="cursor"
                  onClick={() => setShowingExcludedProducts(!showingExcludedProducts)}
                />
              </CustomTooltip>
              <InputElement
                key={'excluded_cost'}
                id="excluded_cost"
                placeholder={'0'}
                disabled
                value={formatNumberWithoutSymbol(excluded_cost || 0)}
                variant="size_32"
                extra_text_before="£"
              />
            </div>
          </div>
          <InputElement
            key={'total_amount'}
            className="flex flex-1 items-center justify-between"
            nameClassName={'inter-400-text natural-500-text one-line'}
            name={t('TOTAL_AMOUNT')}
            placeholder={'0'}
            value={formatNumberWithoutSymbol(total_amount || 0)}
            variant="size_32"
            disabled
            extra_text_before="£"
          />
        </div>
        <div className="flex flex-column row-gap-2 h-fit-content">
          <InputElement
            className="flex flex-1 items-center justify-between"
            nameClassName={'inter-400-text natural-500-text one-line'}
            name={t('FINANCE_AMOUNT')}
            placeholder={'0'}
            value={finance_amount || ''}
            type="number"
            onChange={value => onFinancialUpdate({ ...financialData, finance_amount: value })}
            variant="size_32"
            extra_text_before="£"
          />
          <InputElement
            className="flex flex-1 items-center justify-between"
            nameClassName={'inter-400-text natural-500-text one-line'}
            name={t('FINANCE_APR')}
            placeholder={'0'}
            value={apr || ''}
            type="number"
            onChange={value => onFinancialUpdate({ ...financialData, apr: value })}
            variant="size_32"
            extra_text_after="%"
          />
          <InputElement
            className="flex flex-1 items-center justify-between"
            nameClassName={'inter-400-text natural-500-text one-line'}
            name={t('TERM')}
            placeholder={'0'}
            type="number"
            value={finance_period_months || ''}
            onChange={value => onFinancialUpdate({ ...financialData, finance_period_months: value })}
            variant="size_32"
            extra_text_after="months"
          />
          <InputElement
            className="flex flex-1 items-center justify-between"
            nameClassName={'inter-400-text natural-500-text one-line'}
            name={t('INTEREST_PAYMENTS')}
            placeholder={'0'}
            disabled
            value={formatNumberWithoutSymbol(interest_payments || 0)}
            variant="size_32"
            extra_text_before="£"
          />
        </div>
      </div>
    </div>
  );
};

const PPAFundings = ({ financialData, onFinancialUpdate }) => {
  const { total_install_cost, payback_period, payback_years, is_override } = financialData || {};

  const handlePaybackPeriodChange = value => {
    const newPaybackPeriod = parseFloat(value);
    onFinancialUpdate({ ...financialData, payback_period: newPaybackPeriod });
  };

  const handlePaybackYearsChange = value => {
    const newPaybackYears = parseFloat(value);
    onFinancialUpdate({ ...financialData, payback_years: newPaybackYears });
  };
  return (
    <div className="flex-column border-top pt-6">
      <label className="inter-600-text font-12 line-height-1">FUNDING</label>
      <div className="costs-wrapper mt-2">
        <InputElement
          className="flex flex-1 items-center justify-between"
          nameClassName={'inter-400-text natural-500-text one-line'}
          name={t('TOTAL_FUNDING_REQUIRED')}
          placeholder={'0'}
          disabled
          value={formatNumberWithoutSymbol(total_install_cost || 0)}
          variant="size_32"
          extra_text_before="£"
        />
        <div className="flex flex-1 items-center justify-between">
          <label className="inter-400-text natural-500-text one-line">Term factor</label>
          <div className="flex items-center gap-2">
            <InputElement
              placeholder={'0'}
              type="number"
              value={payback_period}
              onChange={value => handlePaybackPeriodChange(value)}
              variant="size_32"
              className="playback-input"
              extra_text_after="multiple"
              disabled={is_override}
            />
            <InputElement
              placeholder={'0'}
              type="number"
              disabled
              value={payback_years}
              onChange={value => handlePaybackYearsChange(value)}
              variant="size_32"
              extra_text_after="payback"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PPAOpexCosts = ({ financialData, onFinancialUpdate, design_data }) => {
  const { autoconsumption } = design_data || {};
  const { energy_cost, contract_length_years } = financialData || {};
  const { rates } = energy_cost || {};

  const autoConsumptionRate = rates?.find(rate => rate.charge_type === 'AUTOCONSUMPTION');
  const { platform_fee = 0, installer_fee = 0 } = autoConsumptionRate || {};

  const headers = [
    { name: '', key: 'ID' },
    { name: 'MONTHLY', key: 'MONTHLY' },
    { name: 'ANNUAL', key: 'ANNUAL' },
    { name: 'TERM', key: 'TERM' },
  ];

  const onUpdateCosts = (key, value) => {
    let updatedRates = rates || [];
    if (autoConsumptionRate) {
      updatedRates = rates.map(rate => {
        if (rate.charge_type === 'AUTOCONSUMPTION') {
          return { ...rate, [key]: value };
        }
        return rate;
      });
    } else {
      const newRate = {
        start_time: '00:00:00',
        end_time: '23:59:59',
        start_day: 1,
        end_day: 7,
        charge_type: 'AUTOCONSUMPTION',
        price_per_kwh: 0,
        platform_fee: 0,
        installer_fee: 0,
        [key]: value,
      };
      updatedRates = [...updatedRates, newRate];
    }
    onFinancialUpdate({ ...financialData, energy_cost: { ...energy_cost, rates: updatedRates } });
  };

  const getTotalOfOpexCosts = () => {
    const installerFeeTotal = parseFloat(autoconsumption * installer_fee * contract_length_years);
    const platformFeeTotal = parseFloat(autoconsumption * platform_fee * contract_length_years);
    return installerFeeTotal + platformFeeTotal;
  };

  const onNumericInput = input => {
    return Math.abs(parseFloat(input ? input : '0').toFixed(2));
  };

  return (
    <div className="flex-column border-top pt-6">
      <label className="inter-600-text font-12 mb-2">OPEX COSTS</label>
      <div className="opex-costs-wrapper mt-2">
        <TableHeader headers={headers} headerClassName="border-top" />
        <div className="flex-column row-gap-3 py-3 border-bottom">
          <div className="data-container">
            <div className="flex items-center justify-between">
              <label className="inter-400-text natural-700-text one-line">{t('INSTALLER_RATE')}</label>
              <InputElement
                type="number"
                variant="size_32"
                placeholder={'0'}
                value={parseFloat(installer_fee) >= 0 ? onNumericInput((installer_fee || 0) * 100) : ''}
                onChange={value => {
                  onUpdateCosts('installer_fee', parseFloat(value) >= 0 ? value / 100 : '');
                }}
                extra_text_after="p"
              />
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat((installer_fee * autoconsumption) / 12))}
              </label>
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat(installer_fee * autoconsumption))}
              </label>
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat(installer_fee * autoconsumption * contract_length_years))}
              </label>
            </div>
          </div>
          <div className="data-container">
            <div className="flex items-center justify-between">
              <label className="inter-400-text natural-700-text one-line">{t('PLATFORM_COST')}</label>
              <InputElement
                type="number"
                variant="size_32"
                placeholder={'0'}
                value={parseFloat(platform_fee) >= 0 ? onNumericInput((platform_fee || 0) * 100) : ''}
                onChange={value => {
                  onUpdateCosts('platform_fee', parseFloat(value) >= 0 ? value / 100 : '');
                }}
                extra_text_after="p"
              />
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat((platform_fee * autoconsumption) / 12))}
              </label>
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat(platform_fee * autoconsumption))}
              </label>
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat(platform_fee * autoconsumption * contract_length_years))}
              </label>
            </div>
          </div>
        </div>
        <div className="flex flex-1 items-center justify-end mt-3">
          <label className="inter-400-text natural-700-text mr-1">Total:</label>
          <label className="inter-600-text">{getFormattedNumber(getTotalOfOpexCosts())}</label>
        </div>
      </div>
    </div>
  );
};

const PPARevenue = ({ financialData, onFinancialUpdate, design_data }) => {
  const { autoconsumption, annual_export } = design_data || {};
  const { energy_cost, contract_length_years } = financialData || {};
  const { rates } = energy_cost || {};

  const exportRate = rates?.find(rate => rate.charge_type === 'EXPORT') || {};
  const autoConsumptionRate = rates?.find(rate => rate.charge_type === 'AUTOCONSUMPTION') || {};

  const { price_per_kwh } = autoConsumptionRate || {};
  const { price_per_kwh: exportPrice = 0 } = exportRate || {};

  const headers = [
    { name: '', key: 'ID' },
    { name: 'MONTHLY', key: 'MONTHLY' },
    { name: 'ANNUAL', key: 'ANNUAL' },
    { name: 'TERM', key: 'TERM' },
  ];

  const onExportRateChange = value => {
    if (exportRate.charge_type === 'EXPORT') {
      const updatedRates = rates.map(rate => {
        if (rate.charge_type === 'EXPORT') {
          return { ...rate, price_per_kwh: value };
        }
        return rate;
      });
      onFinancialUpdate({ ...financialData, energy_cost: { ...energy_cost, rates: updatedRates } });
    } else {
      const newRate = {
        start_time: '00:00:00',
        end_time: '23:59:59',
        start_day: 1,
        end_day: 7,
        charge_type: 'EXPORT',
        price_per_kwh: value,
        platform_fee: 0,
        installer_fee: 0,
      };
      onFinancialUpdate({ ...financialData, energy_cost: { ...energy_cost, rates: [...rates, newRate] } });
    }
  };

  const getTotalOfEnergyAndExport = () => {
    const energyTotal = parseFloat(autoconsumption * price_per_kwh * contract_length_years);
    const exportTotal = parseFloat(annual_export * exportPrice * contract_length_years);
    return energyTotal + exportTotal;
  };

  const onNumericInput = input => {
    return Math.abs(parseFloat(input ? input : '0').toFixed(2));
  };

  return (
    <div className="flex-column border-top pt-6">
      <label className="inter-600-text font-12 mb-2">REVENUE</label>
      <div className="opex-costs-wrapper mt-2">
        <TableHeader headers={headers} headerClassName="border-top" />
        <div className="flex-column row-gap-3 py-3 border-bottom">
          <div className="data-container">
            <div className="flex items-center justify-between">
              <label className="inter-400-text natural-700-text one-line">{t('ENERGY')}</label>
              <InputElement
                variant="size_32"
                placeholder={'0'}
                disabled
                value={getFormattedNumberStyle(onNumericInput((price_per_kwh || 0) * 100), 'decimal', 2, 2)}
                extra_text_after="p"
              />
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat((autoconsumption * price_per_kwh) / 12))}
              </label>
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat(autoconsumption * price_per_kwh))}
              </label>
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat(autoconsumption * price_per_kwh * contract_length_years))}
              </label>
            </div>
          </div>
          <div className="data-container">
            <div className="flex items-center justify-between">
              <label className="inter-400-text natural-700-text one-line">{t('EXPORT')}</label>
              <InputElement
                variant="size_32"
                placeholder={'0'}
                type="number"
                value={parseFloat(exportPrice) >= 0 ? onNumericInput((exportPrice || 0) * 100) : ''}
                onChange={value => {
                  onExportRateChange(parseFloat(value) >= 0 ? value / 100 : '');
                }}
                extra_text_after="p"
              />
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat((annual_export * exportPrice) / 12))}
              </label>
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat(annual_export * exportPrice))}
              </label>
            </div>
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text one-line">
                {getFormattedNumber(parseFloat(annual_export * exportPrice * contract_length_years))}
              </label>
            </div>
          </div>
        </div>
        <div className="flex flex-1 items-center justify-end mt-3">
          <label className="inter-400-text natural-700-text mr-1">{t('TOTAL')}:</label>
          <label className="inter-600-text">{getFormattedNumber(getTotalOfEnergyAndExport())}</label>
        </div>
      </div>
    </div>
  );
};

const PPAContent = ({
  isEditing,
  financialData,
  onFinancialUpdate = () => {},
  design_data,
  loading = false,
  quoteProducts = [],
  onUpdateQuoteProducts = () => {},
  updatingQuote = false,
}) => {
  const {
    energy_cost,
    contract_length_years,
    total_annual_revenue,
    total_annual_cost,
    total_annual_net_revenue,
    is_override,
    payback_period,
  } = financialData || {};

  const { rates } = energy_cost || {};
  const autoConsumptionRate = rates?.find(rate => rate.charge_type === 'AUTOCONSUMPTION') || {};

  const { price_per_kwh = 0 } = autoConsumptionRate || {};

  const onUpdatePPARate = (key, value) => {
    let updatedRates = rates || [];
    if (autoConsumptionRate) {
      updatedRates = rates.map(rate => {
        if (rate.charge_type === 'AUTOCONSUMPTION') {
          return { ...rate, [key]: value };
        }
        return rate;
      });
    } else {
      const newRate = {
        start_time: '00:00:00',
        end_time: '23:59:59',
        start_day: 1,
        end_day: 7,
        charge_type: 'AUTOCONSUMPTION',
        price_per_kwh: 0,
        platform_fee: 0,
        installer_fee: 0,
        [key]: value,
      };
      updatedRates = [...updatedRates, newRate];
    }
    onFinancialUpdate({ ...financialData, energy_cost: { ...energy_cost, rates: updatedRates } });
  };

  const onNumericInput = input => {
    return Math.abs(parseFloat(input ? input : '0').toFixed(2));
  };

  return (
    <PPAContentWrapper
      initial={{ height: 0 }}
      animate={{ height: isEditing ? 'auto' : 0, padding: isEditing ? '24px 0' : 0, transition: { duration: 0.5 } }}
      className={classNames(
        'mx-4',
        isEditing ? 'overflow-scroll flex-column row-gap-6 border-top' : 'overflow-hidden',
      )}>
      {loading ? (
        <Skeleton height={'400px'} width={'100%'} />
      ) : (
        <Fragment>
          <div className="flex flex-1 items-center justify-end col-gap-4">
            <label className="inter-400-text">Override rates</label>
            <Switch
              enabled={is_override}
              onClick={() =>
                onFinancialUpdate({
                  ...financialData,
                  is_override: !is_override,
                  payback_period: is_override ? 2 : payback_period,
                })
              }
            />
          </div>
          <div className="flex flex-1 items-center col-gap-4">
            <PPAContentSlider
              label="PPA Rate"
              value={getFormattedNumberStyle((price_per_kwh || 0) * 100, 'decimal', 1, 1)}
              inputValue={parseFloat(price_per_kwh) >= 0 ? onNumericInput((price_per_kwh || 0) * 100) : ''}
              min={5}
              max={35}
              step={0.1}
              showInput
              inputExtra="p"
              formattedValue={`${getFormattedNumberStyle((price_per_kwh || 0) * 100, 'decimal', 1, 1)}p`}
              onChange={({ target: { value } }) => {
                onUpdatePPARate('price_per_kwh', parseFloat(value) >= 0 ? value / 100 : '');
              }}
            />
            <PPAContentSlider
              label="Contract Length"
              value={parseFloat(contract_length_years || 0).toFixed(0)}
              formattedValue={parseFloat(contract_length_years || 0).toFixed(0)}
              min={5}
              max={30}
              step={1}
              disabled={!is_override}
              showInput
              inputExtra="years"
              inputValue={parseFloat(contract_length_years) >= 0 ? parseFloat(contract_length_years).toFixed(0) : ''}
              onChange={({ target: { value } }) => {
                const newValue = parseFloat(value) >= 0 ? value : '';
                const contract_length_months = parseFloat(newValue) * 12;
                onFinancialUpdate({ ...financialData, contract_length_years: newValue, contract_length_months });
              }}
            />
          </div>
          <div className="content-details col-gap-4">
            <RenderPPADetails
              label={t('ANNUAL_GROSS_REVENUE')}
              value={getFormattedNumber(total_annual_revenue)}
              valueMetric=""
            />
            <RenderPPADetails label={t('ANNUAL_COST')} value={getFormattedNumber(total_annual_cost)} valueMetric="" />
            <RenderPPADetails
              label={t('ANNUAL_NET_REVENUE')}
              value={getFormattedNumber(total_annual_net_revenue)}
              valueMetric=""
            />
          </div>
          <PPACosts
            financialData={financialData}
            onFinancialUpdate={onFinancialUpdate}
            design_data={design_data}
            quoteProducts={quoteProducts}
            onUpdateQuoteProducts={onUpdateQuoteProducts}
            updatingQuote={updatingQuote}
          />
          <PPAFundings financialData={financialData} onFinancialUpdate={onFinancialUpdate} />
          <PPAOpexCosts financialData={financialData} onFinancialUpdate={onFinancialUpdate} design_data={design_data} />
          <PPARevenue financialData={financialData} onFinancialUpdate={onFinancialUpdate} design_data={design_data} />
        </Fragment>
      )}
    </PPAContentWrapper>
  );
};

const SystemPPA = ({ solar_id, systemDetails }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);

  const { design_data: system_design_data, quote } = systemDetails || {};
  const design_data = {
    ...system_design_data,
    autoconsumption: parseFloat(system_design_data?.autoconsumption).toFixed(0),
    annual_export: parseFloat(system_design_data?.annual_export).toFixed(0),
  };
  const { system_size = 0, annual_export = 0, autoconsumption = 0 } = design_data || {};
  const { id: quote_id } = quote || {};

  const [loading, setLoading] = useState(false);
  const [loadingFinancial, setLoadingFinancial] = useState(false);
  const [updatingFinancial, setUpdatingFinancial] = useState(false);
  const [financialData, setFinancialData] = useState([]);
  const [editingFiancial, setEditingFinancial] = useState({});
  const [quoteData, setQuoteData] = useState({});
  const [updatingQuote, setUpdatingQuote] = useState(false);

  const { products: quoteProducts } = quoteData || {};
  const excluded_products = useMemo(
    () => quoteProducts?.filter(product => product.ppa_excluded) || [],
    [quoteProducts],
  );

  const fetchFinancialData = (showLoading = true) => {
    setLoading(showLoading);
    dispatch(getFinancialData({ sid: solar_id }))
      .then(data => {
        setFinancialData(data);
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SOLAR_FINANCIAL') })))
      .finally(() => setLoading(false));
  };

  const fetchIndividualFinancialData = financial => {
    setLoadingFinancial(true);
    dispatch(getFinancialDesign({ id: solar_id, fid: financial?.id }))
      .then(data => {
        const updatedFinancial = updateFinanceWithCalculatedValues({ ...financial, ...data });
        setEditingFinancial({ ...updatedFinancial });
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SOLAR_FINANCIAL') })))
      .finally(() => setLoadingFinancial(false));
  };

  const fetchQuoteProducts = () => {
    dispatch(getQuoteProducts({ quote_id }))
      .then(data => {
        setQuoteData(data);
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_QUOTE_PRODUCTS') })));
  };

  const onUpdateQuoteProducts = async products => {
    const request = {
      ...quoteData,
      integration: quoteData?.integration?.id ? quoteData.integration : null,
      products,
    };
    setUpdatingQuote(true);
    await dispatch(updateQuoteProducts({ quote_id, request }))
      .then(data => {
        setQuoteData(data);
        fetchFinancialData(false);
        dispatch(addToast({ success: true, text: t('PPA_EXCLUDED_UPDATED_SUCCESSFULLY') }));
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_UPDATING_PPA_EXCLUDED') })))
      .finally(() => setUpdatingQuote(false));
  };

  useEffect(() => {
    fetchFinancialData();
  }, [solar_id]);

  useEffect(() => {
    if (quote_id) {
      fetchQuoteProducts();
    }
  }, [quote_id]);

  useEffect(() => {
    if (editingFiancial?.id) {
      const updatedFinancial = updateFinanceWithCalculatedValues(editingFiancial);
      setEditingFinancial({ ...updatedFinancial });
    }
  }, [quoteProducts, editingFiancial?.id]);

  const onAddNewPPA = () => {
    const newId = nanoid();
    const newFinancial = {
      id: newId,
      isNew: true,
      hardware_cost_per_kw: 400,
      service_cost_per_kw: 350,
      apr: 0,
      finance_period_months: null,
      payback_period: 2,
      amortization_rate: 5,
      amortization_threshold_pct: 25,
      amortization_type: 'FIXED',
      energy_cost: {
        standing_charge: 0,
        rates: [
          {
            start_time: '00:00:00',
            end_time: '23:59:59',
            start_day: 1,
            end_day: 7,
            charge_type: 'AUTOCONSUMPTION',
            price_per_kwh: 0.17,
            platform_fee: 0,
            installer_fee: 0,
          },
          {
            start_time: '00:00:00',
            end_time: '23:59:59',
            start_day: 1,
            end_day: 7,
            charge_type: 'EXPORT',
            price_per_kwh: 0.04,
            platform_fee: 0,
            installer_fee: 0,
          },
        ],
      },
    };
    const updatedFinancial = updateFinanceWithCalculatedValues(newFinancial);
    setFinancialData([...financialData, { ...newFinancial }]);
    setEditingFinancial({ ...updatedFinancial });
  };

  const onCancelEdit = financial => {
    if (financial.isNew) {
      setFinancialData(financialData.filter(f => f.id !== financial.id));
    }
    setEditingFinancial(null);
  };

  const updateFinanceWithCalculatedValues = (financial, forHeader = false) => {
    const {
      energy_cost,
      apr,
      finance_period_months,
      hardware_cost_per_kw,
      service_cost_per_kw,
      payback_period,
      is_override,
      contract_length_years,
      contract_length_months,
      amortization_rate = 5,
      amortization_threshold_pct = 25,
      amortization_type = 'FIXED',
      finance_amount,
    } = financial || {};
    const { rates } = energy_cost || [];

    const total_hardware_cost = parseFloat(system_size * hardware_cost_per_kw);
    const total_service_cost = parseFloat(system_size * service_cost_per_kw);
    const excluded_cost = excluded_products.reduce((acc, product) => acc + parseFloat(product.total_net), 0);

    const total_amount = total_hardware_cost + total_service_cost + excluded_cost;

    const autoConsumptionRate = rates?.find(rate => rate.charge_type === 'AUTOCONSUMPTION') || {};
    const exportRate = rates?.find(rate => rate.charge_type === 'EXPORT') || {};

    const { price_per_kwh = 0, platform_fee = 0, installer_fee = 0 } = autoConsumptionRate || {};
    const { price_per_kwh: exportPrice = 0 } = exportRate || {};

    const energyTotal = parseFloat(autoconsumption * price_per_kwh);
    const exportTotal = parseFloat(annual_export * exportPrice);

    const installerFeeTotal = parseFloat(autoconsumption * installer_fee);
    const platformFeeTotal = parseFloat(autoconsumption * platform_fee);

    const total_annual_revenue = energyTotal + exportTotal;
    const total_annual_cost = installerFeeTotal + platformFeeTotal;
    const total_annual_net_revenue = total_annual_revenue - total_annual_cost;

    const interest_payments = calculateInterest(
      parseFloat(finance_amount || 0),
      parseFloat(apr || 0),
      parseFloat(finance_period_months || 0),
    );

    const base_install_cost = parseFloat(system_size * total_amount);
    const total_install_cost = forHeader ? financial.total_install_cost : total_amount + interest_payments;

    let payback_years = 0;
    let contract_length_years_calculated = 0;
    let contract_length_months_calculated = contract_length_months;
    let payback_period_calculated = 0;

    if (is_override) {
      contract_length_months_calculated = parseInt(contract_length_months_calculated).toFixed(0) || 0;
      contract_length_years_calculated = parseFloat(contract_length_months_calculated / 12.0).toFixed(2) || '';
      const annual_revenue = total_annual_net_revenue / contract_length_years_calculated;
      const install_cost = total_install_cost / contract_length_years_calculated;
      payback_years = annual_revenue ? Number(parseFloat(install_cost / annual_revenue).toFixed(2)) : 0;
      payback_period_calculated = payback_years
        ? Number(parseFloat(contract_length_years_calculated / payback_years).toFixed(2))
        : 0;
    } else {
      payback_years = total_annual_net_revenue
        ? Number(parseFloat(total_install_cost / total_annual_net_revenue).toFixed(2))
        : 0;
      payback_period_calculated = payback_period;
      contract_length_months_calculated = parseFloat(payback_years * payback_period_calculated * 12).toFixed(0);
      contract_length_years_calculated = parseFloat(contract_length_months_calculated / 12.0).toFixed(2);
    }

    if (forHeader) {
      return {
        ...financial,
        amortization_rate,
        amortization_threshold_pct,
        amortization_type,
        total_annual_revenue,
        total_annual_net_revenue,
        contract_length_years: contract_length_years_calculated,
      };
    }

    return {
      ...financial,
      total_amount,
      excluded_cost,
      amortization_rate,
      amortization_threshold_pct,
      amortization_type,
      payback_period: payback_period_calculated,
      payback_years,
      total_annual_revenue,
      total_annual_cost,
      total_annual_net_revenue,
      total_install_cost,
      interest_payments,
      base_install_cost,
      contract_length_months: contract_length_months_calculated,
      contract_length_years: contract_length_years_calculated,
    };
  };

  const onFinancialUpdate = financial => {
    const updatedFinancial = updateFinanceWithCalculatedValues(financial);
    setEditingFinancial({ ...updatedFinancial });
  };

  const onCreateNewFinancial = finacial_id => {
    const updatedEditingFiancial = { ...editingFiancial };
    delete updatedEditingFiancial?.payback_percentage;
    const request = {
      ...updatedEditingFiancial,
      finance_type: 'PPA',
      finance_period_months:
        updatedEditingFiancial?.finance_period_months > 0 ? updatedEditingFiancial?.finance_period_months : null,
    };
    setUpdatingFinancial(true);
    dispatch(createFinancial({ solar_design_id: solar_id, request }))
      .then(data => {
        const updatedFinancials = financialData.map(financial => {
          if (financial.id === finacial_id) {
            return { ...data };
          }
          return financial;
        });
        setFinancialData(updatedFinancials);
        setEditingFinancial(null);
        dispatch(addToast({ error: false, text: 'Financial created successfully' }));
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Error while creating financial' }));
      })
      .finally(() => setUpdatingFinancial(false));
  };

  const onUpdateFinancial = finacial_id => {
    const request = {
      ...editingFiancial,
      finance_type: 'PPA',
      finance_period_months: editingFiancial?.finance_period_months > 0 ? editingFiancial?.finance_period_months : null,
    };
    setUpdatingFinancial(true);
    dispatch(updateFinancial({ solar_design_id: solar_id, finacial_id: editingFiancial.id, request }))
      .then(data => {
        const updatedFinancials = financialData.map(financial => {
          if (financial.id === finacial_id) {
            return { ...data };
          }
          return financial;
        });
        setFinancialData(updatedFinancials);
        setEditingFinancial(null);
        dispatch(addToast({ error: false, text: 'Financial updated successfully' }));
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Error while updating financial' }));
      })
      .finally(() => setUpdatingFinancial(false));
  };

  const onCreateOrUpdateFinancial = finacial => {
    if (!editingFiancial) return;
    const isNew = editingFiancial?.isNew;
    if (isNew) {
      onCreateNewFinancial(finacial?.id);
    } else {
      onUpdateFinancial(finacial?.id);
    }
  };

  const onDeletePPA = financial => {
    setModal({
      type: 'delete-ppa',
      content: {
        financial: financial,
        solar_id: solar_id,
        title: t('DELETE_PPA_CALCULATION'),
        description: t('THIS_ACTION_CANNOT_UNDONE'),
        onSuccess: fetchFinancialData,
      },
    });
  };

  return (
    <SystemPPAWrapper className="flex-column row-gap-3 my-4 flex-1">
      {loading ? (
        <Skeleton height={'100%'} containerClassName="line-height-1 h-full flex-1" />
      ) : (
        <Fragment>
          {financialData.map((financial, index) => (
            <div key={financial.id} className="border radius-2 flex-column">
              <PPAHeader
                index={index}
                financial={
                  editingFiancial?.id === financial?.id && !loadingFinancial
                    ? editingFiancial
                    : updateFinanceWithCalculatedValues(financial, true)
                }
                onCancel={() => onCancelEdit(financial)}
                onUpdate={() => onCreateOrUpdateFinancial(financial)}
                onDelete={() => onDeletePPA(financial)}
                onEdit={() => {
                  const isNew = financial.isNew;
                  if (!isNew) {
                    fetchIndividualFinancialData(financial);
                  }
                  setEditingFinancial(financial);
                }}
                isEditing={editingFiancial?.id === financial?.id}
                isUpdating={updatingFinancial}
              />
              <PPAContent
                financial={financial}
                isEditing={editingFiancial?.id === financial?.id}
                financialData={editingFiancial?.id === financial?.id ? editingFiancial : {}}
                onFinancialUpdate={data => onFinancialUpdate(data)}
                design_data={design_data}
                loading={editingFiancial?.id === financial?.id && loadingFinancial}
                quoteProducts={quoteProducts}
                onUpdateQuoteProducts={onUpdateQuoteProducts}
                updatingQuote={updatingQuote}
              />
            </div>
          ))}
          <div className="border radius-2 flex items-center pxy-3 cursor col-gap-1" onClick={onAddNewPPA}>
            <AddIcon className="primary-500-text " width={12} height={12} />
            <label className="inter-400-text primary-500-text font-12">Add new</label>
          </div>
        </Fragment>
      )}
    </SystemPPAWrapper>
  );
};

const SystemPPAWrapper = styled.div``;

const PPAContentWrapper = styled(motion.div)`
  .content-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .slider-wrapper {
    .input-element {
      width: 120px;
    }
  }

  .costs-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(430px, 1fr));
    row-gap: 12px;
    column-gap: 24px;
    margin-right: 8px;

    .input-element {
      width: 130px;
    }
    .playback-input .input-element {
      width: 120px;
    }
  }

  .opex-costs-wrapper,
  .revenue-wrapper {
    .header-container,
    .data-container {
      display: grid;
      grid-template-columns: 3fr 1fr 1fr 1fr;
      column-gap: 16px;
    }

    .header-container {
      height: 32px;
      background-color: ${({ theme }) => theme.natural_50};
    }
  }

  .excluded-products-tooltip {
    padding: 0;
  }
`;

export default SystemPPA;
