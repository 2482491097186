import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import IconContainer from '../../components/common/icon-container';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription, initSideModal } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { getUserData, updateUsersProfile } from '../../store/features/userManagementSlice';
import UserInfo from './user-info';
import UserServices from './user-services';
import UserTeams from './user-teams';

const EditUserProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { sideModal, setSideModal } = useContext(OrganisationContext);
  const { user_id, fromMyProfile } = sideModal?.content;

  const [editKey, setEditKey] = useState('');
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [editUserDetails, setEditUserDetails] = useState({});

  const { teams, services } = editUserDetails || {};

  const getModifiedUserDetails = userDetails => {
    const { services, teams, ...rest } = userDetails;
    return {
      ...rest,
      services: services
        ? services?.map(service => {
            return { ...service, service_id: nanoid() };
          })
        : [],
      teams: teams
        ? teams?.map(team => {
            return { ...team, team_id: nanoid(), isNew: false };
          })
        : [],
    };
  };

  const onUpdateOtherUser = data => {
    // update user details
  };

  const onUpdateOwnUser = data => {
    // update own user details in local storage
  };

  const fetchProfileDetails = async user_id => {
    setLoading(true);
    dispatch(getUserData({ user_id: user_id }))
      .then(data => setUserDetails(getModifiedUserDetails(data)))
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_USER_DETAILS'), id: nanoid() })))
      .finally(() => setLoading(false));
  };

  const handleUpdateProfile = async (type, isSendCode = false) => {
    const { title, name, email, phone, country_code = '+44', employee_number, locations } = editUserDetails;

    const fullName = name?.split(' ');
    const phoneRequest = phone ? phone?.replaceAll(country_code, '')?.replaceAll('-', '')?.replaceAll(' ', '') : null;
    const { formatted_address } = locations || {};

    const request = {
      title: title || '',
      employee_number: employee_number ?? null,
      email: email,
      forename: fullName?.[0],
      surname: fullName?.[1],
      phone: phoneRequest ? phoneRequest : null,
      country_code: phoneRequest ? country_code || '+44' : null,
      locations: formatted_address
        ? [
            {
              is_primary: true,
              is_billing: true,
              is_postal: true,
              ...locations,
            },
          ]
        : null,
    };
    dispatch(updateUsersProfile({ id: user_id, request: request }))
      .then(({ data }) => {
        if (isSendCode) {
          dispatch(addToast({ error: false, text: t('CODE_SENT_SUCCESSFULLY') }));
        } else {
          dispatch(addToast({ error: false, text: t('USER_VALUE_UPDATED', { value: t(type) }) }));
          setEditKey(null);
          setUserDetails(
            getModifiedUserDetails({ ...data, locations: Array.isArray(locations) ? locations : [locations] }),
          );
        }
      })
      .catch(error => {
        const errorText = getErrorDescription(error, t('ERROR_WHILE_UPDATING_PROFILE_DETAIL'));
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => setLoading(false));
  };

  const handleUpdateServices = async services => {
    const updatedServices = services.map(({ daily_capacity, service }) => ({
      daily_capacity,
      ...service,
    }));
    const updatedUserDetails = { ...userDetails, services: updatedServices };
    setUserDetails(getModifiedUserDetails(updatedUserDetails));
  };

  useEffect(() => {
    if (user_id) {
      fetchProfileDetails(user_id);
    }

    return () => {
      setUserDetails({});
    };
  }, [user_id]);

  useEffect(() => {
    if (editKey) {
      setEditUserDetails(userDetails);
    } else {
      setEditUserDetails({});
    }
  }, [editKey, userDetails]);

  return (
    <EditUserProfileWrapper className="flex-column bg-white h-full">
      {loading ? (
        <div className="item flex items-center justify-center flex-1 w-full">
          <Skeleton height={'100%'} containerClassName="line-height-1 h-full w-full" />
        </div>
      ) : (
        <Fragment>
          <div className="flex justify-between items-center border-bottom px-6 pt-6_5 pb-5">
            <label className="inter-600-text font-20 line-height-28 natural-900-text">{t('PROFILE_INFO')}</label>
            <IconContainer
              Icon={CloseIcon}
              backgroundColor={loading ? 'natural_50' : 'transparent'}
              iconWidth={16}
              iconHeight={16}
              iconColor={loading ? 'natural_400' : 'natural_900'}
              iconContainerClassname="cursor"
              onClick={() => {
                if (loading) return;
                setSideModal(initSideModal);
              }}
            />
          </div>
          <div className="px-6 py-4 flex-1 overflow-auto custom-scrollbar thin-scrollbar">
            <UserInfo
              user_id={user_id}
              editKey={editKey}
              setEditKey={setEditKey}
              userDetails={editKey ? editUserDetails : userDetails}
              setUserDetails={setEditUserDetails}
              fetchProfileDetails={fetchProfileDetails}
              fromMyProfile={fromMyProfile}
              handleUpdateProfile={handleUpdateProfile}
              updateMainUserDetails={setUserDetails}
              mainUserDetails={userDetails}
            />
            <UserServices
              editKey={editKey}
              setEditKey={setEditKey}
              services={editKey === 'SERVICES' ? services : userDetails?.services}
              fetchProfileDetails={fetchProfileDetails}
              user_id={user_id}
              setUserDetails={setEditUserDetails}
              userDetails={editKey ? editUserDetails : userDetails}
              handleUpdateServices={handleUpdateServices}
            />
            <UserTeams
              teams={editKey === 'TEAMS' ? teams : userDetails?.teams}
              editKey={editKey}
              setEditKey={setEditKey}
              fetchProfileDetails={fetchProfileDetails}
              user_id={user_id}
              handleUpdateProfile={handleUpdateProfile}
            />
          </div>
        </Fragment>
      )}
    </EditUserProfileWrapper>
  );
};

const EditUserProfileWrapper = styled.div`
  .avtar-box {
    border: none !important;
  }

  .edit-file-input {
    .input-element {
      width: 100%;
      height: 24px;
      padding: 2px 8px;
    }
  }

  .edit-icon-box {
    top: 8px;
    right: 8px;
  }

  .contact-label {
    width: 64px !important;
  }

  .rander-item {
    .edit-text {
      .edit-icon {
        display: none;
      }
      &:hover {
        .edit-icon {
          display: block !important;
        }
        .info-icon {
          &:hover {
            color: ${({ theme }) => theme.primary_500};
          }
        }
        padding: 2px 8px;
        gap: 10px;
        border-radius: 6px;
        background-color: ${({ theme }) => theme.natural_200};
      }
    }
  }

  .profile-update-action {
    background-color: ${({ theme }) => theme.white};
    z-index: 999;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }

  .cross-icon {
    width: 32px;
    height: 32px;
  }

  .right-icon {
    background-color: ${({ theme }) => theme.primary_500};
    width: 32px;
    height: 32px;
  }

  .opt-right-icon {
    width: 32px;
    height: 32px;
    border-radius: 40px;
  }

  .phone-input {
    min-width: 240px;
  }

  .otp-input {
    height: 44px;
    .input {
      width: 44px;
    }
  }

  .teams-service-edit-wrapper {
    .edit-icon {
      display: none;
    }
    &:hover {
      .edit-icon {
        display: block !important;
      }
    }

    .add-service-icon {
      width: 24px;
      height: 24px;
    }
  }
`;

export default EditUserProfile;
