import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as BundleIcon } from '../../assets/icons/bundles.svg';
import { ReactComponent as ComponenetIcon } from '../../assets/icons/components.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh-icon.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as SummaryIcon } from '../../assets/icons/summary.svg';
import { ReactComponent as SolarBattery } from '../../assets/images/batteries.svg';
import { ReactComponent as SolarInvertor } from '../../assets/images/invertor.svg';
import { ReactComponent as SolarPanel } from '../../assets/images/panel.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputSearch from '../../components/common/input-search';
import Status from '../../components/common/status';
import useDebounce from '../../helpers/useDebounceHook';
import { getComponentPrice, getFormattedNumberStyle } from '../../helpers/utils';
import { getSolarOptions } from '../../store/features/propertySlice';
import {
  createQuote,
  getProductComponents,
  getProductList,
  getProductSpecificationsInfo,
  updateQuoteDetails,
} from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const designTabs = [
  {
    name: 'Summary',
    key: 'SUMMARY',
    Icon: SummaryIcon,
  },
  {
    name: 'Bundle',
    key: 'BUNDLE',
    tag: 'SOLAR_BUNDLE',
    Icon: BundleIcon,
  },
  {
    name: 'Panels',
    key: 'PANELS',
    tag: 'SOLAR_PANELS',
    Icon: SolarPanel,
  },
  {
    name: 'Inverters',
    key: 'INVERTERS',
    tag: 'SOLAR_INVERTORS',
    Icon: SolarInvertor,
  },
  {
    name: 'Batteries',
    key: 'BATTERIES',
    tag: 'SOLAR_BATTERIES',
    Icon: SolarBattery,
  },
  {
    name: 'Add-on Extras',
    key: 'SOLAR_UPGRADES',
    tag: 'SOLAR_UPGRADES',
    Icon: StarIcon,
  },
];

const soldAsTabs = [
  {
    name: 'Bundle',
    key: 'BUNDLE',
    Icon: BundleIcon,
  },
  {
    name: 'Components',
    key: 'COMPONENTS',
    Icon: ComponenetIcon,
  },
];

const Tabs = ({ selectedTab, setSelectedTab, selectedSoldAs }) => {
  const isBundleSelected = selectedSoldAs === 'BUNDLE';
  const tabsList = isBundleSelected ? designTabs : designTabs.filter(t => t.key !== 'BUNDLE');
  return (
    <div
      className={classNames('col-gap-1', {
        'tab-wrapper-bundle': isBundleSelected,
        'tab-wrapper': !isBundleSelected,
      })}>
      {tabsList.map(tab => (
        <div
          key={tab.key}
          className={classNames(
            'flex-column items-center justify-center w-full bg-natural-50 cursor pxy-1 radius-1 tab',
            selectedTab === tab.key && 'selected-tab',
          )}
          onClick={() => setSelectedTab(tab.key)}>
          <tab.Icon
            width={24}
            height={24}
            className={classNames(
              'mxy-1',
              selectedTab === tab.key && 'primary-500-text',
              selectedTab !== tab.key && 'natural-300-text',
            )}
          />
          <div
            className={classNames(
              'inter-500-text text-center font-12',
              selectedTab === tab.key && 'primary-500-text',
              selectedTab !== tab.key && 'natural-700-text',
            )}>
            {tab.name}
          </div>
        </div>
      ))}
    </div>
  );
};

const SoldAsTabs = ({ selectedSoldAs, setSelectedSoldAs, setSelectedTab }) => {
  return (
    <div className="sold-as-tab-wrapper flex items-center pxy-1 radius-2 bg-natural-50 col-gap-2">
      {soldAsTabs.map(tab => (
        <div
          key={tab.key}
          className={classNames(
            'flex items-center justify-center w-full cursor px-3 py-1 radius-2 cursor',
            selectedSoldAs === tab.key && 'bg-primary-500',
          )}
          onClick={() => {
            setSelectedSoldAs(tab.key);
            setSelectedTab('SUMMARY');
          }}>
          <tab.Icon
            width={20}
            height={20}
            className={classNames(
              'mxy-1',
              selectedSoldAs === tab.key && 'white-text',
              selectedSoldAs !== tab.key && 'natural-700-text',
            )}
          />
          <div
            className={classNames(
              'inter-500-text text-center',
              selectedSoldAs === tab.key && 'white-text',
              selectedSoldAs !== tab.key && 'natural-700-text',
            )}>
            {tab.name}
          </div>
        </div>
      ))}
    </div>
  );
};

const RenderComponentUI = ({
  loading = false,
  component,
  DefaultIcon,
  isSelected,
  showSwap = false,
  onSwapClick = () => {},
  isBundleTab,
  energyUsage = [],
  data,
  showAddOrRemove = false,
  onAddOrRemoveProduct = () => {},
}) => {
  const { estimate } = data || {};
  const { annual_production_kwh } = estimate || {};
  const totalEnergyUsage = (energyUsage || []).reduce((acc, curr) => acc + parseFloat(curr.consumption_kwh), 0);
  const percentageOfTotalEnergy = (annual_production_kwh / (totalEnergyUsage || 1)) * 100;

  return (
    <div className={classNames('relative flex-column', component.isRecommended && 'mt-3')} key={component.id}>
      {component.isRecommended && (
        <div className="absolute flex flex-1 items-center justify-end recommended-wrapper">
          <div className="flex items-center justify-center radius-1 recommended-content">
            <label className="inter-600-text font-10 white-text">Recommended</label>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'flex-column pxy-4 border row-gap-2 radius-2 relative bg-white',
          component.isRecommended && 'recommended-component',
          isSelected && 'selected-component',
        )}>
        <div className="flex col-gap-3">
          <div className="border flex items-center justify-center image-wrapper radius-1 pxy-1 overflow-hidden">
            {component?.image?.url ? (
              <img
                src={component?.image?.url}
                alt="component"
                className=""
                height={80}
                width={54}
                style={{ objectFit: 'fill' }}
              />
            ) : (
              <DefaultIcon width={32} height={32} className="natural-400-text" />
            )}
          </div>
          <div className="flex items-start col-gap-1 flex-1">
            <div className="flex-column flex-1 row-gap-2">
              <div className="inter-500-text font-14">{component.name}</div>
              {isBundleTab ? (
                <Status
                  statusText={
                    totalEnergyUsage > 0
                      ? `Energy: ${getFormattedNumberStyle(percentageOfTotalEnergy, 'decimal', 0, 0)}%`
                      : '-'
                  }
                  status={totalEnergyUsage ? (percentageOfTotalEnergy > 100 ? 'SUCCESS' : 'MEDIUM') : ''}
                />
              ) : showSwap ? (
                <Button
                  label="Swap"
                  lableSize="font-10"
                  className="primary-blue specified-width px-3 py-1"
                  afterIcon={<RefreshIcon width={12} height={12} className="primary-500-text" />}
                  onClick={() => !loading && onSwapClick(component)}
                />
              ) : showAddOrRemove && !isSelected ? (
                <Button
                  label="Add"
                  lableSize="font-10"
                  className="primary-blue specified-width px-3 py-1"
                  afterIcon={<AddIcon width={10} height={10} className="primary-500-text" />}
                  onClick={() => !loading && onAddOrRemoveProduct({ product: data, type: 'ADD' })}
                />
              ) : showAddOrRemove && isSelected ? (
                <div className="flex items-center w-fit-content px-2 py-1 col-gap-3 bg-white radius-3">
                  <IconContainer
                    Icon={MinusIcon}
                    backgroundColor="transparent"
                    iconHeight={14}
                    iconWidth={14}
                    iconColor="natural_700"
                    iconContainerClassname="p-0 cursor"
                    onClick={() =>
                      !loading &&
                      onAddOrRemoveProduct({
                        product: data,
                        type: 'UPDATE',
                        quantity: (component.qty || 1) - 1,
                      })
                    }
                  />
                  <label className="inter-500-text font-12">{component.qty || 1}</label>
                  <IconContainer
                    Icon={AddIcon}
                    backgroundColor="transparent"
                    iconHeight={12}
                    iconWidth={12}
                    iconColor="natural_700"
                    iconContainerClassname="p-0 cursor"
                    onClick={() =>
                      !loading &&
                      onAddOrRemoveProduct({
                        product: data,
                        type: 'UPDATE',
                        quantity: (component.quantity || component.qty || 1) + 1,
                      })
                    }
                  />
                </div>
              ) : (
                <div className="flex items-center col-gap-3">
                  <Status statusText={`Qty: ${component.quantity || component.qty || 1}`} />
                </div>
              )}
            </div>
            {showAddOrRemove && isSelected && (
              <IconContainer
                Icon={CloseIcon}
                iconContainerClassname="radius-full cursor"
                backgroundColor="white"
                onClick={() => !loading && onAddOrRemoveProduct({ product: data, type: 'REMOVE' })}
              />
            )}
          </div>
        </div>
        <div className="flex items-center three-lines inter-400-text font-12 natural-500-text">
          {component?.description}
        </div>
      </div>
    </div>
  );
};

const SummaryTab = ({ quoteDetails }) => {
  const { products } = quoteDetails || {};
  const productWithComponents =
    products
      ?.filter(p => p?.components?.length)
      ?.map(p => p?.components)
      .flat() || [];
  const productWithoutComponents = products?.filter(p => !p?.components?.length) || [];
  const productComponents = [...productWithComponents, ...productWithoutComponents];

  const panelComponents = productComponents.filter(c => c?.families?.find(f => f?.tag?.includes('PANELS')));
  const invertorComponents = productComponents.filter(c => c?.families?.find(f => f?.tag?.includes('INVERTERS')));
  const batteriesComponents = productComponents.filter(c => c?.families?.find(f => f?.tag?.includes('BATTERIES')));
  const addOnComponents = productComponents.filter(c => c?.families?.find(f => f?.tag?.includes('SOLAR_UPGRADES')));

  return (
    <div className="flex-column col-gap-3 row-gap-4 px-2 overflow-scroll thin-scrollbar custom-scrollbar">
      {panelComponents.length > 0 && (
        <div className="flex-column">
          <label className="inter-600-text font-12 mb-2 letter-spacing-1">PANELS</label>
          <div className="flex-column row-gap-3">
            {panelComponents.map(component => (
              <RenderComponentUI key={component.id} component={component} DefaultIcon={SolarPanel} />
            ))}
          </div>
        </div>
      )}
      {invertorComponents.length > 0 && (
        <div className="flex-column">
          <label className="inter-600-text font-12 mb-2 letter-spacing-1">INVERTERS</label>
          <div className="flex-column row-gap-3">
            {invertorComponents.map(component => (
              <RenderComponentUI key={component.id} component={component} DefaultIcon={SolarInvertor} />
            ))}
          </div>
        </div>
      )}
      {batteriesComponents.length > 0 && (
        <div className="flex-column">
          <label className="inter-600-text font-12 mb-2 letter-spacing-1">BATTERIES</label>
          <div className="flex-column row-gap-3">
            {batteriesComponents.map(component => (
              <RenderComponentUI key={component.id} component={component} DefaultIcon={SolarBattery} />
            ))}
          </div>
        </div>
      )}
      {addOnComponents.length > 0 && (
        <div className="flex-column">
          <label className="inter-600-text font-12 mb-2 letter-spacing-1">ADD-ON EXTRAS</label>
          <div className="flex-column row-gap-3">
            {addOnComponents.map(component => (
              <RenderComponentUI key={component.id} component={component} DefaultIcon={StarIcon} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const TabContents = ({
  isResidencial = false,
  selectedTab,
  quoteDetails,
  solarOptionProducts,
  showSwap = false,
  isBundleTab = false,
  onUpdateProduct = () => {},
  energyUsage,
  solarOptions,
  showAddOrRemove = false,
  onAddOrRemoveProduct = () => {},
}) => {
  const dispatch = useDispatch();

  const { products: quoteProducts } = quoteDetails || {};
  const bundleProduct = isResidencial ? quoteProducts?.[0] : null;

  const productWithComponents = isBundleTab
    ? quoteProducts
    : quoteProducts
        ?.filter(p => p?.components?.length)
        ?.map(p => p?.components)
        .flat() || [];
  const productWithoutComponents = quoteProducts?.filter(p => !p?.components?.length) || [];
  const productComponents = [...productWithComponents, ...productWithoutComponents];

  const recommendedProductsIds = solarOptionProducts?.map(p => p?.id) || [];

  const selectedComponents =
    productComponents
      .filter(c => c?.families?.find(f => f?.tag?.includes(isBundleTab ? 'SOLAR_BUNDLE' : selectedTab)))
      ?.map(c => ({ ...c, isRecommended: recommendedProductsIds.includes(c?.product?.id) })) || [];
  const selectedComponentIds = selectedComponents?.map(s => s?.product?.id)?.filter(Boolean) || [];

  const DefaultIcon = designTabs.find(t => t.key === selectedTab).Icon;

  const [originalProducts, setOriginalProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const debouncedSearch = useDebounce(searchText, 500);

  const filteredProducts = products?.filter(p => !selectedComponentIds.includes(p.id));

  const fetchProducts = () => {
    if (isBundleTab && isResidencial) {
      const products = solarOptions?.map(option => ({
        ...option.bundle,
        config: option.config,
        estimate: option.estimate,
      }));
      const filteredContent = products.map(c => ({ ...c, isRecommended: recommendedProductsIds.includes(c.id) }));
      setProducts(filteredContent);
      setOriginalProducts(products);
      return;
    }
    if (isResidencial && !isBundleTab) {
      setLoading(true);
      dispatch(getProductComponents({ product_id: bundleProduct?.product?.id }))
        .then(data => {
          const selectedTabTag = designTabs.find(t => t.key === selectedTab)?.tag;
          const tabCoponent = data?.find(c => c?.tag?.includes(selectedTabTag));
          const { components } = tabCoponent || {};
          const filteredContent = components.map(c => ({ ...c, isRecommended: recommendedProductsIds.includes(c.id) }));
          setProducts(filteredContent);
          setOriginalProducts(components);
        })
        .catch(error => {
          setProducts([]);
          setOriginalProducts([]);
        })
        .finally(() => setLoading(false));
    } else {
      const family_tag = isBundleTab ? 'SOLAR_BUNDLE' : selectedTab;
      setLoading(true);
      dispatch(
        getProductList({
          params: {
            search: debouncedSearch,
            family_tag: family_tag,
            include_pricing: true,
            size: 100,
            sold_as: isBundleTab ? 'BUNDLE' : undefined,
          },
        }),
      )
        .then(data => {
          const { content } = data || {};
          const filteredContent = content.map(c => ({ ...c, isRecommended: recommendedProductsIds.includes(c.id) }));
          setProducts(filteredContent);
          setOriginalProducts(content);
        })
        .catch(error => {
          setProducts([]);
          setOriginalProducts([]);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [selectedTab, debouncedSearch]);

  const onSwapClick = product => {
    const firstSelectedComponent = selectedComponents?.[0] || {};
    onUpdateProduct(firstSelectedComponent, product);
  };

  return (
    <div className="flex-column row-gap-4 px-2 overflow-scroll thin-scrollbar custom-scrollbar">
      <div className="flex-column row-gap-3">
        {selectedComponents.length > 0 && (
          <div className="flex-column">
            <label className="inter-600-text font-12 mb-2">Selected</label>
            <div className="flex-column row-gap-3">
              {selectedComponents.map(component => (
                <RenderComponentUI
                  key={component.id}
                  loading={loading}
                  component={{ ...component, isRecommended: recommendedProductsIds.includes(component?.product?.id) }}
                  DefaultIcon={DefaultIcon}
                  isSelected={true}
                  isBundleTab={isBundleTab}
                  energyUsage={energyUsage}
                  data={originalProducts.find(p => p.id === component?.product?.id)}
                  showAddOrRemove={showAddOrRemove}
                  onAddOrRemoveProduct={onAddOrRemoveProduct}
                />
              ))}
            </div>
          </div>
        )}
        {!isResidencial && (
          <InputSearch
            className={'my-3'}
            placeholder={'Search'}
            value={searchText}
            onChange={setSearchText}
            autoFocus={!!searchText}
          />
        )}
        {loading ? (
          <Skeleton height={200} width={'100%'} />
        ) : (
          <Fragment>
            {filteredProducts.length > 0 && (
              <div className="flex-column">
                <label className="inter-600-text font-12 mb-2">Other options</label>
                <div className="flex-column row-gap-3">
                  {filteredProducts.map(component => (
                    <RenderComponentUI
                      key={component.id}
                      loading={loading}
                      component={component}
                      DefaultIcon={DefaultIcon}
                      showSwap={showSwap}
                      onSwapClick={onSwapClick}
                      isBundleTab={isBundleTab}
                      energyUsage={energyUsage}
                      data={component}
                      showAddOrRemove={showAddOrRemove}
                      onAddOrRemoveProduct={onAddOrRemoveProduct}
                    />
                  ))}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

const DesignProducts = ({
  fetchSolarDetails,
  selectedDesign,
  setSelectedDesign,
  fetchSolarDesignData,
  panel_count,
  engagement_id,
  property_id,
  fetchSolarDesignAdditionalData,
  onSwapPanels,
  energyUsage,
}) => {
  const dispatch = useDispatch();

  const { jobDetails, propertyDetails } = useSelector(state => state.property);
  const { engagement } = jobDetails || {};
  const { primary_contact } = engagement || {};
  const { property_class } = propertyDetails || {};

  const { quote: quoteDetails, id: design_id, panel_product } = selectedDesign || {};
  const { id: panel_product_id } = panel_product || {};

  const initialProductWithComponents = quoteDetails?.products?.find(p => p?.components?.length);

  const [solarOptions, setSolarOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSolarOptions, setLoadingSolarOptions] = useState(true);
  const [selectedTab, setSelectedTab] = useState('SUMMARY');
  const [selectedSoldAs, setSelectedSoldAs] = useState(initialProductWithComponents ? 'BUNDLE' : 'COMPONENTS');
  const [showSelectedSoldAs, setShowSelectedSoldAs] = useState(true);
  const [quoteData, setQuoteData] = useState(quoteDetails);
  const [isSavingQuote, setIsSavingQuote] = useState(false);

  const { id: quote_id } = quoteData || {};

  const showActionBtn = JSON.stringify(quoteDetails) !== JSON.stringify(quoteData);

  useEffect(() => {
    setQuoteData(quoteDetails);
  }, [quoteDetails]);

  const isResidencial = useMemo(() => {
    return property_class?.class_type === 'RESIDENTIAL';
  }, [property_class?.class_type]);

  const getRecommendedOptionProducts = () => {
    const sortedOptions = solarOptions?.sort((a, b) => b?.config?.panels - a?.config?.panels);
    const optionWithMatchingPanels = sortedOptions.find(option => option?.config?.panels <= panel_count);
    const { bundle, products } = optionWithMatchingPanels || {};

    if (bundle) {
      const { component_maps } = bundle || {};
      const components = component_maps
        ?.map(({ components }) => components.find(comp => comp?.is_inclusive))
        ?.filter(Boolean);

      return components?.flat() || [];
    }
    return products || [];
  };

  const solarOptionProducts = getRecommendedOptionProducts();

  const onCreateQuote = async (solarOptions, use_components = false) => {
    let request = {
      integration: null,
      code: null,
      status: 'DRAFT',
      solar_design: {
        id: selectedDesign?.id,
      },
      quote_type: 'COST',
      payment_terms_template: null,
      quote_template: null,
      recipients: primary_contact?.id ? [{ contact: { id: primary_contact?.id }, requires_acceptance: true }] : null,
    };
    if (isResidencial && !use_components) {
      const sortedOptions = solarOptions?.sort((a, b) => b?.products?.length - a?.products?.length);
      const optionWithMatchingPanels = sortedOptions.find(option => option?.config?.panels <= panel_count);
      const { bundle } = optionWithMatchingPanels || {};

      request = {
        ...request,
        products: [
          {
            name: bundle?.name,
            description: bundle?.description,
            sku: bundle?.sku,
            components: bundle?.component_maps
              ?.map(c => {
                return c.components.find(comp => comp?.sell_type === 'BASE');
              })
              .filter(Boolean)
              .map((c, index) => ({
                name: c?.name,
                description: c?.description,
                sku: c?.sku,
                line_item_type: 'OTHER',
                image: c?.image?.media_external_id ? { ...c?.image, id: c?.image?.media_external_id } : null,
                qty: c.quantity,
                line_no: index + 1,
                unit: 'COUNT',
                product_type: 'PHYSICAL',
              })),
            product: { id: bundle?.id },
            qty: 1,
            line_no: 1,
            unit: 'COUNT',
            line_item_type: 'OTHER',
            product_type: 'PHYSICAL',
            total_net: bundle?.pricing?.total_net,
            total_amount: bundle?.pricing?.total_amount,
            total_tax: bundle?.pricing?.total_tax,
            unit_price: bundle?.pricing?.unit_price,
          },
        ],
        total_net: bundle?.pricing?.total_net,
        total_amount: bundle?.pricing?.total_amount,
        total_tax: bundle?.pricing?.total_tax,
        unit_price: bundle?.pricing?.unit_price,
      };
    } else {
      const firstOption = solarOptions?.[0];
      const { bundle, products: optionProducts } = firstOption || {};
      const products = bundle ? [{ ...bundle }] : optionProducts || [];

      request = {
        ...request,
        products: products.map((product, index) => {
          const { pricing, quantity, id, image, ...rest } = product;
          return {
            ...rest,
            image: image?.media_external_id ? { ...image, id: image?.media_external_id } : null,
            product_type: 'PHYSICAL',
            unit: 'COUNT',
            line_no: index + 1,
            product: { id },
            qty: quantity || 1,
            unit_price: pricing?.unit_price || 0,
            discount_amount: pricing?.discount_amount || 0,
            total_net: pricing?.total_net || 0,
            total_tax: pricing?.total_tax || 0,
            total_amount: pricing?.total_amount || 0,
          };
        }),
        total_amount: products.reduce((acc, product) => acc + (product.pricing?.total_amount || 0), 0),
        total_net: products.reduce((acc, product) => acc + (product.pricing?.total_net || 0), 0),
        total_tax: products.reduce((acc, product) => acc + (product.pricing?.total_tax || 0), 0),
        total_discount: products.reduce((acc, product) => acc + (product.pricing?.discount_amount || 0), 0),
      };
    }
    const quoteData = await dispatch(createQuote({ request: request, engagement_id }));
    const initialProductWithComponents = quoteData?.products?.find(p => p?.components?.length);
    setSelectedSoldAs(initialProductWithComponents ? 'BUNDLE' : 'COMPONENTS');
    setSelectedDesign({ ...selectedDesign, quote: quoteData });
    fetchSolarDesignData();
    fetchSolarDesignAdditionalData();
  };

  const fetchSolarOptionsAndCreateQuote = async (create_quote = false, params = {}, fetchOnly = false) => {
    try {
      setLoadingSolarOptions(true);
      let bundleProducts = [];
      if (isResidencial) {
        const { content } = await dispatch(getProductList({ params: { family_tag: 'SOLAR_BUNDLE', size: 100 } }));
        bundleProducts = content || [];
        setShowSelectedSoldAs(bundleProducts.length > 0);
      }
      const force_components = isResidencial ? !bundleProducts?.length : undefined;
      const optionsData = await dispatch(
        getSolarOptions({
          id: design_id,
          params: { panel_count: create_quote && !isResidencial ? panel_count : null, force_components, ...params },
        }),
      );
      const { options } = optionsData || {};
      if (create_quote) {
        await onCreateQuote(options, force_components);
      }
      !fetchOnly && setSolarOptions(options);
      setLoadingSolarOptions(false);
      return options;
    } catch (error) {
      console.log('error', error);
      setLoadingSolarOptions(false);
      return [];
    }
  };

  const onSwapInverterOrBatteryInBundle = ({ product, selectedTab, selectedComponent }) => {
    let newQuoteData = { ...quoteData };

    const { products } = quoteData || {};
    const { pricing, quantity: productQuantity, id, image } = product;
    const componentPrice = getComponentPrice(product, false);

    let newProducts = products.map(p => ({
      ...p,
      components: p.components.map((c, index) => {
        if (c.id === selectedComponent?.id) {
          return {
            ...product,
            pricing,
            id: nanoid(),
            families: [{ tag: selectedTab }],
            image: image?.media_external_id ? { ...image, id: image?.media_external_id, url: image?.url } : null,
            product_type: 'PHYSICAL',
            unit: 'COUNT',
            line_no: index + 1,
            product: { id },
            qty: productQuantity || 1,
            unit_price: componentPrice?.unit_price || 0,
            discount_amount: componentPrice?.discount_amount || 0,
            total_net: componentPrice?.total_net || 0,
            total_tax: componentPrice?.total_tax || 0,
            total_amount: componentPrice?.total_amount || 0,
          };
        }
        return c;
      }),
    }));

    newProducts = newProducts.map(p => ({
      ...p,
      unit_price: p.components.reduce((acc, c) => acc + (c.unit_price || 0), 0),
      total_net: p.components.reduce((acc, c) => acc + (c.total_net || 0), 0),
      total_tax: p.components.reduce((acc, c) => acc + (c.total_tax || 0), 0),
      total_amount: p.components.reduce((acc, c) => acc + (c.total_amount || 0), 0),
    }));

    newQuoteData = {
      ...quoteData,
      products: newProducts,
      unit_price: newProducts.reduce((acc, p) => acc + (p.unit_price || 0), 0),
      total_net: newProducts.reduce((acc, p) => acc + (p.total_net || 0), 0),
      total_tax: newProducts.reduce((acc, p) => acc + (p.total_tax || 0), 0),
      total_amount: newProducts.reduce((acc, p) => acc + (p.total_amount || 0), 0),
    };

    setQuoteData(newQuoteData);
  };

  const onUpdateComponent = useCallback(
    async (_selectedComponent, product, updatedDesign = null) => {
      if (selectedTab === 'INVERTERS' || selectedTab === 'BATTERIES') {
        //swap invertes or batteries
        onSwapInverterOrBatteryInBundle({ product, selectedTab, selectedComponent: _selectedComponent });
      } else {
        setLoading(true);
        await dispatch(getProductSpecificationsInfo({ id: product.id }))
          .then(async data => {
            const specifications = data;
            const design = updatedDesign || selectedDesign;
            const newDesign = { ...design, panel_product: { ...product, specifications: specifications } };
            setSelectedDesign({ ...newDesign });
            const { countDelta, newPanelCount } = await onSwapPanels(specifications, newDesign);
            return countDelta;
          })
          .catch(error => {
            dispatch(addToast({ error: true, message: 'Failed to swap product', id: nanoid() }));
            return null;
          })
          .finally(() => setLoading(false));
      }
    },
    [selectedDesign, selectedTab],
  );

  useEffect(() => {
    if (fetchSolarDetails) {
      if (quote_id && selectedDesign?.id) {
        fetchSolarOptionsAndCreateQuote(false);
      } else {
        fetchSolarOptionsAndCreateQuote(true);
      }
    }
  }, [selectedDesign?.id]);

  const onChangeToComponents = async () => {
    let request = {
      ...quoteDetails,
      integration: quoteDetails?.integration?.id ? { id: quoteDetails?.integration?.id } : null,
    };
    const solarOptions = await fetchSolarOptionsAndCreateQuote(false, {
      force_components: true,
      panel_product_id: panel_product_id,
    });
    const firstOption = solarOptions?.[0];
    const { bundle, products: optionProducts } = firstOption || {};
    const products = bundle ? [{ ...bundle }] : optionProducts || [];

    request = {
      ...request,
      products: products.map((product, index) => {
        const { pricing, quantity, id, image, ...rest } = product;
        return {
          ...rest,
          image: image?.media_external_id ? { ...image, id: image?.media_external_id, url: image?.url } : null,
          product_type: 'PHYSICAL',
          unit: 'COUNT',
          line_no: index + 1,
          product: { id },
          qty: quantity || 1,
          unit_price: pricing?.unit_price || 0,
          discount_amount: pricing?.discount_amount || 0,
          total_net: pricing?.total_net || 0,
          total_tax: pricing?.total_tax || 0,
          total_amount: pricing?.total_amount || 0,
        };
      }),
      total_amount: products.reduce((acc, product) => acc + (product.pricing?.total_amount || 0), 0),
      total_net: products.reduce((acc, product) => acc + (product.pricing?.total_net || 0), 0),
      total_tax: products.reduce((acc, product) => acc + (product.pricing?.total_tax || 0), 0),
      total_discount: products.reduce((acc, product) => acc + (product.pricing?.discount_amount || 0), 0),
    };
    setLoading(true);
    await dispatch(updateQuoteDetails({ quote_id, request }))
      .then(async data => {
        setSelectedDesign({ ...selectedDesign, quote: data });
        await fetchSolarDesignAdditionalData();
      })
      .catch(error => dispatch(addToast({ error: true, message: 'Failed to update quote', id: nanoid() })))
      .finally(() => setLoading(false));
  };

  const onChangeToBundle = async () => {
    const qty = panel_count;
    let request = {
      ...quoteDetails,
      integration: quoteDetails?.integration?.id ? { id: quoteDetails?.integration?.id } : null,
    };
    const solarOptions = await fetchSolarOptionsAndCreateQuote(false, {});
    const sortedOptions = solarOptions?.sort((a, b) => b?.config?.panels - a?.config?.panels);
    const optionWithMatchingPanels = sortedOptions.find(option => option?.config?.panels <= qty);

    const { bundle } = optionWithMatchingPanels || {};
    const panelProduct = bundle?.component_maps
      ?.find(c => c.name?.includes('Panel'))
      ?.components?.find(c => c.is_inclusive);

    request = {
      ...request,
      products: [
        {
          name: bundle?.name,
          description: bundle?.description,
          sku: bundle?.sku,
          components: bundle?.component_maps
            ?.map(c => {
              return c.components.find(comp => comp?.is_inclusive);
            })
            .filter(Boolean)
            .map((c, index) => ({
              name: c?.name,
              description: c?.description,
              sku: c?.sku,
              line_item_type: 'OTHER',
              image: c?.image?.media_external_id
                ? { ...c?.image, id: c?.image?.media_external_id, url: c?.image?.url }
                : null,
              qty: c.quantity,
              product: { id: c?.id },
              line_no: index + 1,
              unit: 'COUNT',
              product_type: 'PHYSICAL',
              total_net: c?.pricing?.total_net,
              total_amount: c?.pricing?.total_amount,
              total_tax: c?.pricing?.total_tax,
              unit_price: c?.pricing?.unit_price,
            })),
          product: { id: bundle?.id },
          qty: 1,
          line_no: 1,
          unit: 'COUNT',
          line_item_type: 'OTHER',
          product_type: 'PHYSICAL',
          total_net: bundle?.pricing?.total_net,
          total_amount: bundle?.pricing?.total_amount,
          total_tax: bundle?.pricing?.total_tax,
          unit_price: bundle?.pricing?.unit_price,
        },
      ],
      total_net: bundle?.pricing?.total_net,
      total_amount: bundle?.pricing?.total_amount,
      total_tax: bundle?.pricing?.total_tax,
      unit_price: bundle?.pricing?.unit_price,
    };
    const design = { ...selectedDesign, quote: request };
    setSelectedDesign({ ...design });
    await onUpdateComponent(null, panelProduct, design);
  };

  const onUpdateQuote = async () => {
    const { products, integration } = quoteData || {};
    const request = {
      ...quoteData,
      integration: integration?.id ? { id: integration?.id } : null,
      total_amount: products.reduce((acc, product) => acc + (product.total_amount || 0), 0),
      total_net: products.reduce((acc, product) => acc + (product.total_net || 0), 0),
      total_tax: products.reduce((acc, product) => acc + (product.total_tax || 0), 0),
      total_discount: products.reduce((acc, product) => acc + (product.discount_amount || 0), 0),
    };
    setIsSavingQuote(true);
    await dispatch(updateQuoteDetails({ quote_id, request }))
      .then(async data => {
        setQuoteData(data);
        setSelectedDesign({ ...selectedDesign, quote: data });
        fetchSolarDesignAdditionalData();
      })
      .catch(error => dispatch(addToast({ error: true, message: 'Failed to update quote', id: nanoid() })))
      .finally(() => setIsSavingQuote(false));
  };

  const onSoldAsTabChange = async tab => {
    if (tab === 'COMPONENTS') {
      await onChangeToComponents();
    } else {
      await onChangeToBundle();
    }
    setSelectedSoldAs(tab);
    setSelectedTab('SUMMARY');
  };

  const onAddOrRemoveProduct = ({ product, type, quantity }) => {
    let newQuoteData = { ...quoteData };

    const { products } = quoteData || {};
    const { pricing, quantity: productQuantity, id, image, ...rest } = product;

    if (type === 'ADD') {
      const componentPrice = getComponentPrice(product, false);
      newQuoteData = {
        ...quoteData,
        products: [
          ...products,
          {
            ...rest,
            pricing,
            id: nanoid(),
            families: [{ tag: selectedTab }],
            image: image?.media_external_id ? { ...image, id: image?.media_external_id, url: image?.url } : null,
            product_type: 'PHYSICAL',
            unit: 'COUNT',
            line_no: products.length,
            product: { id },
            qty: productQuantity || 1,
            unit_price: componentPrice?.unit_price || 0,
            discount_amount: componentPrice?.discount_amount || 0,
            total_net: componentPrice?.total_net || 0,
            total_tax: componentPrice?.total_tax || 0,
            total_amount: componentPrice?.total_amount || 0,
          },
        ],
      };
    }
    if (type === 'UPDATE') {
      const componentPrice = getComponentPrice({ ...product, quantity }, false);

      const updatedProducts = products.map(p => {
        if (p?.product?.id === product.id) {
          return {
            ...p,
            image: image?.media_external_id ? { ...image, id: image?.media_external_id } : null,
            qty: quantity,
            unit_price: componentPrice?.unit_price || 0,
            discount_amount: componentPrice?.discount_amount || 0,
            total_net: componentPrice?.total_net || 0,
            total_tax: componentPrice?.total_tax || 0,
            total_amount: componentPrice?.total_amount || 0,
          };
        } else {
          return {
            ...p,
            image: p?.image?.media_external_id ? { ...p?.image, id: p?.image?.media_external_id } : null,
          };
        }
      });
      newQuoteData = { ...quoteData, products: updatedProducts };
    }
    if (type === 'REMOVE') {
      const updatedProducts = products.filter(p => p?.product?.id !== product.id);
      newQuoteData = { ...quoteData, products: updatedProducts };
    }
    setQuoteData(newQuoteData);
  };

  return (
    <DesignProductsWrapper className="pxy-2 flex overflow-hidden">
      {loading || loadingSolarOptions ? (
        <Skeleton height={'400px'} width={'100%'} containerClassName="w-full" />
      ) : (
        <div className="flex-column row-gap-4 pb-3 flex-1 overflow-hidden">
          {isResidencial && showSelectedSoldAs && (
            <SoldAsTabs
              selectedSoldAs={selectedSoldAs}
              setSelectedSoldAs={tab => onSoldAsTabChange(tab)}
              setSelectedTab={setSelectedTab}
            />
          )}
          <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} selectedSoldAs={selectedSoldAs} />
          {selectedTab === 'SUMMARY' && <SummaryTab quoteDetails={quoteDetails} />}
          {selectedTab !== 'SUMMARY' && (
            <TabContents
              isResidencial={isResidencial && selectedSoldAs === 'BUNDLE'}
              selectedTab={selectedTab}
              quoteDetails={quoteData}
              solarOptionProducts={solarOptionProducts}
              solarOptions={solarOptions}
              energyUsage={energyUsage}
              showSwap={
                selectedTab === 'PANELS' ||
                (selectedSoldAs === 'BUNDLE' && (selectedTab === 'INVERTERS' || selectedTab === 'BATTERIES'))
              }
              isBundleTab={selectedTab === 'BUNDLE'}
              onUpdateProduct={(selectedComponent, product) => onUpdateComponent(selectedComponent, product)}
              showAddOrRemove={
                selectedSoldAs === 'COMPONENTS' &&
                (selectedTab === 'INVERTERS' || selectedTab === 'BATTERIES' || selectedTab === 'SOLAR_UPGRADES')
              }
              onAddOrRemoveProduct={({ product, type, quantity }) => onAddOrRemoveProduct({ product, type, quantity })}
            />
          )}
          {showActionBtn && (
            <div className="flex col-gap-4 mx-2 my-1 justify-end">
              <Button
                size="medium"
                label="Cancel"
                className="default"
                disabled={isSavingQuote}
                onClick={() => {
                  setQuoteData(quoteDetails);
                }}
              />
              <Button
                size="medium"
                label="Save"
                className="primary"
                onClick={() => onUpdateQuote()}
                loading={isSavingQuote}
              />
            </div>
          )}
        </div>
      )}
    </DesignProductsWrapper>
  );
};

const DesignProductsWrapper = styled.div`
  height: 100%;

  .tab-wrapper-bundle {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  .tab-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .tab-wrapper,
  .tab-wrapper-bundle {
    .tab {
      border: 1px solid transparent;
    }

    .selected-tab {
      border-color: ${({ theme }) => theme.primary_500};
    }
  }

  .image-wrapper {
    height: 80px;
    width: 54px;
    min-width: 54px;
    min-height: 80px;
    background-color: white;
  }

  .selected-component {
    border-color: ${({ theme }) => theme.primary_500};
    background-color: ${({ theme }) => theme.primary_50};
  }

  .recommended-component {
    border-color: ${({ theme }) => theme.primary_500};
  }

  .recommended-wrapper {
    top: -19px;
    right: 0;

    .recommended-content {
      padding: 4px 8px 8px 8px;
      background-color: ${({ theme }) => theme.primary_500};
      border-radius: 6px 6px 0px 0px;
      background: linear-gradient(127deg, #77f7ff -4.27%, #4987e5 73.58%);
    }
  }
`;

export default DesignProducts;
