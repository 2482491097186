import classNames from 'classnames';
import { Reorder } from 'framer-motion';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { parse } from 'papaparse';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/property/calendar.svg';
import { ReactComponent as RetryIcon } from '../../assets/icons/property/refresh-upload.svg';
import { ReactComponent as MoneyIcon } from '../../assets/icons/property/value.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { ReactComponent as RoundExplanationIcon } from '../../assets/icons/round-explanation.svg';
import { ReactComponent as UploadFileIcon } from '../../assets/icons/upload-file.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { ReactComponent as Dot } from '../../assets/images/dot.svg';
import {
  bytesToSize,
  capitalize,
  formatText,
  getFormattedNumber,
  getFormattedNumberStyle,
  groupFilesByMonth,
} from '../../helpers/utils';
import { uploadFile } from '../../store/features/fileuploadSlice';
import { importEnergyConsumption } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';
import InputElement from '../common/input';
import SearchableDropdown from '../common/searchable-dropdown';
import Dropdown from '../common/select-dropdown';
import TimeSelectorElement from '../common/time-selector/time-selector-element';
import BarGraph from '../graphs/barGraph';
import Loader from '../loader';

const TabButtons = ({ tabInterval, handleTabClick, setEditConsumption }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-4 pb-6">
      <div className="label-electricity-usage">
        <label className="font-14 inter-400-text natural-500-text line-height-20">{t('USAGE_INTERVAL')}</label>
      </div>
      <div className="flex items-center gap-2 w-full">
        {['YEAR', 'QUARTER', 'MONTH'].map(interval => {
          let label;
          switch (interval) {
            case 'YEAR':
              label = t('ANNUALLY');
              break;
            case 'QUARTER':
              label = t('QUARTERLY');
              break;
            case 'MONTH':
              label = t('MONTHLY');
              break;
            default:
              label = '';
          }
          return (
            <Button
              key={interval}
              size="medium"
              width="112px"
              height="h-32px"
              className={classNames(
                'specified-width bg-white border-0 px-3 py-1_5',
                tabInterval === interval && 'secondary',
              )}
              label={label}
              borderRadius="6px"
              onClick={() => {
                handleTabClick(interval === 'YEAR' ? 'YEAR' : interval === 'QUARTER' ? 'QUARTER' : 'MONTH'),
                  setEditConsumption(false);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const UsageValues = ({
  tabInterval,
  totalConsumption,
  editConsumption,
  setEditConsumption,
  property_id,
  fetchConsumption,
  energyConsumption,
  setEnergyConsumption,
  loading,
  editedData,
  setEditedData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [updateLoading, setUpdateLoading] = useState(false);

  const onUpdate = async label => {
    setUpdateLoading(true);
    try {
      const request = editedData.map(item => ({
        month: item?.month || null,
        quarter: item?.quarter || null,
        day: null,
        hour: null,
        half_hour: null,
        consumption_kwh: parseInt(item.consumption_kwh) || 0,
      }));
      dispatch(
        addToast({
          error: false,
          text: t('UPDATING_CONSUMPTION_PLEASE_WAIT'),
        }),
      );
      await dispatch(importEnergyConsumption({ id: property_id, type: 'ELECTRICITY', request }));
      fetchConsumption(tabInterval);
      fetchConsumption('MONTH', true);
      setEditConsumption(false);
      dispatch(addToast({ error: false, text: `${label} updated successfully` }));
      setUpdateLoading(false);
    } catch (error) {
      dispatch(
        addToast({
          error: true,
          text: error?.response?.data?.error_description || t('ERROR_WHILE_ELECTRICITY_CONSUMPTION'),
        }),
      );
      setUpdateLoading(false);
    }
  };
  useEffect(() => {
    if (energyConsumption) {
      setEditedData(energyConsumption);
    }
  }, [energyConsumption]);

  const editInputContent = (key, value, lable, type, showlable, alltypesdata, index) => {
    const handleEditData = (key, value, alltypesdata, index) => {
      const updatedUsage = [...editedData];
      if (updatedUsage[index]) {
        updatedUsage[index]['consumption_kwh'] = value;
        setEditedData(updatedUsage);
      }
    };
    return (
      <div className="flex-column w-full input-container ">
        <div className={classNames('flex items-center justify-end w-auto')}>
          {showlable && (
            <label className="w-full inter-400-text natural-400-text line-height-20 font-14">{lable}</label>
          )}
          <input
            className="inter-400-text natural-900-text one-line input-consumption w-auto "
            defaultValue={value}
            type={type}
            onChange={({ target: { value } }) => {
              handleEditData(key, value, alltypesdata, index);
            }}
          />
        </div>
      </div>
    );
  };

  const monthNames = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'Jun',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  const quarterLabels = [{ label: 'Jan-Mar' }, { label: 'Apr-Jun' }, { label: 'Jul-Sep' }, { label: 'Oct-Dec' }];

  const energyData = energyConsumption?.map(item => ({
    ...item,
    monthName: monthNames[item?.month],
  }));

  return (
    <div className="flex items-center gap-4 pb-6">
      <div className="label-electricity-usage">
        <label className="font-14 inter-400-text natural-500-text line-height-20">{t('USAGE_VALUES')}</label>
      </div>
      <div className="border radius-2 px-4 py-3 h-auto flex items-center justify-between w-full relative">
        {loading ? (
          <Loader width={30} height={30} />
        ) : (
          <>
            {tabInterval === 'YEAR' && (
              <>
                <label className="font-14 inter-400-text natural-900-text">{capitalize(formatText(tabInterval))}</label>
                {energyData?.length ? (
                  energyData?.map((annualdata, index) => {
                    return (
                      <>
                        {editConsumption ? (
                          <label
                            key={index}
                            className="font-14 inter-400-text natural-900-text w-auto flex justify-end">
                            {editInputContent(
                              'consumption_kwh',
                              annualdata?.consumption_kwh || 0,
                              t('ANNUALLY'),
                              'text',
                              false,
                              annualdata?.month,
                              index,
                            )}
                          </label>
                        ) : (
                          <LabelWrapper
                            key={index}
                            className="font-14 inter-400-text natural-900-text w-auto flex justify-end max-w-50"
                            onClick={() => setEditConsumption(true)}>
                            {getFormattedNumberStyle(annualdata?.consumption_kwh || 0, 'decimal', 0, 0)}
                          </LabelWrapper>
                        )}
                      </>
                    );
                  })
                ) : (
                  <label className="inter-400-text natural-400-text font-14">{t('NO_DATA')}</label>
                )}
              </>
            )}
            {tabInterval === 'QUARTER' && (
              <>
                <div
                  className={classNames(
                    'w-full row-gap-4 col-gap-9 quarter-consumption',
                    energyData?.length == 0 && 'items-center justify-center',
                  )}>
                  {energyData?.length ? (
                    energyData?.map((quarterdata, index) => {
                      const quarterLabel = quarterLabels[quarterdata.quarter - 1]?.label;
                      return (
                        <label
                          key={index}
                          className="font-14 inter-400-text natural-900-text w-auto flex justify-between ">
                          <label className="w-full">{quarterLabel}</label>
                          {editConsumption ? (
                            <label
                              key={index}
                              className="font-14 inter-400-text natural-900-text w-auto flex justify-end">
                              {editInputContent(
                                'consumption_kwh',
                                quarterdata?.consumption_kwh || 0,
                                t('MONTHALLY'),
                                'text',
                                false,
                                quarterdata?.month,
                                index,
                              )}
                            </label>
                          ) : (
                            <LabelWrapper
                              key={index}
                              className="font-14 inter-400-text natural-900-text w-auto flex justify-end"
                              onClick={() => setEditConsumption(true)}>
                              {getFormattedNumberStyle(quarterdata?.consumption_kwh || 0, 'decimal', 0, 0)}
                            </LabelWrapper>
                          )}
                        </label>
                      );
                    })
                  ) : (
                    <label className="inter-400-text natural-400-text font-14">{t('NO_DATA')}</label>
                  )}
                </div>
              </>
            )}
            {tabInterval === 'MONTH' && (
              <>
                <div
                  className={classNames(
                    'w-full row-gap-4 col-gap-9 month-consumption',
                    energyData?.length == 0 && 'items-center justify-center',
                  )}>
                  {energyData?.length ? (
                    energyData?.map((monthdata, index) => {
                      return (
                        <label
                          key={index}
                          className="font-14 inter-400-text natural-900-text w-auto flex justify-between">
                          <label className="w-full">{monthdata?.monthName}</label>
                          {editConsumption ? (
                            <label
                              key={index}
                              className="font-14 inter-400-text natural-900-text w-auto flex justify-end">
                              {editInputContent(
                                'consumption_kwh',
                                monthdata?.consumption_kwh || 0,
                                t('MONTHALLY'),
                                'text',
                                false,
                                monthdata?.month,
                                index,
                              )}
                            </label>
                          ) : (
                            <LabelWrapper
                              key={index}
                              className="font-14 inter-400-text natural-900-text w-auto flex justify-end"
                              onClick={() => setEditConsumption(true)}>
                              {getFormattedNumberStyle(monthdata?.consumption_kwh || 0, 'decimal', 0, 0)}
                            </LabelWrapper>
                          )}
                          {/* {editConsumption
                            ? editInputContent(
                                'consumption_kwh',
                                getFormattedNumberStyle(monthdata?.consumption_kwh || 0, 'decimal', 0, 0),
                                t('MONTHALLY'),
                                'text',
                                false,
                                monthdata,
                                index,
                              )
                            : getFormattedNumberStyle(monthdata?.consumption_kwh || 0, 'decimal', 0, 0)} */}
                        </label>
                      );
                    })
                  ) : (
                    <label className="inter-400-text natural-400-text font-14">{t('NO_DATA')}</label>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {editConsumption && (
          <div className="flex justify-center items-end radius-3 consumption-update-action mt-1 pxy-2 gap-2_5 absolute">
            <span
              className="flex justify-center items-center cross-icon border radius-100 cursor"
              onClick={() => {
                setEditConsumption(false);
                setEnergyConsumption([...energyConsumption]);
              }}>
              <CrossIcon color="#171717" />
            </span>
            <span
              className="flex justify-center items-center right-icon border radius-100 cursor "
              onClick={() => {
                onUpdate(tabInterval);
                setEnergyConsumption([...editedData]);
              }}>
              {updateLoading ? <Loader width={15} height={15} /> : <RightIcon color="#fff" />}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const SupplyCharge = ({ standing_charge, onUpdateSupplyCharge }) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(standing_charge);

  const onCancel = () => {
    setIsEditing(false);
    setEditedValue(standing_charge);
  };

  return (
    <div className="flex-column flex-1 px-4 py-2 row-gap-3 radius-2 bg-natural-50">
      <div className="flex-column">
        <label className="font-12 inter-600-text natural-700-text">{t('DAILY_SUPPLY_CHARGE')}</label>
        <label className="font-12 natural-500-text inter-400-text">{t('DAILY_SUPPLY_CHARGE_INFO')}</label>
      </div>
      {isEditing ? (
        <div className="flex w-fit-content relative">
          <InputElement
            variant="size_32"
            type="number"
            value={editedValue || ''}
            onChange={setEditedValue}
            extra_text_after="£"
            autoFocus
            onFocus={e => e.target.select()}
          />
          <div className="flex items-center justify-end col-gap-2 action-wrapper">
            <IconContainer
              Icon={CloseIcon}
              iconHeight={16}
              iconWidth={16}
              iconContainerClassname="border bg-white radius-full cursor"
              onClick={onCancel}
            />
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor="white"
              iconContainerClassname="bg-primary-500 radius-full cursor"
              onClick={() => {
                onUpdateSupplyCharge(editedValue || 0);
                setIsEditing(false);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="flex items-center pxy-1 radius-2 cursor charge-value-wrapper"
          onClick={() => {
            setIsEditing(true);
            setEditedValue(standing_charge);
          }}>
          <label className="font-14 natural-900-text inter-400-text">
            {getFormattedNumber(standing_charge || 0)} {t('PER_DAY')}
          </label>
        </div>
      )}
    </div>
  );
};

const SmartExportGuarantee = ({ exportRate, onUpdateExportRate }) => {
  const { t } = useTranslation();

  const { price_per_kwh } = exportRate || {};

  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(price_per_kwh);

  const onCancel = () => {
    setIsEditing(false);
    setEditedValue(price_per_kwh);
  };

  return (
    <div className="flex-column flex-1 px-4 py-2 row-gap-3 radius-2 bg-natural-50">
      <div className="flex-column">
        <label className="font-12 inter-600-text natural-700-text">{t('SMART_EXPORT_GUARANTEE')}</label>
        <label className="font-12 natural-500-text inter-400-text">{t('SMART_EXPORT_GUARANTEE_INFO')}</label>
      </div>
      {isEditing ? (
        <div className="flex w-fit-content relative">
          <InputElement
            variant="size_32"
            type="number"
            value={editedValue || ''}
            onChange={setEditedValue}
            extra_text_after="£"
            autoFocus
            onFocus={e => e.target.select()}
          />
          <div className="flex items-center justify-end col-gap-2 action-wrapper">
            <IconContainer
              Icon={CloseIcon}
              iconHeight={16}
              iconWidth={16}
              iconContainerClassname="border bg-white radius-full cursor"
              onClick={onCancel}
            />
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor="white"
              iconContainerClassname="bg-primary-500 radius-full cursor"
              onClick={() => {
                onUpdateExportRate(editedValue || 0);
                setIsEditing(false);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="flex items-center pxy-1 radius-2 cursor charge-value-wrapper"
          onClick={() => {
            setIsEditing(true);
            setEditedValue(price_per_kwh);
          }}>
          <label className="font-14 natural-900-text inter-400-text">
            {getFormattedNumber(price_per_kwh || 0)} {t('PER_KWH')}
          </label>
        </div>
      )}
    </div>
  );
};

const weekDayOptions = [
  { label: 'Mon', value: 1 },
  { label: 'Tue', value: 2 },
  { label: 'Wed', value: 3 },
  { label: 'Thu', value: 4 },
  { label: 'Fri', value: 5 },
  { label: 'Sat', value: 6 },
  { label: 'Sun', value: 7 },
];

const ElectricityRates = ({ electricityRates = [], onUpdateElectricityRates }) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);
  const [editedRates, setEditedRates] = useState(electricityRates);

  const onCancel = () => {
    setIsEditing(false);
    setEditedRates(electricityRates);
  };

  const onEditRates = (key, value, id) => {
    const updatedRates = editedRates.map(rate => (rate.id === id ? { ...rate, [key]: value } : rate));
    setEditedRates(updatedRates);
  };

  const onRemoveEditingRate = id => {
    const updatedRates = editedRates?.filter(rate => rate.id !== id);
    setEditedRates(updatedRates);
  };

  const renderEditingState = (rate, showRemove = false) => {
    const { id, start_day, end_day, start_time, end_time, price_per_kwh } = rate || {};

    return (
      <Fragment>
        <Dropdown
          className="w-fit-content"
          options={weekDayOptions}
          value={weekDayOptions.find(w => w.value === start_day)}
          customStyle={{
            control: {
              minHeight: '32px',
              height: '32px',
              minWidth: '120px',
              width: '120px',
            },
            valueContainer: {
              padding: '0 8px',
            },
            dropdownIndicator: {
              padding: '4px',
            },
          }}
          onChange={option => onEditRates('start_day', option.value, id)}
        />
        <label className="font-14 natural-900-text inter-400-text">{'-'}</label>
        <Dropdown
          className="w-fit-content"
          options={weekDayOptions}
          value={weekDayOptions.find(w => w.value === end_day)}
          customStyle={{
            control: {
              minHeight: '32px',
              height: '32px',
              minWidth: '120px',
              width: '120px',
            },
            valueContainer: {
              padding: '0 8px',
            },
            dropdownIndicator: {
              padding: '4px',
            },
          }}
          onChange={option => onEditRates('end_day', option.value, id)}
        />
        <TimeSelectorElement
          showClockIcon={false}
          containerClassname="time-selector"
          className="w-full"
          selectedTime={start_time}
          showSeconds={true}
          setSelectedTime={time => onEditRates('start_time', time, id)}
        />
        <label className="font-14 natural-900-text inter-400-text">{'-'}</label>
        <TimeSelectorElement
          showClockIcon={false}
          containerClassname="time-selector"
          className="w-full"
          selectedTime={end_time}
          showSeconds={true}
          setSelectedTime={time => onEditRates('end_time', time, id)}
        />
        <InputElement
          className="w-fit-content"
          variant="size_32"
          type="number"
          value={price_per_kwh || ''}
          onChange={value => onEditRates('price_per_kwh', value, id)}
          extra_text_after="£"
          autoFocus
          onFocus={e => e.target.select()}
        />
        {showRemove && (
          <IconContainer
            Icon={CloseIcon}
            backgroundColor="transparent"
            iconContainerClassname="cursor"
            onClick={() => onRemoveEditingRate(id)}
          />
        )}
      </Fragment>
    );
  };

  return (
    <div className="flex-column flex-1 px-4 py-2 row-gap-3 radius-2 bg-natural-50">
      <div className="flex-column">
        <label className="font-12 inter-600-text natural-700-text">{t('ELECTRICITY_RATES')}</label>
        <label className="font-12 natural-500-text inter-400-text">{t('ELECTRICITY_RATES_INFO')}</label>
      </div>
      <div className="flex-column gap-1 w-full">
        {isEditing ? (
          <div className="flex col-gap-4 items-start relative">
            <div className="flex-column flex-1 row-gap-2 rate-edit-wrapper">
              {editedRates?.map(rate => (
                <div key={rate?.id} className="flex items-center gap-3">
                  {renderEditingState(rate, editedRates.length > 1)}
                </div>
              ))}
            </div>
            <IconContainer
              Icon={PlusIcon}
              iconColor="primary-500"
              iconClassName="primary-500-text"
              iconContainerClassname="bg-primary-50 radius-full cursor pxy-2"
              onClick={() => {
                setEditedRates([
                  ...editedRates,
                  {
                    id: nanoid(),
                    start_day: 1,
                    end_day: 7,
                    start_time: '00:00:00',
                    end_time: '23:59:59',
                    price_per_kwh: 0,
                    charge_type: 'GRID',
                  },
                ]);
              }}
            />
            <div className="flex items-center justify-end col-gap-2 action-wrapper">
              <IconContainer
                Icon={CloseIcon}
                iconHeight={16}
                iconWidth={16}
                iconContainerClassname="border bg-white radius-full cursor"
                onClick={onCancel}
              />
              <IconContainer
                Icon={RightIcon}
                iconHeight={16}
                iconWidth={16}
                iconColor="white"
                iconContainerClassname="bg-primary-500 radius-full cursor"
                onClick={() => {
                  const newRates = editedRates.map(rate => ({
                    ...rate,
                    price_per_kwh: rate.price_per_kwh || 0,
                  }));
                  onUpdateElectricityRates(newRates);
                  setIsEditing(false);
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <div>
              {!electricityRates?.length && (
                <div
                  className="flex items-center gap-1"
                  onClick={() => {
                    setIsEditing(true);
                    setEditedRates(prevRates => [
                      ...prevRates,
                      {
                        id: nanoid(),
                        start_day: 1,
                        end_day: 7,
                        start_time: '00:00:00',
                        end_time: '23:59:59',
                        price_per_kwh: null,
                        charge_type: 'GRID',
                      },
                    ]);
                  }}>
                  <IconContainer
                    backgroundColor="transparent"
                    Icon={PlusIcon}
                    iconWidth={12}
                    iconHeight={12}
                    iconColor="primary_500"
                  />
                  <label className="inter-500-text primary-text cursor font-12 line-height-20">
                    {t('ADD_ELECTRICITY_RATE')}
                  </label>
                </div>
              )}
            </div>
            {electricityRates?.length > 0 && (
              <div
                className={classNames(
                  'flex-column gap-2  radius-2 cursor ',
                  electricityRates?.length && 'charge-value-wrapper pxy-1',
                )}
                onClick={() => {
                  setIsEditing(true);
                  setEditedRates(
                    electricityRates?.map(rate => ({ ...rate, id: nanoid(), price_per_kwh: rate.price_per_kwh })),
                  );
                }}>
                {electricityRates?.map((rate, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <div className="flex items-center col-gap-1">
                      <IconContainer
                        backgroundColor="transparent"
                        Icon={CalendarIcon}
                        iconWidth={14}
                        iconHeight={14}
                        iconColor="natural_500"
                      />
                      <label className="font-14 natural-900-text inter-400-text">
                        {moment().set('weekday', rate?.start_day).format('ddd')}
                      </label>
                      <label className="font-14 natural-900-text inter-400-text">{'-'}</label>
                      <label className="font-14 natural-900-text inter-400-text">
                        {moment().set('weekday', rate?.end_day).format('ddd')}
                      </label>
                    </div>
                    <div className="flex items-center col-gap-1">
                      <IconContainer
                        backgroundColor="transparent"
                        Icon={ClockIcon}
                        iconWidth={14}
                        iconHeight={14}
                        iconColor="natural_500"
                      />
                      <label className="font-14 natural-900-text inter-400-text">{rate?.start_time}</label>
                      <label className="font-14 natural-900-text inter-400-text">{'-'}</label>
                      <label className="font-14 natural-900-text inter-400-text">{rate?.end_time}</label>
                    </div>
                    <IconContainer
                      backgroundColor="transparent"
                      Icon={MoneyIcon}
                      iconWidth={14}
                      iconHeight={14}
                      iconColor="natural-400-text"
                    />
                    <label className="font-14 natural-900-text inter-400-text">
                      {getFormattedNumber(rate?.price_per_kwh || 0)} {t('PER_KWH')}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const BarGraphSection = ({ graphData, importFileOpen, className = '', barSize = 24, isShowValue = false }) => {
  const monthNames = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const chartData = graphData?.map(item => ({
    ...item,
    monthName: monthNames[item?.month],
  }));

  const dummyData = Array.from({ length: 12 }, (_, index) => ({
    month: index + 1,
    consumption_kwh: (index + 1) * 5,
    monthName: monthNames[index + 1],
  }));

  return (
    <div
      className={classNames('flex-1 border', !importFileOpen && 'pt-0', className ? className : 'px-3 py-4  radius-3')}>
      <BarGraph
        data={chartData}
        dummyData={dummyData}
        useDummy={!chartData?.length}
        barSize={barSize}
        isShowValue={isShowValue && !importFileOpen}
      />
    </div>
  );
};

const maxFileSize = 50 * 1024 * 1024;

const FileUpload = ({ file, setFiles, setReadData, readData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, media_id, name, size, file_data } = file || {};

  const [fileUploading, setFileUploading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [maxSizeError, setMaxSizeError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [parsingFile, setParsingFile] = useState(false);

  const validDateFormats = ['DD/MM/YYYY', 'YYYY/MM/DD HH:mm', 'YYYY/MM/DD hh:mm A', 'YYYY-MM-DD'];

  const calculateFileKwhForFormatWithTime = (data, isAnyHalfHourAvailable) => {
    return data.reduce((total, record) => {
      const date = record[0];
      const dateTimeMoment = moment(date, validDateFormats, true);

      const month = dateTimeMoment.get('month');
      const day = dateTimeMoment.get('dates');
      const hour = dateTimeMoment.get('hours');
      const minutes = dateTimeMoment.get('minutes');
      const consumption_kwh = record[1] || 0;
      return [
        ...total,
        {
          quarter: null,
          month: month + 1,
          day,
          hour: isAnyHalfHourAvailable ? null : hour,
          half_hour: isAnyHalfHourAvailable ? 2 * hour + (minutes >= 30 ? 2 : 1) : null, // 1 for first half hour, 2 for second half hour
          consumption_kwh,
        },
      ];
    }, []);
  };

  const calculateFileKwhForFormatWithoutTime = data => {
    return data.reduce((total, record) => {
      const [date, ...restRecord] = record;
      const dateTimeMoment = moment(date, validDateFormats, true);
      const month = dateTimeMoment.get('month');
      const day = dateTimeMoment.get('dates');
      const firstRecord = restRecord[0];
      const restRecordWithoutFirst = restRecord.slice(1)?.filter(record => record && record !== 'A');
      const totalOfRestRecord = restRecordWithoutFirst.reduce((total, record) => total + parseFloat(record), 0);

      const isTotalAvailable = parseFloat(firstRecord) === totalOfRestRecord;

      const filtedRestRecord = isTotalAvailable
        ? restRecord.slice(1, 49)?.filter(record => record !== 'A')
        : restRecord.slice(0, 48)?.filter(record => record !== 'A');
      const isHalfHourlyRecord = filtedRestRecord.length === 48;

      const recordData =
        filtedRestRecord.map((record, index) => {
          return {
            quarter: null,
            month: month + 1,
            day,
            hour: isHalfHourlyRecord ? null : index + 1,
            half_hour: isHalfHourlyRecord ? index + 1 : null,
            consumption_kwh: record || 0,
          };
        }) || [];

      return [...total, ...recordData];
    }, []);
  };

  const calculateTotalKwhFromFile = data => {
    const dataWithOnlyValidDates = data?.filter(record => {
      const date = record[0]?.toString();
      const dateTimeMoment = moment(date, validDateFormats, true);
      return dateTimeMoment.isValid();
    });

    const isAnyDateContainsTime = dataWithOnlyValidDates.find(record => record[0]?.split(' ')[1]?.includes(':'));
    const isAnyHalfHourAvailable = dataWithOnlyValidDates.find(record => record[0]?.split(' ')[1]?.includes(':30'));

    const calculatedData = isAnyDateContainsTime
      ? calculateFileKwhForFormatWithTime(dataWithOnlyValidDates, isAnyHalfHourAvailable)
      : calculateFileKwhForFormatWithoutTime(dataWithOnlyValidDates);
    const totalKwh = calculatedData.reduce((total, record) => total + parseFloat(record.consumption_kwh), 0);
    return { data: calculatedData, totalKwh: totalKwh };
  };

  const onUploadFile = async () => {
    if (size > maxFileSize) {
      setMaxSizeError(true);
      setFileUploading(false);
      return;
    }
    setFileUploading(true);
    dispatch(
      uploadFile({
        file: file_data,
        onSuccess: data => {
          setFileUploading(false);
          setParsingFile(true);
          parse(file_data, {
            header: false,
            complete: dataOnComplete => {
              setParsingFile(false);
              const { data: parsedData } = dataOnComplete;
              const { data: transformedData, totalKwh } = calculateTotalKwhFromFile(parsedData);
              setFiles(prev =>
                prev.map(item =>
                  item.id === file.id
                    ? {
                        ...item,
                        media_id: data?.media_id,
                        media_url: data?.url,
                        created_on: moment().unix(),
                        totalKwh: totalKwh,
                        transformedData: transformedData,
                      }
                    : item,
                ),
              );
            },
            error: () => {
              setParsingFile(false);
              setFiles(prev =>
                prev.map(item =>
                  item.id === file.id
                    ? { ...item, media_id: data?.media_id, media_url: data?.url, created_on: moment().unix(), kWh: 0 }
                    : item,
                ),
              );
            },
          });
        },
        onError: () => {
          dispatch(addToast({ error: true, text: t('ERROR_WHILE_UPLOADING_FILE', { name: name }), id: nanoid() }));
          setFileUploading(false);
          setUploadError(true);
        },
        setProgress,
      }),
    );
  };

  const onRetryFile = () => {
    setUploadError(false);
    setFileUploading(true);
    setProgress(0);
    onUploadFile();
  };

  const onRemoveFile = () => {
    setFiles(prev => prev?.filter(item => item.id !== id));
  };

  useEffect(() => {
    if (id && !media_id) {
      onUploadFile();
    }
  }, [id]);

  return (
    <FileUploadWrapper className="border px-4 py-3 radius-1_5 bg-white">
      <div className="flex gap-3">
        <div className="flex items-center uploaded-file">
          <IconContainer
            Icon={FileIcon}
            iconWidth={fileUploading || maxSizeError || uploadError ? 24 : 12}
            iconHeight={fileUploading || maxSizeError || uploadError ? 24 : 12}
            iconContainerClassname={classNames(fileUploading || maxSizeError ? 'uploading' : 'uploaded')}
          />
        </div>
        <div className="w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <label className="inter-400-text natural-500-text font-12">{file?.fileMonthName}</label>
              <IconContainer
                Icon={Dot}
                iconHeight={4}
                iconWidth={4}
                iconColor="natural_500"
                backgroundColor="transparent"
                iconContainerClassname="cursor p-0"
              />
              <label className="inter-400-text natural-900-text one-line word-break-all white-space-break-spaces">
                {name}
              </label>
              <label className="inter-400-text natural-400-text font-12">{`(${bytesToSize(size)})`}</label>
            </div>
            <div className="flex items-center col-gap-2">
              {fileUploading || maxSizeError || uploadError ? (
                <>
                  {uploadError && (
                    <IconContainer
                      Icon={RetryIcon}
                      iconHeight={12}
                      iconWidth={12}
                      iconColor="natural_500"
                      backgroundColor="transparent"
                      iconContainerClassname="cursor"
                      onClick={onRetryFile}
                    />
                  )}
                  <IconContainer
                    Icon={CloseIcon}
                    iconHeight={16}
                    iconWidth={16}
                    iconColor="natural_500"
                    backgroundColor="transparent"
                    iconContainerClassname="cursor"
                    onClick={onRemoveFile}
                  />
                </>
              ) : (
                <>
                  <label className="inter-400-text natural-400-text font-12">
                    {getFormattedNumberStyle(file.totalKwh || 0, 'decimal', 0, 0)} kwh
                  </label>
                  <Button
                    label={t('VALIDATE')}
                    className="primary-blue specified-width "
                    width="71px"
                    size="medisamum"
                    onClick={() => {}}
                    fontSize="12px"
                  />
                  <IconContainer
                    Icon={RightIcon}
                    iconHeight={16}
                    iconWidth={16}
                    iconColor="success_500"
                    backgroundColor="transparent"
                    iconContainerClassname="cursor"
                  />
                  <IconContainer
                    Icon={CloseIcon}
                    iconHeight={16}
                    iconWidth={16}
                    iconColor="natural_500"
                    backgroundColor="transparent"
                    iconContainerClassname="cursor"
                    onClick={onRemoveFile}
                  />
                </>
              )}
            </div>
          </div>
          {fileUploading ? (
            <div className="flex items-center w-full gap-3 mt-2">
              <div className="w-full progress-container">
                <div className="h-full progress-bar" style={{ width: `${Math.ceil(progress)}%` }}></div>
              </div>
              <div className="inter-400-text natural-400-text progress-label">{Math.ceil(progress)}%</div>
            </div>
          ) : (
            (maxSizeError || uploadError) && (
              <div className="flex items-center mt-2">
                <IconContainer
                  Icon={RoundExplanationIcon}
                  iconColor="error_500"
                  iconContainerClassname="mr-1 p-0"
                  backgroundColor="transparent"
                />
                <p className="error-text inter-400-text font-12">
                  {maxSizeError ? t('THIS_FILE_TOO_LARGE') : t('ERROR_WHILE_UPLOADING_FILE_TRY_AGAIN')}
                </p>
              </div>
            )
          )}
        </div>
      </div>
    </FileUploadWrapper>
  );
};

const ConsumptionFileUpload = ({ setFiles }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [onFile, setOnFile] = useState(false);

  const onAddFiles = addedFiles => {
    const validFiles = addedFiles?.filter(file => {
      const isValidType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'].includes(
        file.type,
      );
      const isValidSize = file.size <= 50 * 1024 * 1024; // 50 MB
      if (!isValidType) {
        dispatch(addToast({ error: true, text: t('ONLY_XLSX_AND_CSV_FILES_ARE_ALLOWED'), id: nanoid() }));
      }
      if (!isValidSize) {
        dispatch(addToast({ error: true, text: t('FILE_SIZE_EXCEEDS_50MB'), id: nanoid() }));
      }
      return isValidType && isValidSize;
    });

    const newFiles = validFiles.map(file => ({
      file_data: file,
      name: file.name,
      size: file.size,
      type: file.type,
      url: URL.createObjectURL(file),
      id: nanoid(),
    }));
    setFiles(prev => [...prev, ...newFiles]);
  };
  const onFileDrop = e => {
    e.preventDefault();
    onAddFiles([...e.dataTransfer?.files]);
    setOnFile(false);
  };

  const onFileAdd = e => {
    e.preventDefault();
    onAddFiles([...e?.target?.files]);
    e.target.value = null;
  };

  return (
    <ConsumptionFileUploadWrapper className="flex-column row-gap-6 overflow-hidden flex-1">
      <div className="flex-1 overflow-scroll">
        <div
          className={classNames(
            'py-10 border-dotted radius-1_5 flex-column items-center justify-center ',
            onFile ? 'on-droparea' : 'off-droparea',
          )}
          onDragLeave={e => {
            e.preventDefault();
            setOnFile(false);
          }}
          onDragOver={e => {
            e.preventDefault();
            setOnFile(true);
          }}
          onDrop={onFileDrop}>
          <IconContainer
            iconContainerClassname="mr-2"
            Icon={UploadFileIcon}
            iconColor="natural_300"
            backgroundColor="transparent"
            iconWidth={52}
            iconHeight={52}
          />
          <p className="flex font-16 inter-400-text mt-4">
            <span className="natural-700-text mr-1">{t('DRAG_AND_DEOP_FILES_HERE')}</span>
            <p className="flex">
              <input type="file" multiple id="file-upload-input" style={{ display: 'none' }} onChange={onFileAdd} />
              <label htmlFor="file-upload-input" className="primary-text cursor">
                {t('BROWSE')}
              </label>
            </p>
          </p>
          <label className="font-12 inter-400-text natural-500-text mt-1">{t('MAXIMUM_FILE_SIZE_50MP')}</label>
        </div>
      </div>
    </ConsumptionFileUploadWrapper>
  );
};

const ElectricityConsumption = ({
  tabInterval,
  setTabInterval,
  energyConsumption,
  graphData,
  importFileOpen,
  setImportFileOpen,
  setFiles,
  files,
  setEditConsumption,
  property_id,
  setEnergyConsumption,
  fetchConsumption,
  loading,
  editConsumption,
  energyPatterns,
  selectedEnergyPattern,
  setSelectedEnergyPattern,
  energyCosts,
  onUpdateCosts,
  energy_consumption_pattern,
  onUpdateEnergyPattern,
  importingEnergyConsumption,
  onImportFileData,
}) => {
  const { t } = useTranslation();
  const totalConsumption = energyConsumption.reduce((total, record) => total + record.consumption_kwh, 0);
  const [readData, setReadData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [graphPreviewData, setGraphPreviewData] = useState([]);

  const { standing_charge, rates } = energyCosts || {};
  const electricityRates = rates?.filter(rate => rate.charge_type === 'GRID');
  const exportRate = rates?.find(rate => rate.charge_type === 'EXPORT');

  const handleTabClick = interval => {
    setTabInterval(interval);
  };
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const groupedFiles = groupFilesByMonth(files, months);

  useEffect(() => {
    const uploadedFilesKwhData = files.map(f => f.transformedData).flat();
    const data = uploadedFilesKwhData;
    const aggregatedByMonth = data.reduce((acc, item) => {
      const month = item?.month;
      if (month) {
        if (!acc[month]) {
          acc[month] = {
            consumption_kwh: 0,
            day: null,
            half_hour: null,
            hour: null,
            month: month,
            quarter: null,
          };
        }
        acc[month].consumption_kwh += Number(item.consumption_kwh) || 0;
        acc[month].day = item.day || acc[month].day;
        acc[month].half_hour = item.half_hour || acc[month].half_hour;
        acc[month].hour = item.hour || acc[month].hour;
        acc[month].quarter = item.quarter || acc[month].quarter;
      }
      return acc;
    }, {});
    const dataArray = Object.values(aggregatedByMonth);
    setGraphPreviewData(dataArray);
  }, [files, files?.length]);

  const filesMonthData = files.map(file => {
    const groupName = groupedFiles.find(group => group.files.includes(file))?.label;
    if (!groupName) {
      return { ...file, fileMonthName: 'Unknown' };
    }
    return { ...file, fileMonthName: groupName };
  });

  const onUpdateSupplyCharge = newCharge => {
    const request = {
      ...energyCosts,
      inflation_rate: energyCosts?.inflation_rate || 0,
      rates: energyCosts?.rates || [],
      standing_charge: newCharge,
    };
    onUpdateCosts(request);
  };

  const onUpdateExportRate = newRate => {
    let request = {
      ...energyCosts,
      rates: energyCosts?.rates || [],
      standing_charge: energyCosts?.standing_charge || 0,
      inflation_rate: energyCosts?.inflation_rate || 0,
    };
    if (exportRate) {
      request.rates = request.rates.map(rate =>
        rate.charge_type === 'EXPORT' ? { ...rate, price_per_kwh: newRate } : rate,
      );
    } else {
      request.rates.push({
        charge_type: 'EXPORT',
        price_per_kwh: newRate,
        start_day: 1,
        end_day: 7,
        start_time: '00:00:00',
        end_time: '23:59:59',
      });
    }
    onUpdateCosts(request);
  };

  const onUpdateElectricityRates = newRates => {
    const ratesWithoutGrid = rates?.filter(rate => rate.charge_type !== 'GRID') || [];
    const request = {
      ...energyCosts,
      standing_charge: energyCosts?.standing_charge || 0,
      inflation_rate: energyCosts?.inflation_rate || 0,
      rates: [...ratesWithoutGrid, ...newRates],
    };
    onUpdateCosts(request);
  };

  return (
    <ElectricityWrapperStyled className={classNames('border radius-2 mt-4 pxy-6 flex-column')}>
      <div className={classNames(importFileOpen && 'flex items-center gap-5')}>
        {importFileOpen ? (
          <Fragment>
            <ConsumptionFileUpload setImportFileOpen={setImportFileOpen} setFiles={setFiles} files={files} />
          </Fragment>
        ) : (
          <>
            <div className="flex items-center gap-4 mb-4 energy-pattern-wrapper">
              <label className="inter-400-text natural-500-text">{'Energy pattern'}</label>
              <SearchableDropdown
                defaultAdditional={{
                  defaultOptions: energyPatterns,
                }}
                isCustomSearchable={false}
                value={selectedEnergyPattern}
                onChange={value => {
                  setSelectedEnergyPattern(value);
                  onUpdateEnergyPattern(value);
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder="Select energy pattern"
                customStyle={{
                  control: {
                    minHeight: '32px',
                    height: '32px',
                  },
                  valueContainer: {
                    fontSize: '14px',
                  },
                }}
              />
            </div>
            <TabButtons
              tabInterval={tabInterval}
              handleTabClick={handleTabClick}
              setEditConsumption={setEditConsumption}
            />
            <UsageValues
              tabInterval={tabInterval}
              totalConsumption={totalConsumption}
              editConsumption={editConsumption}
              setEditConsumption={setEditConsumption}
              property_id={property_id}
              fetchConsumption={fetchConsumption}
              energyConsumption={energyConsumption}
              setEnergyConsumption={setEnergyConsumption}
              loading={loading}
              setEditedData={setEditedData}
              editedData={editedData}
            />
          </>
        )}
        {loading ? (
          <Loader width={30} height={30} />
        ) : (
          <BarGraphSection
            graphData={importFileOpen && files.length ? graphPreviewData : graphData}
            importFileOpen={importFileOpen}
            isShowValue
          />
        )}
        {!importFileOpen && (
          <div className="flex-column w-full row-gap-3 mt-6">
            <label className="font-12 inter-600-text natural-900-text line-height-20 letter-spacing-1">
              {'ELECTRICITY COSTS'}
            </label>
            <div className="flex items-center flex-1 col-gap-4">
              <SupplyCharge standing_charge={standing_charge} onUpdateSupplyCharge={onUpdateSupplyCharge} />
              <SmartExportGuarantee exportRate={exportRate} onUpdateExportRate={onUpdateExportRate} />
            </div>
            <ElectricityRates electricityRates={electricityRates} onUpdateElectricityRates={onUpdateElectricityRates} />
          </div>
        )}
      </div>

      {importFileOpen && (
        <div className="flex-column row-gap-4 pt-8">
          <div className={classNames('flex items-center justify-between', !files?.length && 'justify-end')}>
            {files?.length > 0 ? (
              <div>
                <label className="inter-500-text natural-700-text font-14 line-height-20">
                  {files?.length} {t('FILES')}
                </label>
              </div>
            ) : (
              ''
            )}
            <div className="flex items-center gap-4  ">
              <Button
                size="medium"
                label={t('CANCEL')}
                className=" primary-grey bg-white specified-width"
                width="80px"
                fontSize="12px"
                disabled={importingEnergyConsumption}
                onClick={() => {
                  setImportFileOpen(false);
                  setFiles([]);
                }}
              />
              <Button
                size="medium"
                label={t('UPLOAD')}
                className="secondary specified-width"
                width="80px"
                fontSize="12px"
                loading={importingEnergyConsumption}
                disabled={!files?.length || importingEnergyConsumption}
                onClick={onImportFileData}
              />
            </div>
          </div>
          <div className="flex-column row-gap-4">
            {filesMonthData.length > 0 ? (
              <Reorder.Group
                axis="y"
                className="reorder-ul row-gap-4 flex-column"
                values={filesMonthData}
                onReorder={setFiles}>
                {filesMonthData.map(file => (
                  <Reorder.Item className="reorder-li" key={`item-${file.id}`} value={file}>
                    <FileUpload
                      key={file.id}
                      file={file}
                      setFiles={setFiles}
                      setReadData={setReadData}
                      readData={readData}
                    />
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            ) : (
              <lable className="text-center inter-400-text font-18 natural-400-text">
                {t('PLEASE_UPLOAD_SOME_FILES')}
              </lable>
            )}
          </div>
        </div>
      )}
    </ElectricityWrapperStyled>
  );
};

const ElectricityWrapperStyled = styled.div`
  .energy-pattern-wrapper {
    display: grid;
    grid-template-columns: 150px 1fr 80px;
  }

  .label-electricity-usage {
    min-width: 150px;
    display: flex;
  }

  .month-consumption {
    display: grid;
    grid-template-columns: auto auto;
  }
  .quarter-consumption {
    display: grid;
    grid-template-columns: auto auto;
  }
  .consumption-update-action {
    background-color: ${({ theme }) => theme.white};
    z-index: 999;
    box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
    right: -5px;
    bottom: -47px;
  }
  .right-icon {
    background-color: ${({ theme }) => theme.primary_500};
    width: 32px;
    height: 32px;
  }
  .cross-icon {
    width: 32px;
    height: 32px;
  }
  .input-consumption {
    height: auto !important;
    border-bottom: 1px solid ${({ theme }) => theme.lightGrey} !important;
    border: none;
    border-radius: 6px;
    color: ${({ theme }) => theme.darkBlue};
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    outline: none;
    text-align: right;
  }

  .charge-value-wrapper {
    :hover {
      background-color: ${({ theme }) => theme.natural_200};
    }
  }

  .action-wrapper {
    position: absolute;
    right: 0;
    bottom: -36px;
    z-index: 1000;
    padding: 4px 12px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }

  .rate-edit-wrapper {
    .input-element {
      width: 110px;
    }
  }

  .time-selector {
    padding: 4px;
    max-height: 32px;
    background-color: white;
  }

  @media (max-width: 1200px) {
    .specified-width {
      width: 100%;
      padding: 8px;
    }
  }
`;

const ConsumptionFileUploadWrapper = styled.div`
  .off-droparea {
    border-color: ${({ theme }) => theme.natural_300};
    background-color: ${({ theme }) => theme.natural_50};
    height: 250px;
  }
  .on-droparea {
    border-color: ${({ theme }) => theme.primary_500};
    background-color: ${({ theme }) => theme.primary_50};
    height: 250px;
  }
`;

const LabelWrapper = styled.div`
  width: 70%;
  padding: 3px 8px;
  cursor: pointer;
  :hover {
    background: rgb(240, 240, 240);
    border-radius: 6px;
  }
`;

const FileUploadWrapper = styled.div`
  .progress-container {
    height: 4px;
    background-color: ${({ theme }) => theme.natural_200};
    border-radius: 100px;

    .progress-bar {
      background-color: ${({ theme }) => theme.primary_500};
      border-radius: 100px;
    }
  }

  .uploaded-file {
    .uploading {
      height: 48px;
      width: 48px;
      border-radius: 6px;
    }

    .uploaded {
      height: 24px;
      width: 24px;
      border-radius: 2px;
    }
  }

  .edit-file-input {
    .input-element {
      height: 24px;
      padding: 2px 8px;
    }
  }
`;

export default ElectricityConsumption;
