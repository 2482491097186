import {
  DrawingManagerF,
  Marker,
  MarkerF,
  OverlayView,
  OverlayViewF,
  Polygon,
  Polyline,
  useGoogleMap,
} from '@react-google-maps/api';
import * as turf from '@turf/turf';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import uuid from 'react-uuid';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as AddPanelIcon } from '../../assets/icons/add-panel.svg';
import { ReactComponent as AddRoofIcon } from '../../assets/icons/add-roof.svg';
import { ReactComponent as AIIcon } from '../../assets/icons/ai-icon.svg';
import CompassIcon from '../../assets/icons/compass-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditPensilIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as ElectricityCordIcon } from '../../assets/icons/electricity-cord.svg';
import { ReactComponent as ElectricityIcon } from '../../assets/icons/electricity.svg';
import { ReactComponent as EnergyLeafIcon } from '../../assets/icons/energy-leaf.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as GridIcon } from '../../assets/icons/grid.svg';
import { ReactComponent as HandIcon } from '../../assets/icons/hand.svg';
import GrabHandle from '../../assets/icons/handle-grab.svg';
import MapPanelRotateIcon from '../../assets/icons/map-panel-rotate.svg';
import { ReactComponent as SnapshotIcon } from '../../assets/icons/maximize.svg';
import { ReactComponent as PointerIcon } from '../../assets/icons/pointer.svg';
import { ReactComponent as SizeIcon } from '../../assets/icons/property/size.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';
import { ReactComponent as SolarPanelIcon } from '../../assets/icons/solar-panel.svg';
import { ReactComponent as SolarSizeIcon } from '../../assets/icons/solar-size.svg';
import { ReactComponent as WidthSpecificationIcon } from '../../assets/icons/width-specification.svg';
import { ReactComponent as BatteryIcon } from '../../assets/images/batteries.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/close-image.svg';
import { ReactComponent as PlatformIcon } from '../../assets/images/sidebar/platforms.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import CustomTooltip from '../../components/common/tooltip-new';
import Loader from '../../components/loader';
import { OrganisationContext } from '../../context/organisationContext';
import {
  calculateRotationAngleToNorth,
  customRectangleGrid,
  findClosestAngle,
  getCenterOfBoundaryLines,
  getFormattedNumberStyle,
  getPolygonCenter,
  getRoofDetailsFromManualOrImported,
  normaLizeAngle,
  rotatePoint,
} from '../../helpers/utils';
import {
  addRoofInformation,
  createSegmentPanels,
  getRoofPanelInformation,
  importRoofInformation,
  setRoofPanelInformation,
  updateRoofInformation,
  updateRoofPanels,
} from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import DesignProducts from './design-products';
import SolarEnergyUsage, { EnergyUsageEditor } from './solar-energy-usage';

const snapshotOptions = [
  {
    value: 'AERIAL_LANDSCAPE',
    label: 'Aerial Landscape 16:9',
    ratio: 16 / 9,
    lexicon: 'AERIAL_LANDSCAPE',
  },
  {
    value: 'AERIAL_PORTRAIT',
    label: 'Aerial Portrait 9:16',
    ratio: 9 / 16,
    lexicon: 'AERIAL_PORTRAIT',
  },
  {
    value: 'AERIAL_SQUARE',
    label: 'Aerial Square 1:1',
    ratio: 1,
    lexicon: 'AERIAL_SQUARE',
  },
  {
    value: 'FRONT_LANDSCAPE',
    label: 'Front Landscape 16:9',
    ratio: 16 / 9,
    lexicon: 'FRONT_LANDSCAPE',
  },
  {
    value: 'FRONT_PORTRAIT',
    label: 'Front Portrait 9:16',
    ratio: 9 / 16,
    lexicon: 'FRONT_PORTRAIT',
  },
  {
    value: 'FRONT_SQUARE',
    label: 'Front Square 1:1',
    ratio: 1,
    lexicon: 'FRONT_SQUARE',
  },
];

const Option = props => {
  const { innerProps, data, isSelected, isSingleValue } = props;
  const { name, design_data } = data;
  const { panels, battery_capacity, system_size, annual_production } = design_data || {};

  return (
    <div {...props}>
      <div
        {...innerProps}
        className={classNames('flex-column row-gap-1 cursor', isSelected && 'selected', !isSingleValue && 'pxy-2')}>
        <label className={classNames('natural-900-text', isSelected ? 'inter-500-text' : 'inter-400-text')}>
          {name}
        </label>
        <div className="flex flex-1 flex-wrap items-center col-gap-2">
          <div className="flex items-center col-gap-1">
            <IconContainer
              Icon={SolarPanelIcon}
              backgroundColor="transparent"
              iconContainerClassname="p-0"
              iconWidth={14}
              iconHeight={14}
              iconColor="natural_400"
            />
            <label className="inter-500-text natural-400-text one-line">{panels}</label>
          </div>
          <div className="flex items-center col-gap-1">
            <IconContainer
              Icon={BatteryIcon}
              backgroundColor="transparent"
              iconContainerClassname="p-0"
              iconWidth={14}
              iconHeight={14}
              iconColor="natural_400"
            />
            <label className="inter-500-text natural-400-text  one-line">{battery_capacity} kWh</label>
          </div>
          <div className="flex items-center col-gap-1">
            <IconContainer
              Icon={SizeIcon}
              backgroundColor="transparent"
              iconContainerClassname="p-0"
              iconWidth={12}
              iconHeight={12}
              iconColor="natural_400"
            />
            <label className="inter-500-text natural-400-text  one-line">
              {parseFloat(system_size || 0).toFixed(2)} kW
            </label>
          </div>
          <div className="flex items-center col-gap-1">
            <IconContainer
              Icon={ElectricityCordIcon}
              backgroundColor="transparent"
              iconContainerClassname="p-0"
              iconWidth={14}
              iconHeight={14}
              iconColor="natural_400"
            />
            <label className="inter-500-text natural-400-text  one-line">
              {getFormattedNumberStyle(annual_production || 0, 'decimal', 0, 0)} kWh
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomSingleValue = ({ data, ...rest }) => {
  const { selectProps } = rest;
  const { name, design_data } = data;
  const { panels, battery_capacity, system_size, annual_production } = design_data || {};

  const { panel_count, size, output } = selectProps;

  return (
    <components.SingleValue {...rest} className="flex-column row-gap-1">
      <label className={classNames('natural-900-text inter-500-text')}>{name}</label>
      <div className="flex flex-1 flex-wrap items-center col-gap-2">
        <div className="flex items-center col-gap-1">
          <IconContainer
            Icon={SolarPanelIcon}
            backgroundColor="transparent"
            iconContainerClassname="p-0"
            iconWidth={14}
            iconHeight={14}
            iconColor="natural_400"
          />
          <label className="inter-500-text natural-400-text one-line">{panels || panel_count || 0}</label>
        </div>
        <div className="flex items-center col-gap-1">
          <IconContainer
            Icon={BatteryIcon}
            backgroundColor="transparent"
            iconContainerClassname="p-0"
            iconWidth={14}
            iconHeight={14}
            iconColor="natural_400"
          />
          <label className="inter-500-text natural-400-text  one-line">{battery_capacity || 0} kWh</label>
        </div>
        <div className="flex items-center col-gap-1">
          <IconContainer
            Icon={SizeIcon}
            backgroundColor="transparent"
            iconContainerClassname="p-0"
            iconWidth={12}
            iconHeight={12}
            iconColor="natural_400"
          />
          <label className="inter-500-text natural-400-text  one-line">
            {parseFloat(system_size || size || 0).toFixed(2)} kW
          </label>
        </div>
        <div className="flex items-center col-gap-1 one-line">
          <IconContainer
            Icon={ElectricityCordIcon}
            backgroundColor="transparent"
            iconContainerClassname="p-0"
            iconWidth={14}
            iconHeight={14}
            iconColor="natural_400"
          />
          <label className="inter-500-text natural-400-text  one-line">
            {getFormattedNumberStyle(annual_production || output || 0, 'decimal', 0, 0)} kWh
          </label>
        </div>
      </div>
    </components.SingleValue>
  );
};

const SystemDesign = ({
  fetchSolarDetails,
  solarDetails,
  selectedDesign,
  setSelectedDesign,
  panel_count,
  size,
  output,
}) => {
  return (
    <Fragment>
      {fetchSolarDetails ? (
        <SearchableDropdown
          className="pt-5 pb-3 px-6 system-design-dropdown"
          defaultAdditional={{
            defaultOptions: solarDetails,
          }}
          panel_count={panel_count}
          size={size}
          output={output}
          isCustomSearchable={false}
          placeholder="Select design"
          value={selectedDesign}
          onChange={value => selectedDesign?.id !== value?.id && setSelectedDesign(value)}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          // menuIsOpen
          customStyle={{
            valueContainer: {
              padding: '8px 12px',
            },
          }}
          customComponent={{
            Option,
            SingleValue: CustomSingleValue,
          }}
        />
      ) : null}
    </Fragment>
  );
};

const PanelDetails = ({ panelDetails }) => {
  const { capacity, name, image, width, height } = panelDetails || {};

  return (
    <PanelDetailsWrapper className="flex flex-1 items-center px-4 py-3 border-top col-gap-3">
      <div className="border radius-1 panel-image-wrapper h-fit-content">
        {image?.url ? (
          <img src={image?.url} alt="panel" className="panel-image" />
        ) : (
          <SolarPanelIcon height={24} width={24} className="natural-400-text panel-image" />
        )}
      </div>
      <div className="flex-column flex-1 my-1 row-gap-2 overflow-hidden">
        <label className="inter-600-text">{name}</label>
        <div className="flex items-center justify-between">
          <div className="flex items-center col-gap-2">
            <div className="flex items-center col-gap-1">
              <IconContainer
                iconContainerClassname="p-0"
                Icon={WidthSpecificationIcon}
                backgroundColor="transparent"
                iconColor="natural_400"
              />
              <label className="inter-500-text natural-400-text font-12">{width}m</label>
            </div>
            <div className="flex items-center col-gap-1">
              <IconContainer
                iconContainerClassname="p-0"
                Icon={WidthSpecificationIcon}
                backgroundColor="transparent"
                iconColor="natural_400"
                iconClassName="rotate-270"
              />
              <label className="inter-500-text natural-400-text font-12">{height}m</label>
            </div>
            <div className="flex items-center col-gap-1">
              <IconContainer
                iconContainerClassname="p-0"
                Icon={ElectricityIcon}
                backgroundColor="transparent"
                iconColor="natural_400"
              />
              <label className="inter-500-text natural-400-text font-12">{capacity}W</label>
            </div>
          </div>
        </div>
      </div>
    </PanelDetailsWrapper>
  );
};

const PolygonWrapper = ({
  polygonId,
  editable,
  draggable,
  paths,
  setPaths = () => {},
  options,
  onPolygonClick = () => {},
  onLoadPolygon = () => {},
  onUnmountPolygon = () => {},
  onlySetPath = false,
}) => {
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      onlySetPath && polygonRef.current.setPath(nextPath);
      setPaths(nextPath, polygonId);
    }
  }, [setPaths]);

  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit),
      );
      onLoadPolygon(polygon);
    },
    [onLoadPolygon, onEdit],
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
    onUnmountPolygon();
  }, [onUnmountPolygon, onLoad]);

  return (
    <Polygon
      key={`child-polygon-segment-${polygonId}`}
      editable={editable}
      draggable={draggable}
      paths={paths}
      onMouseUp={onEdit}
      onDragEnd={onEdit}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
    />
  );
};

const calcBounds = (center, size, isLandScape) => {
  const top = google.maps.geometry.spherical.computeOffset(center, size.height, 0);
  const right = google.maps.geometry.spherical.computeOffset(center, size.width, 90);
  const left = google.maps.geometry.spherical.computeOffset(center, size.width, 270);

  const topRight = google.maps.geometry.spherical.computeOffset(top, size.width, 90);
  const bottomLeft = google.maps.geometry.spherical.computeOffset(left, size.height, 180);

  return new google.maps.LatLngBounds(
    new google.maps.LatLng(bottomLeft.lat(), bottomLeft.lng()),
    new google.maps.LatLng(topRight.lat(), topRight.lng()),
  );
};

const MarkerWrapper = ({
  polygonId,
  editable,
  draggable,
  paths,
  options,
  onPolygonClick = () => {},
  onLoadPanel = () => {},
  onUnmountPanel = () => {},
  onPolygonMouseUp = () => {},
  onPolygonMouseDown = () => {},
  onPolygonMouseOver = () => {},
}) => {
  const polygonRef = useRef(null);
  const previousId = useRef(null);

  const onLoad = useCallback(polygon => {
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
      polygonRef.current = null;
    }
    polygonRef.current = polygon;
    previousId.current = polygonId;
    onLoadPanel(polygon, polygonId);
  }, []);

  const onUnmount = useCallback(() => {
    polygonRef.current.setMap(null);
    polygonRef.current = null;
    onUnmountPanel(previousId.current);
    previousId.current = null;
  }, []);

  const onClick = () => {
    if (polygonRef.current) {
      onPolygonClick(polygonRef.current, polygonId);
    }
  };

  const onMouseUp = () => {
    if (polygonRef.current) {
      onPolygonMouseUp(polygonRef.current, polygonId);
    }
  };

  const onMouseDown = () => {
    if (polygonRef.current) {
      onPolygonMouseDown(polygonRef.current, polygonId);
    }
  };

  const onMouseOver = () => {
    if (polygonRef.current) {
      onPolygonMouseOver(polygonRef.current, polygonId);
    }
  };

  return (
    <>
      <Polygon
        key={polygonId}
        editable={editable}
        draggable={draggable}
        paths={paths}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
        onClick={onClick}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseOver={onMouseOver}
      />
    </>
  );
};

const PanelDragger = ({
  id,
  segmentId,
  draggable,
  position,
  onEditMarker,
  onUpdateMarker,
  options,
  onDragStart,
  onMouseUp = () => {},
}) => {
  const markerRef = useRef(null);

  const onEdit = useCallback(() => {
    if (markerRef.current) {
      const newPosition = markerRef.current.getPosition();
      onEditMarker(segmentId, { lat: newPosition.lat(), lon: newPosition.lng() });
    }
  }, [onEditMarker]);

  const onUpdate = useCallback(() => {
    if (markerRef.current) {
      const newPosition = markerRef.current.getPosition();
      onUpdateMarker(segmentId, { lat: newPosition.lat(), lon: newPosition.lng() });
    }
  }, [onUpdateMarker]);

  const onLoad = useCallback(
    marker => {
      markerRef.current = marker;
    },
    [onEdit],
  );

  const onUnmount = useCallback(() => {
    markerRef.current = null;
  }, []);

  return (
    <MarkerF
      key={id}
      draggable={draggable}
      onDrag={onEdit}
      onDragEnd={onUpdate}
      onDragStart={onDragStart}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onMouseUp={onMouseUp}
      position={position}
      options={options}
      icon={{ url: GrabHandle, scaledSize: new google.maps.Size(20, 20), anchor: new google.maps.Point(10, 10) }}
    />
  );
};

const PanelRotate = ({ id, segmentId, onClickPanelRotate, position, options, onMouseUp = () => {} }) => {
  const markerRef = useRef(null);

  const onClick = useCallback(() => {
    if (markerRef.current) {
      onClickPanelRotate(segmentId);
    }
  }, [onClickPanelRotate]);

  const onLoad = useCallback(
    marker => {
      markerRef.current = marker;
    },
    [onClick],
  );

  const onUnmount = useCallback(() => {
    markerRef.current = null;
  }, [onClickPanelRotate]);

  return (
    <Marker
      key={id}
      onMouseUp={onMouseUp}
      draggable={false}
      onClick={onClick}
      onLoad={onLoad}
      onUnmount={onUnmount}
      position={position}
      options={options}
      icon={{ url: MapPanelRotateIcon, scaledSize: new google.maps.Size(20, 20) }}
    />
  );
};

const GridPolygon = ({
  polygonId,
  paths,
  options,
  onPolygonClick = () => {},
  onLoadPanel = () => {},
  onUnmountPanel = () => {},
  onPolygonMouseUp = () => {},
  onPolygonMouseDown = () => {},
  onPolygonMouseOver = () => {},
}) => {
  const polygonRef = useRef(null);

  const onLoad = useCallback(polygon => {
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
      polygonRef.current = null;
    }
    polygonRef.current = polygon;
    onLoadPanel(polygon);
  }, []);

  const onUnmount = useCallback(() => {
    polygonRef.current.setMap(null);
    polygonRef.current = null;
    onUnmountPanel();
  }, []);

  const onClick = () => {
    if (polygonRef.current) {
      onPolygonClick(polygonRef.current, polygonId);
    }
  };

  const onMouseUp = () => {
    if (polygonRef.current) {
      onPolygonMouseUp(polygonRef.current, polygonId);
    }
  };

  const onMouseDown = () => {
    if (polygonRef.current) {
      onPolygonMouseDown(polygonRef.current, polygonId);
    }
  };

  const onMouseOver = () => {
    if (polygonRef.current) {
      onPolygonMouseOver(polygonRef.current, polygonId);
    }
  };

  return (
    <>
      <Polygon
        key={polygonId}
        editable={false}
        draggable={false}
        paths={paths}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
        onClick={onClick}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseOver={onMouseOver}
      />
    </>
  );
};

const RoofEditV2 = ({
  segments,
  roofPanels,
  onSegmentBoundryUpdate,
  onSaveSegment,
  onCancelSegment,
  onDeleteRoofOrSegment,
  onUpdatePanelPaths,
  propertyImages,
  segmentsToSave,
  onSegmentDetailsUpdate,
  panelsToDelete,
  onPanelSelectForDelete,
  annual_electric_kwh,
  onClearPanelSelection,
  onUpdatePanelCenter,
  roofInformation,
  property_id,
  solarDetails,
  fetchSolarDetails,
  onPanelAdd,
  onAddedPanelDelete,
  setRoofPanels,
  takePhoto,
  designId,
  engagement_id,
  fetchSolarDesignData,
  selectedDesign,
  setSelectedDesign,
  panelDetails,
  fetchSolarDesignAdditionalData,
  maxBundle,
  fetchSolarOptionsAndCreateQuote,
  updateQuoteQuantity,
  changingSegmentFormat,
  setChangingSegmentFormat,
  isResidencial,
  savingSegment,
  visiblePanelsBySegment,
  hiddenPanelsBySegment,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const roofListRef = useRef(null);
  const panelRefs = useRef({});
  const map = useGoogleMap();
  const mapProjection = map?.getProjection();
  const isSelecting = useRef(false);
  const isAdding = useRef(false);
  const snapshotOverlay = useRef(null);
  const segmentPolygonRefs = useRef({});
  const segmentGrids = useRef({});
  const segmentGridRefs = useRef({});

  const onZoomChanged = () => {
    const zoom = map.getZoom();
    panelRefs.current &&
      Object.keys(panelRefs.current).forEach(key => {
        const panel = panelRefs.current[key];
        if (panel) {
          panel.setOptions({ strokeWeight: zoom >= 20 ? 1 : zoom >= 19 ? 0.5 : zoom >= 18 ? 0.3 : 0.3 });
        }
      });
  };

  const onTiltOrHeadingChanged = () => {
    const tilt = map.getTilt() || 0;
    const heading = map.getHeading() || 0;
    setDirectionValues({ compass: parseFloat(heading).toFixed(0), tilt: parseFloat(tilt).toFixed(0) });
  };

  const onMouseUp = () => {
    isAdding.current = false;
    isSelecting.current = false;
  };

  const onMapClick = e => {
    setSelectedSegments([]);
    setIsAllPanelSelectedOfSegment(null);
  };

  useEffect(() => {
    let listerner = null;
    let tiltListener = null;
    let headingListener = null;
    let mouseUpListener = null;
    let clickListener = null;

    if (map) {
      listerner = map.addListener('zoom_changed', onZoomChanged);
      tiltListener = map.addListener('tilt_changed', onTiltOrHeadingChanged);
      headingListener = map.addListener('heading_changed', onTiltOrHeadingChanged);
      mouseUpListener = map.addListener('mouseup', onMouseUp);
      clickListener = map.addListener('click', onMapClick);
    }

    return () => {
      if (map) {
        listerner.remove();
        tiltListener.remove();
        headingListener.remove();
        mouseUpListener.remove();
        clickListener.remove();
      }
    };
  }, [map]);

  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);

  const [selectedSegments, setSelectedSegments] = useState([]);
  const [showPanels, setShowPanels] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedTab, setSelectedTab] = useState('DESIGN');
  const [editSegment, setEditSegment] = useState(null);
  const [selectedInstrument, setSelectedInstrument] = useState(null);
  const [selectedPanels, setSelectedPanels] = useState([]);
  const [isAllPanelSelectedOfSegment, setIsAllPanelSelectedOfSegment] = useState(null);
  const [collapsedRoofs, setCollapsedRoofs] = useState({});
  const [newRoofPolygon, setNewRoofPolygon] = useState(null);
  const [savingRoof, setSavingRoof] = useState(false);
  const [addRoofSegmentForRoof, setAddRoofSegmentForRoof] = useState(null);
  const [directionValues, setDirectionValues] = useState({ compass: 0, tilt: 0 });
  const [savingGridAndPanels, setSavingGridAndPanels] = useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState(
    snapshotOptions?.find(option => option.value === 'AERIAL_LANDSCAPE'),
  );
  const [draggingSegment, setDraggingSegment] = useState(null);
  const [usageEditorOpened, setUsageEditorOpened] = useState(false);
  const [energyUsage, setEnergyUsage] = useState([]);

  const totalPanels = roofPanels?.length || 0;
  const isRoofAdd = selectedInstrument?.key === 'ADD_ROOF' || addRoofSegmentForRoof;

  const { capacity, width, height, boundaryGap } = panelDetails || {};

  const currentPanelEnergy = useMemo(() => {
    const energy = ((roofPanels?.length || 0) * capacity) / 1000;
    return energy;
  }, [roofPanels, capacity]);

  const currentPanelOutput = useMemo(() => {
    const { design_data } = selectedDesign || {};
    return design_data?.annual_production || 0;
  }, [selectedDesign]);

  const roofColors = useMemo(() => {
    return [theme.primary_500, theme.success_500, theme.orange_500, theme.error_500, theme.natural_500];
  }, []);

  const onInstrumentClick = instrument => {
    const { key, isDisabled } = instrument;

    if (isDisabled || !map) return;
    if (key === 'HAND') {
      map.setOptions({ gestureHandling: 'greedy', keyboardShortcuts: true });
    }
    if (key === 'POINTER') {
      map.setOptions({ gestureHandling: 'none', keyboardShortcuts: false, draggableCursor: null });
    } else {
      setSelectedPanels([]);
    }
    if (key === 'DELETE') {
      map.setOptions({ gestureHandling: 'none', keyboardShortcuts: false, draggableCursor: null });
    } else {
      onClearPanelSelection();
    }
    setAddRoofSegmentForRoof(null);
    setNewRoofPolygon(null);
    setSelectedInstrument(instrument);
  };

  const instrumentTools = useMemo(() => {
    return [
      {
        name: 'Hand',
        icon: HandIcon,
        key: 'HAND',
        onClick: onInstrumentClick,
      },
      {
        name: 'Pointer',
        icon: PointerIcon,
        key: 'POINTER',
        onClick: onInstrumentClick,
      },
      // {
      //   name: 'Rotate portrait',
      //   icon: PanelRotatePortraitIcon,
      //   key: 'ROTATE_PORTRAIT',
      //   onClick: () => {},
      //   isDisabled: true,
      // },
      // {
      //   name: 'Rotate landscape',
      //   icon: PanelRotateLandscapeIcon,
      //   key: 'ROTATE_LANDSCAPE',
      //   onClick: () => {},
      //   isDisabled: true,
      // },
      {
        name: 'Add roof',
        icon: AddRoofIcon,
        key: 'ADD_ROOF',
        onClick: onInstrumentClick,
        isDisabled: !!newRoofPolygon,
      },
      {
        name: 'Delete',
        icon: DeleteIcon,
        key: 'DELETE',
        onClick: onInstrumentClick,
      },
      // {
      //   name: 'Layout',
      //   icon: LayoutIcon,
      //   key: 'LAYOUT',
      //   onClick: onInstrumentClick,
      //   isDisabled: true,
      // },
    ];
  }, [map, newRoofPolygon]);

  const tabs = useMemo(() => {
    return [
      {
        name: 'Design',
        key: 'DESIGN',
      },
      {
        name: 'Products',
        key: 'PRODUCTS',
      },
    ];
  }, []);

  const onDeleteRoofSegment = (roof_id, index, isSegment) => {
    setModal({
      type: 'delete-roof-segment-action',
      content: {
        roof_id: roof_id,
        isSegment: isSegment,
        property_id: property_id,
        selectedDesign: selectedDesign,
        title: isSegment ? `${t('SEGMENT_TITLE', { index: index + 1 })}?` : `${t('ROOF_TITLE', { index: index + 1 })}?`,
        description: isSegment ? t('ARE_YOU_SURE_DELETE_SEGMENT') : t('ARE_YOU_SURE_DELETE_ROOF'),
        onSuccess: () => {
          onDeleteRoofOrSegment(roof_id, isSegment);
        },
      },
    });
  };

  const onSegmentClick = segmentId => {
    const isSegmentAlreadySelected = selectedSegments.includes(segmentId);
    if (isSegmentAlreadySelected) {
      setSelectedSegments(selectedSegments.filter(seg => seg !== segmentId));
    } else {
      const isAllPanelSelected = isAllPanelSelectedOfSegment === segmentId;
      setIsAllPanelSelectedOfSegment(isAllPanelSelected ? segmentId : null);
      setSelectedSegments([segmentId]);
    }
  };

  const onSegmentAzimuthChange = (
    segmentId,
    azimuth,
    prevAzimuth,
    isManualSegment = false,
    segmentData = {},
    grid_alignment,
  ) => {
    const { manual } = segmentData || {};
    const manualBoundary = getRoofDetailsFromManualOrImported(segmentData, 'boundary', true, false, false);
    let updatedBoundary = manualBoundary;
    const azimuthChange = parseFloat(azimuth) - parseFloat(prevAzimuth);
    let newGridAlignment = parseFloat(grid_alignment || prevAzimuth) + azimuthChange;
    newGridAlignment = normaLizeAngle(newGridAlignment);
    // if (isManualSegment) {
    //   const boundaryCenter = getPolygonCenter(updatedBoundary.map(bound => ({ lat: bound.lat, lng: bound.lon })));
    //   const origin = mapProjection.fromLatLngToPoint(boundaryCenter);
    //   updatedBoundary = manualBoundary.map(bound => {
    //     const point = mapProjection.fromLatLngToPoint({ lat: bound.lat, lng: bound.lon });
    //     const rotatedPoint = rotatePoint(point, origin, azimuth - prevAzimuth);
    //     const newBound = mapProjection.fromPointToLatLng(rotatedPoint);
    //     return { lat: newBound.lat(), lon: newBound.lng() };
    //   });
    // }
    if (!isManualSegment) {
      const segmentPanels = roofPanels.filter(panel => panel.roof.id === segmentId);
      const polygonCenter = getPolygonCenter(
        segmentPanels.map(({ center }) => ({ lat: center.lat, lng: center.lon })).flat(),
      );
      const origin = mapProjection.fromLatLngToPoint(polygonCenter);

      const updatedPanelCenters = segmentPanels.map(({ center, ...rest }) => {
        const point = mapProjection.fromLatLngToPoint({ lat: center.lat, lng: center.lon });
        const rotatedPoint = rotatePoint(point, origin, azimuth - prevAzimuth);
        const newCenter = mapProjection.fromPointToLatLng(rotatedPoint);
        return { ...rest, center: { lat: newCenter.lat(), lon: newCenter.lng() } };
      });

      onUpdatePanelCenter(updatedPanelCenters, [segmentId]);
    }

    onSegmentDetailsUpdate(segmentId, 'manual', {
      ...manual,
      azimuth,
      grid_alignment: newGridAlignment,
      boundary: updatedBoundary,
    });
  };

  const drawGridOnSegment = (segmentId, segment, panels = [], isNewSegment = false, panelDetails = null) => {
    const { boundary, pitch, alignment_line, orientation, grid_alignment } = segment;
    const firstPanelOrientation = orientation ? orientation : panels[0]?.orientation || 'PORTRAIT';
    const {
      height: customHeight,
      width: customWidth,
      boundaryGap: customGap,
    } = panelDetails || { height: height, width: width, boundaryGap: boundaryGap };
    const panelIds = panels.map(panel => panel.id);

    let panelHeight = customHeight;
    let panelWidth = customWidth;
    if (firstPanelOrientation === 'LANDSCAPE') {
      panelHeight = customWidth;
      panelWidth = customHeight;
    }
    const pitchInRadians = pitch * (Math.PI / 180);
    const pitchElevation = parseFloat(panelWidth * Math.cos(pitchInRadians)).toFixed(2);
    panelWidth = pitchElevation > 0 ? pitchElevation : 0.03;

    const paths = boundary.map(bound => ({ lat: bound.lat, lng: bound.lon }));
    let angleOfLine = 0;
    let firstPointOfLine = null;
    let secondPointOfLine = null;
    let isGridAlignmentAvailable = parseFloat(grid_alignment) >= 0;

    if (isNewSegment || alignment_line) {
      const { angle, firstPoint, secondPoint } = calculateRotationAngleToNorth(paths);
      angleOfLine = angle;
      firstPointOfLine = firstPoint;
      secondPointOfLine = secondPoint;
    } else if (isGridAlignmentAvailable) {
      angleOfLine = parseFloat(grid_alignment) + 90;
    } else {
      angleOfLine = turf.bearing(
        [boundary[0].lon, boundary[0].lat],
        [boundary[boundary.length - 2].lon, boundary[boundary.length - 2].lat],
      );
    }

    const turfPolygon = turf.polygon([boundary.map(bound => [bound.lon, bound.lat])]);

    const innerTurfPolygon = turf.buffer(turfPolygon, -customGap, { units: 'meters' });

    if (!innerTurfPolygon) {
      dispatch(addToast({ error: true, text: 'Boundary is too small to create grid' }));
      return null;
    }

    const rotatedPolygon = turf.transformRotate(innerTurfPolygon, -angleOfLine, {
      pivot: turf.center(innerTurfPolygon.geometry),
    });

    const rectangleGrid = customRectangleGrid(
      turf.bbox(rotatedPolygon),
      parseFloat(customGap),
      parseFloat(panelWidth),
      parseFloat(panelHeight),
      {
        mask: rotatedPolygon,
      },
    );

    const pivot = turf.center(innerTurfPolygon.geometry);
    const innerPolygonPath = innerTurfPolygon.geometry.coordinates[0].map(coord => ({ lat: coord[1], lng: coord[0] }));

    let intersectingPolygons = rectangleGrid.features
      .map(feature => {
        const rotatedFeature = turf.transformRotate(feature, angleOfLine, {
          pivot: pivot,
        });
        if (turf.booleanContains(innerTurfPolygon, rotatedFeature)) {
          return rotatedFeature;
        }
      })
      .filter(Boolean);

    if (panelIds.length > 0) {
      let panelCenterPoints = panels
        .map(panel => ({
          id: panel?.id,
          isNew: panel?.isNew,
          row: panel?.row,
          column: panel?.column,
          point: turf.point([panel.center.lon, panel.center.lat]),
        }))
        .sort((a, b) => a.row - b.row || a.column - b.column);
      // Don't merge below two loops as it will cause issues in panel center points
      // Check if panel is already present in the grid with same row and column
      intersectingPolygons = intersectingPolygons.map(polygon => {
        const { properties } = polygon || {};
        const { row, column } = properties || {};
        const isRowColumnMatch = panelCenterPoints.find(p => p.row === row && p.column === column);
        if (isRowColumnMatch) {
          panelCenterPoints = panelCenterPoints.filter(p => p.id !== isRowColumnMatch.id);
          return { ...polygon, id: isRowColumnMatch.id, isNew: isRowColumnMatch.isNew };
        }
        return { ...polygon };
      });
      // Check if panel is already present in the grid with same center point
      intersectingPolygons = intersectingPolygons.map(polygon => {
        if (polygon.id) return polygon;
        const { properties } = polygon || {};
        const { row, column } = properties || {};
        const isRowColumnMatch = panelCenterPoints.find(p => p.row === row && p.column === column);
        const panelInPolygon = panelCenterPoints.find(p => turf.booleanPointInPolygon(p.point, polygon));
        if (panelInPolygon && !isRowColumnMatch) {
          panelCenterPoints = panelCenterPoints.filter(p => p.id !== panelInPolygon.id);
          return { ...polygon, id: panelInPolygon.id, isNew: panelInPolygon.isNew };
        }
        return { ...polygon };
      });

      const visiblePanelsIds = intersectingPolygons.filter(p => p.id).map(p => p.id);
      const hiddenPanelsIds = panelCenterPoints.map(p => p.id).filter(id => !visiblePanelsIds.includes(id));

      visiblePanelsBySegment.current[segmentId] = visiblePanelsIds;
      hiddenPanelsBySegment.current[segmentId] = hiddenPanelsIds;
    }

    const intersectingPolygonsPath = intersectingPolygons.map(polygon => {
      return polygon.geometry.coordinates[0].map(coord => ({ lat: coord[1], lng: coord[0] }));
    });

    const center = turf.centerOfMass(turfPolygon);

    const centerOfAlignmentLine = getCenterOfBoundaryLines(
      alignment_line || [
        { lat: firstPointOfLine?.lat || 0, lon: firstPointOfLine?.lng || 0 },
        { lat: secondPointOfLine?.lat || 0, lon: secondPointOfLine?.lng || 0 },
      ],
    );
    const firstCenterOfAlignmentLine = centerOfAlignmentLine?.[0];

    let angelOfCenterAndLine = google.maps.geometry.spherical.computeHeading(
      new google.maps.LatLng(center.geometry.coordinates[1], center.geometry.coordinates[0]),
      new google.maps.LatLng(firstCenterOfAlignmentLine.lat, firstCenterOfAlignmentLine.lng),
    );

    const closestAngle = findClosestAngle(angelOfCenterAndLine, angleOfLine + 90, angleOfLine - 90);

    const data = {
      segmentId: segmentId,
      id: nanoid(),
      innerPolygon: innerPolygonPath,
      innerPolygonCenter: pivot,
      grid: intersectingPolygonsPath,
      panels: intersectingPolygonsPath,

      gridPolygons: intersectingPolygons,
      panelPolygons: intersectingPolygons,
      angle: closestAngle,
      firstPoint: firstPointOfLine,
      secondPoint: secondPointOfLine,
      orientation: firstPanelOrientation,
    };

    return data;
  };

  const onChangeAIToGrid = async (segmentId, roof_id) => {
    const segment = segments.find(segment => segment.id === segmentId);
    let manualBoundary = getRoofDetailsFromManualOrImported(segment, 'boundary', true, false, false);
    const pitch = getRoofDetailsFromManualOrImported(segment, 'pitch', false, false, false);
    const panel_count = getRoofDetailsFromManualOrImported(segment, 'panels_count', true, false, false);
    let boundary = manualBoundary;

    if (!manualBoundary) {
      const segmentPanel = roofPanels.filter(panel => panel.roof.id === segmentId);
      const gridPolygons = segmentPanel.map(panel => {
        return panelRefs.current[panel.id];
      });
      const gridCoordinates = gridPolygons
        .map(polygon => {
          const path = polygon?.getPath()?.getArray();
          return path?.map(latlng => [latlng.lng(), latlng.lat()]);
        })
        .filter(p => p?.length > 3);

      const turfPolygons = turf.featureCollection(
        gridCoordinates.map(coords => turf.polygon([[...coords, coords[0]]])),
      );
      const convexHull = turf.convex(turfPolygons);
      const bbox = turf.bbox(convexHull);
      const polygonFromBbox = turf.bboxPolygon(bbox);

      boundary = polygonFromBbox.geometry.coordinates[0].map(coord => ({ lat: coord[1], lon: coord[0] }));
    }

    const gridData = drawGridOnSegment(
      segmentId,
      { boundary, pitch, alignment_line: segment?.alignment_line },
      [],
      true,
    );
    if (!gridData) return;

    const { panelPolygons, innerPolygon, angle } = gridData;
    const panel_grid = innerPolygon.map(path => ({ lat: path.lat, lon: path.lng }));
    const updatedSegment = {
      ...segment,
      panel_grid,
      manual: {
        ...segment.manual,
        azimuth: angle < 0 ? 360 + angle : angle,
        grid_alignment: angle < 0 ? 360 + angle : angle,
        boundary: !manualBoundary ? boundary : manualBoundary,
      },
    };

    const newPanelPolygons = panelPolygons;

    try {
      setChangingSegmentFormat(true);
      setSelectedPanels([]);
      setIsAllPanelSelectedOfSegment(null);
      onClearPanelSelection();
      visiblePanelsBySegment.current[segmentId] = [];
      hiddenPanelsBySegment.current[segmentId] = [];
      let data = [];
      if (panel_count) {
        data = await dispatch(
          getRoofPanelInformation({ id: selectedDesign.id, roof_id: segmentId, params: { source: 'MANUAL' } }),
        );
      } else {
        await saveGridAndPanels(segment, newPanelPolygons);
      }
      await onSaveSegment(segment?.id, { ...updatedSegment, whole_roof: { id: roof_id } }, true);
      if (panel_count) {
        const roofPanelsWithoutCurrentSegment = roofPanels.filter(panel => panel.roof.id !== segmentId);
        setRoofPanels([...roofPanelsWithoutCurrentSegment, ...data]);
        dispatch(setRoofPanelInformation([...roofPanelsWithoutCurrentSegment, ...data]));
      }
      setChangingSegmentFormat(false);
    } catch (error) {
      console.log('error onChangeAIToGrid', error);
      dispatch(addToast({ error: true, text: 'Failed to save grid and panels' }));
      setChangingSegmentFormat(false);
    }
  };

  const onChangeGridToAI = async (segmentId, roof_id) => {
    const segment = segments.find(segment => segment.id === segmentId);
    const azimuth = getRoofDetailsFromManualOrImported(segment, 'azimuth', false, true, false);
    const updatedSegment = {
      ...segment,
      panel_grid: null,
      alignment_line: null,
      manual: {
        ...segment.manual,
        azimuth: azimuth,
      },
    };

    try {
      setChangingSegmentFormat(true);
      setSelectedPanels([]);
      setIsAllPanelSelectedOfSegment(null);
      onClearPanelSelection();
      visiblePanelsBySegment.current[segmentId] = [];
      hiddenPanelsBySegment.current[segmentId] = [];
      const data = await dispatch(
        getRoofPanelInformation({ id: selectedDesign.id, roof_id: segmentId, params: { source: 'IMPORTED' } }),
      );
      await onSaveSegment(segment?.id, { ...updatedSegment, whole_roof: { id: roof_id } }, true);
      const roofPanelsWithoutCurrentSegment = roofPanels.filter(panel => panel.roof.id !== segmentId);
      setRoofPanels([...roofPanelsWithoutCurrentSegment, ...data]);
      dispatch(setRoofPanelInformation([...roofPanelsWithoutCurrentSegment, ...data]));
      setChangingSegmentFormat(false);
    } catch (error) {
      console.log('error', error);
      dispatch(addToast({ error: true, text: 'Failed to save grid and panels' }));
      setChangingSegmentFormat(false);
    }
  };

  const onChangePanelOrientation = async segmentId => {
    const segment = segments.find(segment => segment.id === segmentId);
    const { whole_roof } = segment;

    const manualBoundary = getRoofDetailsFromManualOrImported(segment, 'boundary', true, false, false);
    const pitch = getRoofDetailsFromManualOrImported(segment, 'pitch', false, false, false);
    const azimuth = getRoofDetailsFromManualOrImported(segment, 'azimuth', true, false, false);
    const grid_alignment = getRoofDetailsFromManualOrImported(segment, 'grid_alignment', true, false, false);
    const firstPanel = roofPanels?.find(p => p.roof.id === segmentId);
    const panelOrientation = firstPanel?.orientation || 'PORTRAIT';
    const updatedOrientation = panelOrientation === 'PORTRAIT' ? 'LANDSCAPE' : 'PORTRAIT';

    const gridData = drawGridOnSegment(
      segmentId,
      {
        boundary: manualBoundary,
        pitch,
        alignment_line: segment?.alignment_line,
        orientation: updatedOrientation,
        azimuth,
        grid_alignment,
      },
      [],
    );
    if (!gridData) return;

    const { panelPolygons, innerPolygon } = gridData;
    const panel_grid = innerPolygon.map(path => ({ lat: path.lat, lon: path.lng }));
    const updatedSegment = {
      ...segment,
      panel_grid,
      manual: {
        ...segment.manual,
        boundary: !manualBoundary ? boundary : manualBoundary,
      },
    };

    const newPanelPolygons = panelPolygons;

    try {
      setChangingSegmentFormat(true);
      setSelectedPanels([]);
      setIsAllPanelSelectedOfSegment(null);
      onClearPanelSelection();
      visiblePanelsBySegment.current[segmentId] = [];
      hiddenPanelsBySegment.current[segmentId] = [];
      const panelData = await dispatch(
        updateRoofPanels({
          id: selectedDesign.id,
          roof_id: segmentId,
          request: [],
          params: {
            source: 'MANUAL',
          },
        }),
      );
      await saveGridAndPanels(segment, newPanelPolygons, true, updatedOrientation);
      await onSaveSegment(segment?.id, { ...updatedSegment, whole_roof: { id: whole_roof?.id } }, true);
      setChangingSegmentFormat(false);
    } catch (error) {
      console.log('error onChangePanelOrientation', error);
      dispatch(addToast({ error: true, text: 'Failed to save panels' }));
      setChangingSegmentFormat(false);
    }
  };

  const onSwapPanels = async (specifications = [], design = null) => {
    const manualSegments = segments.filter(segment => segment.panel_grid?.length > 0);
    const manualSegmentIds = manualSegments.map(s => s.id);
    const prevPanelCount = roofPanels.length;
    const roofPanelsWithoutManual = roofPanels.filter(p => !manualSegmentIds.includes(p.roof?.id));

    const width = specifications.find(({ classification }) => classification === 'WIDTH')?.specification || 187.9;
    const height = specifications.find(({ classification }) => classification === 'HEIGHT')?.specification || 104.5;
    const capacity = specifications.find(({ classification }) => classification === 'POWER_OUT')?.specification || 430;
    const boundaryGap = isResidencial ? 20 : 50;

    const panel_details = {
      width: parseFloat(width / 100).toFixed(3),
      height: parseFloat(height / 100).toFixed(3),
      capacity: capacity,
      boundaryGap: parseFloat(boundaryGap / 100).toFixed(3),
    };

    setSelectedPanels([]);
    setIsAllPanelSelectedOfSegment(null);
    onClearPanelSelection();

    try {
      setChangingSegmentFormat(true);
      const promiseData = await Promise.all(
        manualSegments.map(async segment => {
          try {
            const { whole_roof, id: segmentId } = segment;
            visiblePanelsBySegment.current[segmentId] = [];
            hiddenPanelsBySegment.current[segmentId] = [];
            const manualBoundary = getRoofDetailsFromManualOrImported(segment, 'boundary', true, false, false);
            const pitch = getRoofDetailsFromManualOrImported(segment, 'pitch', false, false, false);
            const azimuth = getRoofDetailsFromManualOrImported(segment, 'azimuth', true, false, false);
            const grid_alignment = getRoofDetailsFromManualOrImported(segment, 'grid_alignment', true, false, false);
            const firstPanel = roofPanels?.find(p => p.roof.id === segmentId);
            const panelOrientation = firstPanel?.orientation || 'PORTRAIT';

            const gridData = drawGridOnSegment(
              segmentId,
              {
                boundary: manualBoundary,
                pitch,
                alignment_line: segment?.alignment_line,
                orientation: panelOrientation,
                azimuth,
                grid_alignment,
              },
              [],
              false,
              panel_details,
            );
            if (!gridData) return;

            const { panelPolygons, innerPolygon } = gridData;
            const panel_grid = innerPolygon.map(path => ({ lat: path.lat, lon: path.lng }));
            const updatedSegment = {
              ...segment,
              panel_grid,
              manual: {
                ...segment.manual,
                boundary: !manualBoundary ? boundary : manualBoundary,
              },
            };

            await dispatch(
              updateRoofPanels({
                id: selectedDesign.id,
                roof_id: segmentId,
                request: [],
                params: {
                  source: 'MANUAL',
                },
              }),
            );
            const newPanels = await saveGridAndPanels(segment, panelPolygons, false, panelOrientation, panel_details);
            await onSaveSegment(segment?.id, { ...updatedSegment, whole_roof: { id: whole_roof?.id } }, true);
            return Promise.resolve(newPanels);
          } catch (error) {
            return Promise.reject([]);
          }
        }),
      );
      const panels = promiseData.flat();
      const newPanels = [...roofPanelsWithoutManual, ...panels];
      const newPanelsCount = newPanels.length;
      setRoofPanels([...newPanels]);
      dispatch(setRoofPanelInformation([...newPanels]));
      const countDelta = newPanelsCount - prevPanelCount;
      if (countDelta === 0) {
        await updateQuoteQuantity(design);
      }
      return { countDelta, newPanelsCount }; // return the count difference to check if quote will update or not
    } catch (error) {
      setChangingSegmentFormat(false);
      dispatch(addToast({ error: true, text: 'Error while swapping panels in sections' }));
      return null;
    }
  };

  const renderRoofSegment = roof => {
    const { segments: roofSegments, whole_roof } = roof;
    const roofSegmentIds = roofSegments?.map(segment => segment.id);
    const segmentsToRender = segments?.filter(segment => roofSegmentIds.includes(segment.id));

    return segmentsToRender?.map((segment, index) => {
      const { segment: segmentNumber, id, panel_grid, manual } = segment;
      const { id: editSegmentId, type } = editSegment || {};

      const roofColor = roofColors[segmentNumber % roofColors.length];
      const pitch = getRoofDetailsFromManualOrImported(segment, 'pitch', false, false, false) || 0;
      const azimuth = getRoofDetailsFromManualOrImported(segment, 'azimuth', false, false, false) || 0;
      const grid_alignment = getRoofDetailsFromManualOrImported(segment, 'grid_alignment', true, false, false) || 0;
      const panels_count = getRoofDetailsFromManualOrImported(segment, 'panels_count', false, false, false);
      const importedBoundary = getRoofDetailsFromManualOrImported(segment, 'boundary', false, true, false);
      const isSelected = selectedSegments.includes(id);
      const isSegmentToSave = segmentsToSave.includes(id);
      const isSegmentToEdit = editSegmentId === id;
      const isAllPanelSelected = isAllPanelSelectedOfSegment === id;
      const isManualSegment = panel_grid?.length > 0;
      const isSavingSegment = isSegmentToSave && savingSegment[id];

      return (
        <div
          key={id}
          id={id}
          className={classNames('pxy-3 border radius-2 row-gap-3 flex-column', isSelected && 'selected-segment')}>
          <div className="pb-3 border-bottom flex items-center col-gap-2">
            <div className="dot" style={{ backgroundColor: roofColor }} />
            <label className="inter-500-text flex-1">Section {index + 1}</label>
            {isSegmentToSave ? (
              <div className="flex items-center col-gap-1">
                <span
                  className="flex justify-center items-center cross-icon border radius-100 cursor pxy-1"
                  onClick={e => {
                    e.stopPropagation();
                    onCancelSegment(id);
                  }}>
                  <CrossIcon className="natural-500-text" height={12} width={12} />
                </span>
                <span
                  className="flex justify-center items-center bg-primary-500 border radius-100 cursor pxy-1"
                  onClick={e => {
                    e.stopPropagation();
                    onSaveSegment(id);
                  }}>
                  {isSavingSegment ? (
                    <Loader color="#FFFFFF" wrapperClassName="m-0" height={12} width={12} />
                  ) : (
                    <RightIcon color="#FFFFFF" height={12} width={12} />
                  )}
                </span>
              </div>
            ) : (
              <div className="flex items-center col-gap-2">
                <div className="flex items-center col-gap-1 grid-ai-container bg-natural-50 radius-3">
                  <CustomTooltip
                    key={'grid-tooltip'}
                    id={'grid-tooltip'}
                    place="bottom"
                    wrapperClassName="segment-tooltip-wrapper"
                    delayShow={200}
                    content={<span className="inter-400-text font-12">{'Grid Panels'}</span>}>
                    <IconContainer
                      Icon={GridIcon}
                      backgroundColor={isManualSegment ? 'primary_500' : 'transparent'}
                      iconColor={isManualSegment ? 'white' : 'natural_500'}
                      iconContainerClassname="radius-50-percent cursor"
                      onClick={() => (!isManualSegment ? onChangeAIToGrid(id, whole_roof?.id) : null)}
                    />
                  </CustomTooltip>
                  <CustomTooltip
                    key={'ai-tooltip'}
                    id={'ai-tooltip'}
                    place="bottom"
                    wrapperClassName="segment-tooltip-wrapper"
                    delayShow={200}
                    content={<span className="inter-400-text font-12">{'AI Panels'}</span>}>
                    <IconContainer
                      Icon={AIIcon}
                      backgroundColor={!isManualSegment ? 'primary_500' : 'transparent'}
                      iconColor={!isManualSegment ? 'white' : 'natural_500'}
                      iconContainerClassname="radius-50-percent cursor"
                      onClick={() => (isManualSegment ? onChangeGridToAI(id, whole_roof?.id) : null)}
                      disabled={!importedBoundary}
                    />
                  </CustomTooltip>
                </div>
                <CustomTooltip
                  key={'select-all-panels-tooltip'}
                  id={'select-all-panels-tooltip'}
                  place="bottom"
                  wrapperClassName="segment-tooltip-wrapper"
                  delayShow={200}
                  content={<span className="inter-400-text font-12">{'Select all panels'}</span>}>
                  <IconContainer
                    Icon={AddPanelIcon}
                    backgroundColor={isAllPanelSelected ? 'primary_50' : 'natural_100'}
                    iconColor={isAllPanelSelected ? 'primary_500' : 'natural_500'}
                    iconContainerClassname="radius-50-percent cursor"
                    onClick={() => {
                      if (isAllPanelSelected) {
                        setIsAllPanelSelectedOfSegment(null);
                      } else {
                        setIsAllPanelSelectedOfSegment(id);
                        setSelectedPanels([]);
                      }
                    }}
                  />
                </CustomTooltip>
                <CustomTooltip
                  key={'section-boundary-tooltip'}
                  id={'section-boundary-tooltip'}
                  place="bottom"
                  wrapperClassName="segment-tooltip-wrapper"
                  delayShow={200}
                  content={<span className="inter-400-text font-12">{'Section boundary'}</span>}>
                  <IconContainer
                    Icon={EyeIcon}
                    backgroundColor={isSelected ? 'primary_50' : 'natural_100'}
                    iconColor={isSelected ? 'primary_500' : 'natural_500'}
                    iconContainerClassname="radius-50-percent cursor"
                    onClick={() => onSegmentClick(id)}
                  />
                </CustomTooltip>
                <CustomTooltip
                  key={'delete-section-tooltip'}
                  id={'delete-section-tooltip'}
                  place="bottom"
                  wrapperClassName="segment-tooltip-wrapper"
                  delayShow={200}
                  content={<span className="inter-400-text font-12">{'Delete section'}</span>}>
                  <IconContainer
                    Icon={DeleteIcon}
                    backgroundColor="natural_100"
                    iconColor="natural_500"
                    iconContainerClassname="radius-50-percent cursor"
                    onClick={() => {
                      onDeleteRoofSegment(id, index, true);
                    }}
                  />
                </CustomTooltip>
              </div>
            )}
          </div>
          <div className="flex items-center col-gap-2">
            <div className="flex-column row-gap-2 w-full">
              <div className="flex items-center justify-between flex-1 col-gap-4 segment-detail-grid">
                <div className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1">
                  <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                    0<span className="inter-500-text natural-400-text ml-1">obstacles</span>
                  </label>
                </div>
                {isSegmentToEdit && type === 'PITCH' ? (
                  <InputElement
                    type="number"
                    autoFocus
                    min={0}
                    max={90}
                    value={pitch || ''}
                    variant="size_28"
                    className="input-pitch"
                    showNumberArrows={true}
                    onBlur={e => {
                      let pitchValue = parseFloat(e.target.value);
                      if (pitchValue > 90) {
                        pitchValue = pitchValue % 90;
                        onSegmentDetailsUpdate(id, 'pitch', pitchValue.toString());
                      } else if (pitchValue < 0) {
                        pitchValue = 90 + (pitchValue % 90);
                        onSegmentDetailsUpdate(id, 'pitch', pitchValue.toString());
                      }
                      setEditSegment(null);
                    }}
                    onChange={value => {
                      const isValueValid = value >= 0 && value <= 90;
                      onSegmentDetailsUpdate(id, 'pitch', isValueValid ? value : pitch);
                    }}
                    onWheel={e => e.target.blur()}
                  />
                ) : (
                  <div
                    className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1"
                    onClick={e => {
                      e.stopPropagation();
                      setEditSegment({ id, type: 'PITCH' });
                    }}>
                    <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                      {getFormattedNumberStyle(pitch, 'decimal')}°
                      <span className="inter-500-text natural-400-text ml-1">pitch</span>
                    </label>
                  </div>
                )}
              </div>
              <div className="items-center justify-between w-full col-gap-4 segment-detail-grid">
                {isSegmentToEdit && type === 'AZIMUTH' ? (
                  <InputElement
                    type="number"
                    autoFocus
                    value={azimuth || ''}
                    variant="size_28"
                    className="input-azimuth"
                    showNumberArrows={true}
                    onBlur={e => {
                      const value = parseFloat(e.target.value);
                      if (value > 360) {
                        onSegmentDetailsUpdate(id, 'azimuth', parseFloat(value % 360).toFixed(2));
                      }
                      if (value < 0) {
                        onSegmentDetailsUpdate(id, 'azimuth', parseFloat(360 + (value % 360)).toFixed(2));
                      }
                      setEditSegment(null);
                    }}
                    onChange={value =>
                      onSegmentAzimuthChange(id, value, azimuth, isManualSegment, segment, grid_alignment)
                    }
                    onWheel={e => e.target.blur()}
                  />
                ) : (
                  <div
                    className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1"
                    onClick={e => {
                      e.stopPropagation();
                      setEditSegment({ id, type: 'AZIMUTH' });
                    }}>
                    <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                      <span className="inter-500-text natural-400-text mr-1">Azimuth</span>
                      {getFormattedNumberStyle(azimuth, 'decimal')}°
                    </label>
                  </div>
                )}
                <div className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1">
                  <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                    {panels_count || 0}
                    <span className="inter-500-text natural-400-text ml-1">panels</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const onPolygonEdit = (paths, polygonId) => {
    const updatedPath = paths.map(path => ({ lat: path.lat, lon: path.lng }));
    const polygonArea = google.maps.geometry.spherical.computeArea(paths?.map(path => new google.maps.LatLng(path)));
    onSegmentBoundryUpdate(polygonId, updatedPath, polygonArea);
  };

  const onPanelClick = (_polygon, polygonId, segment_id, removeIfSelected = true, selectAllPanel = false) => {
    const isPanelDeleteSelected = selectedInstrument?.key === 'DELETE';

    if (isPanelDeleteSelected) {
      onPanelSelectForDelete(polygonId, segment_id, true);
    }
    if (!isPanelDeleteSelected) {
      setSelectedSegments([segment_id]);
      setIsAllPanelSelectedOfSegment(prev => (selectAllPanel ? segment_id : prev));
      const segmentEle = document.getElementById(segment_id);
      if (segmentEle) {
        const count = segmentEle?.offsetTop - roofListRef?.current?.offsetTop - 50;
        roofListRef.current.scrollTo({ top: count, behavior: 'smooth' });
      }
    }
  };

  const onGridPolygonClick = (gridPolygon, polygonId, segment_id, removeIfSelected = true) => {
    const { center, row, column } = gridPolygon;
    const isPanelDeleteSelected = selectedInstrument?.key === 'DELETE';
    const isPointerSelected = selectedInstrument?.key === 'POINTER';

    if (isPanelDeleteSelected) {
      onAddedPanelDelete(polygonId, segment_id);
    }
    if (isPointerSelected) {
      const isPanelAdded = roofPanels.find(panel => panel.id === polygonId);
      if (isPanelAdded) {
        removeIfSelected && onAddedPanelDelete(polygonId, segment_id);
      } else {
        onPanelAdd({ center, row, column }, segment_id, polygonId);
      }
    }
  };

  const onPanelMouseDown = (polygon, polygonId, segment_id, selectAllPanel = false) => {
    onPanelClick(polygon, polygonId, segment_id, false, selectAllPanel);
    isSelecting.current = true;
  };

  const onPanelMouseOver = (polygon, polygonId, segment_id, selectAllPanel = false) => {
    if (isSelecting.current) {
      onPanelMouseDown(polygon, polygonId, segment_id, selectAllPanel);
    }
  };

  const onAddPanelMouseDown = (polygon, polygonId, segment_id) => {
    onGridPolygonClick(polygon, polygonId, segment_id, false);
    isAdding.current = true;
  };

  const onAddPanelMouseOver = (polygon, polygonId, segment_id) => {
    if (isAdding.current) {
      onAddPanelMouseDown(polygon, polygonId, segment_id);
    }
  };

  const getPanelPaths = (panelSegment, center, panelHeight, panelWidth, orientation) => {
    const isLandScape = orientation === 'LANDSCAPE';

    let height = panelHeight / 2;
    let width = panelWidth / 2;

    if (isLandScape) {
      const previousHeight = height;

      height = width;
      width = previousHeight;
    }

    const angle = parseFloat(getRoofDetailsFromManualOrImported(panelSegment, 'azimuth', false, false, false) || 0);
    const pitch = parseFloat(getRoofDetailsFromManualOrImported(panelSegment, 'pitch', false, false, false) || 0);
    const pitchElevation = Math.sin(pitch / 360);

    height = height - pitchElevation;

    const centerPosition = {
      lat: center.lat,
      lng: center.lon,
    };

    const top = google.maps.geometry.spherical.computeOffset(centerPosition, height, angle + 0);
    const right = google.maps.geometry.spherical.computeOffset(centerPosition, width, angle + 90);
    const left = google.maps.geometry.spherical.computeOffset(centerPosition, width, angle + 270);

    const topRight = google.maps.geometry.spherical.computeOffset(top, width, angle + 90);
    const bottomRight = google.maps.geometry.spherical.computeOffset(right, height, angle + 180);
    const bottomLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 180);
    const topLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 0);

    return [topRight, bottomRight, bottomLeft, topLeft];
  };

  const onMarkerDrag = (segmentId, panels, prevCenter, newCenter, isUpdatePanelsInState) => {
    const distanceBetweenLatLon = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(prevCenter.lat, prevCenter.lng),
      new google.maps.LatLng(newCenter.lat, newCenter.lng),
    );
    const headingBetweenLatLon = google.maps.geometry.spherical.computeHeading(
      new google.maps.LatLng(prevCenter.lat, prevCenter.lng),
      new google.maps.LatLng(newCenter.lat, newCenter.lng),
    );
    const segment = segments.find(segment => segment.id === segmentId);
    const isManualSegment = segment?.panel_grid?.length > 0;

    if (isManualSegment) {
      const segmentRef = segmentPolygonRefs.current[segmentId];
      const boundary = getRoofDetailsFromManualOrImported(segment, 'boundary', false, false, false);
      const boundaryWithoutLast = boundary.slice(0, boundary.length - 1);
      const updatedBoundary = boundaryWithoutLast.map(bound => {
        const newBound = google.maps.geometry.spherical.computeOffset(
          new google.maps.LatLng(bound.lat, bound.lon),
          distanceBetweenLatLon,
          headingBetweenLatLon,
        );
        return { lat: newBound.lat(), lon: newBound.lng() };
      });

      const newPath = updatedBoundary.map(bound => new google.maps.LatLng(bound.lat, bound.lon));
      segmentRef?.setPaths(newPath);

      if (isUpdatePanelsInState) {
        const polygonArea = google.maps.geometry.spherical.computeArea(
          updatedBoundary.map(bound => new google.maps.LatLng({ lat: bound.lat, lng: bound.lon })),
        );
        onSegmentBoundryUpdate(segmentId, updatedBoundary, polygonArea);
      }

      if (!isUpdatePanelsInState) {
        const segmentGridPolyRefs = segmentGridRefs.current[segmentId] || {};
        const segmentGridPathRefs = segmentGrids.current[segmentId] || {};

        const filteredRefs = Object.entries(segmentGridPathRefs).filter(([_key, value]) => Boolean(value));

        filteredRefs?.reduce((acc, [key, _value]) => {
          const grid = segmentGridPolyRefs[key];
          const gridPath = segmentGridPathRefs[key] || [];

          const paths = gridPath?.map(path => {
            const newBound = google.maps.geometry.spherical.computeOffset(
              new google.maps.LatLng(path.lat, path.lng),
              distanceBetweenLatLon,
              headingBetweenLatLon,
            );
            return { lat: newBound.lat(), lng: newBound.lng() };
          });
          grid?.setPaths(paths);
          acc[key] = paths;
          return acc;
        }, {});
      }
    }
    if (!isManualSegment || (isManualSegment && isUpdatePanelsInState)) {
      const updatedPanels = panels.map(panel => {
        const panelRefToBeUpdated = panelRefs.current[panel.id];
        const newCenter = google.maps.geometry.spherical.computeOffset(
          new google.maps.LatLng(panel.center.lat, panel.center.lon),
          distanceBetweenLatLon,
          headingBetweenLatLon,
        );
        const paths = getPanelPaths(
          segment,
          { lat: newCenter.lat(), lon: newCenter.lng() },
          panel.height,
          panel.width,
          panel.orientation,
        );
        if (panelRefToBeUpdated && !isManualSegment) {
          panelRefToBeUpdated?.setPaths(paths);
          panelRefs.current[panel.id] = panelRefToBeUpdated;
        }
        return { ...panel, center: { lat: newCenter.lat(), lon: newCenter.lng() } };
      });

      if (isUpdatePanelsInState) {
        onUpdatePanelCenter(updatedPanels, [segmentId]);
      }
    }
  };

  const renderSegmentPolygons = () => {
    return segments?.map((segment, index) => {
      const { segment: segmentNumber, id, alignment_line: segmentAlignmentLine, panel_grid } = segment;
      const roofColor = roofColors[segmentNumber % roofColors.length];
      let boundary = getRoofDetailsFromManualOrImported(segment, 'boundary', true, false, false);
      const manualAzimuth = getRoofDetailsFromManualOrImported(segment, 'azimuth', true, false, false);

      const isSelected = selectedSegments.includes(id);
      const currentlyDraggingSegment = draggingSegment === id;
      const isManualSegment = panel_grid?.length > 0;

      const isAllPanelSelected = isAllPanelSelectedOfSegment === id;
      const segmentPanels = roofPanels.filter(panel => panel.roof.id === id);

      if (!boundary) {
        const segmentPanel = roofPanels.filter(panel => panel.roof.id === id);
        const gridPolygons = segmentPanel.map(panel => {
          return panelRefs.current[panel.id];
        });
        const gridCoordinates = gridPolygons
          .map(polygon => {
            const path = polygon?.getPath()?.getArray();
            return path?.map(latlng => [latlng.lng(), latlng.lat()]);
          })
          .filter(p => p?.length > 3);

        const turfPolygons = turf.featureCollection(
          gridCoordinates.map(coords => turf.polygon([[...coords, coords[0]]])),
        );
        const convexHull = turf.convex(turfPolygons);
        const bbox = convexHull ? turf.bbox(convexHull) : null;
        const polygonFromBbox = bbox ? turf.bboxPolygon(bbox) : null;

        boundary = polygonFromBbox?.geometry.coordinates[0].map(coord => ({ lat: coord[1], lon: coord[0] }));
      }

      if (!boundary) return null;

      const azimuthInNumber = Number(parseFloat(manualAzimuth || 0).toFixed(2));
      const turfPolygon = turf.polygon([boundary.map(bound => [bound.lon, bound.lat])]);
      const bbox = turf.bbox(turfPolygon);
      const westPoint = turf.point([bbox[0], (bbox[1] + bbox[3]) / 2]); // min longitude
      const eastPoint = turf.point([bbox[2], (bbox[1] + bbox[3]) / 2]); // max longitude
      const southPoint = turf.point([(bbox[0] + bbox[2]) / 2, bbox[1]]); // min latitude
      const northPoint = turf.point([(bbox[0] + bbox[2]) / 2, bbox[3]]); // max latitude

      const eastWestDistance = turf.distance(westPoint, eastPoint, { units: 'meters' });
      const northSouthDistance = turf.distance(southPoint, northPoint, { units: 'meters' });
      const center = turf.centerMedian(turfPolygon);

      const distanceForLine = 2000; // 2 km
      const bearing = azimuthInNumber;
      const destinationPoint = turf.destination(center, distanceForLine, bearing, { units: 'meters' });
      const oppositeDestinationPoint = turf.destination(center, distanceForLine, bearing + 180, { units: 'meters' });

      const line = turf.lineString([center.geometry.coordinates, destinationPoint.geometry.coordinates]);
      const oppositeLine = turf.lineString([
        center.geometry.coordinates,
        oppositeDestinationPoint.geometry.coordinates,
      ]);

      const intersection = turf.lineIntersect(line, turfPolygon);
      const oppositeIntersection = turf.lineIntersect(oppositeLine, turfPolygon);
      let lineLength = 0;
      if (intersection.features.length > 0 || oppositeIntersection.features.length > 0) {
        const intersectionPoint = intersection.features[0];
        const oppositeIntersectionPoint = oppositeIntersection.features[0];
        const firstLineLength =
          intersection.features.length > 0 ? turf.distance(center, intersectionPoint, { units: 'meters' }) : 0;
        const oppositeLineLength =
          oppositeIntersection.features.length > 0
            ? turf.distance(center, oppositeIntersectionPoint, { units: 'meters' })
            : 0;
        lineLength = firstLineLength > oppositeLineLength ? firstLineLength : oppositeLineLength;
      } else {
        lineLength = eastWestDistance > northSouthDistance ? eastWestDistance : northSouthDistance;
      }

      const paths = boundary.map(bound => ({
        lat: parseFloat(bound?.lat),
        lng: bound?.lon ? parseFloat(bound?.lon) : parseFloat(bound?.lng),
      }));

      let alignment_line =
        segmentAlignmentLine && segmentAlignmentLine?.every(bound => bound?.lat && bound?.lon)
          ? segmentAlignmentLine
          : false;
      const { firstPoint, secondPoint } = calculateRotationAngleToNorth(paths);

      if (!alignment_line) {
        alignment_line = [
          { lat: firstPoint?.lat || 0, lon: firstPoint?.lng || 0 },
          { lat: secondPoint?.lat || 0, lon: secondPoint?.lng || 0 },
        ];
      }

      let oppositeAzimuth = azimuthInNumber
        ? azimuthInNumber + 180 > 180
          ? azimuthInNumber + 180 - 360
          : azimuthInNumber + 180
        : 180;
      oppositeAzimuth = Number(parseFloat(oppositeAzimuth).toFixed(2));

      const lineInAzimuth = google.maps.geometry.spherical.computeOffset(
        new google.maps.LatLng(center.geometry.coordinates[1], center.geometry.coordinates[0]),
        lineLength + 3,
        azimuthInNumber || 0,
      );

      const lineOppositeAzimuth = google.maps.geometry.spherical.computeOffset(
        new google.maps.LatLng(center.geometry.coordinates[1], center.geometry.coordinates[0]),
        lineLength + 3,
        oppositeAzimuth,
      );

      const pointForOrientationSwitch = google.maps.geometry.spherical.computeOffset(
        new google.maps.LatLng(lineOppositeAzimuth.lat(), lineOppositeAzimuth.lng()),
        2.5,
        oppositeAzimuth + 90,
      );

      const pointForPanelGrabber = google.maps.geometry.spherical.computeOffset(
        new google.maps.LatLng(lineOppositeAzimuth.lat(), lineOppositeAzimuth.lng()),
        2.5,
        oppositeAzimuth - 90,
      );

      const selectedSegmentPanels = isAllPanelSelected
        ? segmentPanels
        : segmentPanels.filter(panel => selectedPanels.includes(panel.id));

      const centerOfPanels = isManualSegment
        ? !isSelected || !pointForPanelGrabber.lat() || !pointForPanelGrabber.lng()
          ? getPolygonCenter(paths)
          : { lat: pointForPanelGrabber.lat(), lng: pointForPanelGrabber.lng() }
        : selectedSegmentPanels?.length > 0
        ? getPolygonCenter(selectedSegmentPanels.map(panel => ({ lat: panel.center.lat, lng: panel.center.lon })))
        : {};

      return (
        <>
          <PolygonWrapper
            key={`polygon-segment-${id}`}
            polygonId={id}
            draggable={isSelected}
            editable={isSelected}
            paths={boundary?.slice(0, boundary.length - 1).map(bound => ({
              lat: parseFloat(bound?.lat),
              lng: bound?.lon ? parseFloat(bound?.lon) : parseFloat(bound?.lng),
            }))}
            setPaths={onPolygonEdit}
            onLoadPolygon={polygon => {
              segmentPolygonRefs.current[id] = polygon;
            }}
            onUnmountPolygon={() => {
              segmentPolygonRefs.current[id] = null;
            }}
            options={{
              map: map,
              strokeWeight: 1,
              strokeColor: roofColor,
              fillColor: roofColor,
              strokeOpacity: 1,
              geodesic: false,
              zIndex: index + 1,
              editable: isSelected,
              draggable: isSelected,
              visible: isSelected || currentlyDraggingSegment,
            }}
          />
          {isManualSegment && pointForOrientationSwitch && (
            <PanelRotate
              id={`orientation-switch-${id}`}
              key={`orientation-switch-${id}`}
              segmentId={id}
              onClickPanelRotate={() => onChangePanelOrientation(id)}
              position={{ lat: pointForOrientationSwitch.lat(), lng: pointForOrientationSwitch.lng() }}
              options={{
                map: map,
                draggable: false,
                clickable: true,
                visible: isSelected,
                zIndex: 1004,
                icon: {
                  url: MapPanelRotateIcon,
                  scaledSize: new google.maps.Size(20, 20),
                  anchor: new google.maps.Point(10, 10),
                },
              }}
            />
          )}
          {centerOfPanels.lat && centerOfPanels.lng && (
            <PanelDragger
              id={`panel-dragger-${id}`}
              key={`panel-dragger-${id}`}
              onMouseUp={onMouseUp}
              segmentId={id}
              draggable
              position={{
                lat: parseFloat(centerOfPanels.lat),
                lng: parseFloat(centerOfPanels.lng),
              }}
              onDragStart={() => {
                map.setOptions({ gestureHandling: 'none', keyboardShortcuts: false });
                if (isManualSegment) {
                  setDraggingSegment(id);
                }
              }}
              onEditMarker={(segmentId, newPosition) => {
                onMarkerDrag(
                  segmentId,
                  isManualSegment ? segmentPanels : selectedSegmentPanels,
                  { lat: centerOfPanels.lat, lng: centerOfPanels.lng },
                  { lat: newPosition.lat, lng: newPosition.lon },
                  false,
                );
              }}
              onUpdateMarker={(segmentId, newPosition) => {
                if (selectedInstrument) {
                  onInstrumentClick(selectedInstrument);
                } else {
                  map.setOptions({ gestureHandling: 'greedy', keyboardShortcuts: true });
                }
                setDraggingSegment(null);
                onMarkerDrag(
                  segmentId,
                  isManualSegment ? segmentPanels : selectedSegmentPanels,
                  { lat: centerOfPanels.lat, lng: centerOfPanels.lng },
                  { lat: newPosition.lat, lng: newPosition.lon },
                  true,
                );
              }}
              options={{
                zIndex: 2000,
                map: map,
                visible: selectedSegmentPanels.length > 0,
              }}
            />
          )}
          {isManualSegment && lineInAzimuth && center && (
            <Polyline
              path={[
                { lat: center.geometry.coordinates[1], lng: center.geometry.coordinates[0] },
                { lat: lineInAzimuth.lat(), lng: lineInAzimuth.lng() },
              ]}
              options={{
                map: map,
                visible: isSelected,
                strokeColor: theme.primary_500,
                strokeOpacity: 1,
                strokeWeight: 3,
                zIndex: 4000,
                icons: [
                  {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      strokeColor: theme.primary_500,
                      strokeOpacity: 1,
                    },
                  },
                ],
              }}
            />
          )}
          {isManualSegment && lineOppositeAzimuth && center && (
            <Fragment>
              <Polyline
                path={[
                  { lat: center.geometry.coordinates[1], lng: center.geometry.coordinates[0] },
                  { lat: lineOppositeAzimuth.lat(), lng: lineOppositeAzimuth.lng() },
                ]}
                options={{
                  map: map,
                  visible: isSelected,
                  strokeColor: theme.primary_500,
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  zIndex: 4000,
                }}
              />
              <MarkerF
                position={{ lat: lineOppositeAzimuth.lat(), lng: lineOppositeAzimuth.lng() }}
                onClick={() =>
                  onSegmentDetailsUpdate(id, 'azimuth', parseFloat(normaLizeAngle(azimuthInNumber + 180)).toFixed(2))
                }
                options={{
                  map: map,
                  draggable: false,
                  clickable: true,
                  visible: isSelected,
                  zIndex: 4000,
                  icon: {
                    url: CompassIcon,
                    scaledSize: new google.maps.Size(20, 20),
                    anchor: new google.maps.Point(10, 10),
                  },
                }}
              />
            </Fragment>
          )}
        </>
      );
    });
  };

  const renderPanelPolygons = () => {
    const manualSegmentIds =
      segments?.filter(segment => segment?.panel_grid?.length > 0).map(segment => segment.id) || [];
    return roofPanels
      ?.filter(p => !manualSegmentIds.includes(p?.roof?.id))
      .map(panel => {
        const { id, center, height: panelHeight, width: panelWidth, orientation, roof } = panel;
        const isVisible = showPanels;
        const panelSegment = segments.find(segment => segment.id === roof?.id);
        const isPanelToBeDeleted = panelsToDelete.includes(id);
        const isPanelSelected = selectedPanels.includes(id) && isVisible;
        const isLandScape = orientation === 'LANDSCAPE';
        const isPanelToDrag = isAllPanelSelectedOfSegment === roof?.id && isVisible;

        let height = parseFloat(panelHeight / 2).toFixed(2);
        let width = parseFloat(panelWidth / 2).toFixed(2);

        if (isLandScape) {
          const previousHeight = height;

          height = width;
          width = previousHeight;
        }

        const angle = parseFloat(getRoofDetailsFromManualOrImported(panelSegment, 'azimuth', false, false, false) || 0);
        const pitch = parseFloat(getRoofDetailsFromManualOrImported(panelSegment, 'pitch', false, false, false) || 0);

        const pitchElevation = parseFloat(Math.sin(pitch / 360)).toFixed(2);
        height = height - pitchElevation;
        const centerPosition = {
          lat: center?.lat,
          lng: center?.lon,
        };

        const top = google.maps.geometry.spherical.computeOffset(centerPosition, height, angle + 0);
        const right = google.maps.geometry.spherical.computeOffset(centerPosition, width, angle + 90);
        const left = google.maps.geometry.spherical.computeOffset(centerPosition, width, angle + 270);
        const bottom = google.maps.geometry.spherical.computeOffset(centerPosition, height, angle + 180);

        const topRight = google.maps.geometry.spherical.computeOffset(top, width, angle + 90);
        const bottomRight = google.maps.geometry.spherical.computeOffset(right, height, angle + 180);
        const bottomLeft = google.maps.geometry.spherical.computeOffset(bottom, width, angle + 270);
        const topLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 0);

        const zoom = map.getZoom();

        return (
          <MarkerWrapper
            key={id}
            polygonId={id}
            draggable={false}
            paths={[topRight, bottomRight, bottomLeft, topLeft]}
            bounds={calcBounds(centerPosition, {
              height: isLandScape ? width : height,
              width: isLandScape ? height : width,
            })}
            onPolygonMouseUp={(polygon, polygonId) => onMouseUp()}
            onPolygonMouseDown={(polygon, polygonId) => onPanelMouseDown(polygon, polygonId, panelSegment.id)}
            onPolygonMouseOver={(polygon, polygonId) => onPanelMouseOver(polygon, polygonId, panelSegment.id)}
            angle={isLandScape ? angle + 90 : angle}
            isPanelToBeDeleted={isPanelToBeDeleted}
            isPanelToDrag={isPanelToDrag || isPanelSelected}
            onLoadPanel={polygon => {
              panelRefs.current[id] = polygon;
            }}
            onUnmountPanel={() => {
              panelRefs.current[id] = null;
            }}
            panelsDragging={isPanelToDrag || isPanelSelected}
            options={{
              map: map,
              strokeWeight: zoom >= 20 ? 1 : zoom >= 19 ? 0.5 : zoom >= 18 ? 0.3 : 0.3,
              strokePosition: google.maps.StrokePosition.INSIDE,
              fillColor: isPanelToBeDeleted ? '#ab1c20' : theme.natural_900,
              strokeColor: isPanelToDrag || isPanelSelected ? theme.primary_500 : theme.white,
              fillOpacity: isPanelToDrag || isPanelSelected ? 0.9 : 0.9,
              strokeOpacity: 1,
              geodesic: false,
              zIndex: 1000,
              draggable: false,
              visible: isVisible,
              imageVisible: true,
            }}
          />
        );
      });
  };

  const renderGroundOverlay = () => {
    const propertyImage = propertyImages?.find(image => image.image_type === 'AERIAL');
    const { image, boundary } = propertyImage || {};

    if (image && boundary) {
      return (
        <OverlayViewF
          bounds={
            new google.maps.LatLngBounds(
              new google.maps.LatLng(boundary[2].lat, boundary[3].lon),
              new google.maps.LatLng(boundary[0].lat, boundary[1].lon),
            )
          }
          getPixelPositionOffset={(width, height) => {
            return { x: Math.sin(-width), y: Math.sin(-height) };
          }}
          mapPaneName={OverlayView.MAP_PANE}>
          <div>
            <img
              src={image?.url}
              alt="property"
              height={'100%'}
              width={'100%'}
              style={{ border: '4px solid white', visibility: showOverlay ? 'visible' : 'hidden' }}
            />
          </div>
        </OverlayViewF>
      );
    }

    return null;
  };

  const renderInstruments = () => {
    return instrumentTools.map(tool => {
      const { key, icon: Icon, name, onClick, isDisabled } = tool;
      const isSelected = selectedInstrument?.key === key;

      return (
        <CustomTooltip
          key={key}
          id={key}
          place="bottom"
          wrapperClassName="instrument-tooltip-wrapper"
          delayShow={200}
          content={<span className="inter-400-text font-12">{name}</span>}>
          <IconContainer
            key={key}
            Icon={Icon}
            iconColor={isDisabled ? 'natural_300' : isSelected ? 'primary_500' : 'natural_500'}
            backgroundColor="natural_100"
            iconWidth={18}
            iconHeight={18}
            onClick={() => onClick(tool)}
            iconContainerClassname={classNames('cursor px-1_5 py-1_5', isDisabled && 'opacity-50 pointer-events-none')}
          />
        </CustomTooltip>
      );
    });
  };

  const saveGridAndPanels = async (
    segment,
    panelPolygons,
    updatePanels = true,
    orientation = 'PORTRAIT',
    panelDetails = null,
  ) => {
    const { id: segmentId } = segment;
    const {
      width: customWidth,
      height: customHeight,
      capacity: customCapacity,
    } = panelDetails || { width, height, boundaryGap, capacity };
    const request = panelPolygons.map((panel, index) => {
      const center = turf.center(panel).geometry.coordinates;
      const properties = panel.properties || {};

      return {
        id: uuid(),
        center: {
          lat: center[1],
          lon: center[0],
        },
        row: properties.row >= 0 ? properties.row : null,
        column: properties.column >= 0 ? properties.column : null,
        capacity: customCapacity,
        orientation: orientation,
        width: customWidth,
        height: customHeight,
        yearly_energy: null,
      };
    });

    setSavingGridAndPanels(true);

    const data = await dispatch(
      createSegmentPanels({
        id: selectedDesign.id,
        roof_id: segmentId,
        request,
        params: {
          source: 'MANUAL',
        },
      }),
    )
      .then(data => {
        if (updatePanels) {
          const roofPanelsWithoutCurrentSegment = roofPanels.filter(panel => panel.roof.id !== segmentId);
          setRoofPanels([...roofPanelsWithoutCurrentSegment, ...data]);
          dispatch(setRoofPanelInformation([...roofPanelsWithoutCurrentSegment, ...data]));
        }
        setSavingGridAndPanels(false);
        dispatch(addToast({ error: false, text: t('PANELS_SAVED_SUCCESSFULLY') }));
        return data;
      })
      .catch(() => {
        setSavingGridAndPanels(false);
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_SAVING_PANELS') }));
        return [];
      });

    return data;
  };

  const onAddNewSegment = (roof_id, gridDataAsParent) => {
    const boundraryOfPolygon = newRoofPolygon
      .getPath()
      .getArray()
      .map(path => ({
        lat: path.lat(),
        lon: path.lng(),
        lng: path.lng(),
      }));
    const centerOfRoof = getPolygonCenter(boundraryOfPolygon);
    const area = google.maps.geometry.spherical.computeArea(
      boundraryOfPolygon.map(bound => new google.maps.LatLng(bound)),
    );
    const boundary = [...boundraryOfPolygon, boundraryOfPolygon[0]];
    let gridData = gridDataAsParent;
    if (!gridDataAsParent) {
      const landscapeGridData = drawGridOnSegment(
        null,
        { boundary: boundary, pitch: 0, orientation: 'LANDSCAPE' },
        [],
        true,
      );
      const portraitGridData = drawGridOnSegment(
        null,
        { boundary: boundary, pitch: 0, orientation: 'PORTRAIT' },
        [],
        true,
      );

      const { panelPolygons: landscapePanelPolygons = [] } = landscapeGridData || {};
      const { panelPolygons: portraitPanelPolygons = [] } = portraitGridData || {};
      if (!landscapePanelPolygons.length && !portraitPanelPolygons.length) {
        dispatch(addToast({ error: true, text: 'Boundary is too small to create panels' }));
        return;
      }

      gridData = landscapePanelPolygons.length > portraitPanelPolygons.length ? landscapeGridData : portraitGridData;
    }

    const { innerPolygon = [], panelPolygons = [], angle, firstPoint, secondPoint, orientation } = gridData || {};
    const newPanelPolygons = panelPolygons;
    let angleForSegment = parseFloat(angle < 0 ? 360 + angle : angle).toFixed(2);

    const request = {
      center: { lat: centerOfRoof.lat, lon: centerOfRoof.lng },
      boundary: boundary,
      azimuth: angleForSegment,
      grid_alignment: angleForSegment,
      pitch: 0,
      area: area,
      parent: {
        id: roof_id ? roof_id : addRoofSegmentForRoof,
      },
      panel_grid: innerPolygon?.map(path => ({ lat: path.lat, lon: path.lng })),
    };
    const previousSegments = segments.map(segment => segment.id);
    setChangingSegmentFormat(true);
    dispatch(addRoofInformation({ id: selectedDesign.id, request, forFetchOnly: true }))
      .then(async data => {
        const { segments: roofSegments } = data;
        const segment = roofSegments.find(segment => !previousSegments.includes(segment.id));
        await saveGridAndPanels(segment, newPanelPolygons, true, orientation || 'PORTRAIT');
        dispatch(
          updateRoofInformation({
            data: {
              ...data,
              segments: data.segments?.map(s =>
                s.id === segment.id
                  ? { ...segment, manual: { ...segment.manual, panels_count: newPanelPolygons.length } }
                  : s,
              ),
            },
            whole_roof_id: data.whole_roof.id,
          }),
        );
        onCancelDrawRoof();
        setAddRoofSegmentForRoof(null);
        dispatch(addToast({ error: false, text: t('ROOF_SAVED_SUCCESSFULLY') }));
        setChangingSegmentFormat(false);
        setTimeout(() => onSegmentClick(segment.id), 500); //  important to have setTimeout here.
      })
      .catch(error => {
        console.log('error', error);
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_SAVING_NEW_ROOF') }));
        setChangingSegmentFormat(false);
      });
  };

  const onCancelDrawRoof = () => {
    newRoofPolygon?.setMap(null);
    setNewRoofPolygon(null);
  };

  const onSaveDrawRoof = () => {
    if (addRoofSegmentForRoof) {
      onAddNewSegment();
    } else {
      const centerOfRoof = getPolygonCenter(
        newRoofPolygon
          .getPath()
          .getArray()
          .map(path => ({ lat: path.lat(), lng: path.lng() })),
      );
      const boundraryOfPolygon = newRoofPolygon
        .getPath()
        .getArray()
        .map(path => ({
          lat: path.lat(),
          lon: path.lng(),
          lng: path.lng(),
        }));
      const boundary = [...boundraryOfPolygon, boundraryOfPolygon[0]];
      const area = google.maps.geometry.spherical.computeArea(
        boundraryOfPolygon.map(bound => new google.maps.LatLng(bound)),
      );

      const landscapeGridData = drawGridOnSegment(
        null,
        { boundary: boundary, pitch: 0, orientation: 'LANDSCAPE' },
        [],
        true,
      );
      const portraitGridData = drawGridOnSegment(
        null,
        { boundary: boundary, pitch: 0, orientation: 'PORTRAIT' },
        [],
        true,
      );

      const { panelPolygons: landscapePanelPolygons = [] } = landscapeGridData || {};
      const { panelPolygons: portraitPanelPolygons = [] } = portraitGridData || {};
      if (!landscapePanelPolygons.length && !portraitPanelPolygons.length) {
        dispatch(addToast({ error: true, text: 'Boundary is too small to create panels' }));
        return;
      }

      const gridData =
        landscapePanelPolygons.length > portraitPanelPolygons.length ? landscapeGridData : portraitGridData;

      const request = {
        center: { lat: centerOfRoof.lat, lon: centerOfRoof.lng },
        boundary: boundary,
        area: area,
        pitch: 0,
        azimuth: 0,
      };
      setSavingRoof(true);
      dispatch(importRoofInformation({ id: selectedDesign.id, request }))
        .then(data => {
          const parent = data.whole_roof.id;
          onAddNewSegment(parent, gridData);
          setSavingRoof(false);
        })
        .catch(() => {
          setSavingRoof(false);
          dispatch(addToast({ error: true, text: t('ERROR_WHILE_SAVING_NEW_ROOF') }));
        });
    }
  };

  const onRedrawRoof = () => {
    onCancelDrawRoof();
    if (addRoofSegmentForRoof) {
      return;
    }
    setSelectedInstrument(instrumentTools.find(tool => tool.key === 'ADD_ROOF'));
  };

  const onContinueToSystemOverview = async () => {
    if (solarDetails.length === 0) {
      dispatch(addToast({ error: true, text: 'No solar design found' }));
    } else {
      const designId = selectedDesign?.id;
      await fetchSolarOptionsAndCreateQuote(true);
      navigate(`system-overview/${designId}`);
    }
  };

  const initWebGLOverlay = () => {
    const webGLOverlay = new google.maps.WebGLOverlayView();
    webGLOverlay.onAdd = () => {
      // Add your WebGL initialization code here
      // console.log('WebGL overlay added');
    };
    webGLOverlay.onContextRestored = ({ gl }) => {
      // Setup WebGL context here
      // console.log('WebGL onContextRestored');
    };
    webGLOverlay.onDraw = ({ gl, transformer }) => {
      // Add your WebGL drawing code here
      // console.log('WebGL onDraw');
    };
    webGLOverlay.setMap(map);
  };

  const renderGridAndPanels = () => {
    const zoom = map.getZoom();

    const manualSegmentsToRender = segments.filter(segment => segment.panel_grid?.length > 0);

    return manualSegmentsToRender.map(segment => {
      const boundary = getRoofDetailsFromManualOrImported(segment, 'boundary', false, false, false);
      const pitch = getRoofDetailsFromManualOrImported(segment, 'pitch', false, false, false);
      const azimuth = getRoofDetailsFromManualOrImported(segment, 'azimuth', true, false, false);
      const grid_alignment = getRoofDetailsFromManualOrImported(segment, 'grid_alignment', true, false, false);
      const panelSegments = roofPanels.filter(panel => panel.roof.id === segment.id);
      const activePanelIds = panelSegments?.map(panel => panel.id) || [];
      const currentlyDraggingSegment = draggingSegment === segment.id;
      const isActiveSegment = selectedSegments.includes(segment.id);

      const data = drawGridOnSegment(
        segment?.id,
        { boundary, pitch, alignment_line: segment.alignment_line, azimuth, grid_alignment },
        panelSegments,
      );

      const { segmentId, innerPolygon, gridPolygons } = data || {
        segmentId: null,
        innerPolygon: null,
        gridPolygons: [],
      };

      return (
        <Fragment key={segmentId}>
          {innerPolygon && (
            <GridPolygon
              polygonId={`inner-polygon-${segmentId}`}
              path={innerPolygon}
              options={{
                map: map,
                strokeColor: theme.primary_500,
                strokeWeight: 1,
                fillColor: theme.primary_500,
                fillOpacity: 0.3,
                clickable: false,
                zIndex: 900,
                visible: !currentlyDraggingSegment,
              }}
            />
          )}
          {gridPolygons.map((polygon, index) => {
            const properties = polygon.properties || {};
            const paths = polygon.geometry.coordinates[0].map(path => ({
              lat: path[1],
              lng: path[0],
            }));
            const center = turf.center(polygon).geometry.coordinates;
            const gridCenter = {
              lat: center[1],
              lon: center[0],
            };
            const panelId = polygon?.id;
            const isPanelAvailable = activePanelIds.includes(panelId);
            const isPanelSelected = selectedPanels.includes(panelId);
            const isPanelToBeDeleted = panelsToDelete.includes(panelId);
            const isPanelToDrag = isAllPanelSelectedOfSegment === segmentId;
            const polygonId = `${segmentId}-${index}`;

            if (!segmentGrids.current[segmentId]) {
              segmentGrids.current[segmentId] = {};
            }
            segmentGrids.current[segmentId][polygonId] = paths;

            return (
              <MarkerWrapper
                polygonId={polygonId}
                key={polygonId}
                paths={paths}
                onLoadPanel={polygon => {
                  panelRefs.current[panelId] = polygon;
                  if (!segmentGridRefs.current[segmentId]) {
                    segmentGridRefs.current[segmentId] = {};
                  }
                  segmentGridRefs.current[segmentId][polygonId] = polygon;
                }}
                onUnmountPanel={previousId => {
                  panelRefs.current[previousId] = null;
                  delete segmentGridRefs.current[segmentId][previousId];
                  delete segmentGrids.current[segmentId][previousId];
                }}
                onPolygonMouseDown={(polygon, polygonId) => {
                  isPanelAvailable
                    ? onPanelMouseDown(polygon, panelId, segmentId, true)
                    : onAddPanelMouseDown({ center: gridCenter, ...properties }, panelId || uuid(), segmentId);
                }}
                onPolygonMouseUp={(polygon, polygonId) => {
                  isPanelAvailable ? onMouseUp() : onMouseUp();
                }}
                onPolygonMouseOver={(polygon, polygonId) => {
                  isPanelAvailable
                    ? onPanelMouseOver(polygon, panelId, segmentId, true)
                    : onAddPanelMouseOver({ center: gridCenter, ...properties }, panelId || uuid(), segmentId);
                }}
                options={{
                  map: map,
                  strokeWeight: isPanelAvailable ? (zoom >= 20 ? 1 : zoom >= 19 ? 0.5 : zoom >= 18 ? 0.3 : 0.3) : 0.6,
                  strokePosition: google.maps.StrokePosition.INSIDE,
                  fillOpacity: isPanelAvailable ? (showPanels ? 0.9 : 0.3) : 0.1,
                  fillColor: isPanelAvailable
                    ? isPanelToBeDeleted
                      ? '#ab1c20'
                      : theme.natural_900
                    : theme.natural_900,
                  strokeColor: isPanelAvailable
                    ? isPanelToDrag || isPanelSelected
                      ? theme.primary_500
                      : theme.white
                    : theme.natural_900,
                  strokeOpacity: 1,
                  geodesic: false,
                  zIndex: 1002,
                  draggable: false,
                  visible: isPanelAvailable ? true : isActiveSegment,
                }}
              />
            );
          })}
        </Fragment>
      );
    });
  };

  const onTakePhoto = () => {
    setModal({
      type: 'take-photo',
      content: {
        engagement_id: engagement_id,
        designId: designId,
        selectedSnapshot: selectedSnapshot,
        onSuccess: () => {
          navigate(-1);
        },
      },
    });
  };

  return (
    <Fragment>
      {!takePhoto && (
        <Fragment>
          {fetchSolarDetails && (
            <RightActionIconWrapper
              top={'8px'}
              right={'48px'}
              padding={'8px'}
              as={'button'}
              className="bg-white radius-2 cursor border-0 flex items-center justify-center pxy-2"
              onClick={onContinueToSystemOverview}>
              <label className="inter-500-text font-12 primary-500-text mr-1">Continue to system overview</label>
              <RightArrowIcon width={14} height={14} className="primary-500-text" />
            </RightActionIconWrapper>
          )}
          <RightActionIconWrapper
            top={'8px'}
            as={'button'}
            className="bg-white radius-2 cursor border-0 flex items-center justify-center"
            onClick={() => navigate(-1)}>
            <CustomTooltip
              id={'back'}
              place="left"
              wrapperClassName="tooltip-wrapper pxy-2"
              content={<span className="inter-400-text font-12">Back</span>}>
              <CrossIcon width={16} height={16} className="primary-500-text" />
            </CustomTooltip>
          </RightActionIconWrapper>
          <RightActionIconWrapper
            top={'48px'}
            as={'button'}
            className="bg-white radius-2 cursor border-0 flex items-center justify-center"
            onClick={e => setShowPanels(!showPanels)}>
            <CustomTooltip
              id={'toggle-panels'}
              place="left"
              wrapperClassName="tooltip-wrapper pxy-2"
              content={<span className="inter-400-text font-12">Toggle panels</span>}>
              <SolarPanelIcon
                width={16}
                height={16}
                className={classNames(showPanels ? 'primary-500-text' : 'natural-500-text')}
              />
            </CustomTooltip>
          </RightActionIconWrapper>
          <RightActionIconWrapper
            top={'88px'}
            as={'button'}
            className="bg-white radius-2 cursor border-0 flex items-center justify-center"
            onClick={e => setShowOverlay(!showOverlay)}>
            <CustomTooltip
              id={'toggle-overlay'}
              place="left"
              wrapperClassName="tooltip-wrapper pxy-2"
              content={<span className="inter-400-text font-12">Toggle overlay</span>}>
              <PlatformIcon
                width={16}
                height={16}
                className={classNames(showOverlay ? 'primary-500-text' : 'natural-500-text')}
              />
            </CustomTooltip>
          </RightActionIconWrapper>
          <RightActionIconWrapper
            top={'none'}
            bottom={'24px'}
            right={'60px'}
            as={'div'}
            padding={'8px'}
            className="flex-column bg-white radius-2 border-0 flex items-center justify-center">
            <div className="flex items-center grid-layout">
              <label className="inter-500-text">Compass: {directionValues.compass}</label>
              <input
                type="range"
                min="0"
                max="360"
                step="1"
                value={directionValues.compass}
                className="compass-range"
                onChange={({ target: { value } }) => {
                  map.setHeading(parseFloat(value));
                  setDirectionValues({ ...directionValues, compass: value });
                }}
              />
            </div>
            <div className="flex items-center grid-layout">
              <label className="inter-500-text">Tilt: {directionValues.tilt}</label>
              <input
                type="range"
                min="0"
                max="67"
                step="1"
                value={directionValues.tilt}
                className="tilt-range"
                onChange={({ target: { value } }) => {
                  map.setTilt(parseFloat(value));
                  setDirectionValues({ ...directionValues, tilt: value });
                }}
              />
            </div>
          </RightActionIconWrapper>
          {newRoofPolygon && (
            <NewRoofWrapper className="flex items-center col-gap-3">
              <Button
                size="medium"
                borderRadius="6px"
                label="Cancel"
                afterIcon={<CrossIcon width={14} height={14} className="primary-500-text" />}
                className="primary-white px-3 specified-width"
                width="fit-content"
                onClick={onCancelDrawRoof}
                disabled={savingRoof}
              />
              <Button
                size="medium"
                borderRadius="6px"
                label="Save"
                afterIcon={<RightIcon className="primary-500-text" />}
                className="primary-white px-3 specified-width"
                width="fit-content"
                onClick={onSaveDrawRoof}
                loading={savingRoof}
              />
              <Button
                size="medium"
                borderRadius="6px"
                label="Re-draw"
                afterIcon={<EditPensilIcon width={14} height={14} className="primary-500-text" />}
                className="primary-white px-3 specified-width"
                width="fit-content"
                onClick={onRedrawRoof}
                disabled={savingRoof}
              />
            </NewRoofWrapper>
          )}
          {usageEditorOpened && selectedTab === 'DESIGN' && (
            <EnergyUsageEditor
              energyUsage={energyUsage}
              setEnergyUsage={setEnergyUsage}
              setUsageEditorOpened={setUsageEditorOpened}
            />
          )}
          {changingSegmentFormat && (
            <LoaderWrapper className="flex items-center justify-center">
              <Loader size={20} />
            </LoaderWrapper>
          )}
          <RoofEditWrapper className="absolute">
            {segments && roofPanels && map ? (
              <div className="flex-column data-wrapper bg-white radius-2">
                {renderSegmentPolygons()}
                {renderPanelPolygons()}
                {renderGroundOverlay()}
                {renderGridAndPanels()}
                {initWebGLOverlay()}
                <DrawingManagerF
                  key={'drawing-manager'}
                  drawingMode={isRoofAdd ? 'polygon' : null}
                  options={{
                    drawingControl: false,
                    polygonOptions: {
                      fillColor: `#2196F3`,
                      strokeColor: `#2196F3`,
                      fillOpacity: 0.5,
                      strokeWeight: 2,
                      clickable: true,
                      editable: true,
                      draggable: true,
                      zIndex: 100,
                    },
                  }}
                  onPolygonComplete={poly => {
                    setNewRoofPolygon(poly);
                    setSelectedInstrument(instrumentTools.find(tool => tool.key === 'HAND'));
                  }}
                />
                {/* <SystemDesign
                  fetchSolarDetails={fetchSolarDetails}
                  solarDetails={solarDetails}
                  selectedDesign={selectedDesign}
                  setSelectedDesign={setSelectedDesign}
                  panel_count={totalPanels}
                  size={currentPanelEnergy}
                  output={currentPanelOutput}
                /> */}
                <div className="pxy-2 flex items-center">
                  {tabs.map(({ key, name }) => (
                    <div
                      key={key}
                      onClick={() => setSelectedTab(key)}
                      className={classNames(
                        'pxy-2 flex-1 flex items-center justify-center cursor',
                        selectedTab === key ? 'border-bottom-primary' : 'border-bottom',
                      )}>
                      <label
                        className={classNames(
                          'inter-600-text',
                          selectedTab === key ? 'primary-500-text' : 'natural-700-text',
                        )}>
                        {name}
                      </label>
                    </div>
                  ))}
                </div>
                {selectedTab === 'DESIGN' && (
                  <Fragment>
                    <div className="px-4 pb-5 pt-3 flex items-center flex-1 col-gap-2">
                      <div className="flex w-fit-content items-center col-gap-1 bg-natural-50 radius-3 py-2 px-3">
                        <SolarPanelIcon width={32} height={32} className="primary-500-text" />
                        <div className="flex-column">
                          <label className="inter-600-text natural-500-text font-12">Panels</label>
                          <label className="inter-700-text font-16">{totalPanels}</label>
                        </div>
                      </div>
                      <div className="flex flex-1 items-center col-gap-2 bg-natural-50 radius-3 py-2 px-3">
                        <SolarSizeIcon width={32} height={32} className="orange-500-text" />
                        <div className="flex-column">
                          <label className="inter-600-text natural-500-text font-12 flex item-center col-gap-1">
                            Size
                            <span className="inter-600-text natural-500-text font-12">kW</span>
                          </label>
                          <label className="inter-700-text font-16 flex items-center">
                            {`${getFormattedNumberStyle(currentPanelEnergy || 0, 'decimal', 2, 2)}`}
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-1 items-center col-gap-2 bg-natural-50 radius-3 py-2 px-3">
                        <EnergyLeafIcon width={32} height={32} className="success-500-text" />
                        <div className="flex-column">
                          <label className="inter-600-text natural-500-text font-12 flex item-center col-gap-1">
                            Output
                            <span className="inter-600-text natural-500-text font-12">kWh</span>
                          </label>
                          <label className="inter-700-text font-16 flex items-center">
                            {`${getFormattedNumberStyle(currentPanelOutput || 0, 'decimal', 0, 0)}`}
                          </label>
                        </div>
                      </div>
                    </div>
                    <SolarEnergyUsage
                      usageEditorOpened={usageEditorOpened}
                      setUsageEditorOpened={setUsageEditorOpened}
                      setEnergyUsage={setEnergyUsage}
                      energyUsage={energyUsage}
                      systemPanelOutput={currentPanelOutput}
                    />
                    <PanelDetails panelDetails={panelDetails} />
                    <div className="flex items-center justify-evenly border-top px-3 py-4">{renderInstruments()}</div>
                    <div
                      className="px-4 py-4 border-top flex-column row-gap-4 overflow-scroll custom-scrollbar thin-scrollbar"
                      ref={roofListRef}>
                      {roofInformation?.map((info, index) => (
                        <div key={info.id} className="flex-column row-gap-4">
                          <div className="flex items-center">
                            <label className="inter-600-text flex-1">ROOF {index + 1}</label>
                            <Button
                              icon={<AddIcon className="primary-500-text" />}
                              label="Add section"
                              width="120px"
                              className="secondary specified-width mr-4"
                              onClick={() => {
                                if (info?.whole_roof?.id === addRoofSegmentForRoof) {
                                  setAddRoofSegmentForRoof(null);
                                } else {
                                  setAddRoofSegmentForRoof(info?.whole_roof?.id);
                                }
                                setSelectedInstrument(null);
                                setNewRoofPolygon(null);
                              }}
                            />
                            <CustomTooltip
                              key={'delete-roof-tooltip'}
                              id={'delete-roof-tooltip'}
                              place="bottom"
                              wrapperClassName="instrument-tooltip-wrapper"
                              delayShow={200}
                              content={<span className="inter-400-text font-12">{'Delete roof'}</span>}>
                              <IconContainer
                                Icon={DeleteIcon}
                                backgroundColor="primary_50"
                                iconColor="primary_500"
                                iconContainerClassname="radius-50-percent cursor mr-4"
                                onClick={() => {
                                  onDeleteRoofSegment(info?.whole_roof?.id, index);
                                }}
                              />
                            </CustomTooltip>
                            <IconContainer
                              Icon={RightArrowIcon}
                              iconContainerClassname={classNames(
                                'radius-50-percent cursor',
                                collapsedRoofs[info?.whole_roof?.id] ? 'rotate-0' : 'rotate-90',
                              )}
                              onClick={() => {
                                setCollapsedRoofs({
                                  ...collapsedRoofs,
                                  [info?.whole_roof?.id]: !collapsedRoofs[info?.whole_roof?.id],
                                });
                              }}
                            />
                          </div>
                          {!collapsedRoofs[info?.whole_roof?.id] && renderRoofSegment(info)}
                        </div>
                      ))}
                    </div>
                  </Fragment>
                )}
                {selectedTab === 'PRODUCTS' && (
                  <DesignProducts
                    fetchSolarDetails={fetchSolarDetails}
                    selectedDesign={selectedDesign}
                    setSelectedDesign={setSelectedDesign}
                    panel_count={totalPanels}
                    engagement_id={engagement_id}
                    property_id={property_id}
                    fetchSolarDesignData={fetchSolarDesignData}
                    fetchSolarDesignAdditionalData={fetchSolarDesignAdditionalData}
                    onSwapPanels={onSwapPanels}
                    energyUsage={energyUsage}
                  />
                )}
              </div>
            ) : null}
          </RoofEditWrapper>
        </Fragment>
      )}
      {takePhoto && (
        <Fragment>
          <RightActionIconWrapper
            top={'8px'}
            right={'none'}
            left={'4px'}
            padding={'0px'}
            className="flex items-center justify-center col-gap-2">
            <SearchableDropdown
              placeholder="Select snapshot"
              defaultAdditional={{
                defaultOptions: snapshotOptions,
              }}
              isCustomSearchable={false}
              value={selectedSnapshot}
              onChange={value => setSelectedSnapshot(value)}
              customStyle={{
                control: {
                  height: '32px',
                  minHeight: '32px',
                  width: '240px',
                },
                menuList: {
                  minHeight: '200px',
                },
              }}
            />
            <CustomTooltip
              id={'toggle-overlay'}
              place="right"
              wrapperClickable={true}
              onWrapperClick={onTakePhoto}
              wrapperClassName="tooltip-wrapper pxy-1 bg-white radius-2 cursor border-0"
              content={<span className="inter-400-text font-12">Click to take photo</span>}>
              <SnapshotIcon width={24} height={24} />
            </CustomTooltip>
          </RightActionIconWrapper>
          <RightActionIconWrapper
            top={'8px'}
            right={'8px'}
            padding={'6px 16px'}
            onClick={() => navigate(-1)}
            className="bg-white radius-2 cursor px-2 py-1 radius-3 flex items-center justify-center">
            <label className="inter-500-text font-12">Cancel</label>
          </RightActionIconWrapper>
          <RightActionIconWrapper
            top={'none'}
            bottom={'24px'}
            right={'60px'}
            as={'div'}
            padding={'8px'}
            className="flex-column bg-white radius-2 border-0 flex items-center justify-center">
            <div className="flex items-center grid-layout">
              <label className="inter-500-text">Compass: {directionValues.compass}</label>
              <input
                type="range"
                min="0"
                max="360"
                step="1"
                value={directionValues.compass}
                className="compass-range"
                onChange={({ target: { value } }) => {
                  map.setHeading(parseFloat(value));
                  setDirectionValues({ ...directionValues, compass: value });
                }}
              />
            </div>
            <div className="flex items-center grid-layout">
              <label className="inter-500-text">Tilt: {directionValues.tilt}</label>
              <input
                type="range"
                min="0"
                max="67"
                step="1"
                value={directionValues.tilt}
                className="tilt-range"
                onChange={({ target: { value } }) => {
                  map.setTilt(parseFloat(value));
                  setDirectionValues({ ...directionValues, tilt: value });
                }}
              />
            </div>
          </RightActionIconWrapper>
          {segments && roofPanels && map ? (
            <Fragment>
              {renderPanelPolygons()}
              {renderGridAndPanels(false)}
              {/* {renderOverlayForImage()} */}
              {initWebGLOverlay()}
            </Fragment>
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
};

const RightActionIconWrapper = styled.div`
  position: absolute;
  right: ${({ right }) => right || '8px'};
  padding: ${({ padding }) => padding || '0px'};
  top: ${({ top }) => top || '8px'};
  bottom: ${({ bottom }) => bottom || 'none'};
  left: ${({ left }) => left || 'none'};

  .tooltip-wrapper {
    display: flex;
    .custom-tooltip {
      padding: 6px 12px;
      filter: drop-shadow(0px 3px 3px #0000001f);
    }
  }

  .grid-layout {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
`;

const NewRoofWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 420px;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.6);
`;

const RoofEditWrapper = styled.div`
  left: 8px;
  top: 8px;
  overflow: hidden;
  height: calc(100% - 16px);
  overflow: hidden;

  .data-wrapper {
    min-width: 396px;
    max-width: 396px;
    height: fit-content;
    max-height: 100%;
    overflow: hidden;
  }

  .dot {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.natural_300};
  }

  .segment-detail-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .selected-segment {
    border: 1px solid ${({ theme }) => theme.focus_border};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
    .dot {
      background-color: ${({ theme }) => theme.primary_500};
    }
  }

  .panel-details {
    .input {
      height: 24px;
      padding: 4px;
      width: 60px;
      font-size: 12px;
      &:focus {
        box-shadow: none;
        border: 1px solid ${({ theme }) => theme.primary_500};
      }
    }
  }

  .input-pitch,
  .input-azimuth {
    .input-element {
      &:focus {
        box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.focus_border};
      }
    }
  }

  .instrument-tooltip-wrapper {
    .custom-tooltip {
      padding: 6px 12px;
      filter: drop-shadow(0px 3px 3px #0000001f);
    }
  }

  .segment-tooltip-wrapper {
    .custom-tooltip {
      padding: 6px 12px;
      filter: drop-shadow(0px 3px 3px #0000000d);
    }
  }

  .grid-ai-container {
    padding: 2px;
  }
`;

const PanelDetailsWrapper = styled.div`
  .panel-image-wrapper {
    padding: 4px;

    .panel-image {
      width: 32px;
      height: 52px;
      object-fit: cover;
    }
  }
`;

export default RoofEditV2;
